import { useState } from "react";
import IconButton from "./IconButton";
import { ConfirmAlert, SimpleAlert } from "../Alerts/Alerts";
import {
  ADS_CAMPAIGN_HAS_BEEN_DUPLICATE,
  ALERT_DUPLICATE_CAMPAIGN_DESCRIPTION,
  ALERT_DUPLICATE_CAMPAIGN_TITLE,
  BUTTON_DUPLICATE,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import {
  ALERT_ICON_TYPE_INFO,
  ALERT_ICON_TYPE_SUCCESS,
} from "../../utils/constants";
import { useDispatchApp } from "../../lib/redux";
import { startDuplicateAdsCampaign } from "../../actions/adsCampaigns";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Loader from "../Loaders/Loader";

const ButtonDuplicateCampaign = ({
  campaign,
  sx = {},
  onCallbackDuplicate = () => {},
  mode = "buttonIcon",
}) => {
  const [isDuplicating, setIsDuplicating] = useState(false);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();

  const onDuplicateCampaign = async (e) => {
    e?.stopPropagation();
    const result = await ConfirmAlert({
      title: t(ALERT_DUPLICATE_CAMPAIGN_TITLE),
      text: t(ALERT_DUPLICATE_CAMPAIGN_DESCRIPTION),
      icon: ALERT_ICON_TYPE_INFO,
      confirmButtonText: t(BUTTON_DUPLICATE),
    });

    if (!result.isConfirmed) return;

    setIsDuplicating(true);
    const response = await dispatch(
      startDuplicateAdsCampaign({
        campaign,
      })
    );

    if (response.ok) {
      const campaignID = response.data.campaignID;
      SimpleAlert({
        title: t(ADS_CAMPAIGN_HAS_BEEN_DUPLICATE),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      navigate(`/ads-campaign?campaignID=${campaignID}`);
      onCallbackDuplicate(campaignID);
    }

    setIsDuplicating(false);
  };

  if (mode === "icon") {
    return (
      <>
        {isDuplicating ? (
          <Loader size={20} hasMarginTop={false} />
        ) : (
          <ContentCopyIcon sx={sx} onClick={onDuplicateCampaign} />
        )}
      </>
    );
  }

  return (
    <IconButton
      loading={isDuplicating}
      onClick={onDuplicateCampaign}
      tooltipText={BUTTON_DUPLICATE}
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <ContentCopyIcon sx={sx} />
    </IconButton>
  );
};

export default ButtonDuplicateCampaign;
