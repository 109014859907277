import { useState, useEffect } from "react";
import _ from "lodash";
import { GOOGLE } from "../../../utils/constants";
import {
  startDeleteAdsContentUploaded,
  startListenerAdsContentsUploaded,
  startUpdateImageUrlAssetGroup,
  startUpdateMediaUrlAd,
  startUpdateImageUrlPlatformData,
} from "../../../actions/adsCampaigns";
import { useDispatchApp } from "../../../lib/redux";
import { off } from "firebase/database";

export function useUploadAsyncContentInCampaign({
  campaignID,
  setValue,
  getValues,
  watch,
}) {
  const [adsContentsUploadedListener, setAdsContentsUploadedListener] =
    useState(null);
  const [dataNotProcessed, setDataNotProcessed] = useState({});

  const platforms = watch("platforms");
  const dispatch = useDispatchApp();

  const onUpdatedAdsContentsUploaded = (data = {}) => {
    if (!data) return;
    if (typeof data === "string") return;

    const platforms = getValues("platforms");
    if (_.isEmpty(platforms)) {
      setDataNotProcessed((prevData) => ({ ...prevData, ...data }));
      return;
    }

    const hasGooglePlatform = platforms.includes("google");

    const dataKeys = Object.keys(data || {});
    const dataToSearch = {};

    dataKeys.forEach((key) => {
      const content = data[key];
      dataToSearch[content.preview] = { ...content, id: key };
    });

    const adsGroups = getValues("adsGroups") || [];
    const assetsGroups = getValues("assetsGroups") || [];

    adsGroups.forEach((adGroup, indexAdGroup) => {
      adGroup.ads.forEach((ad, indexAd) => {
        if (!ad.mediaUrls) return;
        ad.mediaUrls.forEach((mediaUrl, indexMediaUrl) => {
          const content = dataToSearch[mediaUrl];
          if (!content) {
            return;
          }

          onUpdateMediaUrlAndRemovePreviewAd({
            indexAdGroup,
            indexAd,
            indexMediaUrl,
            mediaUrl: content.value,
            contentID: content.id,
          });
        });
      });
    });

    assetsGroups.forEach((assetsGroup, indexAssetsGroup) => {
      (assetsGroup.imageUrls || []).forEach((imageUrl, indexImageUrl) => {
        const content = dataToSearch[imageUrl];
        if (!content) {
          return;
        }

        onUpdateImageUrlAndRemovePreviewAssetGroup({
          indexAssetsGroup,
          indexImageUrl,
          imageUrl: content.value,
          contentID: content.id,
        });
      });
    });

    if (hasGooglePlatform) {
      const platformData = getValues(`platformsData.${GOOGLE}`);
      if (platformData?.imageUrls) {
        platformData.imageUrls.forEach((imageUrl, indexImageUrl) => {
          const content = dataToSearch[imageUrl];
          if (!content) return;

          onUpdateImageUrlAndRemovePreviewInPlatformData({
            platform: GOOGLE,
            indexImageUrl,
            imageUrl: content.value,
            contentID: content.id,
          });
        });
      }
    }
  };

  const onUpdateImageUrlAndRemovePreviewInPlatformData = async ({
    platform,
    indexImageUrl,
    imageUrl,
    contentID,
  }) => {
    const responseUpdateImageUrl = await dispatch(
      startUpdateImageUrlPlatformData({
        campaignID,
        platform,
        imageUrl,
        index: indexImageUrl,
      })
    );
    if (!responseUpdateImageUrl) return;

    const responseDeleteAdsContentsUploaded = await dispatch(
      startDeleteAdsContentUploaded({
        campaignID,
        contentID,
      })
    );
    if (!responseDeleteAdsContentsUploaded) return;

    setValue(
      `platformsData.${platform}.imageUrls.[${indexImageUrl}]`,
      imageUrl
    );
  };
  const onUpdateImageUrlAndRemovePreviewAssetGroup = async ({
    indexAssetsGroup,
    indexImageUrl,
    imageUrl,
    contentID,
  }) => {
    const assetsGroup = getValues(`assetsGroups.[${indexAssetsGroup}]`);
    const assetsGroupID = assetsGroup.id;

    const responseUpdateImageUrl = await dispatch(
      startUpdateImageUrlAssetGroup({
        assetsGroupID,
        index: indexImageUrl,
        imageUrl,
      })
    );
    if (!responseUpdateImageUrl) return;

    const responseDeleteAdsContentsUploaded = await dispatch(
      startDeleteAdsContentUploaded({
        campaignID,
        contentID,
      })
    );
    if (!responseDeleteAdsContentsUploaded) return;

    setValue(
      `assetsGroups.[${indexAssetsGroup}].imageUrls.[${indexImageUrl}]`,
      imageUrl
    );
  };
  const onUpdateMediaUrlAndRemovePreviewAd = async ({
    indexAdGroup,
    indexAd,
    indexMediaUrl,
    mediaUrl,
    contentID,
  }) => {
    const ad = getValues(`adsGroups.[${indexAdGroup}].ads.[${indexAd}]`);
    const adID = ad.id;

    const responseUpdateMediaUrl = await dispatch(
      startUpdateMediaUrlAd({
        adID,
        mediaUrl,
        index: indexMediaUrl,
      })
    );
    if (!responseUpdateMediaUrl) return;

    const responseDeleteAdsContentsUploaded = await dispatch(
      startDeleteAdsContentUploaded({
        campaignID,
        contentID,
      })
    );
    if (!responseDeleteAdsContentsUploaded) return;

    setValue(
      `adsGroups.[${indexAdGroup}].ads.[${indexAd}].mediaUrls.[${indexMediaUrl}]`,
      mediaUrl
    );
  };

  useEffect(() => {
    if (!campaignID) return;

    dispatch(
      startListenerAdsContentsUploaded({
        campaignID,
        onReferenceAvailable: (listener) =>
          setAdsContentsUploadedListener(listener),
        onUpdatedDataCallback: (contents) =>
          onUpdatedAdsContentsUploaded(contents),
      })
    );

    return () => {
      if (adsContentsUploadedListener) {
        off(adsContentsUploadedListener);
      }
    };
    // eslint-disable-next-line
  }, [campaignID]);

  useEffect(() => {
    if (!campaignID) return;
    onUpdatedAdsContentsUploaded(dataNotProcessed);
    // eslint-disable-next-line
  }, [platforms]);
}
