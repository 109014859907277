import { useState } from "react";
import { useTranslationApp } from "../../../lib/i18next";
import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import KeywordsTableActions from "./KeywordsTableActions";
import TableContainer from "../../Tables/TableContainer";
import TableCell from "../../../pages/BillingAndPayments/components/TableCell";
import {
  ALERT_DELETE_KEYWORD_DESCRIPTION,
  ALERT_DELETE_KEYWORD_TITLE,
  ALERT_DELETE_KEYWORDS_DESCRIPTION,
  ALERT_DELETE_KEYWORDS_TITLE,
  BUTTON_DELETE,
  COLUMN_NAME_ACTIONS,
  COLUMN_NAME_KEYWORD,
  COLUMN_NAME_MATCH_TYPE,
  KEYWORDS_NEGATIVE_NOT_ADDED,
  KEYWORDS_NOT_ADDED,
  SELECTED,
} from "../../../i18n/keysTranslations";
import { optionsKeywordMatchType } from "../../../utils/options";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Button from "../../Buttons/Button";
import { ConfirmAlert } from "../../Alerts/Alerts";

const KeywordsTable = ({
  isNegativeKeywords,
  keywords = [],
  onEdit,
  onDelete,
  disabled = false,
  loading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const [selected, setSelected] = useState([]);
  const { t } = useTranslationApp();

  const onChangePage = (newPage) => {
    setPage(newPage);
  };

  const onSelectAllKeywords = (event) => {
    if (event.target.checked) {
      const newSelected = keywords.map((keyword) => keyword.original);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const onSelectKeyword = (original) => {
    const selectedIndex = selected.indexOf(original);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, original);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const onDeleteKeywords = async () => {
    const result = await ConfirmAlert({
      title: t(
        selected.length > 1
          ? ALERT_DELETE_KEYWORDS_TITLE
          : ALERT_DELETE_KEYWORD_TITLE
      ),
      text: t(
        selected.length > 1
          ? ALERT_DELETE_KEYWORDS_DESCRIPTION
          : ALERT_DELETE_KEYWORD_DESCRIPTION,
        {
          value: selected.length,
        }
      ),
    });
    if (!result.isConfirmed) return true;
    const response = await onDelete(selected);
    if (response) {
      setSelected([]);
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        page={page}
        loading={loading}
        hasMarginBottom={false}
        emptyText={
          isNegativeKeywords ? KEYWORDS_NEGATIVE_NOT_ADDED : KEYWORDS_NOT_ADDED
        }
        onPageChange={onChangePage}
        rowsPerPage={rowsPerPage}
        keys={keywords}
        sx={{ maxHeight: "calc(100vh - 400px)" }}
        customComponentBottomLeft={
          selected.length > 0 ? (
            <Typography variant="body1" sx={{ ml: 1 }}>
              {`${selected.length} ${t(SELECTED)}`}
            </Typography>
          ) : null
        }
      >
        <TableHead>
          <TableRow>
            {!disabled && (
              <TableCell isHeader={true} sx={{ width: 32 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < keywords.length
                    }
                    checked={
                      keywords.length > 0 && selected.length === keywords.length
                    }
                    onChange={onSelectAllKeywords}
                    sx={{
                      "&.MuiCheckbox-indeterminate": {
                        color: "white !important",
                      },
                    }}
                  />
                  {selected.length > 0 && (
                    <Button onClick={onDeleteKeywords}>
                      {t(BUTTON_DELETE)}
                    </Button>
                  )}
                </Box>
              </TableCell>
            )}
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_KEYWORD)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_MATCH_TYPE)}
            </TableCell>
            {!disabled && (
              <TableCell align="center" isHeader={true}>
                {t(COLUMN_NAME_ACTIONS)}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? keywords.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : keywords
          ).map((keyword, index) => (
            <TableRow
              key={keyword.original}
              selected={selected.indexOf(keyword.original) !== -1}
            >
              {!disabled && (
                <TableCell indexRow={index} sx={{ width: 32 }}>
                  <Checkbox
                    color="primary"
                    checked={selected.indexOf(keyword.original) !== -1}
                    onChange={() => onSelectKeyword(keyword.original)}
                  />
                </TableCell>
              )}
              <TableCell align="center" indexRow={index}>
                {keyword.original}
              </TableCell>
              <TableCell align="center" indexRow={index}>
                {t(
                  optionsKeywordMatchType.find(
                    (option) => option.value === keyword.matchType
                  )?.label
                )}
              </TableCell>
              {!disabled && (
                <TableCell align="center" indexRow={index}>
                  <KeywordsTableActions
                    keyword={keyword}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    disabled={disabled}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default KeywordsTable;
