import { z } from "zod";
import i18next from "i18next";
import { FIELD_REQUIRED } from "../../../../../i18n/keysTranslations";

export const metaCampaignSchema = z.object({
  objective: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  integrationID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  adAccountID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  pageID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  instagramID: z.string().min(1, i18next.t(FIELD_REQUIRED)),

  destinationType: z.string().optional(),
  whatsappBusinessNumber: z.any().optional(),
  messageTemplate: z.any().optional(),
  instantForm: z.any().optional(),

  trackingID: z.any().optional(),
  trackingCustomEventID: z.any().optional(),
});
