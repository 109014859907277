import { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslationApp } from "../../lib/i18next";
import LoadingButton from "./LoadingButton";
import IconButton from "@mui/material/IconButton";
import Loader from "../Loaders/Loader";
import Tooltip from "@mui/material/Tooltip";

const ButtonMenu = ({
  loading = false,
  options,
  textButton = "",
  variantButton = "contained",
  colorButton = "primary",
  fullWidth = false,
  sx = {},
  sxMenu = {},
  mode = "button",
  iconButton = null,
  tooltipText,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const onSelectMenu = (event) => {
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const { t } = useTranslationApp();

  return (
    <>
      {mode === "icon" && (
        <>
          {loading ? (
            <Loader size={20} />
          ) : (
            <Tooltip title={tooltipText}>
              <IconButton onClick={onSelectMenu} sx={sx}>
                {iconButton}
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      {mode === "button" ? (
        <LoadingButton
          loading={loading}
          fullWidth={fullWidth}
          variant={variantButton}
          color={colorButton}
          onClick={onSelectMenu}
          sx={sx}
        >
          {t(textButton)}
        </LoadingButton>
      ) : null}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={{ ...sxMenu }}
      >
        {options.map((option, index) => {
          if (option.customComponent)
            return <Box key={index}>{option.customComponent}</Box>;
          return (
            <MenuItem
              key={option.label}
              onClick={(e) => {
                e?.stopPropagation();
                option.onClick();
                setAnchorEl(null);
              }}
            >
              {option.icon ? option.icon : null} {t(option.label)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ButtonMenu;
