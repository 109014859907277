import { useController, useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import TextField from "../../../Form/TextField";
import {
  BUTTON_ADD_VIDEO,
  DO_NOT_UPLOAD_VIDEO_ON_YOUTUBE_HELPER_TEXT,
  YOUTUBE_VIDEO_LINK,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import Typography from "@mui/material/Typography";
import { openLink } from "../../../../utils/string";

const ContainerVideosYoutube = ({ name }) => {
  const { control, formState: { errors = {} } = {} } = useFormContext();
  const { t } = useTranslationApp();
  const {
    field: { value = [], onChange },
  } = useController({
    name: name,
    control,
  });
  const onAddVideo = () => {
    if (value.length >= 5) return;
    onChange([...value, ""]);
  };
  const onRemoveVideo = (index) => {
    const newVideos = value.filter((_, i) => i !== index);
    onChange(newVideos);
  };

  return (
    <Box
      sx={{
        mb: 2,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {value.length === 0 && (
        <Typography
          variant="body2"
          align="center"
          onClick={onAddVideo}
          sx={{
            cursor: "pointer",
          }}
        >
          <AddIcon />
          {t(BUTTON_ADD_VIDEO)}
        </Typography>
      )}
      {value.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <TextField
              label={`${t(YOUTUBE_VIDEO_LINK)} ${index + 1}`}
              variant="filled"
              fullWidth
              name={`${name}.${index}`}
              control={control}
              errors={errors}
            />
            <Typography
              variant="caption"
              color="fields.placeholder"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() =>
                openLink("https://studio.youtube.com/channel/videos?d=ud")
              }
            >
              {t(DO_NOT_UPLOAD_VIDEO_ON_YOUTUBE_HELPER_TEXT)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {value.length < 5 && (
              <IconButton onClick={onAddVideo} size="small">
                <AddIcon />
              </IconButton>
            )}
            <IconButton onClick={() => onRemoveVideo(index)} size="small">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ContainerVideosYoutube;
