import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslationApp } from "../../lib/i18next";
import {
  ADS_CAMPAIGN_LOCATION_EXAMPLE,
  ADS_CAMPAIGN_LOCATION_PLACEHOLDER,
  FIELD_REQUIRED,
  TYPE_LEAST_CHARACTERS,
} from "../../i18n/keysTranslations";
import useMapsFeatures from "../../hooks/useMapsFeatures";
import { startGetLocationsByPrompt } from "../../actions/adsCampaigns";
import { useDispatchApp } from "../../lib/redux";
import { useMap } from "@vis.gl/react-google-maps";

const SearchLocationMaps = ({
  onSelectLocation,
  onAddContext,
  onAddMarker,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [predictionResults, setPredictionResults] = useState([]);

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      prompt: "",
    },
    mode: "onChange",
  });

  const { t } = useTranslationApp();
  const { getPredictionsByKeyword, geocoder, parseLocationNames } =
    useMapsFeatures();
  const dispatch = useDispatchApp();
  const map = useMap();

  const onInputChange = async (_, newValue) => {
    setValue("prompt", newValue);
    const predictions = await getPredictionsByKeyword(newValue);
    setPredictionResults(predictions);
  };

  const onSendPosition = async (_, position) => {
    const response = await onSelectLocation(position);
    if (!response) return;
    setValue("prompt", "");
  };

  const geocodeCitiesAndAddMarkers = async (locations) => {
    if (!geocoder) return;

    let lastAssignedFocus = "";
    let firstAssignedFocus = "";

    for (const location of locations) {
      try {
        let locationText = "";
        if (location.type === "city") {
          locationText = location.city;
        }
        if (location.type === "region") {
          locationText = location.region;
        }
        locationText += " " + location.country;
        const response = await geocoder.geocode({ address: locationText });
        const results = response.results || [];

        if (results[0]) {
          const result = results[0];
          const responseNames = parseLocationNames(result, result.types);
          const { name, longName } = responseNames;
          const lat = result.geometry.location.lat();
          const lng = result.geometry.location.lng();
          onAddMarker({
            lat,
            lng,
            name: name || result?.address_components[0]?.short_name || "",
            longName: longName || result?.formatted_address || "",
            id: result.place_id,
          });
          if (result.geometry.viewport) {
            if (!firstAssignedFocus) {
              firstAssignedFocus = true;
              map.fitBounds(result.geometry.viewport);
            }
            lastAssignedFocus = result.geometry.viewport;
          }
        }
      } catch (error) {
        console.error("Error geocoding city:", location, error);
      }
    }
    if (lastAssignedFocus) {
      map.fitBounds(lastAssignedFocus);
    }
  };

  const onFindLocationsByPrompt = async (prompt) => {
    if (!prompt) return;
    setIsLoading(true);
    const response = await dispatch(startGetLocationsByPrompt(prompt));
    if (response.ok) {
      const locations = response.data.locations;
      if (locations.length > 0) {
        onAddContext(prompt);
        await geocodeCitiesAndAddMarkers(locations);
      }
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <Controller
        name="prompt"
        control={control}
        rules={{
          required: {
            value: true,
            message: t(FIELD_REQUIRED),
          },
          minLength: {
            value: 3,
            message: t(TYPE_LEAST_CHARACTERS, { value: 3 }),
          },
        }}
        render={({ field }) => (
          <>
            <Autocomplete
              loading={isLoading}
              fullWidth
              freeSolo
              disableClearable
              selectOnFocus
              clearOnEscape
              clearOnBlur
              blurOnSelect
              options={predictionResults}
              getOptionLabel={(option) => option.description || ""}
              inputValue={field.value}
              onInputChange={onInputChange}
              value={null}
              onChange={(e, value, reason) => {
                if (reason === "createOption")
                  onFindLocationsByPrompt(field.value);
                if (reason === "selectOption") onSendPosition(e, value);
              }}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                const mainText = option.structured_formatting.main_text;
                const secondaryText =
                  option.structured_formatting.secondary_text;
                return (
                  <Box
                    key={key}
                    {...optionProps}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      variant="body2"
                      textAlign="left"
                      sx={{
                        width: "100%",
                      }}
                    >
                      {mainText}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="fields.placeholder"
                      textAlign="left"
                      sx={{
                        width: "100%",
                      }}
                    >
                      {secondaryText}
                    </Typography>
                  </Box>
                );
              }}
              componentsProps={{
                popper: {
                  sx: {
                    zIndex: 9999,
                  },
                },
              }}
              isOptionEqualToValue={(option, value) => {
                if (!option || !value) return true;
                return option.place_id === value.place_id;
              }}
              sx={{
                "& .MuiFormControl-root ": {
                  pl: 0.75,
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // size="small"
                  error={Boolean(errors.prompt)}
                  label={t(ADS_CAMPAIGN_LOCATION_PLACEHOLDER)}
                  placeholder={t(ADS_CAMPAIGN_LOCATION_EXAMPLE)}
                  variant="filled"
                  InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  sx={{
                    backgroundColor: "background.paper",

                    "& .MuiInputLabel-root": {
                      mt: 1,
                      ml: 0.75,
                    },
                  }}
                />
              )}
            />
            {errors.prompt && (
              <Typography variant="caption" color="error">
                {errors.prompt.message}
              </Typography>
            )}
          </>
        )}
      />
    </Box>
  );
};

export default SearchLocationMaps;
