import React, { useEffect, useState } from "react";
import { Controller, useController } from "react-hook-form";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import i18next from "i18next";
import {
  BUTTON_CONNECT_ACCOUNT,
  FIELD_REQUIRED,
} from "../../i18n/keysTranslations";
import { getReactHookFormNestedError } from "../../utils/errors";
import { useSelectorApp } from "../../lib/redux";
import {
  GOOGLE,
  IDS_PLATFORM_BUTTONS,
  META,
  TIKTOK,
} from "../../utils/constants";
import { useTranslationApp } from "../../lib/i18next";
import AddIcon from "@mui/icons-material/Add";
import useButtonActionPlatform from "../../hooks/useButtonActionPlatform";

const filter = createFilterOptions();
const ID_ADD = "Add Account";

const SelectBusinessAccount = ({
  control,
  errors = {},
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  name,
  label,
  disabled = false,
  placeholder,
  variant = "filled",
  platform,
  fullWidth,
  helperText,
  onChangeAction = () => {},
  validationChange = async () => true,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const {
    field: { value: fieldValue, onChange: onFieldChange },
  } = useController({
    name,
    control,
  });
  const error = getReactHookFormNestedError(errors, name);
  const { t } = useTranslationApp();
  const BUTTON_ACTIONS_COMPONENTS = useButtonActionPlatform();

  const { isLoading, integrations } = useSelectorApp((state) => state.user);
  const metaIntegrations = integrations?.facebook;
  const tikTokBusinessIntegrations = integrations?.tiktokBusiness;
  const googleIntegrations = integrations?.google;

  useEffect(() => {
    if (platform === META) {
      const businessAccount = Object.keys(metaIntegrations || {}).map((key) => {
        const integration = metaIntegrations[key];
        const { businessAccounts } = integration;
        const businessAccountsKeys = Object.keys(businessAccounts);
        return businessAccountsKeys.map((key) => ({
          ...businessAccounts[key],
          integrationID: integration.id,
        }));
      });
      setOptions(businessAccount.flat());
    }

    if (platform === TIKTOK) {
      const businessAccount = Object.keys(tikTokBusinessIntegrations || {}).map(
        (key) => {
          const integration = tikTokBusinessIntegrations[key];
          const { advertisers } = integration;
          const advertisersKeys = Object.keys(advertisers);
          return advertisersKeys.map((key) => ({
            ...advertisers[key],
            integrationID: integration.id,
          }));
        }
      );
      setOptions(businessAccount.flat());
    }

    if (platform === GOOGLE) {
      const businessAccount = Object.keys(googleIntegrations || {}).map(
        (key) => {
          const integration = googleIntegrations[key];
          const { advertisers } = integration;
          const advertisersKeys = Object.keys(advertisers);
          return advertisersKeys.map((key) => ({
            ...advertisers[key],
            integrationID: integration.id,
          }));
        }
      );
      setOptions(businessAccount.flat());
    }
  }, [
    metaIntegrations,
    tikTokBusinessIntegrations,
    googleIntegrations,
    platform,
  ]);

  useEffect(() => {
    if (!fieldValue && options.length > 0) {
      const firstOption = options[0];
      onFieldChange(firstOption.id);
      onChangeAction(firstOption);
    }
    // eslint-disable-next-line
  }, [options]);

  const onClickConnectAccount = async () => {
    const id = IDS_PLATFORM_BUTTONS[platform];
    const element = document.getElementById(id);
    element?.click();
    return;
  };

  if (isLoading) return null;

  return (
    <>
      {React.cloneElement(BUTTON_ACTIONS_COMPONENTS[platform], {
        text: BUTTON_CONNECT_ACCOUNT,
        mode: "add",
        icon: <AddIcon />,
        showButtonIcon: true,
        showLogo: false,
        onClick: () => {
          setOpen(true);
        },
        sx: {
          display: "none",
          justifyContent: "flex-start",
          background: "transparent",
          color: "inherit",
          m: 0,
          ml: -1,
          py: 1,
          px: 2,
          fontSize: "1rem",
          fontWeight: 500,
          "&:hover": {
            background: "transparent",
          },
        },
      })}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <Autocomplete
            {...field}
            value={options?.length > 0 ? field.value : ""}
            fullWidth={fullWidth}
            disabled={disabled}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={async (_, value) => {
              const isValid = await validationChange(value);
              if (!isValid) {
                return;
              }
              if (value?.id === ID_ADD) {
                onClickConnectAccount();
                return;
              }
              if (fieldValue !== value?.id) {
                field.onChange(value?.id);
                onChangeAction(value);
              }
            }}
            isOptionEqualToValue={(option, value) => {
              if (value?.id) {
                return option?.id === value?.id;
              }
              return option?.id === value;
            }}
            disableClearable={true}
            getOptionLabel={(option) => {
              const optionValue = options.find((opt) => opt.id === option);
              if (optionValue) {
                return `${optionValue?.name} - ${optionValue?.id}`;
              }
              if (typeof option === "string") {
                return option;
              }
              return `${option?.name} - ${option?.id}`;
            }}
            options={options}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              filtered.unshift({
                id: ID_ADD,
                name: t(BUTTON_CONNECT_ACCOUNT),
              });

              return filtered;
            }}
            renderOption={(props, option) => {
              return (
                <Box
                  {...props}
                  component="li"
                  key={option.id}
                  sx={{
                    display: "flex",
                    gap: 0.5,
                  }}
                >
                  {option.id === ID_ADD && (
                    <AddIcon
                      sx={{
                        ml: -0.5,
                      }}
                    />
                  )}
                  <Typography variant="body1">{option.name}</Typography>
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant={variant}
                placeholder={field?.value?.length > 0 ? "" : placeholder}
                error={!!error}
                helperText={
                  <Box
                    component="span"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>{!!error ? error?.message : helperText}</span>
                  </Box>
                }
                sx={{
                  ".MuiFormHelperText-root": {
                    ml: "0 !important",
                  },
                }}
              />
            )}
          />
        )}
      />
    </>
  );
};

export default SelectBusinessAccount;
