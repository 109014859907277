import { z } from "zod";
import i18next from "i18next";
import { FIELD_REQUIRED } from "../../../../../i18n/keysTranslations";
import { META } from "../../../../../utils/constants";
import { adMetaSchema } from "../ad/adMetaSchema";

export const adGroupMetaBaseSchema = z.object({
  ads: z.array(adMetaSchema).min(1, i18next.t(FIELD_REQUIRED)),
  platform: z.literal(META),
  typeBudget: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  budget: z.any(),

  startDate: z.any().optional(),
  endDate: z.any().optional(),
  customAudiences: z.array(z.any()).optional(),
  excludedCustomAudiences: z.array(z.any()).optional(),
  ageRange: z.tuple(
    [
      z.number().min(18, i18next.t(FIELD_REQUIRED)),
      z.number().max(64, i18next.t(FIELD_REQUIRED)),
    ],
    {
      required_error: i18next.t(FIELD_REQUIRED),
    }
  ),
  genders: z.object({
    male: z.boolean(),
    female: z.boolean(),
  }),
  locations: z.array(z.any()).optional(),
  interests: z.array(z.any()).optional(),
  behaviors: z.array(z.any()).optional(),
  demographics: z.array(z.any()).optional(),

  destinationType: z.string().optional(),
  whatsappBusinessNumber: z.any().optional(),
  trackingCustomEventID: z.any().optional(),
  trackingID: z.any().optional(),
});
