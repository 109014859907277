import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  ADS_CAMPAIGNS_STATISTICS_SLIDE_MENU_METRICS,
  COLUMN_NAME_CLICKS,
  COLUMN_NAME_CPA,
  COLUMN_NAME_CTR,
  COLUMN_NAME_IMPRESSIONS,
  COLUMN_NAME_PLATFORM,
  COLUMN_NAME_RESULTS,
  COLUMN_NAME_SPEND,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import MetaCampaignRow from "./Meta/MetaCampaignRow";
import { useFormContext } from "react-hook-form";
import { getFromStatisticsAdsCampaign } from "../../../../services/adsCampaigns";
import { GOOGLE, META, TIKTOK } from "../../../../utils/constants";
import TableContainer from "../../../../components/Tables/TableContainer";
import TableCell from "../../../BillingAndPayments/components/TableCell";
import GoogleCampaignRow from "./Google/GoogleCampaignRow";
import TiktokCampaignRow from "./Tiktok/TiktokCampaignRow";

const COMPONENT_ROWS = {
  [META]: MetaCampaignRow,
  [GOOGLE]: GoogleCampaignRow,
  [TIKTOK]: TiktokCampaignRow,
};

const TableStatistics = () => {
  const { t } = useTranslationApp();

  const { campaign } = useFormContext();
  const { platforms } = getFromStatisticsAdsCampaign(campaign);
  return (
    <Box id="metrics-section">
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
        {t(ADS_CAMPAIGNS_STATISTICS_SLIDE_MENU_METRICS)}
      </Typography>
      <TableContainer
        hasPagination={false}
        hasMarginBottom={false}
        keys={platforms.length}
      >
        <TableHead>
          <TableRow>
            <TableCell isHeader={true} sx={{ width: 25 }} />
            <TableCell isHeader={true} align="center">
              {t(COLUMN_NAME_PLATFORM)}
            </TableCell>
            <TableCell isHeader={true} align="center">
              {t(COLUMN_NAME_SPEND)}
            </TableCell>
            <TableCell isHeader={true} align="center">
              {t(COLUMN_NAME_IMPRESSIONS)}
            </TableCell>
            <TableCell isHeader={true} align="center">
              {t(COLUMN_NAME_CLICKS)}
            </TableCell>
            <TableCell isHeader={true} align="center">
              {t(COLUMN_NAME_CTR)}
            </TableCell>
            <TableCell isHeader={true} align="center">
              {t(COLUMN_NAME_RESULTS)}
            </TableCell>
            <TableCell isHeader={true} align="center">
              {t(COLUMN_NAME_CPA)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {platforms.map((platform, index) => {
            const Component = COMPONENT_ROWS[platform];
            if (!Component) return null;
            return <Component key={platform} index={index} />;
          })}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default TableStatistics;
