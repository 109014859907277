export const getValueFromPath = (obj, path) => {
  const segments = path.split("/");

  let current = obj;

  for (const segment of segments) {
    if (current === null || current === undefined) {
      return undefined;
    }

    current = current[segment];
  }

  return current;
};
