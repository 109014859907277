import { useEffect, useState } from "react";
import { GOOGLE } from "../../../../utils/constants";
import Grid from "@mui/material/Grid";
import KeywordsGoogleContainer from "../../../Google/KeywordsGoogleContainer";
import {
  FIELD_KEYWORDS_REPEATED,
  FIELD_REQUIRED,
  GOOGLE_KEYWORDS_EXPLANATION,
  GOOGLE_KEYWORDS_NEGATIVE_EXPLANATION,
  KEYWORDS_NEGATIVE_PLACEHOLDER,
  KEYWORDS_PLACEHOLDER,
} from "../../../../i18n/keysTranslations";
import { getDuplicateItemsArray } from "../../../../utils/array";
import { useFormContext } from "react-hook-form";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { useTranslationApp } from "../../../../lib/i18next";
import { waitDelay } from "../../../../utils/date";

const platform = GOOGLE;
const grid = {
  xs: 12,
  // lg: 6,
};

const AdGroupGoogleForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    control,
    formState,
    watch,
    sectionSelected,
    isGeneralDisabledFields,
  } = useFormContext();
  useFocusErrorForm(formState);

  const errors = formState.errors;

  const { t } = useTranslationApp();

  const adsGroups = watch("adsGroups");
  const adGroupID = sectionSelected.adGroupID;
  const index = adsGroups.findIndex((item) => item.id === adGroupID);
  const BASIC_PATH = `adsGroups.[${index}]`;
  const platformData = watch(`platformsData.${platform}`);
  const language = watch("language");

  //To reset values component when change selectedLevel
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await waitDelay(1);
      setIsLoading(false);
    })();
  }, [sectionSelected]);

  if (isLoading) return null;
  return (
    <Grid container spacing={2}>
      <Grid item {...grid}>
        <KeywordsGoogleContainer
          label={t(KEYWORDS_PLACEHOLDER)}
          name={`${BASIC_PATH}.keywords`}
          explanation={GOOGLE_KEYWORDS_EXPLANATION}
          variant="filled"
          fullWidth
          disabled={isGeneralDisabledFields}
          isNegativeKeywords={false}
          adAccountID={platformData?.adAccountID}
          integrationID={platformData?.integrationID}
          language={language}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (!value || value.length === 0) {
                return t(FIELD_REQUIRED);
              }
              const duplicate = getDuplicateItemsArray(value);
              if (duplicate.length > 0) {
                return t(FIELD_KEYWORDS_REPEATED, {
                  value: duplicate.join(", "),
                });
              }
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <KeywordsGoogleContainer
          isOptional={true}
          label={t(KEYWORDS_NEGATIVE_PLACEHOLDER)}
          explanation={GOOGLE_KEYWORDS_NEGATIVE_EXPLANATION}
          name={`${BASIC_PATH}.keywordsNegative`}
          variant="filled"
          fullWidth
          disabled={isGeneralDisabledFields}
          isNegativeKeywords={true}
          adAccountID={platformData?.adAccountID}
          integrationID={platformData?.integrationID}
          language={language}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (!value || value.length === 0) {
                return t(FIELD_REQUIRED);
              }
              const duplicate = getDuplicateItemsArray(value);
              if (duplicate.length > 0) {
                return t(FIELD_KEYWORDS_REPEATED, {
                  value: duplicate.join(", "),
                });
              }
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AdGroupGoogleForm;
