import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useMediaQuery } from "@mui/material";
import { formattedIframeByType } from "../../../../services/adsCampaigns";
import { startGetAdPreview } from "../../../../actions/adsCampaigns";
import { useDispatchApp } from "../../../../lib/redux";
import { META } from "../../../../utils/constants";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import { useDebounced } from "../../../../hooks/useDebounce";

const PlacementPreview = ({
  adFormat,
  customizationPlacements,
  creativeID,
  destinationType,
  adID,
  adPathForm,
  index,
  onCallbackGeneratedAdPreview = () => {},
}) => {
  const [error, setError] = useState("");
  const { campaignID, getValues } = useFormContext();

  const platformData = getValues(`platformsData.${META}`) || {};
  const integrationID = platformData?.integrationID;
  const adAccountID = platformData?.adAccountID;
  const pageID = platformData?.pageID;
  const instagramID = platformData?.instagramID;

  const titles = getValues(`${adPathForm}.titles`);
  const descriptions = getValues(`${adPathForm}.descriptions`);
  const headlines = getValues(`${adPathForm}.headlines`);
  const callToAction = getValues(`${adPathForm}.callToAction`);
  const destinationUrls = getValues(`${adPathForm}.destinationUrls`);
  const mediaUrls = getValues(`${adPathForm}.mediaUrls`);
  const mediaIDs = getValues(`${adPathForm}.mediaIDs`);
  const mediaData = getValues(`${adPathForm}.mediaData`);

  const [iFrameSrc, setIFrameSrc] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const isMobile = useMediaQuery(() => "(max-width: 360px)");

  const dispatch = useDispatchApp();

  const { height, width, src } = formattedIframeByType({
    type: adFormat,
    iframe: iFrameSrc,
    isMobile,
  });

  const onGeneratePreview = useDebounced(async () => {
    setIsGenerating(true);
    setError(null);

    const mediaDataCopy = _.cloneDeep(mediaData);
    if (!mediaDataCopy[index]) {
      mediaDataCopy[index] = {
        from: "upload",
      };
    }
    mediaDataCopy[index].customizationPlacements = customizationPlacements;

    const response = await dispatch(
      startGetAdPreview({
        platform: META,
        campaignID,
        adID,
        mediaUrls,
        mediaIDs,
        mediaData: mediaDataCopy,
        callToAction,
        titles: titles ? titles.map((title) => title.value) : [],
        headlines: headlines ? headlines.map((headline) => headline.value) : [],
        descriptions: descriptions
          ? descriptions.map((description) => description.value)
          : [],
        destinationUrls: destinationUrls
          ? destinationUrls.map((url) => url.value)
          : [],
        pageID,
        instagramID,
        adFormats: [adFormat],
        platformData: {
          integrationID,
          adAccountID,
          pageID,
          instagramID,
        },
        destinationType,
        isCarousel: false,
        from: "placementPreview",
        creativeID,
      })
    );
    if (!response.ok) {
      setError(response.message);
    }
    if (response.ok) {
      const {
        adPreviews: adPreviewsResponse,
        creativeID: creativeIDResponse,
        mediaData: mediaDataResponse,
      } = response.data;
      setIFrameSrc(adPreviewsResponse[adFormat]);
      onCallbackGeneratedAdPreview({
        creativeID: creativeIDResponse,
        mediaData: mediaDataResponse,
      });
    }
    setIsLoadingIframe(true);
    setIsGenerating(false);
  }, [1000]);

  useEffect(() => {
    setIsGenerating(true);
    onGeneratePreview();
    // eslint-disable-next-line
  }, [customizationPlacements, adFormat]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {isGenerating && (
        <Skeleton
          variant="rounded"
          sx={{
            maxWidth: width,
            width: width,
            height,
          }}
        />
      )}
      {!isGenerating && error && (
        <Typography variant="body1" color="error" align="center">
          {error}
        </Typography>
      )}
      {!isGenerating && !error && (
        <Box
          sx={{
            maxWidth: width,
            width: width,
            height,
          }}
        >
          {isLoadingIframe && (
            <Skeleton
              variant="rectangular"
              sx={{
                maxWidth: width,
                width: width,
                height,
              }}
            />
          )}
          <iframe
            src={src}
            onLoad={() => setIsLoadingIframe(false)}
            scrolling="no"
            style={{
              display: isLoadingIframe ? "none" : "block",
              border: isMobile ? "none" : "1px solid white",
              overflow: "hidden",
              maxWidth: width,
              width: width,
              height,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default PlacementPreview;
