import i18next from "i18next";
import { z } from "zod";
import {
  FIELD_CAMPAIGN_MUST_HAVE_ONE_DAY_ACTIVE,
  FIELD_MAX_LENGTH,
  FIELD_MIN_DAILY_BUDGET,
  FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR,
  FIELD_REQUIRED,
  FIELD_URL_VALID,
  START_DATE_AND_END_DATE_VALIDATION,
} from "../../../../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  ADS_CAMPAIGN_GOOGLE_SITE_LINK_NAME_MAX_LENGTH,
  ADS_CAMPAIGN_GOOGLE_SITE_LINK_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_GOOGLE_CALL_OUT_MAX_LENGTH,
  ADS_CAMPAIGN_TYPE_SEARCH_OPTION,
} from "../../../../../utils/constants";
import { differenceInDays } from "date-fns";
import { REGEX_URL } from "../../../../../utils/regex";
import isAfter from "date-fns/isAfter";

const textItemSchema = z.object({
  id: z.string().optional(),
  value: z.string().optional(),
});

const googleBaseSchema = z.object({
  objective: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  integrationID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  adAccountID: z.string().min(1, i18next.t(FIELD_REQUIRED)),

  locations: z.array(z.any()).optional(),
  typeBudget: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  budget: z.any(),
  businessName: z.string().optional(),
  siteLinks: z
    .array(
      z.object({
        linkText: z.string().optional(),
        finalUrl: z.string().optional(),
        descriptions: z
          .array(z.string().optional())
          .min(2, i18next.t(FIELD_REQUIRED)),
      })
    )
    .optional(),
  callOuts: z.array(textItemSchema).optional(),

  conversionActions: z.array(z.any()).optional(),
  startDate: z.any().optional(),
  endDate: z.any().optional(),
  logoUrl: z.string().optional(),
  imageUrls: z.array(z.string()).optional(),
  networkSettings: z
    .object({
      targetGoogleSearch: z.boolean().optional(),
      targetSearchNetwork: z.boolean().optional(),
      targetContentNetwork: z.boolean().optional(),
    })
    .optional(),
});

export const googleCampaignSchema = googleBaseSchema.superRefine(
  (data, ctx) => {
    if (data.objective === ADS_CAMPAIGN_TYPE_SEARCH_OPTION) {
      if (!data.networkSettings) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["networkSettings"],
          message: i18next.t(FIELD_REQUIRED),
        });
      }

      if (!data.businessName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["businessName"],
          message: i18next.t(FIELD_REQUIRED),
        });
      }
    }

    const startDate = data.startDate ? new Date(data.startDate) : null;
    const endDate = data.endDate ? new Date(data.endDate) : null;

    if (!startDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["startDate"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }

    if (startDate && endDate) {
      const differenceDays = differenceInDays(endDate, startDate);

      if (differenceDays <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["startDate"],
          message: i18next.t(FIELD_CAMPAIGN_MUST_HAVE_ONE_DAY_ACTIVE),
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["endDate"],
          message: i18next.t(FIELD_CAMPAIGN_MUST_HAVE_ONE_DAY_ACTIVE),
        });
      }

      if (!isAfter(new Date(endDate), new Date(startDate))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["startDate"],
          message: i18next.t(START_DATE_AND_END_DATE_VALIDATION),
        });
      }
    }

    const numericBudget = Number(data.budget);
    if (isNaN(numericBudget) || !numericBudget) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["budget"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }

    if (data.typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION) {
      if (numericBudget <= 1.5) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["budget"],
          message: i18next.t(FIELD_MIN_DAILY_BUDGET, { value: 1.5 }),
        });
      }
    }

    const isLifetime =
      data.typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION;

    if (isLifetime) {
      const startDate = data.startDate ? new Date(data.startDate) : null;
      const endDate = data.endDate ? new Date(data.endDate) : null;

      if (startDate && endDate) {
        const diffDays = differenceInDays(endDate, startDate);

        if (diffDays > 0) {
          const dailyBudget = data.budget / diffDays;
          if (dailyBudget < 1.5) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["budget"],
              message: i18next.t(FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR, {
                value: diffDays,
              }),
            });
          }
        }
      }
    }

    if (!data.locations || data.locations.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["locations"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }

    if (data.callOuts?.length > 0) {
      data.callOuts.forEach((callOut, index) => {
        if (callOut.value.trim() === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["callOuts", index, "value"],
            message: i18next.t(FIELD_REQUIRED),
          });
        }
        if (callOut.value.length > ADS_CAMPAIGN_GOOGLE_CALL_OUT_MAX_LENGTH) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["callOuts", index, "value"],
            message: i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_GOOGLE_CALL_OUT_MAX_LENGTH,
            }),
          });
        }
      });
    }

    if (data.siteLinks?.length > 0) {
      data.siteLinks.forEach((siteLink, index) => {
        if (siteLink.linkText.trim() === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["siteLinks", index, "linkText"],
            message: i18next.t(FIELD_REQUIRED),
          });
        }
        if (
          siteLink.linkText.length >
          ADS_CAMPAIGN_GOOGLE_SITE_LINK_NAME_MAX_LENGTH
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["siteLinks", index, "linkText"],
            message: i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_GOOGLE_SITE_LINK_NAME_MAX_LENGTH,
            }),
          });
        }
        if (siteLink.finalUrl.trim() === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["siteLinks", index, "finalUrl"],
            message: i18next.t(FIELD_REQUIRED),
          });
        }
        if (!REGEX_URL.test(encodeURI(siteLink.finalUrl))) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["siteLinks", index, "finalUrl"],
            message: i18next.t(FIELD_URL_VALID),
          });
        }
        if (siteLink.descriptions.length < 2) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["siteLinks", index, "descriptions"],
            message: i18next.t(FIELD_REQUIRED),
          });
        }
        siteLink.descriptions.forEach((description, descIndex) => {
          if (description.trim() === "") {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["siteLinks", index, "descriptions", descIndex],
              message: i18next.t(FIELD_REQUIRED),
            });
          }
          if (
            description.length >
            ADS_CAMPAIGN_GOOGLE_SITE_LINK_DESCRIPTION_MAX_LENGTH
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["siteLinks", index, "descriptions", descIndex],
              message: i18next.t(FIELD_MAX_LENGTH, {
                value: ADS_CAMPAIGN_GOOGLE_SITE_LINK_DESCRIPTION_MAX_LENGTH,
              }),
            });
          }
        });
      });
    }
  }
);
