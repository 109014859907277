import Box from "@mui/material/Box";
import { useFormContext } from "react-hook-form";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import {
  AD,
  ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE,
  ALERT_DELETE_PLATFORM_CAMPAIGN_DESCRIPTION,
  ARE_YOU_SURE,
  BUTTON_CREATE_NEW,
  BUTTON_DELETE,
  BUTTON_DUPLICATE,
  GOOGLE_P_MAX,
  GOOGLE_SEARCH,
  THIS_ITEM_HAS_ERRORS,
} from "../../../../i18n/keysTranslations";
import { capitalizeFirstLetter, logoPlatform } from "../../../../utils/string";
import { useTranslationApp } from "../../../../lib/i18next";
import Avatar from "@mui/material/Avatar";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import MovieIcon from "@mui/icons-material/Movie";
import {
  ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_OPTION,
  ADS_CAMPAIGN_TYPE_SEARCH_OPTION,
  DRAWER_WIDTH,
} from "../../../../utils/constants";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "../../../../components/Buttons/IconButton";
import { Drawer, Tooltip } from "@mui/material";
import { GRADIENT_PURPLE_FUCHSIA } from "../../../../utils/colors";
import Skeleton from "@mui/material/Skeleton";
import { getKeyDatabase } from "../../../../services/public";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import _ from "lodash";
import { ConfirmAlert } from "../../../../components/Alerts/Alerts";
import SwitchStatusItem from "../Common/SwitchStatusItem";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { waitDelay } from "../../../../utils/date";
import {
  getAdGroupStructure,
  getAdStructure,
  getAssetsGroupStructure,
} from "../../../../services/adsCampaigns";
import { Link } from "react-router-dom";
import { getNameBusinessTheme } from "../../../../actions/getters";
import i18next from "i18next";

const commonSxText = {
  fontSize: "14px",
  wordBreak: "break-word",
};

const commonSxIcon = {
  minWidth: "unset",
  ".MuiSvgIcon-root ": {
    width: 20,
    height: 20,
  },
};
const commonSx = {
  pr: 0.5,
  pl: 0,
  pt: 0.5,
  pb: 0.5,
  display: "flex",
  alignItems: "center",
  gap: 1,
  fontSize: "14px",
  height: 48,
};

const sxCutText = {
  whiteSpace: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  lineHeight: "1.2",
  WebkitBoxOrient: "vertical",
  wordBreak: "break-all",
};

const getTitlePlatform = ({ platform, objective }) => {
  if (objective === ADS_CAMPAIGN_TYPE_SEARCH_OPTION)
    return i18next.t(GOOGLE_SEARCH);
  if (objective === ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_OPTION)
    return i18next.t(GOOGLE_P_MAX);

  return i18next.t(ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE, {
    platform: capitalizeFirstLetter(i18next.t(platform)),
  });
};

const Sidebar = () => {
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [selectedLevelMenu, setSelectedLevelMenu] = useState(null);
  const openMenu = Boolean(anchorElMenu);

  const {
    watch,
    sectionSelected,
    onChangeSectionSelected,
    platformGenerating,
    onGetItemSelectedLevel,
    levelSectionSelected,
    getShowActionsPlatform,
    isGeneralDisabledFields,
    formState: { errors },
  } = useFormContext();

  const dispatch = useDispatchApp();
  const theme = useSelectorApp((state) => state.ui.theme);
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const platformsData = watch("platformsData") || {};
  const platforms = Object.keys(platformsData);
  const adsGroups = watch("adsGroups") || [];
  const assetsGroups = watch("assetsGroups") || [];

  const { t } = useTranslationApp();

  const getIsSelectedItem = ({ platform, assetsGroupID, adGroupID, adID }) => {
    const itemLevel = onGetItemSelectedLevel({
      platform,
      assetsGroupID,
      adGroupID,
      adID,
    });
    let isSelected = false;

    if (itemLevel && itemLevel === levelSectionSelected) {
      if (itemLevel === "platform") {
        isSelected = sectionSelected.platform === platform;
      } else if (itemLevel === "assetsGroup") {
        isSelected =
          sectionSelected.platform === platform &&
          sectionSelected.assetsGroupID === assetsGroupID;
      } else if (itemLevel === "adGroup") {
        isSelected =
          sectionSelected.platform === platform &&
          sectionSelected.adGroupID === adGroupID;
      } else if (itemLevel === "ad") {
        isSelected =
          sectionSelected.platform === platform &&
          sectionSelected.adGroupID === adGroupID &&
          sectionSelected.adID === adID;
      }
    }

    return isSelected;
  };

  const groupedAdGroups = adsGroups.reduce((acc, adGroup) => {
    const platform = adGroup.platform;
    if (!acc[platform]) {
      acc[platform] = [];
    }
    acc[platform].push(adGroup);
    return acc;
  }, {});

  const groupedAssetsGroups = assetsGroups.reduce((acc, assetsGroup) => {
    const platform = assetsGroup.platform;
    if (!acc[platform]) {
      acc[platform] = [];
    }
    acc[platform].push(assetsGroup);
    return acc;
  }, {});

  const onClickMenu = ({ event, selectedLevel }) => {
    setAnchorElMenu(event.currentTarget);
    setSelectedLevelMenu(selectedLevel);
  };
  const onCloseMenu = () => {
    setAnchorElMenu(null);
    setSelectedLevelMenu(null);
  };

  const onGetLevelHasError = ({ platform, assetsGroupID, adGroupID, adID }) => {
    const itemLevel = onGetItemSelectedLevel({
      platform,
      assetsGroupID,
      adGroupID,
      adID,
    });

    if (itemLevel === "platform") {
      return errors?.platformsData?.[platform];
    }

    if (itemLevel === "assetsGroup") {
      const assetsGroupIndex = assetsGroups.findIndex(
        (assetsGroup) => assetsGroup.id === assetsGroupID
      );
      if (errors?.assetsGroups?.[assetsGroupIndex]) return true;
    }

    if (itemLevel === "adGroup") {
      const adGroupIndex = adsGroups.findIndex(
        (adGroup) => adGroup.id === adGroupID
      );
      if (errors?.adsGroups?.[adGroupIndex]) {
        const errorsKeys = Object.keys(errors?.adsGroups?.[adGroupIndex]);

        if (errorsKeys.length === 1 && errorsKeys[0] === "ads") return false;

        return true;
      }
    }

    if (itemLevel === "ad") {
      const adGroupIndex = adsGroups.findIndex(
        (adGroup) => adGroup.id === adGroupID
      );
      const adIndex = adsGroups[adGroupIndex]?.ads?.findIndex(
        (ad) => ad.id === adID
      );

      return errors?.adsGroups?.[adGroupIndex]?.ads?.[adIndex];
    }
  };

  return (
    <Drawer
      open={true}
      variant={"permanent"}
      anchor="left"
      data-tour="navigation"
      sx={{
        width: DRAWER_WIDTH + 50,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH + 50,
          boxSizing: "border-box",
          backgroundColor: "background.paper",
          color: "background.paperContrastText",
          backgroundImage: "none",
          "&.MuiPaper-root": {
            overflowX: "hidden",
            borderRight: "none",
          },
        },
      }}
    >
      <Box
        sx={{
          width: "15%",
          pt: 1,
          minWidth: DRAWER_WIDTH + 50,
          backgroundColor: "rgba(255, 255, 255, 0.04)",
          height: "100%",
        }}
      >
        <List
          component="nav"
          subheader={
            <Link
              to="/"
              style={{
                display: "flex",
                width: "65%",
                justifyContent: "flex-start",
              }}
            >
              <Avatar
                src={theme.logo}
                alt={`Logo ${NAME_BUSINESS}`}
                variant="square"
                sx={{
                  width: "auto",
                  ml: "18px",
                  ".MuiAvatar-img": {
                    maxWidth: "105px",
                    objectFit: "contain",
                  },
                }}
              />
            </Link>
          }
        >
          {platforms.map((platform) => {
            const isPlatformGenerating = platformGenerating === platform;
            const showAssetsGroupActions = true;
            const showAdGroupActions = true;
            const showAdActions = true;

            const assetsGroups = groupedAssetsGroups[platform] || [];
            const adGroups = groupedAdGroups[platform] || [];
            if (isPlatformGenerating) return null;

            const platformObjectSelection = {
              platform,
              assetsGroupID: undefined,
              adGroupID: undefined,
              adID: undefined,
            };

            const isSelectedPlatform = getIsSelectedItem(
              platformObjectSelection
            );

            const showActionsPlatform = getShowActionsPlatform(platform);

            const hasErrorPlatform = onGetLevelHasError(
              platformObjectSelection
            );

            return (
              <React.Fragment key={platform}>
                <ListItemButton
                  onClick={() => {
                    if (isPlatformGenerating) return;
                    onChangeSectionSelected(platformObjectSelection);
                  }}
                  sx={{
                    ...commonSx,
                    pl: 2,
                  }}
                >
                  {isSelectedPlatform && (
                    <Box
                      sx={{
                        width: 2,
                        height: 40,
                        position: "absolute",
                        background: GRADIENT_PURPLE_FUCHSIA,
                        left: "1px",
                      }}
                    ></Box>
                  )}
                  <ListItemIcon sx={commonSxIcon}>
                    <Avatar
                      alt={platform}
                      src={logoPlatform(platform)}
                      sx={{
                        width: 20,
                        height: 20,
                        ".MuiAvatar-img": {
                          objectFit: "contain",
                        },
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      mt: 0.75,
                      ".MuiTypography-root": {
                        ...commonSxText,
                      },
                    }}
                    primary={getTitlePlatform({
                      platform,
                      objective: platformsData[platform]?.objective,
                    })}
                  />
                  {hasErrorPlatform && <ErrorIcon />}
                  {showActionsPlatform && (
                    <SwitchStatusItem
                      hasLabel={false}
                      disabled={isGeneralDisabledFields}
                      selectedSection={platformObjectSelection}
                      sx={{ width: 30 }}
                    />
                  )}
                  {!isPlatformGenerating && !isGeneralDisabledFields && (
                    <IconButton
                      edge="start"
                      onClick={(event) => {
                        event.stopPropagation();
                        onClickMenu({
                          event,
                          selectedLevel: platformObjectSelection,
                        });
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                </ListItemButton>
                {isPlatformGenerating && <SkeletonGenerating />}
                {assetsGroups.map((assetsGroup) => {
                  const assetsGroupObjectSelection = {
                    platform,
                    assetsGroupID: assetsGroup.id,
                    adGroupID: undefined,
                    adID: undefined,
                  };

                  const isSelectedAssetsGroup = getIsSelectedItem(
                    assetsGroupObjectSelection
                  );

                  const hasErrorAssetsGroup = onGetLevelHasError(
                    assetsGroupObjectSelection
                  );

                  return (
                    <ListItemButton
                      key={assetsGroup.id}
                      onClick={() => {
                        onChangeSectionSelected(assetsGroupObjectSelection);
                      }}
                      sx={{
                        ...commonSx,
                        pl: 4,
                      }}
                    >
                      {isSelectedAssetsGroup && (
                        <Box
                          sx={{
                            width: 2,
                            height: 40,
                            position: "absolute",
                            background: GRADIENT_PURPLE_FUCHSIA,
                            left: "1px",
                          }}
                        ></Box>
                      )}
                      <ListItemIcon sx={commonSxIcon}>
                        <PermMediaIcon />
                      </ListItemIcon>
                      <Tooltip title={assetsGroup.name}>
                        <ListItemText
                          sx={{
                            ...commonSxText,
                            ".MuiTypography-root": {
                              ...commonSxText,
                              ...sxCutText,
                            },
                          }}
                          primary={assetsGroup.name}
                        />
                      </Tooltip>
                      {hasErrorAssetsGroup && <ErrorIcon />}
                      {showActionsPlatform && (
                        <SwitchStatusItem
                          hasLabel={false}
                          disabled={isGeneralDisabledFields}
                          selectedSection={assetsGroupObjectSelection}
                          sx={{
                            width: 30,
                            mr: showAssetsGroupActions ? 0 : 4.5,
                          }}
                        />
                      )}
                      {showAssetsGroupActions && !isGeneralDisabledFields && (
                        <IconButton
                          edge="start"
                          onClick={(event) => {
                            event.stopPropagation();
                            onClickMenu({
                              event,
                              selectedLevel: assetsGroupObjectSelection,
                            });
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      )}
                    </ListItemButton>
                  );
                })}
                {!isPlatformGenerating &&
                  adGroups.map((adGroup) => {
                    const adGroupObjectSelection = {
                      platform,
                      adGroupID: adGroup.id,
                      adID: undefined,
                    };
                    const isSelectedAdGroup = getIsSelectedItem(
                      adGroupObjectSelection
                    );

                    const hasErrorAdGroup = onGetLevelHasError(
                      adGroupObjectSelection
                    );

                    return (
                      <React.Fragment key={adGroup.id}>
                        <ListItemButton
                          onClick={() => {
                            onChangeSectionSelected(adGroupObjectSelection);
                          }}
                          sx={{
                            ...commonSx,
                            pl: 4,
                          }}
                        >
                          {isSelectedAdGroup && (
                            <Box
                              sx={{
                                width: 2,
                                height: 40,
                                position: "absolute",
                                background: GRADIENT_PURPLE_FUCHSIA,
                                left: "1px",
                              }}
                            ></Box>
                          )}
                          <ListItemIcon sx={commonSxIcon}>
                            <PermMediaIcon />
                          </ListItemIcon>
                          <Tooltip title={adGroup.name}>
                            <ListItemText
                              sx={{
                                ...commonSxText,
                                ".MuiTypography-root": {
                                  ...commonSxText,
                                  ...sxCutText,
                                },
                              }}
                              primary={adGroup.name}
                            />
                          </Tooltip>
                          {hasErrorAdGroup && <ErrorIcon />}
                          {showActionsPlatform && (
                            <SwitchStatusItem
                              hasLabel={false}
                              disabled={isGeneralDisabledFields}
                              selectedSection={adGroupObjectSelection}
                              sx={{
                                width: 30,
                                mr: showAdGroupActions ? 0 : 4.5,
                              }}
                            />
                          )}
                          {showAdGroupActions && !isGeneralDisabledFields && (
                            <IconButton
                              edge="start"
                              onClick={(event) => {
                                event.stopPropagation();
                                onClickMenu({
                                  event,
                                  selectedLevel: adGroupObjectSelection,
                                });
                              }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          )}
                        </ListItemButton>
                        {adGroup.ads.map((ad, indexAd) => {
                          const adObjectSelection = {
                            platform,
                            adGroupID: adGroup.id,
                            adID: ad.id,
                          };
                          const isSelectedAd =
                            getIsSelectedItem(adObjectSelection);

                          const hasErrorAd =
                            onGetLevelHasError(adObjectSelection);

                          return (
                            <ListItemButton
                              onClick={() => {
                                onChangeSectionSelected(adObjectSelection);
                              }}
                              key={ad.id}
                              sx={{
                                ...commonSx,
                                pl: 8,
                              }}
                            >
                              {isSelectedAd && (
                                <Box
                                  sx={{
                                    width: 2,
                                    height: 40,
                                    position: "absolute",
                                    background: GRADIENT_PURPLE_FUCHSIA,
                                    left: "1px",
                                  }}
                                ></Box>
                              )}
                              <ListItemIcon sx={commonSxIcon}>
                                <MovieIcon />
                              </ListItemIcon>
                              <Tooltip
                                title={ad.name || `${t(AD)} ${indexAd + 1}`}
                              >
                                <ListItemText
                                  primary={ad.name || `${t(AD)} ${indexAd + 1}`}
                                  sx={{
                                    ".MuiTypography-root": {
                                      ...commonSxText,
                                      ...sxCutText,
                                    },
                                  }}
                                />
                              </Tooltip>
                              {hasErrorAd && <ErrorIcon />}
                              {showActionsPlatform && (
                                <SwitchStatusItem
                                  hasLabel={false}
                                  disabled={isGeneralDisabledFields}
                                  selectedSection={adObjectSelection}
                                  sx={{
                                    width: 30,
                                    mr: showAdActions ? 0 : 4.5,
                                  }}
                                />
                              )}
                              {showAdActions && !isGeneralDisabledFields && (
                                <IconButton
                                  edge="start"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    onClickMenu({
                                      event,
                                      selectedLevel: adObjectSelection,
                                    });
                                  }}
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                              )}
                            </ListItemButton>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            );
          })}
        </List>
        <MenuOptions
          anchorEl={anchorElMenu}
          selectedLevel={selectedLevelMenu}
          open={openMenu}
          onCloseMenu={onCloseMenu}
        />
      </Box>
    </Drawer>
  );
};

const ErrorIcon = () => {
  const { t } = useTranslationApp();
  return (
    <Tooltip title={t(THIS_ITEM_HAS_ERRORS)}>
      <PriorityHighIcon
        sx={{
          color: "error.main",
          fontSize: 20,
        }}
      />
    </Tooltip>
  );
};

const SkeletonGenerating = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {[1, 2, 3, 4].map((index) => (
        <Skeleton
          key={index}
          variant="rounded"
          sx={{
            height: 40,
            ml: 1,
            mr: 1,
          }}
        />
      ))}
    </Box>
  );
};

const MenuOptions = ({ anchorEl, selectedLevel = {}, open, onCloseMenu }) => {
  const {
    onGetItemSelectedLevel,
    getValues,
    setValue,
    sectionSelected,
    onChangeSectionSelected,
    getIsCampaignPlatformCreated,
    campaignID,
  } = useFormContext();

  const itemLevel = onGetItemSelectedLevel({
    platform: selectedLevel?.platform,
    assetsGroupID: selectedLevel?.assetsGroupID,
    adGroupID: selectedLevel?.adGroupID,
    adID: selectedLevel?.adID,
  });

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const getIsItemCreated = () => {
    if (itemLevel === "platform") {
      const isPlatformCreated = getIsCampaignPlatformCreated(
        selectedLevel?.platform
      );
      return isPlatformCreated;
    }

    if (itemLevel === "assetsGroup") {
      const assetsGroups = getValues("assetsGroups");
      const assetsGroup = assetsGroups.find(
        (assetsGroup) => assetsGroup.id === selectedLevel?.assetsGroupID
      );
      return assetsGroup?.idPlatform;
    }

    if (itemLevel === "adGroup") {
      const adGroups = getValues("adsGroups");
      const adGroup = adGroups.find(
        (adGroup) => adGroup.id === selectedLevel?.adGroupID
      );
      return adGroup?.idPlatform;
    }

    if (itemLevel === "ad") {
      const adGroups = getValues("adsGroups");
      const adGroup = adGroups.find(
        (adGroup) => adGroup.id === selectedLevel?.adGroupID
      );
      const ad = adGroup?.ads?.find((ad) => ad.id === selectedLevel?.adID);
      return ad?.idPlatform;
    }
  };

  const onDeletePlatform = async () => {
    const result = await ConfirmAlert({
      title: t(ARE_YOU_SURE),
      text: t(ALERT_DELETE_PLATFORM_CAMPAIGN_DESCRIPTION, {
        platform: capitalizeFirstLetter(selectedLevel?.platform),
      }),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (!result.isConfirmed) return;

    if (sectionSelected.platform === selectedLevel?.platform) {
      const platforms = getValues("platforms") || [];
      const platformsFiltered = platforms.filter(
        (platform) => platform !== selectedLevel?.platform
      );
      onChangeSectionSelected({
        platform: platformsFiltered?.[0] || undefined,
        adGroupID: undefined,
        adID: undefined,
      });
    }

    const platforms = getValues("platforms");
    const newPlatforms = platforms.filter((p) => p !== selectedLevel?.platform);
    const platformsData = { ...getValues("platformsData") };
    const states = { ...getValues("states") };
    const adsGroups = getValues("adsGroups");

    delete platformsData[selectedLevel?.platform];
    delete states[selectedLevel?.platform];
    setValue("platforms", newPlatforms);
    setValue("platformsData", platformsData);
    setValue("states", states);

    const newAdsGroups = adsGroups.filter(
      (adGroup) => adGroup.platform !== selectedLevel?.platform
    );
    setValue("adsGroups", newAdsGroups);
  };

  const onAddAssetsGroup = async ({ platform }) => {
    const newAssetsGroupID = dispatch(getKeyDatabase());
    const newAssetsGroup = getAssetsGroupStructure({
      platform,
      campaignID,
      assetsGroupID: newAssetsGroupID,
    });

    const assetsGroups = getValues("assetsGroups");
    setValue(`assetsGroups`, [...assetsGroups, newAssetsGroup]);
    await waitDelay(500);

    onChangeSectionSelected({
      platform: selectedLevel.platform,
      assetsGroupID: newAssetsGroupID,
      adGroupID: undefined,
      adID: undefined,
    });
  };

  const onDuplicateAssetsGroup = async ({ assetsGroupID }) => {
    const assetsGroups = getValues("assetsGroups");
    const currentAssetsGroupToCopy = assetsGroups.find(
      (assetsGroup) => assetsGroup.id === assetsGroupID
    );

    if (!currentAssetsGroupToCopy) {
      console.error("Assets group not found");
      return;
    }

    const newAssetsGroupID = dispatch(getKeyDatabase());
    const newAssetsGroup = _.cloneDeep(currentAssetsGroupToCopy);
    newAssetsGroup.id = newAssetsGroupID;
    delete newAssetsGroup.idPlatform;

    setValue(`assetsGroups`, [...assetsGroups, newAssetsGroup]);
    await waitDelay(500);

    onChangeSectionSelected({
      platform: selectedLevel.platform,
      assetsGroupID: newAssetsGroupID,
      adGroupID: undefined,
      adID: undefined,
    });
  };

  const onDeleteAssetsGroup = async ({ assetsGroupID }) => {
    const assetsGroups = getValues("assetsGroups");
    const newAssetsGroups = assetsGroups.filter(
      (assetsGroup) => assetsGroup.id !== assetsGroupID
    );
    setValue("assetsGroups", newAssetsGroups);
  };

  const onAddGroup = async ({ platform }) => {
    const newGroupID = dispatch(getKeyDatabase());
    const newAdID = dispatch(getKeyDatabase());

    const newStructure = getAdGroupStructure({
      platform,
      campaignID,
      adGroupID: newGroupID,
      adID: newAdID,
    });

    const newAdGroup = _.cloneDeep(newStructure);

    const adsGroups = getValues("adsGroups");
    setValue(`adsGroups`, [...adsGroups, newAdGroup]);
    await waitDelay(500);

    onChangeSectionSelected({
      platform: selectedLevel.platform,
      adGroupID: newGroupID,
      adID: undefined,
    });
  };

  const onDuplicateAdGroup = async ({ adGroupID }) => {
    const newGroupID = dispatch(getKeyDatabase());

    const adsGroups = getValues("adsGroups");
    const currentAdGroupToCopy = adsGroups.find(
      (adGroup) => adGroup.id === adGroupID
    );

    if (!currentAdGroupToCopy) {
      console.error("Ad group not found");
      return;
    }

    const newAdGroup = _.cloneDeep(currentAdGroupToCopy);
    newAdGroup.id = newGroupID;
    delete newAdGroup.idPlatform;

    const newAds = newAdGroup.ads.map((ad) => {
      const newAd = _.cloneDeep(ad);
      const newAdID = dispatch(getKeyDatabase());
      newAd.id = newAdID;
      newAd.adGroupID = newGroupID;
      delete newAd.idPlatform;
      return newAd;
    });

    newAdGroup.ads = newAds;

    setValue(`adsGroups`, [...adsGroups, newAdGroup]);
    await waitDelay(500);

    onChangeSectionSelected({
      platform: selectedLevel.platform,
      adGroupID: newGroupID,
      adID: undefined,
    });
  };

  const onRemoveAdGroup = ({ adGroupID }) => {
    const adsGroups = getValues("adsGroups");
    const newAdsGroups = adsGroups.filter(
      (adGroup) => adGroup.id !== adGroupID
    );

    if (sectionSelected.adGroupID === adGroupID) {
      onChangeSectionSelected({
        platform: selectedLevel.platform,
        adGroupID: undefined,
        adID: undefined,
      });
    }

    setValue("adsGroups", newAdsGroups);
  };

  const onAddAd = async ({ platform, adGroupID }) => {
    const newAdID = dispatch(getKeyDatabase());

    const newStructure = getAdStructure({
      platform,
      campaignID,
      adGroupID,
      adID: newAdID,
    });

    const adsGroups = getValues("adsGroups");
    const adGroupIndex = adsGroups.findIndex(
      (adGroup) => adGroup.id === adGroupID
    );

    const currentAds = getValues(`adsGroups.[${adGroupIndex}].ads`);

    setValue(`adsGroups.[${adGroupIndex}].ads`, [...currentAds, newStructure]);
    await waitDelay(500);

    onChangeSectionSelected({
      platform,
      adGroupID: adGroupID,
      adID: newAdID,
    });
  };
  const onDuplicateAd = async ({ adGroupID, adID }) => {
    const adGroups = getValues("adsGroups");
    const adGroupIndex = adGroups.findIndex(
      (adGroup) => adGroup.id === adGroupID
    );

    const currentAds = getValues(`adsGroups.[${adGroupIndex}].ads`);
    const adIndex = currentAds.findIndex((ad) => ad.id === adID);

    const currentAdToCopy = getValues(
      `adsGroups.[${adGroupIndex}].ads.[${adIndex}]`
    );
    if (!currentAdToCopy) {
      console.error("Ad not found");
      return;
    }

    const newAdID = dispatch(getKeyDatabase());
    const newAd = _.cloneDeep(currentAdToCopy);

    newAd.id = newAdID;
    delete newAd.idPlatform;

    setValue(`adsGroups.[${adGroupIndex}].ads`, [...currentAds, newAd]);
    await waitDelay(500);

    onChangeSectionSelected({
      platform: selectedLevel.platform,
      adGroupID: adGroupID,
      adID: newAdID,
    });
  };

  const onRemoveAd = ({ adGroupID, adID }) => {
    const adsGroups = getValues("adsGroups");

    const adGroupIndex = adsGroups.findIndex(
      (adGroup) => adGroup.id === adGroupID
    );
    if (adGroupIndex === -1) {
      console.error("Ad group not found");
      return;
    }

    const adIndex = adsGroups[adGroupIndex].ads.findIndex(
      (ad) => ad.id === adID
    );

    if (adIndex === -1) {
      console.error("Ad not found");
      return;
    }

    if (sectionSelected.adID === adID) {
      onChangeSectionSelected({
        platform: selectedLevel.platform,
        adGroupID: adGroupID,
        adID: undefined,
      });
    }
    const ads = getValues(`adsGroups.[${adGroupIndex}].ads`);
    const newAds = ads.filter((ad, index) => index !== adIndex);
    setValue(`adsGroups.[${adGroupIndex}].ads`, newAds);
  };

  const onExecuteAction = async (action) => {
    if (itemLevel === "platform" && action === "delete") {
      onDeletePlatform();
    }

    if (itemLevel === "assetsGroup" && action === "add") {
      onAddAssetsGroup({
        platform: selectedLevel.platform,
      });
    }
    if (itemLevel === "assetsGroup" && action === "duplicate") {
      onDuplicateAssetsGroup({
        assetsGroupID: selectedLevel.assetsGroupID,
      });
    }
    if (itemLevel === "assetsGroup" && action === "delete") {
      onDeleteAssetsGroup({
        assetsGroupID: selectedLevel.assetsGroupID,
      });
    }

    if (itemLevel === "adGroup" && action === "add") {
      onAddGroup({ platform: selectedLevel.platform });
    }
    if (itemLevel === "adGroup" && action === "duplicate") {
      onDuplicateAdGroup({ adGroupID: selectedLevel.adGroupID });
    }
    if (itemLevel === "adGroup" && action === "delete") {
      onRemoveAdGroup({ adGroupID: selectedLevel.adGroupID });
    }

    if (itemLevel === "ad" && action === "add") {
      onAddAd({
        platform: selectedLevel.platform,
        adGroupID: selectedLevel.adGroupID,
      });
    }
    if (itemLevel === "ad" && action === "duplicate") {
      onDuplicateAd({
        adGroupID: selectedLevel.adGroupID,
        adID: selectedLevel.adID,
      });
    }
    if (itemLevel === "ad" && action === "delete") {
      onRemoveAd({
        adGroupID: selectedLevel.adGroupID,
        adID: selectedLevel.adID,
      });
    }
  };

  const isItemCreated = getIsItemCreated();

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onCloseMenu}>
      {itemLevel !== "platform" && (
        <MenuItem
          onClick={() => {
            onCloseMenu();
            onExecuteAction("add");
          }}
        >
          {t(BUTTON_CREATE_NEW)}
        </MenuItem>
      )}
      {itemLevel !== "platform" && (
        <MenuItem
          onClick={() => {
            onCloseMenu();
            onExecuteAction("duplicate");
          }}
        >
          {t(BUTTON_DUPLICATE)}
        </MenuItem>
      )}
      {!isItemCreated && (
        <MenuItem
          onClick={() => {
            onCloseMenu();
            onExecuteAction("delete");
          }}
        >
          {t(BUTTON_DELETE)}
        </MenuItem>
      )}
    </Menu>
  );
};
export default Sidebar;
