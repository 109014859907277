import { useEffect, useState } from "react";
import {
  ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
  ADS_CAMPAIGN_GOOGLE_FIRST_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_GOOGLE_FIRST_TITLE_MAX_LENGTH,
  ADS_CAMPAIGN_GOOGLE_MAX_DESCRIPTIONS,
  ADS_CAMPAIGN_GOOGLE_MAX_LONG_HEADLINES,
  ADS_CAMPAIGN_GOOGLE_MAX_TITLES,
  ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_LONG_HEADLINE_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH,
  GOOGLE,
  GOOGLE_IMAGE_FORMATS,
} from "../../../../utils/constants";
import Grid from "@mui/material/Grid";
import {
  TITLES,
  DESCRIPTIONS,
  LONG_HEADLINES,
  TITLE_PLACEHOLDER,
  DESCRIPTION_PLACEHOLDER,
  LONG_HEADLINE_PLACEHOLDER,
  IMAGES_OF_CAMPAIGN_PLACEHOLDER,
  VIDEOS_OF_CAMPAIGN_PLACEHOLDER,
  LOGO_URLS_PLACEHOLDER,
  ADS_CAMPAIGN_PLATFORM_BRAND_CONFIGURATION_SUB_TITLE,
  BUSINESS_NAME_PLACEHOLDER,
  ADS_CAMPAIGN_PLATFORM_ASSETS_CONFIGURATION_SUB_TITLE,
  ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE,
  ADS_CAMPAIGNS_PLATFORM_TEXTS_SUB_TITLE,
  SEARCH_THEMES_PLACEHOLDER,
  DESTINATION_URL_PLACEHOLDER,
  REQUIRED_IMAGES_GOOGLE_ASSETS_GROUP_HELPER_TEXT,
} from "../../../../i18n/keysTranslations";
import { Controller, useFormContext } from "react-hook-form";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { useTranslationApp } from "../../../../lib/i18next";
import { waitDelay } from "../../../../utils/date";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import TextField from "../../../Form/TextField";
import Loader from "../../../Loaders/Loader";
import MultiContentField from "../../../Form/MultiContentField";
import Accordion from "../../../Containers/Accordion";
import { getUniqueID } from "../../../../utils/numbers";
import { useDispatchApp } from "../../../../lib/redux";
import { generateAdsCampaignDescription } from "../../../../actions/adsCampaigns";
import { getAdsTextsFormatted } from "../../../../services/adsCampaigns";
import { getIsBlockedActions } from "../../../../actions/getters";
import AutocompleteField from "../../../Form/AutocompleteField";
import ContainerVideosYoutube from "./ContainerVideosYoutube";

const platform = GOOGLE;
const grid = {
  xs: 12,
  lg: 6,
};

const commonSxFields = {
  display: "flex",
  flexDirection: "column",
  gap: 1.5,
};

const commonSxAccordion = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  minHeight: 70,
};

const AssetsGroupGoogleForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    control,
    formState,
    watch,
    sectionSelected,
    setValue,
    getValues,
    isGeneralDisabledFields,
    trigger,
  } = useFormContext();
  useFocusErrorForm(formState);
  const dispatch = useDispatchApp();

  const errors = formState.errors;
  const { t } = useTranslationApp();

  const assetsGroups = watch("assetsGroups");
  const assetGroupID = sectionSelected.assetsGroupID;
  const index = assetsGroups.findIndex((item) => item.id === assetGroupID);
  const BASIC_PATH = `assetsGroups.[${index}]`;

  const titles = watch(`${BASIC_PATH}.titles`) || [
    { value: "", id: getUniqueID() },
  ];
  const descriptions = watch(`${BASIC_PATH}.descriptions`) || [
    { value: "", id: getUniqueID() },
  ];
  const longHeadlines = watch(`${BASIC_PATH}.longHeadlines`) || [
    { value: "", id: getUniqueID() },
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await waitDelay(1);
      setIsLoading(false);
    })();
  }, [sectionSelected]);

  const onAddText = ({ type }) => {
    const currentTexts = watch(`${BASIC_PATH}.${type}`) || [];
    setValue(`${BASIC_PATH}.${type}`, [
      ...currentTexts,
      { value: "", id: getUniqueID() },
    ]);
  };

  const onRemoveText = ({ type, index }) => {
    const currentTexts = watch(`${BASIC_PATH}.${type}`);
    setValue(
      `${BASIC_PATH}.${type}`,
      currentTexts.filter((_, i) => i !== index)
    );
    trigger(`${BASIC_PATH}.${type}`);
  };

  const onGenerateText = async ({ type, index }) => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
      })
    );
    if (isBlocked) return;

    const currentTexts = getValues(`${BASIC_PATH}.${type}`) || [];

    const destinationUrl = getValues(`${BASIC_PATH}.destinationUrl`);
    const objective = getValues(`platformsData.${platform}.objective`);

    const response = await dispatch(
      generateAdsCampaignDescription({
        objective,
        platform,
        websiteURL: destinationUrl,
        language: getValues("language"),
        type,
        currentTexts: getAdsTextsFormatted({
          texts: currentTexts,
          format: "save",
        }),
      })
    );

    if (response) {
      setValue(`${BASIC_PATH}.${type}.[${index}].value`, response);
      trigger(`${BASIC_PATH}.${type}`);
    }
  };

  if (isLoading) return null;

  return (
    <Grid container spacing={2}>
      <Grid item {...grid}>
        <Box sx={commonSxFields}>
          <Typography variant="body1">
            {t(DESTINATION_URL_PLACEHOLDER)}
          </Typography>
          <TextField
            name={`${BASIC_PATH}.destinationUrl`}
            variant="filled"
            fullWidth
            disabled={isGeneralDisabledFields}
            control={control}
            errors={errors}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 900,
          }}
        >
          {t(ADS_CAMPAIGN_PLATFORM_BRAND_CONFIGURATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item {...grid}>
        <TextField
          name={`${BASIC_PATH}.businessName`}
          label={t(BUSINESS_NAME_PLACEHOLDER)}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item {...grid}>
        <Accordion
          title={t(LOGO_URLS_PLACEHOLDER)}
          sx={commonSxAccordion}
          name={`${BASIC_PATH}.logoUrls`}
          errors={errors}
          showError={true}
        >
          <Controller
            control={control}
            name={`${BASIC_PATH}.logoUrls`}
            render={({ field }) => (
              <MultiContentField
                acceptedFormats={GOOGLE_IMAGE_FORMATS}
                mediaUrls={field.value}
                onChange={(contents) => {
                  const newLogoUrls = contents.map((content) => content.url);
                  field.onChange([...field.value, ...newLogoUrls]);
                }}
                onDelete={(index) => {
                  field.onChange(field.value.filter((_, i) => i !== index));
                }}
              />
            )}
          />
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 900,
          }}
        >
          {t(ADS_CAMPAIGN_PLATFORM_ASSETS_CONFIGURATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item {...grid}>
        <Accordion
          title={t(IMAGES_OF_CAMPAIGN_PLACEHOLDER)}
          sx={commonSxAccordion}
          name={`${BASIC_PATH}.imageUrls`}
          errors={errors}
          showError={true}
        >
          <Typography
            variant="body2"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: t(REQUIRED_IMAGES_GOOGLE_ASSETS_GROUP_HELPER_TEXT),
            }}
          />
          <Controller
            control={control}
            name={`${BASIC_PATH}.imageUrls`}
            render={({ field }) => (
              <MultiContentField
                acceptedFormats={GOOGLE_IMAGE_FORMATS}
                mediaUrls={field.value}
                onChange={(contents) => {
                  const newImageUrls = contents.map((content) => content.url);
                  field.onChange([...field.value, ...newImageUrls]);
                }}
                onDelete={(index) => {
                  field.onChange(field.value.filter((_, i) => i !== index));
                }}
              />
            )}
          />
        </Accordion>
      </Grid>
      <Grid item {...grid}>
        <Accordion
          title={t(VIDEOS_OF_CAMPAIGN_PLACEHOLDER)}
          sx={commonSxAccordion}
          name={`${BASIC_PATH}.videoUrls`}
          errors={errors}
          showError={true}
        >
          <ContainerVideosYoutube name={`${BASIC_PATH}.videoUrls`} />
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 900,
          }}
        >
          {t(ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item {...grid}>
        <AutocompleteField
          control={control}
          name={`${BASIC_PATH}.searchThemes`}
          label={t(SEARCH_THEMES_PLACEHOLDER)}
          errors={errors}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 900,
          }}
        >
          {t(ADS_CAMPAIGNS_PLATFORM_TEXTS_SUB_TITLE)}
        </Typography>
      </Grid>

      <Grid item {...grid}>
        <Box sx={commonSxFields}>
          <Typography variant="body1">{t(LONG_HEADLINES)}</Typography>
          {longHeadlines.map((headline, index) => (
            <TextFieldWithActions
              key={headline.id}
              type="longHeadlines"
              label={`${t(LONG_HEADLINE_PLACEHOLDER)} ${index + 1}`}
              name={`${BASIC_PATH}.longHeadlines.[${index}].value`}
              disabled={isGeneralDisabledFields}
              showAddIcon={
                index === longHeadlines.length - 1 &&
                index < ADS_CAMPAIGN_GOOGLE_MAX_LONG_HEADLINES - 1
              }
              showDeleteIcon={longHeadlines.length > 3}
              maxLength={
                ADS_CAMPAIGN_PLATFORM_LONG_HEADLINE_MAX_LENGTH[platform]
              }
              index={index}
              platform={platform}
              onAdd={() => onAddText({ type: "longHeadlines" })}
              onRemove={() => onRemoveText({ type: "longHeadlines", index })}
              onGenerate={async () => {
                await onGenerateText({ type: "longHeadlines", index });
              }}
              control={control}
              errors={errors}
            />
          ))}
        </Box>
      </Grid>
      <Grid item {...grid}>
        <Box sx={commonSxFields}>
          <Typography variant="body1">{t(DESCRIPTIONS)}</Typography>
          {descriptions.map((description, index) => (
            <TextFieldWithActions
              key={description.id}
              type="descriptions"
              label={`${t(DESCRIPTION_PLACEHOLDER)} ${index + 1}`}
              name={`${BASIC_PATH}.descriptions.[${index}].value`}
              disabled={isGeneralDisabledFields}
              platform={platform}
              showAddIcon={
                index === descriptions.length - 1 &&
                index < ADS_CAMPAIGN_GOOGLE_MAX_DESCRIPTIONS - 1
              }
              showDeleteIcon={descriptions.length > 3}
              firstItemMaxLength={
                ADS_CAMPAIGN_GOOGLE_FIRST_DESCRIPTION_MAX_LENGTH
              }
              maxLength={ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[platform]}
              index={index}
              onAdd={() => onAddText({ type: "descriptions" })}
              onRemove={() => onRemoveText({ type: "descriptions", index })}
              onGenerate={async () => {
                await onGenerateText({ type: "descriptions", index });
              }}
              control={control}
              errors={errors}
            />
          ))}
        </Box>
      </Grid>
      <Grid item {...grid}>
        <Box sx={commonSxFields}>
          <Typography variant="body1">{t(TITLES)}</Typography>
          {titles.map((title, index) => (
            <TextFieldWithActions
              key={title.id}
              type="titles"
              label={`${t(TITLE_PLACEHOLDER)} ${index + 1}`}
              name={`${BASIC_PATH}.titles.[${index}].value`}
              disabled={isGeneralDisabledFields}
              showAddIcon={
                index === titles.length - 1 &&
                index < ADS_CAMPAIGN_GOOGLE_MAX_TITLES - 1
              }
              showDeleteIcon={titles.length > 3}
              platform={platform}
              onAdd={() => onAddText({ type: "titles" })}
              onRemove={() => onRemoveText({ type: "titles", index })}
              firstItemMaxLength={ADS_CAMPAIGN_GOOGLE_FIRST_TITLE_MAX_LENGTH}
              maxLength={ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[platform]}
              index={index}
              onGenerate={async () => {
                await onGenerateText({ type: "titles", index });
              }}
              control={control}
              errors={errors}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

const TextFieldWithActions = ({
  name,
  label,
  disabled,
  control,
  errors,
  rules,
  sx,
  showAddIcon = true,
  showDeleteIcon = true,
  onAdd = () => {},
  onRemove = () => {},
  onGenerate = () => {},
  type = "titles",
  index,
  firstItemMaxLength = 0,
  maxLength = 0,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const getRows = () => {
    if (type === "titles") {
      return { min: 1, max: 1 };
    }
    return { min: 3, max: 3 };
  };

  const rows = getRows();

  const isDisabled = disabled || isGenerating;

  const maxCharacters =
    index === 0 && firstItemMaxLength ? firstItemMaxLength : maxLength;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <TextField
        label={label}
        name={name}
        variant="filled"
        multiline
        showCounterCharacters={true}
        maxCharacters={maxCharacters}
        minRows={rows.min}
        maxRows={rows.max}
        fullWidth
        disabled={disabled}
        control={control}
        errors={errors}
        rules={rules}
        sx={{
          ...sx,
          zIndex: 1,
          position: "relative",
          ".MuiInputBase-root": {
            pb: 4,
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          position: "absolute",
          mb: 1,
          mr: 1,
          right: 0,
          height: "100%",
          mt: -5,
        }}
      >
        {showDeleteIcon && (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={() => onRemove()}
            sx={{
              p: 0.4,
              zIndex: 1,
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {isGenerating ? (
          <Loader
            size={20}
            hasMarginTop={false}
            sx={{
              mb: 0.75,
            }}
          />
        ) : (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={async () => {
              setIsGenerating(true);
              await onGenerate();
              setIsGenerating(false);
            }}
            sx={{
              p: 0.4,
              zIndex: 1,
            }}
          >
            <AutoAwesomeIcon />
          </IconButton>
        )}
        {showAddIcon && (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={() => onAdd()}
            sx={{
              p: 0.4,
              zIndex: 1,
            }}
          >
            <AddIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default AssetsGroupGoogleForm;
