import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { BORDER_RADIUS } from "../../../utils/constants";
import {
  CREATED_AT,
  CAMPAIGN_TYPE_PLACEHOLDER,
  CAMPAIGNS_NOT_FOUND,
  DATE_SHORT_2,
  GOOGLE,
  OBJECTIVE_PLACEHOLDER,
  STATUS_PLACEHOLDER,
  ALREADY_IMPORTED,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import {
  optionsAdsCampaignObjectives,
  optionsAdsCampaignStatus,
  optionsCampaignsTypeGoogleAds,
} from "../../../utils/options";
import Loader from "../../Loaders/Loader";
import { Chip } from "@mui/material";

const sxCutText = (maxLines = 2) => ({
  whiteSpace: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: maxLines,
  WebkitBoxOrient: "vertical",
});

const getObjectiveLabelTranslation = (platform) => {
  switch (platform) {
    case GOOGLE:
      return CAMPAIGN_TYPE_PLACEHOLDER;

    default:
      return OBJECTIVE_PLACEHOLDER;
  }
};
const getObjectiveValueTranslation = (platform, objective) => {
  let objectiveTranslation;
  switch (platform) {
    case GOOGLE:
      objectiveTranslation =
        optionsCampaignsTypeGoogleAds.find(
          (campaignType) => campaignType.value === objective
        )?.label || objective;

      return objectiveTranslation;
    default:
      objectiveTranslation =
        optionsAdsCampaignObjectives.find(
          (campaignType) => campaignType.value === objective
        )?.label || objective;

      return objectiveTranslation;
  }
};

const ImportAdsCampaignList = ({
  data,
  platform,
  lastItemRef,
  loadedLastCampaign,
  selectedCampaign,
  onSelectCampaign,
}) => {
  const { t } = useTranslationApp();

  if (data.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {t(CAMPAIGNS_NOT_FOUND)}
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      {data.map((campaign, index) => (
        <>
          <Grid item xs={12} md={6} key={campaign.id}>
            <Box
              onClick={() => {
                if (campaign.isAlreadyCreated) return;
                onSelectCampaign(campaign);
              }}
              sx={{
                cursor: campaign.isAlreadyCreated ? "default" : "pointer",
                backgroundColor:
                  selectedCampaign === campaign.id
                    ? "white.main"
                    : "background.paperSecondary",
                color: selectedCampaign === campaign.id ? "black" : "white",
                borderRadius: BORDER_RADIUS,
                py: { xs: 2, sm: 2 },
                px: { xs: 2, sm: 3 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    ...sxCutText(1),
                  }}
                >
                  {campaign.name}
                </Typography>
                {campaign.isAlreadyCreated && (
                  <Chip label={t(ALREADY_IMPORTED)} size="small" />
                )}
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography variant="caption" color="fields.placeholder">
                    {t(getObjectiveLabelTranslation(platform))}
                  </Typography>
                  <Typography variant="body2">
                    {t(
                      getObjectiveValueTranslation(platform, campaign.objective)
                    )}
                  </Typography>
                </Box>
                {campaign.creationTime && (
                  <Box>
                    <Typography variant="caption" color="fields.placeholder">
                      {t(CREATED_AT)}
                    </Typography>
                    <Typography variant="body2">
                      {t(DATE_SHORT_2, {
                        date: new Date(campaign.creationTime),
                      })}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography variant="caption" color="fields.placeholder">
                    {t(STATUS_PLACEHOLDER)}
                  </Typography>
                  <Typography variant="body2">
                    {t(
                      optionsAdsCampaignStatus.find(
                        (status) => status.value === campaign.status
                      )?.label
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          {index === data.length - 1 && !loadedLastCampaign && (
            <Grid item xs={12}>
              <Box ref={lastItemRef} />
              <Loader size={20} hasMarginTop={true} />
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};

export default ImportAdsCampaignList;
