import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormContext } from "react-hook-form";
import TextField from "../../../../components/Form/TextField";
import { t } from "i18next";
import {
  WRITE_INSTRUCTIONS_TO_CREATE_YOUR_CAMPAIGN,
  ADS_CAMPAIGN_LANGUAGE_PLACEHOLDER,
  ADS_CAMPAIGN_PLATFORMS_PLACEHOLDER,
  CREATE_A_CAMPAIGN_FOR_BRAND_NAME,
  CREATE_A_CAMPAIGN,
} from "../../../../i18n/keysTranslations";
import Select from "../../../../components/Form/Select";
import { optionsLanguage } from "../../../../utils/options";
import Typography from "@mui/material/Typography";
import AddPlatformsCampaign from "./AddPlatformsCampaign";
import FieldOpenMapRadius from "../../../../components/Maps/FieldOpenMapRadius";

const SetupContainer = () => {
  const {
    control,
    watch,
    setValue,
    brandInformation,
    formState: { errors },
  } = useFormContext();

  const platforms = watch("platforms");
  const locations = watch("locations");
  const language = watch("language");
  return (
    <Box
      sx={{
        display: "flex",
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        maxWidth: 800,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
            }}
          >
            {t(
              brandInformation.businessName
                ? CREATE_A_CAMPAIGN_FOR_BRAND_NAME
                : CREATE_A_CAMPAIGN,
              {
                value: brandInformation.businessName,
              }
            )}
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={4}>
            <Select
              name="language"
              fullWidth
              variant="filled"
              label={t(ADS_CAMPAIGN_LANGUAGE_PLACEHOLDER)}
              doTranslate={true}
              control={control}
              errors={errors}
              options={optionsLanguage}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <FieldOpenMapRadius
              name={"locations"}
              onCallbackSave={(locations) => setValue("locations", locations)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="context"
              placeholder={t(WRITE_INSTRUCTIONS_TO_CREATE_YOUR_CAMPAIGN)}
              variant="filled"
              fullWidth
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: 92,
            }}
          >
            {language && locations?.locations?.length > 0 && (
              <AddPlatformsCampaign
                className={"animate__animated animate__fadeIn animate__faster"}
                label={ADS_CAMPAIGN_PLATFORMS_PLACEHOLDER}
                filterPlatforms={platforms}
                sxButtons={{
                  px: 4,
                  height: 56,
                  maxHeight: "unset",
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SetupContainer;
