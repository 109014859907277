import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  DATE_SHORT_2,
  EMPTY_TRACKING_IDS_GOOGLE,
  EMPTY_TRACKING_IDS_META,
  EMPTY_TRACKING_IDS_TIKTOK,
  FIELD_REQUIRED,
  LAST_RECORDED_EVENT_ON,
  LOADING,
  PIXEL_HAS_NO_RECORDED_EVENTS,
} from "../../i18n/keysTranslations";
import i18next from "i18next";
import { Controller } from "react-hook-form";
import { useDispatchApp } from "../../lib/redux";
import { useTranslationApp } from "../../lib/i18next";
import { startGetAdsCampaignTrackingIDs } from "../../actions/adsCampaigns";
import { GOOGLE, META, TIKTOK } from "../../utils/constants";
import { getReactHookFormNestedError } from "../../utils/errors";

const PLATFORM_EMPTY_TRACKING_ID_LABELS = {
  [META]: EMPTY_TRACKING_IDS_META,
  [TIKTOK]: EMPTY_TRACKING_IDS_TIKTOK,
  [GOOGLE]: EMPTY_TRACKING_IDS_GOOGLE,
};

const SelectTrackingIDField = ({
  control,
  errors = {},
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  name,
  customOnChange,
  label,
  disabled = false,
  placeholder,
  variant = "filled",
  fullWidth,
  helperText,
  platform,
  integrationID,
  adAccountID,
  onLoadOptions = () => {},
  onChangeAction = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const error = getReactHookFormNestedError(errors, name);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const getTrackingIDs = async () => {
    if (!adAccountID || !integrationID) return;

    setLoading(true);
    const response = await dispatch(
      startGetAdsCampaignTrackingIDs({
        platform,
        integrationID,
        adAccountID,
      })
    );
    if (response) {
      setOptions((prevState) => {
        //filter out the options that are already in the state
        const newOptions = response.filter(
          (option) =>
            !prevState.some((prevOption) => prevOption.name === option.name)
        );

        return [...prevState, ...newOptions];
      });
    } else {
      setOptions([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTrackingIDs();
    // eslint-disable-next-line
  }, [adAccountID]);

  useEffect(() => {
    onLoadOptions(options);
    // eslint-disable-next-line
  }, [options]);
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          fullWidth={fullWidth}
          disabled={disabled}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(_, value) => {
            if (customOnChange) {
              customOnChange(value.id);
              onChangeAction(value);
            } else if (value?.id) {
              field.onChange(value.id);
              onChangeAction(value);
            } else {
              field.onChange(null);
              onChangeAction(null);
            }
          }}
          noOptionsText={t(PLATFORM_EMPTY_TRACKING_ID_LABELS[platform])}
          loadingText={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <CircularProgress color="inherit" size={20} />
              {t(LOADING)}
            </Box>
          }
          isOptionEqualToValue={(option, value) => option?.id === value}
          getOptionLabel={(option) => {
            const optionValue = options.find((opt) => opt.id === option);
            if (optionValue) {
              return `${optionValue?.name} - ${optionValue?.id}`;
            }
            if (typeof option === "string") {
              return option;
            }
            return `${option?.name} - ${option?.id}`;
          }}
          options={options}
          loading={loading}
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                component="li"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start !important",
                }}
              >
                <Typography variant="body1">{option.name}</Typography>
                <Typography variant="body2">{`Pixel ID: ${option.id}`}</Typography>

                {platform === META && (
                  <Typography variant="caption">
                    {option.lastFiredTime
                      ? `${t(LAST_RECORDED_EVENT_ON)}: ${t(DATE_SHORT_2, {
                          date: new Date(option.lastFiredTime),
                        })}`
                      : t(PIXEL_HAS_NO_RECORDED_EVENTS)}
                  </Typography>
                )}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              placeholder={field?.value?.length > 0 ? "" : placeholder}
              error={!!error}
              helperText={
                <Box
                  component="span"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{!!error ? error?.message : helperText}</span>
                </Box>
              }
              sx={{
                ".MuiFormHelperText-root": {
                  ml: "0 !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default SelectTrackingIDField;
