import TableRow from "@mui/material/TableRow";
import TableCell from "../../../../BillingAndPayments/components/TableCell";
import {
  AD,
  VALUE_NUMBER,
  VALUE_WITH_CURRENCY_MANUAL_FORMAT,
  VALUE_WITH_PERCENTAGE,
} from "../../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";
import { useFormContext } from "react-hook-form";

const TiktokAdRow = ({ index, indexAd, data }) => {
  const { t } = useTranslationApp();

  const { getCostByMainCurrency } = useFormContext();

  const { cost, currency } = getCostByMainCurrency({
    cost: data.spend,
    currency: data.currency,
  });

  const { cost: costPerMainAction, currency: currencyPerMainAction } =
    getCostByMainCurrency({
      cost: data.costPerMainAction,
      currency: data.currency,
    });

  return (
    <TableRow>
      <TableCell align="center" indexRow={index} sx={{ width: 25 }} />
      <TableCell align="center" indexRow={index} sx={{ width: 250 }}>
        {`${t(AD)} ${indexAd + 1}`}
      </TableCell>
      <TableCell align="center" indexRow={index}>
        {t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
          value: cost,
          currency,
        })}
      </TableCell>
      <TableCell align="center" indexRow={index}>
        {t(VALUE_NUMBER, { value: data.impressions })}
      </TableCell>
      <TableCell align="center" indexRow={index}>
        {t(VALUE_NUMBER, { value: data.click })}
      </TableCell>
      <TableCell align="center" indexRow={index}>
        {t(VALUE_WITH_PERCENTAGE, { value: data.ctr })}
      </TableCell>
      <TableCell align="center" indexRow={index}>
        {t(VALUE_NUMBER, { value: data.mainAction })}
      </TableCell>
      <TableCell align="center" indexRow={index}>
        {t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
          value: costPerMainAction || 0,
          currency: currencyPerMainAction,
        })}
      </TableCell>
    </TableRow>
  );
};

export default TiktokAdRow;
