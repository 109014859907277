import ContainerModalActions from "../../../../components/Containers/ContainerModalActions";
import {
  BUTTON_GO_BACK,
  BUTTON_APPLY_OPTIMIZATIONS,
  MODAL_PREVIEW_OPTIMIZATIONS_TITLE,
  MODAL_PREVIEW_OPTIMIZATIONS_DESCRIPTION,
  ERROR_OCCURRED,
  BUTTON_TRY_AGAIN,
  AD_GROUP,
  AD,
  COLUMN_NAME_FIELD,
  COLUMN_NAME_CURRENT_VALUE,
  COLUMN_NAME_NEW_VALUE,
  EMPTY_RESULTS_SHOW,
  ACTION,
  DELETE,
  COLUMN_NAME_ACTION,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import ModalBasicLayout from "../../../../components/Modal/ModalBasicLayout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { useDispatchApp } from "../../../../lib/redux";
import {
  startGetDynamicInsightOptimization,
  startGetModificationsInsight,
  startSetModificationsInsight,
} from "../../../../actions/adsCampaigns";
import { useEffect } from "react";
import { Skeleton, TextField } from "@mui/material";
import Button from "../../../../components/Buttons/Button";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import {
  capitalizeFirstLetter,
  getFormattedCampaign,
} from "../../../../utils/string";
import TableContainer from "../../../../components/Tables/TableContainer";
import TableCell from "../../../BillingAndPayments/components/TableCell";
import { getValueFromPath } from "../../../../utils/object";
import { setNestedValue } from "../../../../utils/forms";
import _ from "lodash";
import IconButton from "../../../../components/Buttons/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const INSIGHTS_ARRAY_CATEGORIES = ["keywordsEdit", "texts"];

const ModalPreviewOptimization = ({
  modalOpen,
  insight,
  insightIndex,
  onCloseModal = () => {},
}) => {
  const { t } = useTranslationApp();
  const [isGenerating, setIsGenerating] = useState(true);
  const [isError, setIsError] = useState(false);
  const [modifications, setModifications] = useState([]);
  const { campaignID, getValues, setValue } = useFormContext();
  const dispatch = useDispatchApp();

  const getInitialData = async () => {
    setIsGenerating(true);
    setIsError(false);

    if (insight.modifications) {
      setModifications(insight.modifications);
      setIsGenerating(false);
      return;
    }

    const responseCurrentModifications = await dispatch(
      startGetModificationsInsight({
        campaignID,
        platform: insight.platform,
        insightIndex,
      })
    );

    if (responseCurrentModifications.ok) {
      setModifications(responseCurrentModifications.data);
      setValue(
        `report.states.${insight.platform}.insights.[${insightIndex}].modifications`,
        responseCurrentModifications.data
      );
      setIsGenerating(false);
      return;
    }

    const response = await dispatch(
      startGetDynamicInsightOptimization({
        campaignID,
        insight,
      })
    );

    if (!response.ok) {
      setIsError(true);
      setIsGenerating(false);
      setModifications([]);
      return;
    }

    const data = response.data;

    const modifications = data.modifications || [];

    setValue(
      `report.states.${insight.platform}.insights.[${insightIndex}].modifications`,
      modifications
    );

    dispatch(
      startSetModificationsInsight({
        campaignID,
        platform: insight.platform,
        insightIndex,
        modifications,
      })
    );

    setModifications(modifications);
    setIsGenerating(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  const onApplyOptimizations = () => {
    const campaign = _.cloneDeep(getValues());
    const campaignFormatted = getFormattedCampaign({
      data: campaign,
      format: "save",
    });

    const isInsightArray = INSIGHTS_ARRAY_CATEGORIES.includes(insight.category);

    // Helper function to navigate to a specific path in the object
    const navigateToPath = (obj, pathSegments) => {
      let currentObj = obj;
      pathSegments.forEach((segment, index) => {
        // Skip processing the last segment
        if (index === pathSegments.length - 1) return;

        const key = segment;

        // If we're not at the last segment and the next level doesn't exist, create it
        if (!(key in currentObj) && index < pathSegments.length - 1) {
          currentObj[key] = {};
        }

        // Move to the next level in the object
        currentObj = currentObj[key];
      });

      return {
        currentObj,
        lastSegment: pathSegments[pathSegments.length - 1],
      };
    };

    // Apply each modification
    modifications.forEach((mod) => {
      const { path, value, action } = mod;
      const pathSegments = path.split("/");

      const field = pathSegments[pathSegments.length - 1];
      const isFieldIndex = _.isNumber(Number(field)) && !_.isNaN(Number(field));
      const isArray = isFieldIndex;

      // Navigate to the correct nested path
      const { currentObj, lastSegment } = navigateToPath(
        campaignFormatted,
        pathSegments
      );

      // Set the value at the final path segment
      if (isArray && action === "remove") {
        currentObj[lastSegment] = null;
      } else if (isArray && action === "add") {
        //modify the previous group path
        const previousGroupPath = pathSegments.slice(0, -1).join("/");
        const {
          currentObj: previousGroupObj,
          lastSegment: previousGroupLastSegment,
        } = navigateToPath(campaignFormatted, previousGroupPath.split("/"));
        previousGroupObj[previousGroupLastSegment] = [
          ...previousGroupObj[previousGroupLastSegment],
          value,
        ];
      } else {
        currentObj[lastSegment] = value;
      }
    });

    if (isInsightArray) {
      // Get all paths that have a field index
      const fieldIndexPaths = modifications
        .map((mod) => {
          const pathSegments = mod.path.split("/");
          const field = pathSegments[pathSegments.length - 1];
          const isFieldIndex =
            _.isNumber(Number(field)) && !_.isNaN(Number(field));
          if (isFieldIndex) {
            return mod.path.split("/").slice(0, -1).join("/");
          }
          return null;
        })
        .filter(Boolean);

      // Process unique paths with array modifications
      [...new Set(fieldIndexPaths)].forEach((path) => {
        const value = getValueFromPath(campaignFormatted, path);
        const valueFiltered = value.filter(Boolean);

        const pathSegments = path.split("/");
        const { currentObj, lastSegment } = navigateToPath(
          campaignFormatted,
          pathSegments
        );

        // Update with filtered array (removing null values)
        currentObj[lastSegment] = valueFiltered;
      });
    }

    const campaignModified = getFormattedCampaign({
      data: campaignFormatted,
      format: "load",
    });

    setNestedValue({
      data: campaignModified,
      setValue,
    });

    setValue(
      `report.states.${insight.platform}.insights.[${insightIndex}].isApplied`,
      true
    );

    onCloseModal();
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      maxWidth="lg"
      title={t(MODAL_PREVIEW_OPTIMIZATIONS_TITLE)}
      description={t(MODAL_PREVIEW_OPTIMIZATIONS_DESCRIPTION)}
    >
      <Box sx={{ mt: 2 }}>
        {isGenerating && <SkeletonLoader />}
        {isError && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography>{t(ERROR_OCCURRED)}</Typography>
            <Button
              onClick={() => getInitialData()}
              sx={{
                width: "auto",
              }}
            >
              {t(BUTTON_TRY_AGAIN)}
            </Button>
          </Box>
        )}

        {!isError && !isGenerating && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1">{`${t(ACTION)}: ${
              insight.action
            }`}</Typography>
            <TableModifications
              modifications={modifications}
              onChangeModifications={setModifications}
            />
          </Box>
        )}
      </Box>
      {!isError && (
        <DialogActions sx={{ p: 0 }}>
          <ContainerModalActions
            hasPadding={true}
            propsSecondaryButton={{
              showButton: true,
              onClick: onCloseModal,
              text: BUTTON_GO_BACK,
            }}
            propsPrimaryButton={{
              showButton: true,
              text: BUTTON_APPLY_OPTIMIZATIONS,
              disabled: isGenerating || modifications.length === 0,
              type: "submit",
              onClick: () => {
                onApplyOptimizations();
                onCloseModal();
              },
            }}
          />
        </DialogActions>
      )}
    </ModalBasicLayout>
  );
};

const TableModifications = ({
  modifications = [],
  onChangeModifications = () => {},
}) => {
  const { getValues } = useFormContext();
  const campaign = getValues();
  const campaignFormatted = getFormattedCampaign({
    data: campaign,
    format: "save",
  });
  const { t } = useTranslationApp();

  const KNOWN_GROUPS = {
    adsGroups: {
      label: t(AD_GROUP),
      displayField: "name",
    },
    ads: {
      label: t(AD),
      displayField: "name",
    },
  };

  const analyzePaths = () => {
    const structures = new Set();

    modifications.forEach((mod) => {
      const segments = mod.path.split("/");
      segments.forEach((segment) => {
        if (KNOWN_GROUPS[segment]) {
          structures.add(segment);
        }
      });
    });

    return Array.from(structures);
  };

  const getColumns = () => {
    const structures = analyzePaths();
    return structures.map((structure) => ({
      id: structure,
      header: KNOWN_GROUPS[structure].label || structure,
    }));
  };

  const getColumnValueGroup = (path, columnStructure) => {
    const pathSegments = path.split("/");
    const targetGroup = columnStructure;

    // Find the index of the target group
    let foundIndex = pathSegments.findIndex(
      (segment) => segment === targetGroup
    );

    if (foundIndex >= 0 && foundIndex + 1 < pathSegments.length) {
      const id = pathSegments[foundIndex + 1];
      const groupConfig = KNOWN_GROUPS[targetGroup];
      if (groupConfig.displayField) {
        // Construct the full path up to the target group
        const fullPath = pathSegments.slice(0, foundIndex + 2).join("/");
        const displayPath = `${fullPath}/${groupConfig.displayField}`;
        const displayValue = getValueFromPath(campaignFormatted, displayPath);
        return displayValue || id;
      }

      return id;
    }

    return "-";
  };

  const getCurrentValue = (path, action) => {
    if (action === "add") {
      return "-";
    }
    const value = getValueFromPath(campaignFormatted, path);
    if (_.isArray(value)) {
      return value
        .map((item) => {
          if (_.isObject(value)) {
            return item.name;
          }
          return item;
        })
        .join(", ");
    }
    return value || "-";
  };

  const getNewValue = (value, action) => {
    switch (action) {
      case "remove":
        return t(DELETE);
      case "add":
        return value;
      case "edit":
        return value;

      default:
        break;
    }
  };

  const getFieldName = (path) => {
    const pathSegments = path.split("/");
    const field = pathSegments.pop();
    const isFieldIndex = _.isNumber(Number(field)) && !_.isNaN(Number(field));
    if (isFieldIndex) {
      const group = pathSegments.pop();
      return group;
    }
    return field;
  };

  const columns = getColumns();

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        keys={modifications}
        hasPagination={false}
        hasMarginBottom={false}
      >
        <TableHead>
          <TableRow>
            {columns.map(({ header, id }) => (
              <TableCell key={id} align="center" isHeader={true}>
                {header}
              </TableCell>
            ))}
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_FIELD)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_CURRENT_VALUE)}
            </TableCell>
            <TableCell
              align="center"
              isHeader={true}
              sx={{
                minWidth: 250,
              }}
            >
              {t(COLUMN_NAME_NEW_VALUE)}
            </TableCell>
            <TableCell align="center" isHeader={true}>
              {t(COLUMN_NAME_ACTION)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modifications.map((mod, index) => {
            const currentValue = getCurrentValue(mod.path, mod.action);
            const newValue = getNewValue(mod.value, mod.action);
            return (
              <TableRow key={index}>
                {columns.map(({ id }) => (
                  <TableCell key={id} align="center" indexRow={index}>
                    {getColumnValueGroup(mod.path, id)}
                  </TableCell>
                ))}
                <TableCell align="center" indexRow={index}>
                  {capitalizeFirstLetter(getFieldName(mod.path))}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  {currentValue}
                </TableCell>
                <TableCell
                  align="center"
                  indexRow={index}
                  sx={{
                    minWidth: 250,
                  }}
                >
                  {mod.action !== "remove" ? (
                    <TextField
                      value={newValue}
                      multiline
                      minRows={1}
                      maxRows={5}
                      onChange={(e) => {
                        onChangeModifications((currentModifications) => {
                          const newModifications = currentModifications.map(
                            (mod, i) => {
                              if (i === index) {
                                return { ...mod, value: e.target.value };
                              }
                              return mod;
                            }
                          );
                          return newModifications;
                        });
                      }}
                      size="small"
                      fullWidth
                    />
                  ) : (
                    <Typography>{newValue}</Typography>
                  )}
                </TableCell>
                <TableCell align="center" indexRow={index}>
                  <IconButton
                    onClick={() => {
                      onChangeModifications((currentModifications) => {
                        const newModifications = currentModifications.filter(
                          (_, i) => i !== index
                        );
                        return newModifications;
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
          {modifications.length === 0 && (
            <TableRow>
              <TableCell
                colSpan={columns.length + 4}
                sx={{ textAlign: "center" }}
              >
                {t(EMPTY_RESULTS_SHOW)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

const SkeletonLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Skeleton variant="rounded" height={40} width="100%" />
      <Skeleton variant="rounded" height={40} width="100%" />
      <Skeleton variant="rounded" height={40} width="100%" />
      <Skeleton variant="rounded" height={40} width="100%" />
    </Box>
  );
};

export default ModalPreviewOptimization;
