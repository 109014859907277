import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import {
  CREATE_ADS_CAMPAIGN_INTRO_TITLE,
  CREATE_ADS_CAMPAIGN_INTRO_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_OPTIMIZE_WITH_AI,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_MANAGE_SIMPLE,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ANALYZE_PERFORMANCE,
  CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ALL_IN_ONE_PLACE,
  CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_DESCRIPTION,
  ALERT_FILL_BRAND_SETUP_TITLE,
  ALERT_FILL_BRAND_SETUP_DESCRIPTION,
  BUTTON_CONTINUE,
  BUTTON_COMPLETE_INFORMATION,
  BUTTON_CREATE_FROM_SCRATCH,
  BUTTON_IMPORT_FROM_PLATFORM,
  BUTTON_ADD,
} from "../../../i18n/keysTranslations";
import { useNavigate } from "react-router-dom";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp } from "../../../lib/redux";
import {
  getIsBlockedActions,
  getNameBusinessTheme,
  getUserIsViewer,
} from "../../../actions/getters";
import { ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION } from "../../../utils/constants";
import { useEffect, useState } from "react";
import { ConfirmAlert } from "../../../components/Alerts/Alerts";
import { startGetBrandInformation } from "../../../actions/adsCampaigns";
import ButtonMenu from "../../../components/Buttons/ButtonMenu";
import ModalImportAdsCampaign from "../../../components/Modal/ModalImportAdsCampaign/ModalImportAdsCampaign";

const AdsCampaignsIntro = ({ onCallback = () => {} }) => {
  const [brandInformation, setBrandInformation] = useState({});
  const [modalImportAdsCampaignOpen, setModalImportAdsCampaignOpen] =
    useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const sxSubContainers = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 2,
    width: "100%",
    maxWidth: "sm",
  };
  const sxItems = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 2,
  };

  const variantTextItem = isMobile ? "body2" : "body1";

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());
  const navigate = useNavigate();
  const isViewer = dispatch(getUserIsViewer());
  const hasBrandContext = Boolean(
    brandInformation.businessName && brandInformation.businessDescription
  );

  const getBrandInformation = async () => {
    const response = await dispatch(startGetBrandInformation());
    if (response.ok) {
      const { data } = response;
      setBrandInformation(data);
    }
  };

  const onRedirectToCreateCampaign = async () => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION,
        showUsageConfirmationAlert: false,
      })
    );
    if (isBlocked) return;

    if (!hasBrandContext) {
      const response = await ConfirmAlert({
        title: t(ALERT_FILL_BRAND_SETUP_TITLE),
        text: t(ALERT_FILL_BRAND_SETUP_DESCRIPTION),
        cancelButtonText: t(BUTTON_CONTINUE),
        confirmButtonText: t(BUTTON_COMPLETE_INFORMATION),
      });
      if (response.isConfirmed) {
        navigate("/configuration");
        return;
      }

      if (response.isDismissed && response.dismiss === "backdrop") {
        return;
      }
    }
    onCallback();
    navigate("/ads-campaign");
  };

  useEffect(() => {
    getBrandInformation();
  });

  return (
    <Box
      className="animate__animated animate__fadeIn"
      sx={{
        display: "flex",
        height: {
          xs: "calc(100vh - 104px)",
          sm: "calc(100vh - 140px)",
        },
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        flexDirection: { xs: "column", lg: "row" },
        px: {
          xs: 1.5,
          md: 4,
        },
      }}
    >
      <Box sx={sxSubContainers}>
        <Box sx={sxItems}>
          <Typography
            variant={isMobile ? "h3" : "h2"}
            sx={{
              fontWeight: 900,
              background: GRADIENT_PURPLE_FUCHSIA,
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {t(CREATE_ADS_CAMPAIGN_INTRO_TITLE, {
              NAME_BUSINESS,
            })}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_DESCRIPTION)}
          </Typography>
        </Box>
        <Box sx={{ ...sxItems, gap: 0 }}>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_CREATE_CAMPAIGN)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_OPTIMIZE_WITH_AI)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_MANAGE_SIMPLE)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ANALYZE_PERFORMANCE)}
          </Typography>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_FEATURE_ALL_IN_ONE_PLACE)}
          </Typography>
        </Box>
        <Box sx={sxItems}>
          <Typography variant={variantTextItem}>
            {t(CREATE_ADS_CAMPAIGN_INTRO_CALL_TO_ACTION_DESCRIPTION)}
          </Typography>
          {!isViewer && (
            <ButtonMenu
              textButton={BUTTON_ADD}
              options={[
                {
                  label: BUTTON_CREATE_FROM_SCRATCH,
                  onClick: onRedirectToCreateCampaign,
                },
                {
                  label: BUTTON_IMPORT_FROM_PLATFORM,
                  onClick: () => setModalImportAdsCampaignOpen(true),
                },
              ]}
              sx={{
                background: GRADIENT_PURPLE_FUCHSIA,
                color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                width: {
                  xs: "100%",
                  sm: "min-content",
                },
              }}
              onClick={onRedirectToCreateCampaign}
            />
          )}
        </Box>
      </Box>
      {modalImportAdsCampaignOpen && (
        <ModalImportAdsCampaign
          modalOpen={modalImportAdsCampaignOpen}
          onCloseModal={() => setModalImportAdsCampaignOpen(false)}
        />
      )}
    </Box>
  );
};

export default AdsCampaignsIntro;
