import Box from "@mui/material/Grid";
import IconButtonAddContent from "../Buttons/IconButtonAddContent";
import { formatMediaUrl } from "../../services/adsCampaigns";
import { useDispatchApp } from "../../lib/redux";
import { getPathDatabaseByRole } from "../../actions/auth";
import CardMedia from "../Media/CardMedia";
import ContainerOverlay from "../Containers/ContainerOverlay";
import IconButton from "../Buttons/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import { getUniqueID } from "../../utils/numbers";

const MultiContentField = ({
  id = getUniqueID(),
  label = "",
  acceptedFormats,
  mediaUrls = [],
  propsPostsAdsPlatforms = {},
  showGalleryAdsPlatformsOption = true,
  onChange = () => {},
  onDelete = () => {},
  disabled = false,
}) => {
  const dispatch = useDispatchApp();
  const { userID } = dispatch(getPathDatabaseByRole());

  return (
    <Box>
      <Typography variant="body1">{label}</Typography>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          gap: 1.5,
          flexWrap: "wrap",
        }}
      >
        {!disabled && (
          <IconButtonAddContent
            id={id}
            acceptedFormats={acceptedFormats}
            showGenerateWithAIOption={false}
            showGalleryAdsPlatformsOption={showGalleryAdsPlatformsOption}
            propsPostsAdsPlatforms={propsPostsAdsPlatforms}
            onAddContent={(urls) => {
              const contents = urls.map((url) => formatMediaUrl(url));
              onChange(contents);
            }}
            multiple={true}
            pathStorage={`adsCampaigns/${userID}/`}
            mode="icon"
            sx={{
              width: 100,
              height: 100,
            }}
          />
        )}
        {mediaUrls.map((url, index) => {
          return (
            <Box
              key={index}
              sx={{
                position: "relative",
                "&:hover .overlay": {
                  opacity: 1,
                },
              }}
            >
              <CardMedia
                url={url}
                sx={{
                  objectFit: "cover",
                  borderRadius: 3,
                  height: 100,
                  width: 100,
                }}
                propsType={{
                  controls: false,
                  autoplay: false,
                }}
              />
              <ContainerOverlay
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  top: 0,
                  right: 0,
                }}
              >
                <IconButton
                  edge="start"
                  sx={{ p: 0 }}
                  onClick={() => {
                    onDelete(index);
                  }}
                >
                  <DeleteIcon
                    sx={{
                      color: "white !important",
                    }}
                  />
                </IconButton>
              </ContainerOverlay>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default MultiContentField;
