import { useTranslationApp } from "../../../lib/i18next";
import ModalBasicLayout from "../../Modal/ModalBasicLayout";
import ContainerModalActions from "../../Containers/ContainerModalActions";
import Box from "@mui/material/Box";
import {
  BUTTON_CANCEL,
  BUTTON_SAVE,
  FIELD_REQUIRED,
  FIELD_THIS_KEYWORD_ALREADY_EXIST_IN_YOUR_LIST,
  KEYWORD_MATCH_TYPE_PLACEHOLDER,
  KEYWORD_PLACEHOLDER,
  MODAL_EDIT_KEYWORD_TITLE,
} from "../../../i18n/keysTranslations";
import { useForm } from "react-hook-form";
import { optionsKeywordMatchType } from "../../../utils/options";
import TextField from "../../Form/TextField";
import Select from "../../Form/Select";

const getKeywordCleaned = (text) => {
  return text.toLowerCase().trim().replace(/["']/g, "");
};

const getKeywordMatchedType = ({ text, matchType }) => {
  switch (matchType) {
    case "exact":
      return `[${text}]`;
    case "phrase":
      return `"${text}"`;
    default:
      return text;
  }
};

const KeywordModal = ({
  modalOpen,
  onCloseModal,
  onSubmit: onSubmitModal = () => {},
  currentKeywords = [],
  initialData,
}) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      text: initialData?.text || "",
      matchType: initialData?.matchType || "broad",
    },
  });

  const { t } = useTranslationApp();

  const onSubmit = (data) => {
    const { text, matchType } = data;
    const keywordCleaned = getKeywordCleaned(text);
    const keywordMatchedType = getKeywordMatchedType({
      text: keywordCleaned,
      matchType,
    });

    const originalKeyword = initialData?.original;
    const isKeywordRepeated =
      currentKeywords.indexOf(keywordMatchedType) !== -1;
    const isSameOriginalKeyword = originalKeyword === keywordMatchedType;

    if (isKeywordRepeated && !isSameOriginalKeyword) {
      setError("text", {
        message: t(FIELD_THIS_KEYWORD_ALREADY_EXIST_IN_YOUR_LIST),
      });
      return;
    }

    onSubmitModal(keywordMatchedType);
    onCloseModal();
  };

  const title = t(MODAL_EDIT_KEYWORD_TITLE);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      maxWidth="xs"
      title={title}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
      >
        <TextField
          name="text"
          label={t(KEYWORD_PLACEHOLDER)}
          fullWidth
          variant="filled"
          control={control}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
          errors={errors}
        />
        <Select
          name="matchType"
          label={t(KEYWORD_MATCH_TYPE_PLACEHOLDER)}
          control={control}
          variant="filled"
          fullWidth
          doTranslate={true}
          options={optionsKeywordMatchType}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
          errors={errors}
        />
        <ContainerModalActions
          hasPadding={false}
          propsSecondaryButton={{
            showButton: true,
            onClick: onCloseModal,
            text: BUTTON_CANCEL,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_SAVE,
            type: "button",
            onClick: handleSubmit(onSubmit),
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default KeywordModal;
