import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { GoogleMapsContext, useMapsLibrary } from "@vis.gl/react-google-maps";

const usePolygon = (props) => {
  const {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    encodedPaths,
    ...polygonOptions
  } = props;

  // Evitamos que los cambios en los callbacks disparen el useEffect innecesariamente
  const callbacks = useRef({});
  Object.assign(callbacks.current, {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
  });

  const geometryLibrary = useMapsLibrary("geometry");

  // Creamos la instancia del polígono
  const polygonRef = useRef(new window.google.maps.Polygon());
  const polygon = polygonRef.current;

  // Actualizamos las opciones del polígono
  useMemo(() => {
    polygon.setOptions(polygonOptions);
  }, [polygon, polygonOptions]);

  const map = useContext(GoogleMapsContext)?.map;

  // Actualizamos la propiedad "paths" usando la ruta codificada (si existe)
  useMemo(() => {
    if (!encodedPaths || !geometryLibrary) return;
    const paths = encodedPaths.map((path) =>
      geometryLibrary.encoding.decodePath(path)
    );
    polygon.setPaths(paths);
  }, [polygon, encodedPaths, geometryLibrary]);

  // Una vez que el mapa esté disponible, añadimos el polígono a él
  useEffect(() => {
    if (!map) {
      if (map === undefined) {
        console.error("<Polygon> has to be inside a Map component.");
      }
      return;
    }

    polygon.setMap(map);

    return () => {
      polygon.setMap(null);
    };
  }, [map, polygon]);

  // Adjuntamos y re-adjuntamos los manejadores de eventos
  useEffect(() => {
    if (!polygon) return;

    const gme = window.google.maps.event;
    [
      ["click", "onClick"],
      ["drag", "onDrag"],
      ["dragstart", "onDragStart"],
      ["dragend", "onDragEnd"],
      ["mouseover", "onMouseOver"],
      ["mouseout", "onMouseOut"],
    ].forEach(([eventName, eventCallback]) => {
      gme.addListener(polygon, eventName, (e) => {
        const callback = callbacks.current[eventCallback];
        if (callback) callback(e);
      });
    });

    return () => {
      gme.clearInstanceListeners(polygon);
    };
  }, [polygon]);

  return polygon;
};

const Polygon = forwardRef((props, ref) => {
  const polygon = usePolygon(props);

  useImperativeHandle(ref, () => polygon, [polygon]);

  return null;
});

Polygon.displayName = "Polygon";

export default Polygon;
