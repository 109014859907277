import Box from "@mui/material/Box";
import LoadingButton from "../../../../components/Buttons/LoadingButton";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  ADD_PLATFORMS,
  ADS_CAMPAIGN_HAS_BEEN_SAVE,
  BUTTON_EDIT_BUDGET,
  BUTTON_PUBLISH_CAMPAIGN,
  BUTTON_SAVE_AND_PUBLISH,
  BUTTON_SAVE_CHANGES,
  CREATIFY_VIDEO_ERROR_RENDER_CLARIFICATION,
  CREATIFY_VIDEO_RENDERING_CLARIFICATION,
  CREATIFY_VIDEO_RENDERING_CLARIFICATION_AND_CREATE_CAMPAIGN,
  CREATING_AD_GROUPS,
  CREATING_ADS,
  CREATING_CAMPAIGN,
  ERROR,
  ERROR_PROCESSING_CAMPAIGNS,
  GETTING_REQUIRED_INFORMATION,
  READY,
  SAVING_INFORMATION,
  UPLOADING_CONTENTS,
  VERIFYING_PLATFORMS_INFORMATION,
} from "../../../../i18n/keysTranslations";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../utils/colors";
import AddPlatformsCampaign from "../Setup/AddPlatformsCampaign";
import { useFormContext } from "react-hook-form";
import {
  getAdsWithUnRenderedAndFailedRenderedMedia,
  getCampaignUpdatedByStatus,
} from "../../../../services/adsCampaigns";
import {
  startCreateAdsCampaign,
  startSaveAdsCampaign,
  startSaveAdsCampaignDraft,
} from "../../../../actions/adsCampaigns";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import {
  ConfirmAlert,
  SimpleAlert,
} from "../../../../components/Alerts/Alerts";
import { useRef, useState } from "react";
import {
  ADS_CAMPAIGN_RENDERED_VIDEO_ACTION,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  AFTER_RENDER_ACTION_DOWNLOAD,
  AFTER_RENDER_ACTION_PUBLISH,
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  VIDEO_DONE_STATUS_CREATIFY,
  VIDEO_IN_QUEUE_STATUS_CREATIFY,
} from "../../../../utils/constants";
import { setNestedValue } from "../../../../utils/forms";
import {
  getIntegrationKey,
  getIsBlockedActions,
} from "../../../../actions/getters";
import ContainerBudgetAlert from "../../../../components/Containers/ContainerBudgetAlert";
import ModalConnectAccounts from "../../../../components/Modal/ModalConnectAccounts";
import BackdropLoaderRenderMediaCreatify from "../../../../components/Loaders/BackdropLoaderRenderMediaCreatify";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  startGetAwsDownloadUrl,
  startRenderVideoFromCreatify,
} from "../../../../actions/user";
import { createPortal } from "react-dom";
import LinearLoaderWithTime from "../../../../components/Loaders/LinearLoaderWithTime";
import Backdrop from "@mui/material/Backdrop";
import { getPathDatabaseByRole } from "../../../../actions/auth";

const sxButtonGradient = {
  background: GRADIENT_PURPLE_FUCHSIA,
  color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
};

const BottomBar = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [modalConnectAccountsOpen, setModalConnectAccountsOpen] =
    useState(false);
  const [isValidatingIfNeedRenderMedia, setIsValidatingIfNeedRenderMedia] =
    useState(false);
  const renderMediaDataRef = useRef([]);

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { userID } = dispatch(getPathDatabaseByRole());
  const {
    campaignID,
    getValues,
    setValue,
    watch,
    trigger,
    isGenerating,
    isUpdating,
    hasSomePlatformDraft,
    onChangeDataModalErrorsCampaigns,
    onChangeModalErrorsCampaignsOpen,
    onChangeIsUpdating,
    formState: { errors },
    onChangeIsForcedSetupPage,
    onChangeSectionSelected,
  } = useFormContext();

  const afterRenderAction = params.get("afterRenderAction");
  const integrations = useSelectorApp((state) => state.user.integrations);
  const isRenderMedia = renderMediaDataRef?.current?.length > 0;
  const platforms = watch("platforms");

  const onSaveDraftCampaign = async (e, withAlertSave = true) => {
    const campaign = getValues();
    const objectUpdate = getCampaignUpdatedByStatus({
      campaign,
      status: campaign.states.general,
      target: "general",
    });
    setIsSaving(true);
    onChangeIsUpdating(true);
    const response = await dispatch(
      startSaveAdsCampaignDraft({
        campaign: objectUpdate,
        campaignID,
      })
    );
    setIsSaving(false);
    onChangeIsUpdating(false);
    if (response) {
      setNestedValue({
        data: objectUpdate,
        setValue,
      });
      if (withAlertSave) {
        SimpleAlert({
          title: t(ADS_CAMPAIGN_HAS_BEEN_SAVE),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    }
  };
  const onCreateCampaign = async (e, withAlertBudget = true) => {
    let showModalConnectAccounts = false;

    platforms.forEach((platform) => {
      const integrationKey = getIntegrationKey(platform, true);

      if (!integrations[integrationKey]) {
        showModalConnectAccounts = true;
      }
    });

    if (showModalConnectAccounts) {
      setModalConnectAccountsOpen(true);
      return;
    }

    const isValidGlobal = await trigger();
    if (!isValidGlobal) {
      if (errors.context || errors.language || errors.locations) {
        onChangeIsForcedSetupPage(true);
        onChangeSectionSelected({
          platform: undefined,
          adGroupID: undefined,
          adID: undefined,
          from: "addPlatforms",
        });
      }
      return;
    }
    const data = getValues();

    if (withAlertBudget) {
      const result = await ConfirmAlert({
        confirmButtonText: t(BUTTON_PUBLISH_CAMPAIGN),
        cancelButtonText: t(BUTTON_EDIT_BUDGET),
        html: <ContainerBudgetAlert campaign={data} dispatch={dispatch} />,
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    let statusCampaign = ADS_CAMPAIGN_STATUS_ACTIVE;

    setIsValidatingIfNeedRenderMedia(true);
    const responseValidateRenderedMedia =
      await onValidateAdsWithRenderedMedia();
    setIsValidatingIfNeedRenderMedia(false);

    if (!responseValidateRenderedMedia) {
      return;
    }

    const { hasRenderFailedMedia, hasUnRenderedMedia } =
      responseValidateRenderedMedia;

    if (hasRenderFailedMedia) {
      SimpleAlert({
        title: t(ERROR),
        text: t(CREATIFY_VIDEO_ERROR_RENDER_CLARIFICATION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return;
    }
    if (hasUnRenderedMedia) {
      return;
    }

    setIsCreating(true);
    onChangeIsUpdating(true);

    const campaign = {
      ...data,
      status: statusCampaign,
    };

    let response = await dispatch(
      startCreateAdsCampaign({ ...campaign, campaignID })
    );

    setIsCreating(false);
    onChangeIsUpdating(false);

    if (response.ok) {
      const campaign = response.campaign;
      setNestedValue({
        data: campaign,
        setValue,
      });
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    } else if (response.code === ERROR_PROCESSING_CAMPAIGNS) {
      setNestedValue({
        data: response.campaign,
        setValue,
      });
      onChangeDataModalErrorsCampaigns({
        createdCampaigns: response?.createdCampaigns,
        failedCampaigns: response?.failedCampaigns,
        errors: response?.errors,
      });
      onChangeModalErrorsCampaignsOpen(true);
    }
  };

  const onSaveCampaign = async (e, withAlertSave = true) => {
    const isValid = await trigger();
    if (!isValid) {
      if (errors.context || errors.language || errors.locations) {
        onChangeIsForcedSetupPage(true);
        onChangeSectionSelected({
          platform: undefined,
          adGroupID: undefined,
          adID: undefined,
          from: "addPlatforms",
        });
      }
      return;
    }
    const objectUpdate = getCampaignUpdatedByStatus({
      campaign: getValues(),
      status: getValues("states.general"),
      target: "general",
    });

    setIsCreating(true);
    onChangeIsUpdating(true);

    const response = await dispatch(
      startSaveAdsCampaign({
        campaign: objectUpdate,
        campaignID,
      })
    );

    setIsCreating(false);
    onChangeIsUpdating(false);

    if (response.ok) {
      setNestedValue({
        data: response.campaign,
        setValue,
      });
      if (withAlertSave) {
        SimpleAlert({
          title: t(ADS_CAMPAIGN_HAS_BEEN_SAVE),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    } else if (response.code === ERROR_PROCESSING_CAMPAIGNS) {
      onChangeDataModalErrorsCampaigns({
        createdCampaigns: response?.createdCampaigns,
        failedCampaigns: response?.failedCampaigns,
        errors: response?.errors,
      });
      onChangeModalErrorsCampaignsOpen(true);
    }
  };
  const onValidateAdsWithRenderedMedia = async () => {
    const data = getValues();

    const { adsWithUnRenderedMedia, adsWithRenderFailedMedia } =
      getAdsWithUnRenderedAndFailedRenderedMedia(data);

    if (adsWithUnRenderedMedia.length > 0) {
      const numberOfVideosToRender = adsWithUnRenderedMedia.length;

      const isBlocked = await dispatch(
        getIsBlockedActions({
          action: {
            ...ADS_CAMPAIGN_RENDERED_VIDEO_ACTION,
            cost:
              numberOfVideosToRender * ADS_CAMPAIGN_RENDERED_VIDEO_ACTION.cost,
          },
        })
      );
      if (isBlocked) {
        return;
      }

      const promises = adsWithUnRenderedMedia.map((unRenderedItem) => {
        return dispatch(
          startRenderVideoFromCreatify({
            adIDs: unRenderedItem.adIDs,
            videoID: unRenderedItem.videoID,
          })
        );
      });

      await Promise.allSettled(promises);

      const allAdIDs = adsWithUnRenderedMedia.map((item) => item.adIDs).flat();
      const data = getValues();

      const adsGroups = data.adsGroups;
      adsGroups.forEach((adsGroup, indexAdGroup) => {
        const ads = adsGroup.ads;

        ads.forEach((ad, indexAd) => {
          if (allAdIDs.includes(ad.id)) {
            setValue(
              `adsGroups.[${indexAdGroup}].ads.[${indexAd}].mediaData.[0].status`,
              VIDEO_IN_QUEUE_STATUS_CREATIFY
            );
          }
        });
      });

      onSaveCampaign(null, false);
      renderMediaDataRef.current = adsWithUnRenderedMedia;
      navigate(
        `/ads-campaign?campaignID=${campaignID}&afterRenderAction=${AFTER_RENDER_ACTION_PUBLISH}`
      );
    }

    return {
      hasRenderFailedMedia: adsWithRenderFailedMedia.length > 0,
      hasUnRenderedMedia: adsWithUnRenderedMedia.length > 0,
    };
  };
  const onUpdateProgress = async (renderItemData) => {
    const adIDs = renderItemData.adIDs;
    const videoData = renderItemData.videoData;
    const videoID = videoData.id;
    const newStatus = videoData.status;
    const videoOutput = videoData.videoOutput;

    const data = getValues();
    const adsGroups = data.adsGroups;
    adsGroups.forEach((adsGroup, indexAdGroup) => {
      const ads = adsGroup.ads;

      ads.forEach((ad, indexAd) => {
        if (!adIDs.includes(ad.id)) return;

        ad.mediaData.forEach((mediaDataItem, indexMediaData) => {
          if (mediaDataItem.videoID !== videoID) return;
          setValue(
            `adsGroups.[${indexAdGroup}].ads.[${indexAd}].mediaData.[${indexMediaData}].status`,
            newStatus
          );
          if (newStatus === VIDEO_DONE_STATUS_CREATIFY) {
            setValue(
              `adsGroups.[${indexAdGroup}].ads.[${indexAd}].mediaUrls.[${indexMediaData}]`,
              videoOutput || ""
            );
          }
        });
      });
    });

    const currentRenderMe = [...renderMediaDataRef.current];
    currentRenderMe.splice(renderItemData.index, 1);
    if (afterRenderAction === AFTER_RENDER_ACTION_DOWNLOAD && videoOutput) {
      await dispatch(startGetAwsDownloadUrl(videoOutput));
    }
    if (currentRenderMe.length > 0) {
      renderMediaDataRef.current = currentRenderMe;
      return;
    }
    if (afterRenderAction === AFTER_RENDER_ACTION_PUBLISH) {
      onCreateCampaign(null, false);
    }
    renderMediaDataRef.current = [];
  };

  const getShowSaveChangesButton = () => {
    return hasSomePlatformDraft;
  };
  const getShowPublishCampaignButton = () => {
    return hasSomePlatformDraft;
  };
  const getShowPublishChangesButton = () => {
    return !hasSomePlatformDraft;
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        borderTop: "1px solid rgba(255, 255, 255, 0.20)",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <AddPlatformsCampaign
          label={ADD_PLATFORMS}
          disabledButtons={isSaving}
          filterPlatforms={platforms}
          sxButtons={{
            px: 4,
            height: 56,
            maxHeight: "unset",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          gap: 1,
        }}
      >
        {getShowSaveChangesButton() && (
          <LoadingButton
            disabled={
              isCreating ||
              isValidatingIfNeedRenderMedia ||
              isGenerating ||
              isUpdating
            }
            loading={isSaving}
            type="button"
            color="primary"
            onClick={onSaveDraftCampaign}
          >
            {t(BUTTON_SAVE_CHANGES)}
          </LoadingButton>
        )}
        {getShowPublishCampaignButton() && (
          <LoadingButton
            disabled={isSaving || isGenerating || isUpdating}
            onClick={onCreateCampaign}
            loading={isCreating || isValidatingIfNeedRenderMedia}
            type="button"
            sx={sxButtonGradient}
          >
            {t(BUTTON_PUBLISH_CAMPAIGN)}
          </LoadingButton>
        )}
        {getShowPublishChangesButton() && (
          <LoadingButton
            disabled={isSaving || isGenerating || isUpdating}
            onClick={onSaveCampaign}
            loading={isCreating}
            type="button"
            sx={sxButtonGradient}
          >
            {t(BUTTON_SAVE_AND_PUBLISH)}
          </LoadingButton>
        )}
      </Box>

      {isCreating &&
        createPortal(
          <BackDropLoaderCreatingCampaign
            open={isCreating}
            progressID={`processProgress/${userID}/${campaignID}/`}
          />,
          document.body
        )}

      {isRenderMedia && (
        <BackdropLoaderRenderMediaCreatify
          open={isRenderMedia}
          data={renderMediaDataRef.current}
          onRenderMediaFinished={onUpdateProgress}
          mainLabel={
            afterRenderAction === AFTER_RENDER_ACTION_PUBLISH
              ? CREATIFY_VIDEO_RENDERING_CLARIFICATION_AND_CREATE_CAMPAIGN
              : CREATIFY_VIDEO_RENDERING_CLARIFICATION
          }
          sx={{
            maxWidth: 720,
            width: "100%",
          }}
        />
      )}

      {modalConnectAccountsOpen && (
        <ModalConnectAccounts
          modalOpen={modalConnectAccountsOpen}
          onCloseModal={() => setModalConnectAccountsOpen(false)}
          platforms={platforms}
          onCallbackChangeIntegration={({
            platform,
            integrationID,
            adAccountID,
          }) => {
            setValue(`platformsData.${platform}.integrationID`, integrationID);
            setValue(`platformsData.${platform}.adAccountID`, adAccountID);
          }}
        />
      )}
    </Box>
  );
};

const BackDropLoaderCreatingCampaign = ({ open, progressID }) => {
  const { t } = useTranslationApp();

  return (
    <Backdrop
      sx={(theme) => ({
        color: "#fff",
        zIndex: theme.zIndex.drawer + 1,

        backgroundColor: "rgba(0, 0, 0, 0.8)",
      })}
      open={open}
    >
      <Box
        sx={{
          maxWidth: 720,
          width: "100%",
        }}
      >
        <LinearLoaderWithTime
          progressID={progressID}
          preAddProgress={5}
          labels={[
            t(GETTING_REQUIRED_INFORMATION),
            t(VERIFYING_PLATFORMS_INFORMATION),
            t(CREATING_CAMPAIGN),
            t(CREATING_AD_GROUPS),
            t(UPLOADING_CONTENTS),
            t(CREATING_ADS),
            t(SAVING_INFORMATION),
          ]}
        />
      </Box>
    </Backdrop>
  );
};

export default BottomBar;
