import { useState } from "react";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import GoogleAdGroupRow from "./GoogleAdGroupRow";
import { useFormContext } from "react-hook-form";
import { getFromStatisticsAdsCampaign } from "../../../../../services/adsCampaigns";
import { GOOGLE } from "../../../../../utils/constants";
import {
  VALUE_NUMBER,
  VALUE_WITH_CURRENCY_MANUAL_FORMAT,
  VALUE_WITH_PERCENTAGE,
} from "../../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";
import TableCell from "../../../../BillingAndPayments/components/TableCell";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { logoPlatform } from "../../../../../utils/string";
import Typography from "@mui/material/Typography";
import GoogleAssetsGroupRow from "./GoogleAssetsGroupRow";

const GoogleCampaignRow = ({ index }) => {
  const [open, setOpen] = useState(false);
  const { campaign, getCostByMainCurrency } = useFormContext();
  const { statistics } = getFromStatisticsAdsCampaign(campaign);

  const { t } = useTranslationApp();

  const statisticsPlatform = statistics[GOOGLE].campaign;
  const adsGroups = campaign.adsGroups;
  const adsGroupsGoogle = adsGroups.filter(
    (adsGroup) => adsGroup.platform === GOOGLE
  );
  const assetsGroups = campaign.assetsGroups;
  const assetsGroupsGoogle = assetsGroups.filter(
    (assetsGroup) => assetsGroup.platform === GOOGLE
  );

  const { cost, currency } = getCostByMainCurrency({
    cost: statisticsPlatform.spend,
    currency: statisticsPlatform.currency,
  });

  const { cost: costPerMainAction, currency: currencyPerMainAction } =
    getCostByMainCurrency({
      cost: statisticsPlatform.costPerMainAction,
      currency: statisticsPlatform.currency,
    });

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left" indexRow={index} sx={{ width: 25 }}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          align="center"
          indexRow={index}
          sx={{
            width: 200,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              height: "100%",
              width: "100%",
            }}
          >
            <Avatar
              src={logoPlatform(GOOGLE)}
              variant="square"
              sx={{
                width: 20,
                height: 20,
                ".MuiAvatar-img": { objectFit: "contain" },
              }}
            />
            <Typography variant="body2" sx={{ mt: 0.25 }}>
              {t(GOOGLE)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
            value: cost,
            currency,
          })}
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_NUMBER, { value: statisticsPlatform.impressions })}
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_NUMBER, { value: statisticsPlatform.click })}
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_WITH_PERCENTAGE, { value: statisticsPlatform.ctr })}
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_NUMBER, { value: statisticsPlatform.mainAction })}
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
            value: costPerMainAction || 0,
            currency: currencyPerMainAction,
          })}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={9} indexRow={index} sx={{ p: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {adsGroupsGoogle.map((adGroup, indexAdGroup) => {
              const adGroupID = adGroup.id;
              const adGroupStatistics = statistics[GOOGLE].adsGroups[adGroupID];

              if (!adGroupStatistics) return null;

              const keywordsStatistics = statistics[GOOGLE].keywords[adGroupID];

              return (
                <GoogleAdGroupRow
                  index={index}
                  indexAdGroup={indexAdGroup}
                  adGroupID={adGroupID}
                  key={adGroup.id}
                  data={adGroupStatistics}
                  keywordsStatistics={keywordsStatistics}
                />
              );
            })}
            {assetsGroupsGoogle.map((assetsGroup, indexAssetsGroup) => {
              const assetsGroupID = assetsGroup.id;
              const assetsGroupStatistics =
                statistics[GOOGLE].assetsGroups[assetsGroupID];

              if (!assetsGroupStatistics) return null;

              return (
                <GoogleAssetsGroupRow
                  index={index}
                  indexAssetsGroup={indexAssetsGroup}
                  assetsGroupID={assetsGroupID}
                  key={assetsGroup.id}
                  data={assetsGroupStatistics}
                />
              );
            })}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default GoogleCampaignRow;
