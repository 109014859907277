import React from "react";
import NumberFormat from "react-number-format";

const NumberDecimalField = React.forwardRef(function NumberDecimalField(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue ?? null,
          },
        });
      }}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
    />
  );
});

export default NumberDecimalField;
