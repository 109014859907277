import React, { useEffect, useState } from "react";
import {
  ADD_NEW_IDENTITY,
  FIELD_REQUIRED,
  IDENTITIES_TIK_TOK_EMPTY,
  LOADING,
} from "../../i18n/keysTranslations";
import i18next from "i18next";
import { getReactHookFormNestedError } from "../../utils/errors";
import { Controller, useController } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useTranslationApp } from "../../lib/i18next";
import { useDispatchApp } from "../../lib/redux";
import { startGetIdentitiesTikTokBusiness } from "../../actions/adsCampaigns";
import AddIcon from "@mui/icons-material/Add";
import ModalCreateIdentityTikTokBusiness from "../Modal/ModalCreateIdentityTikTokBusiness";
import { ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION } from "../../utils/constants";

const filter = createFilterOptions();
const ID_ADD = "Create Identity";

const SelectTikTokIdentityCustom = ({
  control,
  errors = {},
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  name,
  label,
  disabled = false,
  placeholder,
  variant = "filled",
  fullWidth,
  helperText,
  objective = ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION,
  integrationID,
  adAccountID,
}) => {
  const [open, setOpen] = useState(false);
  const [modalCreateIdentityOpen, setModalCreateIdentityOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const error = getReactHookFormNestedError(errors, name);

  const {
    field: { onChange: fieldOnChange, value: fieldValue },
  } = useController({
    name,
    control,
  });

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  const getInitialData = async () => {
    if (!integrationID || !adAccountID) return;

    setLoading(true);

    const response = await dispatch(
      startGetIdentitiesTikTokBusiness({
        objective,
        adAccountID,
        integrationID,
      })
    );
    if (response.ok) {
      setOptions(response.data);

      if (!fieldValue && response?.data?.length > 0) {
        fieldOnChange(response.data[0].id);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getInitialData({
      objective,
    });
    // eslint-disable-next-line
  }, [objective, integrationID, adAccountID]);

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={""}
        render={({ field }) => (
          <Autocomplete
            {...field}
            fullWidth={fullWidth}
            disabled={disabled}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(_, value) => {
              if (value?.id === ID_ADD) {
                setModalCreateIdentityOpen(true);
              } else {
                field.onChange(value?.id);
              }
            }}
            noOptionsText={t(IDENTITIES_TIK_TOK_EMPTY)}
            loadingText={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CircularProgress color="inherit" size={20} />
                {t(LOADING)}
              </Box>
            }
            loading={loading}
            disableClearable={true}
            isOptionEqualToValue={(option, value) => option?.id === value}
            getOptionLabel={(option) => {
              const optionValue = options.find((opt) => opt.id === option);
              if (optionValue) {
                return `${optionValue?.name}`;
              }
              if (typeof option === "string") {
                return option;
              }
              return `${option?.name}`;
            }}
            options={options}
            renderOption={(props, option) => {
              return (
                <Box
                  {...props}
                  component="li"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                  key={option.id || option.name}
                >
                  {option.id === ID_ADD ? (
                    <AddIcon />
                  ) : (
                    <Avatar
                      src={option.imageURL}
                      alt={option.name}
                      sx={{
                        width: 30,
                        height: 30,
                      }}
                    />
                  )}
                  <Typography variant="body1">{option.name}</Typography>
                </Box>
              );
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (!loading) {
                filtered.unshift({
                  id: ID_ADD,
                  name: t(ADD_NEW_IDENTITY),
                });
              }

              return filtered;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant={variant}
                placeholder={field?.value?.length > 0 ? "" : placeholder}
                error={!!error}
                helperText={
                  <Box
                    component="span"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>{!!error ? error?.message : helperText}</span>
                  </Box>
                }
                sx={{
                  ".MuiFormHelperText-root": {
                    ml: "0 !important",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        )}
      />
      {modalCreateIdentityOpen && (
        <ModalCreateIdentityTikTokBusiness
          modalOpen={modalCreateIdentityOpen}
          adAccountID={adAccountID}
          integrationID={integrationID}
          onCloseModal={() => setModalCreateIdentityOpen(false)}
          onCallbackCreate={(identity) => {
            setOptions((prev) => [identity, ...prev]);
            fieldOnChange(identity.id);
          }}
        />
      )}
    </>
  );
};

export default SelectTikTokIdentityCustom;
