import { z } from "zod";
import i18next from "i18next";
import { FIELD_REQUIRED } from "../../../../../i18n/keysTranslations";

export const tiktokCampaignSchema = z.object({
  objective: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  integrationID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  adAccountID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  identityTikTokID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
});
