import { useState } from "react";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableCell from "../../../../BillingAndPayments/components/TableCell";
import {
  AD_GROUP,
  VALUE_NUMBER,
  VALUE_WITH_CURRENCY_MANUAL_FORMAT,
  VALUE_WITH_PERCENTAGE,
} from "../../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";
import TableContainer from "../../../../../components/Tables/TableContainer";
import TiktokAdRow from "./TiktokAdRow";
import { useFormContext } from "react-hook-form";

const TiktokAdGroupRow = ({ index, indexAdGroup, data, adsStatistics }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslationApp();

  const { getCostByMainCurrency } = useFormContext();

  const { cost, currency } = getCostByMainCurrency({
    cost: data.spend,
    currency: data.currency,
  });

  const { cost: costPerMainAction, currency: currencyPerMainAction } =
    getCostByMainCurrency({
      cost: data.costPerMainAction,
      currency: data.currency,
    });

  return (
    <>
      <TableContainer hasPagination={false} hasMarginBottom={false} keys={[""]}>
        <TableRow>
          <TableCell indexRow={index} sx={{ width: 25, pl: 5 }}>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            align="center"
            indexRow={index}
            sx={{
              width: 200,
            }}
          >
            {`${t(AD_GROUP)} ${indexAdGroup + 1}`}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
              value: cost,
              currency,
            })}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_NUMBER, { value: data.impressions })}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_NUMBER, { value: data.click })}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_WITH_PERCENTAGE, { value: data.ctr })}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_NUMBER, { value: data.mainAction })}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
              value: costPerMainAction || 0,
              currency: currencyPerMainAction,
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={9} indexRow={index} sx={{ p: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {Object.entries(adsStatistics).map(([adId, adData], indexAd) => (
                <TiktokAdRow
                  key={adId}
                  index={index}
                  indexAd={indexAd}
                  data={adData}
                />
              ))}
            </Collapse>
          </TableCell>
        </TableRow>
      </TableContainer>
    </>
  );
};

export default TiktokAdGroupRow;
