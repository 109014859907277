import { z } from "zod";
import {
  ADS_CAMPAIGN_GOOGLE_FIRST_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_GOOGLE_FIRST_TITLE_MAX_LENGTH,
  ADS_CAMPAIGN_GOOGLE_MAX_DESCRIPTIONS,
  ADS_CAMPAIGN_GOOGLE_MAX_IMAGES_ASSETS_GROUP,
  ADS_CAMPAIGN_GOOGLE_MAX_LONG_HEADLINES,
  ADS_CAMPAIGN_GOOGLE_MAX_SEARCH_THEMES,
  ADS_CAMPAIGN_GOOGLE_MAX_TITLES,
  ADS_CAMPAIGN_GOOGLE_MAX_VIDEOS_ASSETS_GROUP,
  ADS_CAMPAIGN_GOOGLE_MIN_IMAGES_ASSETS_GROUP,
  ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_LONG_HEADLINE_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH,
  GOOGLE,
} from "../../../../../utils/constants";
import i18next from "i18next";
import {
  FIELD_MAX_IMAGES_VALID,
  FIELD_MAX_LENGTH,
  FIELD_MAX_TEXTS_VALID,
  FIELD_MAX_VIDEOS_YOUTUBE_VALID,
  FIELD_MIN_TEXTS_VALID,
  FIELD_REQUIRED,
} from "../../../../../i18n/keysTranslations";

const textItemSchema = z.object({
  id: z.string(),
  value: z.string().min(1),
});

export const assetsGroupGoogleSchema = z.object({
  platform: z.literal(GOOGLE),
  id: z.string(),
  destinationUrl: z.string().url(),
  businessName: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  logoUrls: z
    .array(z.string().nonempty(i18next.t(FIELD_REQUIRED)))
    .min(1, i18next.t(FIELD_REQUIRED)),
  imageUrls: z
    .array(z.string().nonempty(i18next.t(FIELD_REQUIRED)))
    .min(ADS_CAMPAIGN_GOOGLE_MIN_IMAGES_ASSETS_GROUP, i18next.t(FIELD_REQUIRED))
    .max(
      ADS_CAMPAIGN_GOOGLE_MAX_IMAGES_ASSETS_GROUP,
      i18next.t(FIELD_MAX_IMAGES_VALID, {
        value: ADS_CAMPAIGN_GOOGLE_MAX_IMAGES_ASSETS_GROUP,
      })
    ),
  videoUrls: z
    .array(z.string().nonempty(i18next.t(FIELD_REQUIRED)))
    .max(
      ADS_CAMPAIGN_GOOGLE_MAX_VIDEOS_ASSETS_GROUP,
      i18next.t(FIELD_MAX_VIDEOS_YOUTUBE_VALID, {
        value: ADS_CAMPAIGN_GOOGLE_MAX_VIDEOS_ASSETS_GROUP,
      })
    )
    .optional(),
  longHeadlines: z
    .array(
      textItemSchema.extend({
        value: z
          .string()
          .min(1, i18next.t(FIELD_REQUIRED))
          .max(
            ADS_CAMPAIGN_PLATFORM_LONG_HEADLINE_MAX_LENGTH[GOOGLE],
            i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_LONG_HEADLINE_MAX_LENGTH[GOOGLE],
            })
          ),
      })
    )
    .min(
      3,
      i18next.t(FIELD_MIN_TEXTS_VALID, {
        value: 3,
      })
    )
    .max(
      ADS_CAMPAIGN_GOOGLE_MAX_LONG_HEADLINES,
      i18next.t(FIELD_MAX_TEXTS_VALID, {
        value: ADS_CAMPAIGN_GOOGLE_MAX_LONG_HEADLINES,
      })
    ),
  descriptions: z
    .array(
      textItemSchema.extend({
        value: z
          .string()
          .min(1, i18next.t(FIELD_REQUIRED))
          .max(
            ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[GOOGLE],
            i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[GOOGLE],
            })
          ),
      })
    )
    .min(
      3,
      i18next.t(FIELD_MIN_TEXTS_VALID, {
        value: 3,
      })
    )
    .max(
      ADS_CAMPAIGN_GOOGLE_MAX_DESCRIPTIONS,
      i18next.t(FIELD_MAX_TEXTS_VALID, {
        value: ADS_CAMPAIGN_GOOGLE_MAX_DESCRIPTIONS,
      })
    )
    .refine(
      (descriptions) => {
        if (descriptions.length === 0) return true;
        return (
          descriptions[0].value.length <=
          ADS_CAMPAIGN_GOOGLE_FIRST_DESCRIPTION_MAX_LENGTH
        );
      },
      {
        message: i18next.t(FIELD_MAX_LENGTH, {
          value: ADS_CAMPAIGN_GOOGLE_FIRST_DESCRIPTION_MAX_LENGTH,
        }),
        path: ["0", "value"],
      }
    ),
  titles: z
    .array(
      textItemSchema.extend({
        value: z
          .string()
          .min(1, i18next.t(FIELD_REQUIRED))
          .max(
            ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[GOOGLE],
            i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[GOOGLE],
            })
          ),
      })
    )
    .min(
      3,
      i18next.t(FIELD_MIN_TEXTS_VALID, {
        value: 3,
      })
    )
    .max(
      ADS_CAMPAIGN_GOOGLE_MAX_TITLES,
      i18next.t(FIELD_MAX_TEXTS_VALID, {
        value: ADS_CAMPAIGN_GOOGLE_MAX_TITLES,
      })
    )
    .refine(
      (titles) => {
        if (titles.length === 0) return true;
        return (
          titles[0].value.length <= ADS_CAMPAIGN_GOOGLE_FIRST_TITLE_MAX_LENGTH
        );
      },
      {
        message: i18next.t(FIELD_MAX_LENGTH, {
          value: ADS_CAMPAIGN_GOOGLE_FIRST_TITLE_MAX_LENGTH,
        }),
        path: ["0", "value"],
      }
    ),
  searchThemes: z
    .array(z.string())
    .min(1, i18next.t(FIELD_REQUIRED))
    .max(
      ADS_CAMPAIGN_GOOGLE_MAX_SEARCH_THEMES,
      i18next.t(FIELD_MAX_TEXTS_VALID, {
        value: ADS_CAMPAIGN_GOOGLE_MAX_SEARCH_THEMES,
      })
    ),
});
