import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";
import CardMedia from "../Media/CardMedia";
import { getReactHookFormNestedError } from "../../utils/errors";
import IconButtonAddContent from "../Buttons/IconButtonAddContent";
import { getPathDatabaseByRole } from "../../actions/auth";
import { useDispatchApp } from "../../lib/redux";
import { getUniqueID } from "../../utils/numbers";
import {
  BUTTON_CHANGE_CONTENT,
  BUTTON_SELECT_CONTENT,
} from "../../i18n/keysTranslations";

const ContentField = ({
  name,
  label,
  propsSelectContentComponent = {},
  onCallbackSave = () => {},
  control,
  errors,
  rules,
  minDimensions = {},
}) => {
  const error = getReactHookFormNestedError(errors, name);

  const dispatch = useDispatchApp();
  const { userID } = dispatch(getPathDatabaseByRole());

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Box
          sx={{
            width: 250,
          }}
        >
          <Typography variant="body1" gutterBottom>
            {label}
          </Typography>
          {error?.message && (
            <Typography variant="caption" color="error" gutterBottom>
              {error?.message}
            </Typography>
          )}
          {field.value && (
            <CardMedia
              url={field.value}
              propsType={{
                controls: false,
                autoplay: false,
                loop: false,
              }}
              sx={{
                marginBottom: "8px",
                objectFit: "cover",
                borderRadius: 3,
                height: 75,
                width: 75,
              }}
            />
          )}
          <IconButtonAddContent
            {...propsSelectContentComponent}
            id={`add-content-media-${getUniqueID()}`}
            mode="button"
            pathStorage={`adsCampaigns/${userID}/`}
            modeUpload="sync"
            onAddContent={(contents) => {
              onCallbackSave({ from: "upload", content: contents[0] });
            }}
            onCallbackSaveAI={(videoData) =>
              onCallbackSave({ from: "creatify", content: videoData })
            }
            minDimensions={minDimensions}
            multiple={false}
            urlContent={field.value}
            textButton={
              field.value ? BUTTON_CHANGE_CONTENT : BUTTON_SELECT_CONTENT
            }
            color="secondary"
            sxButton={{
              mt: 1.5,
              width: "100%",
            }}
          />
        </Box>
      )}
    />
  );
};

export default ContentField;
