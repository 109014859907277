import { useTranslationApp } from "../../../lib/i18next";
import ModalBasicLayout from "../../Modal/ModalBasicLayout";
import ContainerModalActions from "../../Containers/ContainerModalActions";
import { useForm } from "react-hook-form";
import TextField from "../../Form/TextField";
import Box from "@mui/material/Box";
import {
  BUTTON_CANCEL,
  BUTTON_SAVE,
  MODAL_ADD_KEYWORDS_TITLE,
  FIELD_REQUIRED,
  FIELD_KEYWORDS_REPEATED,
  ENTER_ONE_KEYWORD_PER_LINE,
} from "../../../i18n/keysTranslations";
import {
  getArrayIntersection,
  getDuplicateItemsArray,
} from "../../../utils/array";

const KeywordsModal = ({
  modalOpen,
  onCloseModal,
  onSubmit: onSubmitModal = () => {},
  currentKeywords = [],
}) => {
  const { t } = useTranslationApp();

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      keywords: "",
    },
  });

  const onSubmit = (data) => {
    const keywords = data.keywords
      .split(/[\n,]+/)
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword.length > 0);

    const keywordsFormatted = keywords.map((keyword) =>
      keyword.toLowerCase().replace(/[']/g, '"')
    );

    const keywordsRepeated = getDuplicateItemsArray(keywordsFormatted);

    if (keywordsRepeated.length > 0) {
      setError("keywords", {
        message: t(FIELD_KEYWORDS_REPEATED, {
          value: keywordsRepeated.join(", "),
        }),
      });
      return;
    }

    const keywordsRepeatedWithCurrentKeywords = getArrayIntersection(
      keywordsFormatted,
      currentKeywords
    );

    if (keywordsRepeatedWithCurrentKeywords.length > 0) {
      setError("keywords", {
        message: t(FIELD_KEYWORDS_REPEATED, {
          value: keywordsRepeatedWithCurrentKeywords.join(", "),
        }),
      });
      return;
    }

    onSubmitModal(keywordsFormatted);
    onCloseModal();
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      maxWidth="sm"
      title={t(MODAL_ADD_KEYWORDS_TITLE)}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          name="keywords"
          label={t(ENTER_ONE_KEYWORD_PER_LINE)}
          control={control}
          variant="filled"
          fullWidth
          multiline
          minRows={10}
          maxRows={10}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
          errors={errors}
        />
        <ContainerModalActions
          hasPadding={false}
          propsSecondaryButton={{
            showButton: true,
            onClick: onCloseModal,
            text: BUTTON_CANCEL,
          }}
          propsPrimaryButton={{
            showButton: true,
            text: BUTTON_SAVE,
            type: "button",
            onClick: handleSubmit(onSubmit),
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default KeywordsModal;
