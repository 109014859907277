import ContainerPage from "../../../components/Containers/ContainerPage";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  CHAT_ASK_A_QUESTION_ABOUT_YOUR_CAMPAIGN,
  EMPTY_STATISTICS_FOR_DETAILED_REPORT,
} from "../../../i18n/keysTranslations";
import SkeletonLoaderStatisticsGraphs from "./SkeletonLoaderStatisticsGraphs";
import CommonChatMainContainer from "../../../components/CommonChat/CommonChatMainContainer";
import { CHAT_CONFIGURATION_ADS_DETAILED_REPORT_ID } from "../../../utils/constants";
import { useFormContext } from "react-hook-form";
import { useTranslationApp } from "../../../lib/i18next";
import _ from "lodash";
import ConfigurationReport from "./ConfigurationReport";
import OverviewStatistics from "./OverviewStatistics/OverviewStatistics";
import TableStatistics from "./TableStatistics/TableStatistics";
import PerformingAssets from "./PerformingAssets/PerformingAssets";
import Insights from "./Insights/Insights";

const ContainerStatistics = () => {
  const {
    campaign,
    isGeneratingReport,
    isEmptyReport,
    isOpenChat,
    onChangeShowChat,
  } = useFormContext();
  const { t } = useTranslationApp();
  return (
    <ContainerPage
      mode="padding"
      hasMarginBottom={true}
      sx={{
        width: "100%",
        minHeight: "calc(100vh - 64px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "100%",
        }}
      >
        <ConfigurationReport />

        {isEmptyReport && !isGeneratingReport && (
          <Typography variant="body1" align="center">
            {t(EMPTY_STATISTICS_FOR_DETAILED_REPORT)}
          </Typography>
        )}

        {!isEmptyReport && isGeneratingReport && (
          <SkeletonLoaderStatisticsGraphs />
        )}
        {!isEmptyReport && !isGeneratingReport && (
          <>
            <OverviewStatistics />
            <TableStatistics />
            <PerformingAssets />
            <Insights />
          </>
        )}
      </Box>
      {!_.isEmpty(campaign) && (
        <CommonChatMainContainer
          showChat={isOpenChat}
          onChangeShowChat={() => onChangeShowChat(false)}
          showSendFileButton={false}
          chatConfigurationID={CHAT_CONFIGURATION_ADS_DETAILED_REPORT_ID}
          chatContext={{
            campaign,
          }}
          startMode="messageInput"
          placeholderInput={CHAT_ASK_A_QUESTION_ABOUT_YOUR_CAMPAIGN}
          headerTitle={CHAT_ASK_A_QUESTION_ABOUT_YOUR_CAMPAIGN}
        />
      )}
    </ContainerPage>
  );
};

export default ContainerStatistics;
