import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { BORDER_RADIUS } from "../../../../../utils/constants";
import { logoPlatform } from "../../../../../utils/string";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { useTranslationApp } from "../../../../../lib/i18next";

const InsightCard = ({
  title,
  subtitle,
  onSubtitleClick,
  statistics = [],
  previousContent = null,
  nextContent = null,
  sxTitle = {},
  previousContentTitle = null,
  platform,
  sx = {},
}) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        backgroundColor: "background.paperSecondary",
        borderRadius: BORDER_RADIUS,
        p: 2,
        width: 320,
        ...sx,
      }}
    >
      {previousContentTitle && previousContentTitle}

      {platform && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 1,
            gap: 1,
          }}
        >
          <Avatar
            src={logoPlatform(platform)}
            variant="square"
            sx={{
              width: 20,
              height: 20,
              ".MuiAvatar-img": { objectFit: "contain" },
            }}
          />
          <Typography variant="body1">{t(platform)}</Typography>
        </Box>
      )}

      {/* Title Section */}
      {title && (
        <Tooltip title={title}>
          <Typography
            variant="subtitle1"
            color="fields.placeholder"
            sx={sxTitle}
          >
            {title}
          </Typography>
        </Tooltip>
      )}

      {/* Subtitle Section with optional click */}
      {subtitle && (
        <Typography
          variant="body2"
          onClick={onSubtitleClick}
          sx={{
            ...(onSubtitleClick && {
              textDecoration: "underline",
              cursor: "pointer",
            }),
            mb: 2,
          }}
        >
          {subtitle}
        </Typography>
      )}

      {previousContent}

      {/* Statistics Grid */}
      {statistics.length > 0 && (
        <Grid container spacing={1}>
          {statistics.map(({ label, value }, index) => (
            <Grid item xs={6} key={index}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography variant="caption" color="fields.placeholder">
                  {label}
                </Typography>
                <Typography variant="body2">{value}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Additional Content */}
      {nextContent}
    </Box>
  );
};

export default InsightCard;
