import { GOOGLE_IMAGE_FORMATS } from "../../../../utils/constants";
import { useFormContext } from "react-hook-form";
import MultiContentField from "../../../Form/MultiContentField";
import Accordion from "../../../Containers/Accordion";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  ADS_CAMPAIGN_PLATFORM_BRAND_CONFIGURATION_SUB_TITLE,
  IMAGES_OF_CAMPAIGN_PLACEHOLDER,
  BUSINESS_NAME_PLACEHOLDER,
  LOGO,
  BUTTON_UPLOAD_LOGO,
  BUTTON_CHANGE_LOGO,
  FIELD_REQUIRED,
} from "../../../../i18n/keysTranslations";
import ChipOptional from "../../../Chip/ChipOptional";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";
import { useDispatchApp } from "../../../../lib/redux";
import { getPathDatabaseByRole } from "../../../../actions/auth";
import CardMedia from "../../../Media/CardMedia";
import UploadFileButton from "../../../Buttons/UploadFileButton";
import TextField from "../../../Form/TextField";
import { useState } from "react";

const CampaignGoogleSearchForm = ({ platform, grid }) => {
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const {
    watch,
    setValue,
    control,
    isGeneralDisabledFields,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const basicPath = `platformsData.${platform}`;
  const imageUrls = watch(`${basicPath}.imageUrls`) || [];
  const logoUrl = watch(`${basicPath}.logoUrl`);
  const { userID, pathLogoUrl, isUserPlatform } = dispatch(
    getPathDatabaseByRole()
  );
  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 900,
          }}
        >
          {t(ADS_CAMPAIGN_PLATFORM_BRAND_CONFIGURATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item {...grid}>
        <TextField
          name={`${basicPath}.businessName`}
          label={t(BUSINESS_NAME_PLACEHOLDER)}
          variant="filled"
          fullWidth
          disabled={isGeneralDisabledFields}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <Typography variant="body1" gutterBottom>
          {t(LOGO)}
        </Typography>
        {logoUrl && (
          <CardMedia
            url={logoUrl}
            sx={{
              objectFit: "cover",
              borderRadius: 3,
              height: 110,
              width: 110,
              mb: 1,
            }}
          />
        )}
        {!isGeneralDisabledFields && (
          <Controller
            name={`${basicPath}.logoUrl`}
            control={control}
            render={({ field }) => (
              <UploadFileButton
                id="upload-logo-url"
                textUpload={BUTTON_UPLOAD_LOGO}
                colorUpload="primary"
                textRemove={BUTTON_CHANGE_LOGO}
                colorRemove="primary"
                value={field.value}
                onChange={field.onChange}
                pathStorage={`socialMedia/${userID}/`}
                pathRTDB={isUserPlatform ? pathLogoUrl : null}
                acceptedFormats={GOOGLE_IMAGE_FORMATS}
                modeUpload="sync"
                loading={isUploadingLogo}
                onCallbackLoading={(loading) => setIsUploadingLogo(loading)}
                sx={{
                  width: 110,
                }}
              />
            )}
          />
        )}
      </Grid>

      <Grid item {...grid}>
        <Accordion
          title={t(IMAGES_OF_CAMPAIGN_PLACEHOLDER)}
          actionsComponent={<ChipOptional />}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "stretch",
            minHeight: 70,
          }}
        >
          <MultiContentField
            acceptedFormats={GOOGLE_IMAGE_FORMATS}
            compareImages
            disabled={isGeneralDisabledFields}
            mediaUrls={imageUrls}
            onChange={(contents) => {
              const newImageUrls = contents.map((content) => content.url);
              setValue(`${basicPath}.imageUrls`, [
                ...imageUrls,
                ...newImageUrls,
              ]);
            }}
            onDelete={(index) => {
              setValue(
                `${basicPath}.imageUrls`,
                imageUrls.filter((_, i) => i !== index)
              );
            }}
          />
        </Accordion>
      </Grid>
    </>
  );
};

export default CampaignGoogleSearchForm;
