import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RangeDateField from "../../../components/Texts/RangeDateField";
import Select from "../../../components/Form/Select";
import Button from "../../../components/Buttons/Button";
import {
  BUTTON_FILTER,
  DATE_SHORT_2,
  REPORT_BREAKDOWN_PLACEHOLDER,
} from "../../../i18n/keysTranslations";
import { useFormContext } from "react-hook-form";
import { useTranslationApp } from "../../../lib/i18next";
import { optionsAdsCampaignBreakdownsReport } from "../../../utils/options";

const ConfigurationReport = () => {
  const {
    control,
    formState: { errors },
    campaign,
    isGeneratingReport,
    getDetailedCampaignReport,
  } = useFormContext();

  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "flex-start", sm: "center" },
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
          }}
        >
          {campaign.name}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t(DATE_SHORT_2, { date: new Date() })}
        </Typography>
      </Box>

      {!isGeneratingReport && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: 2,
            flexWrap: { xs: "wrap", sm: "nowrap" },
          }}
        >
          <RangeDateField
            control={control}
            name="dateRange"
            sx={{
              width: "100%",
              flexGrow: {
                xs: 1,
                sm: 0,
              },
            }}
            sxField={{
              ".MuiInputBase-root": {
                height: 60,
              },
            }}
          />
          <Select
            name="breakdown"
            label={t(REPORT_BREAKDOWN_PLACEHOLDER)}
            variant="filled"
            fullWidth
            doTranslate={true}
            options={optionsAdsCampaignBreakdownsReport}
            control={control}
            errors={errors}
            sx={{
              maxWidth: {
                xs: "47%",
                sm: 140,
                md: 180,
              },
              ".MuiInputBase-root": {
                height: 60,
              },
            }}
          />
          <Button
            color="white"
            onClick={() => getDetailedCampaignReport(true)}
            sx={{
              width: { xs: "47%", sm: 130 },
              minWidth: { xs: "47%", sm: 130 },
              minHeight: 55,
            }}
          >
            {t(BUTTON_FILTER)}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ConfigurationReport;
