import { useTranslationApp } from "../../lib/i18next";
import Chip from "@mui/material/Chip";
import { OPTIONAL } from "../../i18n/keysTranslations";

const ChipOptional = () => {
  const { t } = useTranslationApp();
  return <Chip label={t(OPTIONAL)} />;
};

export default ChipOptional;
