import {
  AD_ACCOUNT_PLATFORMS_LABELS,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  ADS_CAMPAIGN_TYPE_SEARCH_OPTION,
  FONT_FAMILY,
  GOOGLE,
} from "../../../../utils/constants";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  optionsAdsCampaignTypeBudgets,
  optionsCampaignsTypeGoogleAds,
} from "../../../../utils/options";
import {
  FIELD_REQUIRED,
  CAMPAIGN_TYPE_PLACEHOLDER,
  NETWORK_DISPLAY_SETTING_PLACEHOLDER,
  GOOGLE_NETWORK_SETTING_GOOGLE_SEARCH,
  GOOGLE_NETWORK_SETTING_SEARCH_NETWORK,
  GOOGLE_NETWORK_SETTING_CONTENT_NETWORK,
  GOOGLE_CONVERSION_ACTIONS_PLACEHOLDER,
  LOCATIONS_PLACEHOLDER,
  ADS_CAMPAIGN_AD_GROUP_BUDGET_INFORMATION_SUB_TITLE,
  TYPE_BUDGET_PLACEHOLDER,
  LIFETIME_BUDGET_PLACEHOLDER,
  DAILY_BUDGET_PLACEHOLDER,
  VALUE_EXPRESSED_IN,
  FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR,
  START_DATE_PLACEHOLDER,
  END_DATE_PLACEHOLDER,
  ALERT_CHANGE_AD_ACCOUNT_GENERAL_TITLE,
  BUTTON_CONTINUE,
  BUTTON_CANCEL,
  ALERT_CHANGE_AD_ACCOUNT_GOOGLE_DESCRIPTION,
  ADS_CAMPAIGN_PLATFORM_ADDITIONAL_CONFIGURATION_SUB_TITLE,
} from "../../../../i18n/keysTranslations";
import Select from "../../../Form/Select";
import SelectBusinessAccount from "../../../Meta/SelectBusinessAccount";
import CheckBoxes from "../../../Form/CheckBoxes";
import SelectConversionActionsGoogle from "../../../Google/SelectConversionActionsGoogle";
import { addDays, differenceInDays } from "date-fns";
import { useFormContext } from "react-hook-form";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { useTranslationApp } from "../../../../lib/i18next";
import SearchSegmentationsField from "../../../Form/SearchSegmentationsField";
import TextField from "../../../Form/TextField";
import { useDispatchApp } from "../../../../lib/redux";
import ReasoningContainer from "../../../../pages/NewAdsCampaign/components/Common/ReasoningContainer";
import NumberDecimalField from "../../../Form/NumberDecimalField";
import { getCurrencyAdAccountPlatform } from "../../../../actions/getters";
import DateField from "../../../Form/DateField";
import startOfDay from "date-fns/startOfDay";
import { ConfirmAlert } from "../../../Alerts/Alerts";
import CampaignGoogleSearchForm from "./CampaignGoogleSearchForm";
import SiteLinksContainer from "./SiteLinksContainer";
import CalloutsContainer from "./CalloutsContainer";

const platform = GOOGLE;
const grid = {
  xs: 12,
  lg: 6,
};

const CampaignGoogleForm = () => {
  const {
    control,
    formState,
    watch,
    setValue,
    getValues,
    isGeneralDisabledFields,
    getIsCampaignPlatformCreated,
  } = useFormContext();
  useFocusErrorForm(formState);
  const errors = formState.errors;

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const basicPath = `platformsData.${platform}`;
  const platformData = watch(basicPath);
  const objective = platformData?.objective;
  const typeBudget = platformData?.typeBudget;
  const startDate = platformData?.startDate;
  const endDate = platformData?.endDate;
  const language = watch("language");
  const reasoningPlatform = platformData?.reasoning;
  const oldReasoning = getValues("reasoning");

  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);

  const currency = dispatch(
    getCurrencyAdAccountPlatform({
      platform,
      integrationID: platformData?.integrationID,
      adAccountID: platformData?.adAccountID,
    })
  );

  const validationChangeAdAccount = async () => {
    const result = await ConfirmAlert({
      title: t(ALERT_CHANGE_AD_ACCOUNT_GENERAL_TITLE),
      html: (
        <Typography
          variant="body1"
          sx={{
            fontFamily: FONT_FAMILY,
            "& *": {
              p: "revert",
              m: "revert",
              listStyle: "revert",
              textDecoration: "revert",
            },
          }}
          dangerouslySetInnerHTML={{
            __html: t(ALERT_CHANGE_AD_ACCOUNT_GOOGLE_DESCRIPTION),
          }}
        />
      ),
      confirmButtonText: t(BUTTON_CONTINUE),
      cancelButtonText: t(BUTTON_CANCEL),
    });

    if (!result.isConfirmed) return false;

    return true;
  };

  const onChangeAdAccount = (value) => {
    setValue(`${basicPath}.integrationID`, value?.integrationID);
    setValue(`${basicPath}.conversionActions`, []);
  };

  return (
    <Grid container spacing={2}>
      {!isCampaignPlatformCreated && (
        <Grid item xs={12}>
          <ReasoningContainer reasoning={reasoningPlatform || oldReasoning} />
        </Grid>
      )}

      <Grid item {...grid}>
        <Select
          label={t(CAMPAIGN_TYPE_PLACEHOLDER)}
          options={optionsCampaignsTypeGoogleAds}
          disabled={true}
          name={`${basicPath}.objective`}
          variant="filled"
          fullWidth
          doTranslate={true}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectBusinessAccount
          variant="filled"
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
          label={t(AD_ACCOUNT_PLATFORMS_LABELS[platform])}
          control={control}
          platform={platform}
          name={`${basicPath}.adAccountID`}
          onChangeAction={onChangeAdAccount}
          validationChange={validationChangeAdAccount}
          errors={errors}
          fullWidth={true}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      {objective === ADS_CAMPAIGN_TYPE_SEARCH_OPTION && (
        <Grid item {...grid}>
          <CheckBoxes
            name={`${basicPath}.networkSettings`}
            disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
            label={NETWORK_DISPLAY_SETTING_PLACEHOLDER}
            options={[
              {
                key: "targetGoogleSearch",
                label: GOOGLE_NETWORK_SETTING_GOOGLE_SEARCH,
                disabled: true,
              },
              {
                key: "targetSearchNetwork",
                label: GOOGLE_NETWORK_SETTING_SEARCH_NETWORK,
              },
              {
                key: "targetContentNetwork",
                label: GOOGLE_NETWORK_SETTING_CONTENT_NETWORK,
              },
            ]}
            control={control}
            errors={errors}
          />
        </Grid>
      )}
      <Grid item {...grid}>
        <SelectConversionActionsGoogle
          label={t(GOOGLE_CONVERSION_ACTIONS_PLACEHOLDER)}
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
          name={`${basicPath}.conversionActions`}
          integrationID={platformData?.integrationID}
          adAccountID={platformData?.adAccountID}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <DateField
          control={control}
          name={`${basicPath}.startDate`}
          label={t(START_DATE_PLACEHOLDER)}
          minDate={new Date()}
          actionsChange={(value) => {
            setValue(
              `${basicPath}.startDate`,
              value ? startOfDay(value).toISOString() : null
            );
          }}
          fullWidth
          variant="filled"
          errors={errors}
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
        />
      </Grid>
      <Grid item {...grid}>
        <DateField
          control={control}
          name={`${basicPath}.endDate`}
          label={t(END_DATE_PLACEHOLDER)}
          value={endDate || null}
          minDate={startDate ? new Date(startDate) : new Date()}
          actionsChange={(value) => {
            setValue(
              `${basicPath}.endDate`,
              value ? startOfDay(value).toISOString() : null
            );
          }}
          clearable={true}
          fullWidth
          variant="filled"
          errors={errors}
          disabled={isGeneralDisabledFields}
        />
      </Grid>
      <Grid item {...grid}>
        <SearchSegmentationsField
          label={t(LOCATIONS_PLACEHOLDER)}
          name={`${basicPath}.locations`}
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE}
          mode="fetching"
          platform={platform}
          adAccountID={platformData?.adAccountID}
          integrationID={platformData?.integrationID}
          objective={objective}
          language={language}
          fullWidth
          variant="filled"
          disabled={isGeneralDisabledFields}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 900,
          }}
        >
          {t(ADS_CAMPAIGN_AD_GROUP_BUDGET_INFORMATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item {...grid}>
        <Select
          label={t(TYPE_BUDGET_PLACEHOLDER)}
          disabled={true}
          name={`${basicPath}.typeBudget`}
          actionsChange={(value) => {
            if (value === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION) {
              setValue(`${basicPath}.startDate`, null);
              setValue(`${basicPath}.endDate`, null);
            } else {
              setValue(`${basicPath}.startDate`, new Date().toISOString());
              setValue(
                `${basicPath}.endDate`,
                addDays(new Date(), 7).toISOString()
              );
            }
          }}
          fullWidth
          variant="filled"
          doTranslate={true}
          options={optionsAdsCampaignTypeBudgets}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <TextField
          label={t(
            typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION
              ? DAILY_BUDGET_PLACEHOLDER
              : LIFETIME_BUDGET_PLACEHOLDER
          )}
          name={`${basicPath}.budget`}
          fullWidth
          variant="filled"
          InputProps={{
            inputComponent: NumberDecimalField,
          }}
          helperText={
            currency
              ? t(VALUE_EXPRESSED_IN, {
                  currency: currency.toUpperCase(),
                })
              : ""
          }
          control={control}
          errors={errors}
          disabled={isGeneralDisabledFields}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION) {
                const startDateTime = startDate
                  ? new Date(startDate)
                  : new Date();
                const endDateTime = endDate ? new Date(endDate) : new Date();

                const diffDays = differenceInDays(endDateTime, startDateTime);

                const dailyBudget = value / diffDays;

                if (dailyBudget < 1) {
                  return t(FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR, {
                    value: diffDays,
                  });
                }
              }
            },
          }}
        />
      </Grid>
      {objective === ADS_CAMPAIGN_TYPE_SEARCH_OPTION && (
        <CampaignGoogleSearchForm platform={platform} grid={grid} />
      )}
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 900,
          }}
        >
          {t(ADS_CAMPAIGN_PLATFORM_ADDITIONAL_CONFIGURATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item {...grid}>
        <SiteLinksContainer
          control={control}
          errors={errors}
          name={`${basicPath}.siteLinks`}
          disabled={isGeneralDisabledFields}
        />
      </Grid>
      <Grid item {...grid}>
        <CalloutsContainer
          control={control}
          errors={errors}
          name={`${basicPath}.callOuts`}
          disabled={isGeneralDisabledFields}
        />
      </Grid>
    </Grid>
  );
};

export default CampaignGoogleForm;
