import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../lib/i18next";
import { ACTION, FORECAST } from "../../i18n/keysTranslations";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../utils/colors";
import Tooltip from "@mui/material/Tooltip";
import { BORDER_RADIUS } from "../../utils/constants";

const sxCutText = (maxLines = 2) => ({
  whiteSpace: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: maxLines,
  WebkitBoxOrient: "vertical",
});

const InsightActionableContainer = ({
  insight,
  disabled = false,
  actionButtonText,
  showActionButton = false,
  onActionButtonClick = null,
}) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        mt: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box>
        <Typography variant="subtitle1" color="fields.placeholder">
          {t(ACTION)}
        </Typography>
        <Tooltip title={insight.action}>
          <Typography variant="body2" sx={{ ...sxCutText(2), height: 38 }}>
            {insight.action}
          </Typography>
        </Tooltip>
      </Box>
      <Box>
        <Typography variant="subtitle1" color="fields.placeholder">
          {t(FORECAST)}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            background: GRADIENT_PURPLE_FUCHSIA,
            filter: "brightness(1.3)",
            width: "fit-content",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
            ...sxCutText(1),
          }}
        >
          {insight.forecast.value}
        </Typography>
        <Tooltip title={insight.forecast.description}>
          <Typography variant="body2" sx={{ ...sxCutText(1), height: 18 }}>
            {insight.forecast.description}
          </Typography>
        </Tooltip>
      </Box>
      {showActionButton && (
        <Box
          onClick={disabled ? null : onActionButtonClick}
          sx={{
            cursor: disabled ? "default" : "pointer",
            mt: 0.5,
            p: 0.25,
            borderRadius: BORDER_RADIUS,
            background: GRADIENT_PURPLE_FUCHSIA,
            color: disabled
              ? "fields.placeholder"
              : GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          <Box
            sx={{
              py: 0.5,
              backgroundColor: "background.paperSecondary",
              borderRadius: BORDER_RADIUS,
            }}
          >
            {actionButtonText}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default InsightActionableContainer;
