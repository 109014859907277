import Box from "@mui/material/Box";
import GalleryItem from "./GalleryItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../lib/i18next";
import { MODAL_GALLERY_NO_MORE_CONTENT } from "../../i18n/keysTranslations";
import Loader from "../Loaders/Loader";

const Gallery = ({
  data,
  onCloseModal,
  lastItemRef,
  loadLastItem,
  onFailedLoadUrl,
  onSelectItem,
  onSelectPreview,
  selectedItems,
  multiple,
  from,
}) => {
  const { t } = useTranslationApp();
  return (
    <Grid
      container
      spacing={2}
      sx={{
        px: 4,
      }}
    >
      {data?.length === 0 && !loadLastItem && (
        <Grid item xs={12}>
          <div ref={lastItemRef}></div>
        </Grid>
      )}
      {data?.length > 0 &&
        data.map((item, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
              <GalleryItem
                item={item}
                index={index}
                onCloseModalGallery={onCloseModal}
                onFailedLoadUrl={onFailedLoadUrl}
                onSelectItem={onSelectItem}
                onSelectPreview={onSelectPreview}
                multiple={multiple}
                isSelected={selectedItems.includes(index)}
                from={from}
              />
              {data.length === index + 1 && <div ref={lastItemRef}></div>}
            </Grid>
          );
        })}
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          {!loadLastItem && <Loader size={30} sx={{ mb: 2 }} />}
          {loadLastItem && data?.length > 0 && (
            <Typography variant="h6" sx={{ color: "white" }}>
              {t(MODAL_GALLERY_NO_MORE_CONTENT)}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Gallery;
