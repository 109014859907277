import { useEffect, useState } from "react";
import useNearScreen from "../../../hooks/useNearScreen";
import { usePagination } from "../../../hooks/usePagination";
import {
  BUTTON_ADD,
  BUTTON_CREATE,
  BUTTON_GO_BACK,
  BUTTON_UPDATE,
  LOADING_INFORMATION,
  MODAL_CUSTOM_AUDIENCES_ARIA_LABEL,
  MODAL_CUSTOM_AUDIENCES_DESCRIPTION,
  MODAL_CUSTOM_AUDIENCES_TITLE,
  MODAL_EXCLUDED_CUSTOM_AUDIENCES_ARIA_LABEL,
  MODAL_EXCLUDED_CUSTOM_AUDIENCES_DESCRIPTION,
  MODAL_EXCLUDED_CUSTOM_AUDIENCES_TITLE,
  UPDATING_INFORMATION,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ModalBasicLayout from "../ModalBasicLayout";
import { startGetCustomAudiences } from "../../../actions/adsCampaigns";
import { useDispatchApp } from "../../../lib/redux";
import Loader from "../../Loaders/Loader";
import CustomAudiencesList from "./components/CustomAudiencesList";
import Button from "../../Buttons/Button";
import Box from "@mui/material/Box";
import { openLink } from "../../../utils/string";
import { getCustomAudiencesMeta } from "../../../actions/getters";
import { DialogActions } from "@mui/material";
import ContainerModalActions from "../../Containers/ContainerModalActions";
import LoadingButton from "../../Buttons/LoadingButton";
import CachedIcon from "@mui/icons-material/Cached";

const ModalCustomAudiences = ({
  modalOpen,
  integrationID,
  adAccountID,
  filteredCustomAudiences = [],
  onCloseModal = () => {},
  onCallbackSave = () => {},
  platform,
  isExcludedAudiences = false,
}) => {
  const [customAudiences, setCustomAudiences] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const onGetCustomAudiences = async () => {
    if (loadLastItem) return;

    const response = await dispatch(
      startGetCustomAudiences({
        platform,
        integrationID,
        adAccountID,
        lastKey,
        onChangeLastKey,
        onChangeLoadLastItem,
      })
    );

    if (response.ok) {
      const data = response.data;

      setCustomAudiences((prev) =>
        [...prev, ...data].filter(
          (v) => !filteredCustomAudiences.includes(v.id)
        )
      );
    }

    if (isLoading) setIsLoading(false);

    return response.ok;
  };

  const { lastKey, onChangeLastKey } = usePagination({
    rowsPerPageValue: 10,
    onCallBackNextPage: onGetCustomAudiences,
  });

  const { fromRef, loadLastItem, onChangeLoadLastItem, isNearScreen } =
    useNearScreen({
      distance: "100px",
    });

  const onUpdateCustomAudiences = async () => {
    setIsUpdating(true);
    setIsLoading(true);

    const response = await dispatch(
      startGetCustomAudiences({
        platform,
        integrationID,
        adAccountID,
        onChangeLastKey,
        onChangeLoadLastItem,
      })
    );

    if (response.ok) {
      const data = response.data;

      setCustomAudiences(() =>
        [...data].filter((v) => !filteredCustomAudiences.includes(v.id))
      );
    }

    setIsLoading(false);
    setIsUpdating(false);

    return response.ok;
  };

  useEffect(() => {
    onGetCustomAudiences();
    // eslint-disable-next-line
  }, [isNearScreen]);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={async () => {
        onCloseModal();
      }}
      maxWidth="sm"
      scroll="paper"
      ariaLabel={t(
        isExcludedAudiences
          ? MODAL_EXCLUDED_CUSTOM_AUDIENCES_ARIA_LABEL
          : MODAL_CUSTOM_AUDIENCES_ARIA_LABEL
      )}
      title={t(
        isExcludedAudiences
          ? MODAL_EXCLUDED_CUSTOM_AUDIENCES_TITLE
          : MODAL_CUSTOM_AUDIENCES_TITLE
      )}
      description={t(
        isExcludedAudiences
          ? MODAL_EXCLUDED_CUSTOM_AUDIENCES_DESCRIPTION
          : MODAL_CUSTOM_AUDIENCES_DESCRIPTION
      )}
    >
      {isLoading && (
        <Loader
          hasMessage={true}
          message={t(isUpdating ? UPDATING_INFORMATION : LOADING_INFORMATION)}
        />
      )}

      {!isLoading && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              gap: 1,
              justifyContent: "flex-end",
              width: "100%",
              mb: 2,
            }}
          >
            <LoadingButton
              disabled={isLoading}
              color="white"
              startIcon={<CachedIcon />}
              onClick={onUpdateCustomAudiences}
              sx={{
                minWidth: 150,
              }}
            >
              {t(BUTTON_UPDATE)}
            </LoadingButton>
            <Button
              type="button"
              onClick={() => openLink(dispatch(getCustomAudiencesMeta()))}
              sx={{
                minWidth: 150,
              }}
            >
              {t(BUTTON_CREATE)}
            </Button>
          </Box>
          <CustomAudiencesList
            selectedItems={selectedItems}
            onChangeSelectedItems={setSelectedItems}
            customAudiences={customAudiences}
            lastItemRef={fromRef}
            loadLastItem={loadLastItem}
          />
        </>
      )}
      <DialogActions
        sx={{
          p: 0,
        }}
      >
        <ContainerModalActions
          hasPadding={true}
          propsSecondaryButton={{
            showButton: true,
            text: BUTTON_GO_BACK,
            onClick: onCloseModal,
          }}
          propsPrimaryButton={{
            showButton: true,
            disabled: selectedItems.length === 0,
            text: BUTTON_ADD,
            onClick: async () => {
              onCallbackSave(selectedItems);
            },
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalCustomAudiences;
