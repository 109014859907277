import TableRow from "@mui/material/TableRow";
import TableCell from "../../../../BillingAndPayments/components/TableCell";
import {
  ASSETS_GROUP,
  VALUE_NUMBER,
  VALUE_WITH_CURRENCY_MANUAL_FORMAT,
  VALUE_WITH_PERCENTAGE,
} from "../../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";
import TableContainer from "../../../../../components/Tables/TableContainer";
import { useFormContext } from "react-hook-form";

const GoogleAssetsGroupRow = ({ index, indexAssetsGroup, data }) => {
  const { getCostByMainCurrency } = useFormContext();
  const { t } = useTranslationApp();

  const { cost, currency } = getCostByMainCurrency({
    cost: data.spend,
    currency: data.currency,
  });

  const { cost: costPerMainAction, currency: currencyPerMainAction } =
    getCostByMainCurrency({
      cost: data.costPerMainAction,
      currency: data.currency,
    });

  return (
    <>
      <TableContainer hasPagination={false} hasMarginBottom={false} keys={[""]}>
        <TableRow>
          <TableCell indexRow={index} sx={{ width: 25, pl: 5 }} />
          <TableCell
            align="center"
            indexRow={index}
            sx={{
              width: 200,
            }}
          >
            {`${t(ASSETS_GROUP)} ${indexAssetsGroup + 1}`}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
              value: cost,
              currency,
            })}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_NUMBER, { value: data.impressions })}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_NUMBER, { value: data.click })}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_WITH_PERCENTAGE, { value: data.ctr })}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_NUMBER, { value: data.mainAction })}
          </TableCell>
          <TableCell align="center" indexRow={index}>
            {t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
              value: costPerMainAction || 0,
              currency: currencyPerMainAction,
            })}
          </TableCell>
        </TableRow>
      </TableContainer>
    </>
  );
};

export default GoogleAssetsGroupRow;
