import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useMap } from "@vis.gl/react-google-maps";
const sxText = {
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const MarkersCards = ({ markers = [], onRemoveMarker }) => {
  const map = useMap();
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "auto",
        gap: 2,
        whiteSpace: "nowrap",
      }}
    >
      {markers.map((marker, index) => (
        <Card
          key={index}
          sx={{
            width: 250,
            backgroundColor: "background.paper",
            boxShadow: 3,
            borderRadius: 2,
            flex: "0 0 auto",
            p: 2,
            position: "relative",
            cursor: "pointer",
          }}
          onClick={() => {
            map.setCenter({ lat: marker.lat, lng: marker.lng });
          }}
        >
          <IconButton
            size="small"
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
            }}
            onClick={(e) => {
              e?.stopPropagation();
              onRemoveMarker(index);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Typography variant="body1" fontWeight="bold" sx={sxText}>
            {marker.name}
          </Typography>
          <Typography variant="body2" color="fields.placeholder" sx={sxText}>
            {marker.longName}
          </Typography>
          <Typography
            variant="caption"
            color="fields.placeholder"
            sx={{ ...sxText, ml: -0.25 }}
          >
            {`📍 Lat: ${marker.lat.toFixed(4)}, Lng: ${marker.lng.toFixed(4)}`}
          </Typography>
        </Card>
      ))}
    </Box>
  );
};

export default MarkersCards;
