import { useEffect, useState } from "react";

// Función recursiva para obtener todas las rutas de errores
const getAllErrorPaths = (errors, parentPath = "") => {
  const paths = [];

  Object.keys(errors).forEach((key) => {
    const value = errors[key];
    const isArrayIndex = !isNaN(key); // Verifica si la clave es un índice de array
    const currentPath = parentPath
      ? isArrayIndex
        ? `${parentPath}.[${key}]`
        : `${parentPath}.${key}`
      : key;

    if (typeof value === "object" && value !== null && !value.message) {
      paths.push(...getAllErrorPaths(value, currentPath));
    } else if (value?.message) {
      paths.push(currentPath);
    }
  });

  return paths;
};

const useFocusErrorForm = (formState = {}) => {
  const [isValidating, setIsValidating] = useState(false);
  useEffect(() => {
    const errorPaths = getAllErrorPaths(formState.errors || {});

    if (errorPaths.length === 0) {
      setIsValidating(false);
      return;
    }

    if (isValidating) {
      return;
    }

    setIsValidating(true);
    const elements = errorPaths
      .map((name) => document.getElementsByName(name)[0])
      .filter((el) => !!el);

    elements.sort((a, b) => {
      if (a.localName === "textarea") return 1;
      return b.scrollHeight - a.scrollHeight;
    });

    elements[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
    elements[0]?.focus();

    // eslint-disable-next-line
  }, [formState]);
};

export default useFocusErrorForm;
