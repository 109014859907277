import React, { useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useController } from "react-hook-form";
import TextFieldMUI from "@mui/material/TextField";
import i18next from "i18next";
import Typography from "@mui/material/Typography";
import { DATE_LONG_TEXT, FIELD_REQUIRED } from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { getReactHookFormNestedError } from "../../utils/errors";
import _ from "lodash";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const DateField = React.forwardRef(function DateField(
  {
    control,
    rules = {
      required: {
        value: true,
        message: i18next.t(FIELD_REQUIRED),
      },
    },
    minDate,
    maxDate,
    errors = {},
    name = "",
    label = "",
    disabled = false,
    actionsChange = () => {},
    onOpen = () => {},
    value = new Date(),
    variant = "filled",
    mode = "input",
    sx = {},
    isDisabledClickText = false,
    onClickText = () => {},
    formatText = DATE_LONG_TEXT,
    propsText = {},
    className = "",
    fullWidth = true,
    clearable = false,
  },
  ref
) {
  const [date, setDate] = useState(value);
  const refIcon = useRef(null);

  const error = getReactHookFormNestedError(errors, name);

  const { t } = useTranslationApp();

  const sxMode =
    mode === "text"
      ? {
          visibility: "hidden",
          position: "absolute",
        }
      : {};

  const {
    field: { onChange: onChangeField },
  } = useController({
    control,
    name,
    rules,
  });

  const onClickClear = () => {
    onChangeField(null);
    setDate(null);
    actionsChange(null);
  };
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {mode === "text" && (
        <Typography
          variant="h6"
          {...propsText}
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
            ...(propsText?.sx || {}),
          }}
          onClick={() => {
            if (isDisabledClickText) return;
            const child = refIcon.current.children[1];
            if (child) {
              child.click();
              onClickText();
            }
          }}
        >
          {t(formatText, {
            date: date,
          })}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Controller
          name={name}
          defaultValue={value}
          control={control}
          rules={rules}
          render={({ field }) => (
            <DatePicker
              {...field}
              ref={ref}
              minDate={minDate}
              maxDate={maxDate}
              onOpen={onOpen}
              onChange={(date) => {
                const isValueDate = _.isDate(date) && !isNaN(date.getTime());
                field.onChange(isValueDate ? date : null);
                setDate(isValueDate ? date : null);
                actionsChange(isValueDate ? date : null);
              }}
              disabled={disabled}
              label={label}
              InputAdornmentProps={{
                position: "start",
                ref: refIcon,
              }}
              renderInput={(params) => (
                <TextFieldMUI
                  {...params}
                  className={className}
                  fullWidth={fullWidth}
                  disabled={disabled}
                  variant={variant}
                  error={!!error}
                  sx={{ ...sx, ...sxMode }}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                />
              )}
            />
          )}
        />
        {error?.message && (
          <Typography variant="caption" color="error">
            {error?.message}
          </Typography>
        )}
      </Box>
      {clearable && !disabled && (
        <IconButton
          onClick={onClickClear}
          sx={{
            position: "absolute",
            right: 0,
            top: 14,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
});

export default DateField;
