import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import { Controller, useController, useFormContext } from "react-hook-form";
import { COMING_SOON, FIELD_REQUIRED } from "../../../../i18n/keysTranslations";
import {
  GOOGLE,
  IDS_PLATFORM_BUTTONS,
  LINKEDIN,
  META,
  PLATFORMS_SUPPORTED_WITH_COMING_SOON,
  TIKTOK,
} from "../../../../utils/constants";
import { ADS_CAMPAIGNS_OBJECTIVES_SUPPORTED_BY_PLATFORM } from "../../../../utils/platforms";
import ButtonMetaAction from "../../../../components/Buttons/ButtonMetaAction";
import ButtonGoogleAction from "../../../../components/Buttons/ButtonGoogleAction";
import ButtonLinkedinAction from "../../../../components/Buttons/ButtonLinkedinAction";
import React, { useState } from "react";
import GenerateCampaignMeta from "../../../../components/Platforms/Meta/GenerateCampaign/GenerateCampaignMeta";
import GenerateCampaignTikTok from "../../../../components/Platforms/TikTok/GenerateCampaign/GenerateCampaignTikTok";
import GenerateCampaignGoogle from "../../../../components/Platforms/Google/GenerateCampaign/GenerateCampaignGoogle";
import ButtonTikTokBusinessAction from "../../../../components/Buttons/ButtonTikTokBusinessAction";
import { formatMediaUrl } from "../../../../services/adsCampaigns";
import { useTheme } from "@mui/material";

const GENERATE_CAMPAIGN_FORMS = {
  [META]: <GenerateCampaignMeta />,
  [TIKTOK]: <GenerateCampaignTikTok />,
  [GOOGLE]: <GenerateCampaignGoogle />,
};

const AddPlatformsCampaign = ({
  label,
  disabledButtons,
  sxButtons = {},
  filterPlatforms = [],
  className = "",
}) => {
  const [initialData, setInitialData] = useState(null);
  const [modalPlatformSetupOpen, setModalPlatformSetupOpen] = useState(null);
  const { t } = useTranslationApp();

  const {
    control,
    trigger,
    formState: { errors },
    isGenerating,
    onGenerateCampaignPlatform,
    campaignID,
    getValues,
    brandInformation,
    onChangeIsForcedSetupPage,
    onChangeSectionSelected,
  } = useFormContext();
  const platformField = useController({
    name: "platforms",
    control,
  });

  const theme = useTheme();

  const onCallbackSavePlatform = (platformData) => {
    onGenerateCampaignPlatform(platformData);
    setModalPlatformSetupOpen(null);
  };
  const onSelectPlatform = async (platform) => {
    if (platformField.field.value.includes(platform)) {
      platformField.field.onChange(
        platformField.field.value.filter((v) => v !== platform)
      );
      return;
    }

    const contextValid = await trigger(["context", "language", "locations"]);

    if (!contextValid) {
      if (platformField.field.value.length > 0) {
        onChangeIsForcedSetupPage(true);
        onChangeSectionSelected({
          platform: undefined,
          adGroupID: undefined,
          adID: undefined,
          from: "addPlatforms",
        });
      }
      return;
    }

    getInitialDataByPlatform(platform);
    setModalPlatformSetupOpen(platform);
  };

  const getConfigurationPlatform = (platform) => {
    if (platform === META) {
      return {
        id: IDS_PLATFORM_BUTTONS[platform],
        container: <ButtonMetaAction />,
      };
    }
    if (platform === TIKTOK) {
      return {
        id: IDS_PLATFORM_BUTTONS[platform],
        container: <ButtonTikTokBusinessAction />,
      };
    }
    if (platform === GOOGLE) {
      return {
        id: IDS_PLATFORM_BUTTONS[platform],
        container: <ButtonGoogleAction />,
      };
    }
    if (platform === LINKEDIN) {
      return {
        isConnected: false,
        id: IDS_PLATFORM_BUTTONS[platform],
        container: <ButtonLinkedinAction />,
      };
    }
  };

  const getInitialDataByPlatform = (platform) => {
    const platforms = getValues("platforms");

    if (platforms?.length === 0) {
      setInitialData({
        destinationURL: brandInformation?.websiteUrl || "",
      });
      return;
    }

    let objective = "";
    let destinationURL = "";
    let mediaData = [];
    let mediaUrls = [];

    platforms.forEach((platformIteration) => {
      if (platformIteration === platform) return;

      const platformData =
        getValues(`platformsData.${platformIteration}`) || {};
      if (!objective) {
        const objectivePlatform = platformData?.objective;
        const foundedObjective = ADS_CAMPAIGNS_OBJECTIVES_SUPPORTED_BY_PLATFORM[
          platformIteration
        ].find((objective) => objective.value === objectivePlatform);
        if (foundedObjective) {
          objective = foundedObjective.value;
        }
      }
    });

    const adsGroups = getValues("adsGroups") || [];

    adsGroups.forEach((adGroup) => {
      if (adGroup.platform === platform) return;

      const ads = adGroup.ads || [];

      ads.forEach((ad) => {
        const destinationUrls = ad.destinationUrls || [];
        if (!destinationURL && destinationUrls.length > 0) {
          destinationURL = destinationUrls?.[0]?.value;
        }
        const mediaUrlsAd = ad.mediaUrls || [];
        const mediaDataAd = ad.mediaData || [];

        mediaUrlsAd.forEach((mediaUrl, index) => {
          if (mediaUrl === null) return;
          if (mediaUrls.includes(mediaUrl)) return;

          mediaUrls.push(mediaUrl);
          mediaData.push(mediaDataAd[index] || { from: "upload" });
        });
      });
    });

    let mediaUrlsFormatted = [];
    if (mediaUrls.length > 0) {
      mediaUrlsFormatted = mediaUrls.map((mediaUrl, index) =>
        formatMediaUrl({
          url: mediaUrl,
          ...(mediaData[index] || {}),
        })
      );
    }

    setInitialData({
      objective,
      destinationURL,
      mediaUrls: mediaUrlsFormatted,
    });

    return {
      objective,
      destinationURL,
      mediaUrls: mediaUrlsFormatted,
    };
  };

  return (
    <Box className={className}>
      <Typography
        variant="body1"
        sx={{
          mb: 1.5,
        }}
      >
        {t(label)}
      </Typography>
      <Controller
        name="platforms"
        control={control}
        rules={{
          required: {
            value: true,
            message: t(FIELD_REQUIRED),
          },
        }}
        render={() => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "flex-start",
              }}
            >
              {PLATFORMS_SUPPORTED_WITH_COMING_SOON.map((platform) => {
                if (filterPlatforms.includes(platform)) return null;

                const configurationPlatform =
                  getConfigurationPlatform(platform);

                return (
                  <Box key={platform}>
                    {React.cloneElement(configurationPlatform.container, {
                      id: configurationPlatform.id,
                      text: t(platform),
                      logo: platform === META ? META : undefined,
                      doTranslation: false,
                      onClick: () => onSelectPlatform(platform),
                      disabled: isGenerating || disabledButtons,
                      sx: {
                        backgroundColor: `${theme.palette.background.paperSecondary} !important`,
                        color: `${theme.palette.background.paperContrastText} !important`,
                        filter:
                          platform === LINKEDIN ? "brightness(0.5)" : "unset",
                        "&:hover": {
                          backgroundColor: `${theme.palette.background.paperSecondary} !important`,
                          color: `${theme.palette.background.paperContrastText} !important`,
                        },
                        background: "unset",
                        ...sxButtons,
                      },
                    })}
                    {configurationPlatform.isComingSoon && (
                      <Typography
                        variant="body2"
                        sx={{
                          mt: 0.5,
                          color: "GrayText",
                          fontStyle: "italic",
                        }}
                      >
                        {t(COMING_SOON)}
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </Box>
          );
        }}
      />
      {errors.platforms && (
        <Typography variant="body2" color="error">
          {t(errors.platforms.message)}
        </Typography>
      )}
      {Boolean(modalPlatformSetupOpen) &&
        React.cloneElement(GENERATE_CAMPAIGN_FORMS[modalPlatformSetupOpen], {
          modalOpen: Boolean(modalPlatformSetupOpen),
          onCloseModal: () => setModalPlatformSetupOpen(null),
          campaignID,
          onCallbackSave: onCallbackSavePlatform,
          initialData,
        })}
    </Box>
  );
};

export default AddPlatformsCampaign;
