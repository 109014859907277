import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import Accordion from "../../../../components/Containers/Accordion";
import {
  AN_ERROR_OCCURRED_GETTING_THE_REPORT,
  ANALYZING_CAMPAIGN_STATISTICS,
  APPLYING_OPTIMIZATIONS,
  BUTTON_APPLIED,
  BUTTON_CHANGE_CREATIVE,
  BUTTON_PREVIEW_OPTIMIZATION,
  COMPARING_RESULTS_OBTAINED,
  CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS,
  CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION,
  GENERATING_OPTIMIZATIONS,
  HOW_IS_YOUR_CAMPAIGN_GOING,
} from "../../../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_STATUS_ACTIVE,
  BORDER_RADIUS,
  TIME_LOADER_GENERATING_REPORT_CAMPAIGN,
  TIME_LOADER_OPTIMIZE_CAMPAIGN,
} from "../../../../utils/constants";
import LinearLoaderWithTime from "../../../../components/Loaders/LinearLoaderWithTime";
import TypingText from "../../../../components/Texts/TypingText";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDispatchApp } from "../../../../lib/redux";
import { useTranslationApp } from "../../../../lib/i18next";
import { useFormContext } from "react-hook-form";
import { startGetCampaignReportState } from "../../../../actions/adsCampaigns";
import { getGeneralStatistics } from "../../../../services/adsCampaigns";
import _ from "lodash";
import { differenceInDays, startOfDay } from "date-fns";
import ModalPreviewOptimization from "./ModalPreviewOptimization";
import { getUniqueID } from "../../../../utils/numbers";
import InsightCard from "../../../AdsCampaignStatistics/components/PerformingAssets/components/InsightCard";
import InsightActionableContainer from "../../../../components/Containers/InsightActionableContainer";

const ReportContainer = () => {
  const [allTextExplanation, setAllTextExplanation] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isUpdatingCampaignByOptimization] = useState(false);
  const [hasError, setHasError] = useState(false);

  const {
    campaignID,
    sectionSelected,
    isGeneralCampaignActivated,
    setValue,
    getValues,
    watch,
  } = useFormContext();

  const theme = useTheme();
  const dispatch = useDispatchApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslationApp();

  const now = new Date();
  const report = watch("report") || {};
  const lastReportTime = report?.lastReportTime;
  const firstActiveTime = getValues("firstActiveTime");
  const statistics = getValues("statistics");
  const states = getValues("states");

  const commonSxAccordion = {
    border: `1px solid ${theme.palette.background.paperSecondary}`,
    borderRadius: BORDER_RADIUS,
    "::before": {
      backgroundColor: "transparent",
    },
    m: "0 !important",
    mb: "16px !important",
    width: "100%",
  };

  const onGenerateReport = async () => {
    setIsGenerating(true);
    setHasError(false);

    const response = await dispatch(
      startGetCampaignReportState({
        campaignID,
      })
    );
    if (response) {
      setValue("report", response);
      const explanation = getAllExplanation(response);
      setAllTextExplanation(explanation);
    } else {
      setHasError(true);
    }
    setIsGenerating(false);
  };

  const getAllExplanation = (report) => {
    let explanation = "";

    const reportStateExplanation =
      report?.states?.[sectionSelected.platform].report || "";
    explanation += `${reportStateExplanation}\n`;

    return explanation;
  };

  useEffect(() => {
    if (!firstActiveTime) return;
    if (!statistics) return;

    if (states.general !== ADS_CAMPAIGN_STATUS_ACTIVE || !states) {
      return;
    }

    const generalStatistics = getGeneralStatistics(statistics);

    if (!generalStatistics.impressions) {
      return;
    }
    const differenceDaysFromFirstActiveTime = differenceInDays(
      now,
      firstActiveTime
    );
    //only if the campaign is active for more than 7 days
    if (differenceDaysFromFirstActiveTime < 7) return;
    if (!lastReportTime && !report && isGeneralCampaignActivated) {
      onGenerateReport();
      return;
    }
    const lastReport = new Date(lastReportTime);

    //verify if the last report was generated 7 days ago (since start of the day)
    const differenceDaysFromLastReport = differenceInDays(
      startOfDay(now),
      startOfDay(lastReport)
    );
    if (differenceDaysFromLastReport < 7) return;
    onGenerateReport();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!sectionSelected?.platform) return;

    const explanation = getAllExplanation(report);
    setAllTextExplanation(explanation);

    // eslint-disable-next-line
  }, [sectionSelected?.platform]);

  if (_.isEmpty(report) && !isGenerating) return null;

  return (
    <>
      <Accordion
        initialValue={true}
        title={t(HOW_IS_YOUR_CAMPAIGN_GOING)}
        iconTitle={<AnalyticsIcon />}
        variantTitle={isMobile ? "body1" : "h6"}
        sx={{ ...commonSxAccordion, maxWidth: "calc(100vw - 318px)" }}
        sxTitle={{
          fontWeight: 900,
        }}
        sxContainerTitle={{
          ".MuiAccordionSummary-content": {
            display: "flex",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {},
          pt: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            height: "auto",
          }}
        >
          {hasError ? (
            <Typography variant="body1">
              {t(AN_ERROR_OCCURRED_GETTING_THE_REPORT)}
            </Typography>
          ) : null}
          {isGenerating ? (
            <LinearLoaderWithTime
              time={TIME_LOADER_GENERATING_REPORT_CAMPAIGN}
              labels={[
                t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
                t(ANALYZING_CAMPAIGN_STATISTICS),
                t(COMPARING_RESULTS_OBTAINED),
                t(GENERATING_OPTIMIZATIONS),
              ]}
              sx={{ mt: 1.5 }}
            />
          ) : null}
          {!_.isEmpty(report) && !isGenerating && !hasError ? (
            <TypingText
              text={allTextExplanation}
              sx={{
                height: 270,
              }}
              componentAfterFinishTyping={
                <InsightsComponent
                  insights={
                    report?.states?.[sectionSelected.platform].insights || []
                  }
                />
              }
            />
          ) : null}

          {isUpdatingCampaignByOptimization ? (
            <LinearLoaderWithTime
              time={TIME_LOADER_OPTIMIZE_CAMPAIGN}
              labels={[
                t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
                t(APPLYING_OPTIMIZATIONS),
                t(CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS),
              ]}
              sx={{ mt: 1.5 }}
            />
          ) : null}
        </Box>
      </Accordion>
    </>
  );
};

const sxCutText = (maxLines = 2) => ({
  whiteSpace: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: maxLines,
  WebkitBoxOrient: "vertical",
});

const InsightsComponent = ({ insights = [] }) => {
  const { sectionSelected, onChangeSectionSelected } = useFormContext();
  const [selectedInsight, setSelectedInsight] = useState(null);
  const { t } = useTranslationApp();

  const onSelectInsight = (insight) => {
    if (insight.category !== "creativeEdit") {
      setSelectedInsight(insight);
    }

    if (insight.category === "creativeEdit") {
      const metadata = insight.metadata;
      const { adGroupID, adID } = metadata[0];

      onChangeSectionSelected({
        platform: sectionSelected.platform,
        adGroupID,
        adID,
        customParams: {
          focusChangeButton: true,
          insightIndex: insight.index,
        },
      });
    }
  };

  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          maxWidth: "100%",
          overflowX: "auto",
          pb: 1,
        }}
      >
        {insights.map((insight, index) => {
          const isApplied = insight.isApplied;
          if (isApplied) return null;
          return (
            <InsightCard
              key={getUniqueID()}
              title={insight.title}
              sxTitle={{ height: 26, ...sxCutText(1) }}
              sx={{
                minWidth: 250,
                width: 250,
              }}
              nextContent={
                <InsightActionableContainer
                  insight={insight}
                  showActionButton={insight.category !== "unknown"}
                  disabled={insight.isApplied}
                  actionButtonText={t(
                    insight.isApplied
                      ? BUTTON_APPLIED
                      : insight.category === "creativeEdit"
                      ? BUTTON_CHANGE_CREATIVE
                      : BUTTON_PREVIEW_OPTIMIZATION
                  )}
                  onActionButtonClick={() =>
                    onSelectInsight({ ...insight, index })
                  }
                />
              }
            />
          );
        })}
      </Box>

      {selectedInsight && (
        <ModalPreviewOptimization
          modalOpen={!!selectedInsight}
          onCloseModal={() => setSelectedInsight(null)}
          insight={{ ...selectedInsight, platform: sectionSelected.platform }}
          insightIndex={selectedInsight.index}
        />
      )}
    </Box>
  );
};

export default ReportContainer;
