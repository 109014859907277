import AccordionMUI from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { getUniqueID } from "../../utils/numbers";
import { getReactHookFormNestedError } from "../../utils/errors";
import _ from "lodash";

const Accordion = ({
  id = getUniqueID(),
  children,
  title = "",
  titleAlign = "center",
  sx,
  sxTitle = {},
  sxContainerTitle = {},
  hasBorder = true,
  actionsComponent,
  variantTitle = "body1",
  initialValue = false,
  iconTitle = null,
  minHeight = "48px !important",
  onCallbackChangeExpanded = () => {},
  name = "",
  errors = {},
  showError = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(initialValue);
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:450px)");

  const error = getReactHookFormNestedError(errors, name);
  const hasError = showError && !_.isEmpty(error);

  useEffect(() => {
    onCallbackChangeExpanded(isExpanded);
    // eslint-disable-next-line
  }, [isExpanded]);

  return (
    <>
      <AccordionMUI
        id={id}
        expanded={isExpanded}
        onChange={(_, value) => setIsExpanded(value)}
        defaultExpanded={initialValue}
        TransitionProps={{
          timeout: 350,
        }}
        sx={{
          border: hasBorder
            ? `${
                hasError ? theme.palette.error.main : theme.palette.border.grey
              } 1px solid`
            : "none",
          borderRadius: `${4 * 4}px !important`,
          ...sx,
          "::before": {
            backgroundColor: "transparent",
          },
          ".MuiAccordionSummary-root": {
            ...(sx?.[".MuiAccordionSummary-root"] || {}),
            "& .MuiAccordionSummary-content": {
              m: 0,
            },
            minHeight,
          },
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${title}-content`}
          id={`${title}-header`}
          sx={{
            pt: isMobile ? 1.5 : 0,
            ".MuiAccordionSummary-content": {
              display: "flex",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? 0 : 1,
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              alignSelf: isMobile ? "flex-start" : "center",
              position: isMobile ? "absolute" : "unset",
              top: 10,
              right: 15,
            },
            ...sxContainerTitle,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              flex: 1,
            }}
          >
            {iconTitle && iconTitle}
            <Typography sx={sxTitle} align={titleAlign} variant={variantTitle}>
              {title}
            </Typography>
          </Box>
          {actionsComponent && actionsComponent}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </AccordionMUI>
      {hasError && (
        <Typography variant="caption" color="error">
          {error?.message}
        </Typography>
      )}
    </>
  );
};

export default Accordion;
