import React, { useCallback, useState } from "react";
import { APIProvider, Map, Marker, useMap } from "@vis.gl/react-google-maps";
import Circle from "./Circle";
import Polygon from "./Polygon";
import Polyline from "./Polyline";
import Box from "@mui/material/Box";
import Button from "../Buttons/Button";
import {
  BUTTON_CANCEL,
  BUTTON_CONTINUE,
  BUTTON_DROP_PIN,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useMapsFeatures from "../../hooks/useMapsFeatures";
import _ from "lodash";
import SearchLocationMaps from "./SearchLocationMaps";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../utils/colors";
import MarkersCards from "./MarkersCards";
import { useDebounced } from "../../hooks/useDebounce";

const INITIAL_CENTER = { lat: 41.1897, lng: -96.0627 };

const MapRadius = ({
  value = {},
  initialCenter = INITIAL_CENTER,
  sx = {},
  onCallbackSave = () => {},
}) => {
  const INITIAL_MARKERS = value.locations || [];
  const INITIAL_CONTEXTS = value.contexts || [];
  const [markers, setMarkers] = useState(INITIAL_MARKERS);
  const [contexts, setContexts] = useState(INITIAL_CONTEXTS);
  const [isDroppingPin, setIsDroppingPin] = useState(false);

  const { t } = useTranslationApp();
  const map = useMap();
  const { getLocationNameByLatLng, placesService, sessionToken } =
    useMapsFeatures();

  const onSelectLocationInSearch = useCallback(
    async ({ lat, lng, name, longName, id }) => {
      const newMarker = {
        lat,
        lng,
        type: "specific",
        name,
        longName,
        id,
      };
      setMarkers((prev) => [...prev, newMarker]);
    },
    []
  );

  const onClickMap = useCallback(
    async (e) => {
      if (isDroppingPin) {
        const lat = e.detail.latLng.lat;
        const lng = e.detail.latLng.lng;
        const responseName = await getLocationNameByLatLng({ lat, lng });

        const newMarker = {
          lat,
          lng,
          type: "radius",
          radius: 10000,
          name: responseName.name,
          longName: responseName.longName,
          id: responseName.id,
        };
        setMarkers((prev) => [...prev, newMarker]);
        setIsDroppingPin(false);
      }
    },
    // eslint-disable-next-line
    [isDroppingPin]
  );

  const updateMarkerInfo = useDebounced(
    useCallback(
      async (index) => {
        setMarkers((currentMarkers) => {
          const marker = currentMarkers[index];
          if (!marker) return currentMarkers;
          (async () => {
            const { lat, lng } = marker;
            const originalTypes = marker.originalTypes || [];

            const responseName = await getLocationNameByLatLng({
              lat,
              lng,
              originalTypes,
            });

            // Actualizamos solo el nombre y longName, manteniendo el resto de propiedades
            setMarkers((latestMarkers) => {
              const updatedMarkers = [...latestMarkers];
              if (updatedMarkers[index]) {
                updatedMarkers[index] = {
                  ...updatedMarkers[index],
                  name: responseName.name,
                  longName: responseName.longName,
                };
              }
              return updatedMarkers;
            });
          })();
          return currentMarkers;
        });
      },
      [getLocationNameByLatLng]
    ),
    2000
  );

  const updateMarkerPosition = useCallback(
    ({ index, lat, lng }) => {
      setMarkers((prev) => {
        const updated = [...prev];
        updated[index] = { ...updated[index], lat, lng };
        return updated;
      });

      updateMarkerInfo(index);
    },
    [updateMarkerInfo]
  );

  const updateMarkerRadius = useCallback(({ index, radius }) => {
    setMarkers((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], radius };
      return updated;
    });
  }, []);

  const onRemoveMarker = useCallback((index) => {
    setMarkers((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
  }, []);

  const onMarkerDragEnd = (index, e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    updateMarkerPosition({ index, lat, lng });
  };

  const onSelectLocation = (value) => {
    if (!value || !_.isObject(value) || !placesService) return false;

    const isAdded = markers.some((marker) => marker.id === value.place_id);
    const request = {
      placeId: value.place_id,
      fields: ["geometry"],
      sessionToken,
    };

    // Guardamos los tipos originales del resultado
    const originalTypes = value.types || [];

    placesService.getDetails(request, async (place, status) => {
      if (status !== "OK" || !place?.geometry?.location) return false;

      const { lat: latFunction, lng: lngFunction } = place.geometry.location;
      const lat = latFunction();
      const lng = lngFunction();
      const responseNames = await getLocationNameByLatLng({
        lat,
        lng,
        originalTypes,
      });
      const { name, longName } = responseNames;

      map.fitBounds(place.geometry?.viewport);
      if (isAdded) return false;
      onSelectLocationInSearch({
        lat,
        lng,
        name: name || value.structured_formatting?.main_text || "",
        longName: longName || value.description || "",
        id: value.place_id,
      });

      return true;
    });
  };

  const onSendLocations = () =>
    onCallbackSave({
      contexts,
      locations: markers,
    });

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        ...sx,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          cursor: isDroppingPin ? "crosshair" : "grab",
          "& *": {
            cursor: isDroppingPin ? "crosshair" : "grab",
          },
        }}
      >
        <Map
          defaultCenter={initialCenter}
          defaultZoom={5}
          gestureHandling={"greedy"}
          disableDefaultUI={true}
          style={{
            position: "absolute",
          }}
          onClick={onClickMap}
        >
          {markers.map((marker, index) => {
            const { lat, lng, type, radius } = marker;
            if (type === "specific") {
              return (
                <Marker
                  key={index}
                  position={{ lat, lng }}
                  draggable
                  onDragEnd={(e) => onMarkerDragEnd(index, e)}
                />
              );
            }
            if (type === "radius") {
              return (
                <React.Fragment key={index}>
                  <Marker
                    position={{ lat, lng }}
                    draggable
                    onDragEnd={(e) => onMarkerDragEnd(index, e)}
                  />
                  <Circle
                    center={{ lat, lng }}
                    radius={radius}
                    draggable
                    editable
                    strokeColor="#0c4cb3"
                    fillColor="#3b82f6"
                    fillOpacity={0.3}
                    strokeWeight={3}
                    onCenterChanged={(googleCenter) => {
                      const newLat = googleCenter.lat();
                      const newLng = googleCenter.lng();
                      updateMarkerPosition({
                        index,
                        lat: newLat,
                        lng: newLng,
                      });
                    }}
                    onRadiusChanged={(newRadius) => {
                      updateMarkerRadius({ index, radius: newRadius });
                    }}
                  />
                </React.Fragment>
              );
            }

            return null;
          })}
          <Polygon strokeWeight={1.5} />
          <Polyline strokeWeight={10} strokeColor={"#ff22cc88"} />
        </Map>
        <Box
          sx={{
            position: "absolute",
            bottom: 16,
            left: 8,
            zIndex: 1,
          }}
        >
          <Button
            variant="contained"
            onClick={() => setIsDroppingPin((prev) => !prev)}
            sx={{ width: 120 }}
          >
            <LocationOnIcon sx={{ mr: 0.5 }} />
            {isDroppingPin ? t(BUTTON_CANCEL) : t(BUTTON_DROP_PIN)}
          </Button>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 16,
            right: 8,
            zIndex: 1,
          }}
        >
          <Button
            variant="contained"
            onClick={onSendLocations}
            sx={{
              background: GRADIENT_PURPLE_FUCHSIA,
              color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
              width: 120,
            }}
          >
            {t(BUTTON_CONTINUE)}
          </Button>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            zIndex: 1,
            width: "100%",
            p: 2,
          }}
        >
          <SearchLocationMaps
            onAddContext={(context) =>
              setContexts((prev) => [...prev, context])
            }
            onSelectLocation={onSelectLocation}
            onAddMarker={({ lat, lng, name, longName, id }) => {
              const isAdded = markers.some((marker) => marker.id === id);
              if (isAdded) return false;
              const newMarker = {
                lat,
                lng,
                name,
                longName,
                id,
                type: "specific",
              };
              setMarkers((prev) => [...prev, newMarker]);
            }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 80,
            zIndex: 1,
            width: "100%",
            p: 2,
          }}
        >
          <MarkersCards markers={markers} onRemoveMarker={onRemoveMarker} />
        </Box>
      </Box>
    </Box>
  );
};

const ContainerWithProvider = ({ value, sx, onCallbackSave }) => {
  return (
    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
      <MapRadius sx={sx} onCallbackSave={onCallbackSave} value={value} />
    </APIProvider>
  );
};

export default ContainerWithProvider;
