import { useState } from "react";
import Box from "@mui/material/Box";
import {
  BUTTON_ADD_KEYWORDS,
  GOOGLE_KEYWORDS_EXPLANATION,
} from "../../i18n/keysTranslations";
import { useController } from "react-hook-form";
import Typography from "@mui/material/Typography";
import TooltipModal from "../Tooltip/TooltipModal";
import ContentCommon from "../Tooltip/ContentCommon";
import { getReactHookFormNestedError } from "../../utils/errors";
import ChipOptional from "../Chip/ChipOptional";
import KeywordsTable from "./Keywords/KeywordsTable";
import KeywordModal from "./Keywords/KeywordModal";
import Button from "../Buttons/Button";
import { useTranslationApp } from "../../lib/i18next";
import { getKeywordMatchType } from "../../utils/string";
import KeywordsModal from "./Keywords/KeywordsModal";

const KeywordsGoogleContainer = ({
  label,
  explanation = GOOGLE_KEYWORDS_EXPLANATION,
  name,
  disabled = false,
  control,
  errors,
  rules,
  isNegativeKeywords = false,
}) => {
  const [modalKeywordOpen, setModalKeywordOpen] = useState(false);
  const [modalKeywordsOpen, setModalKeywordsOpen] = useState(false);
  const [editingKeyword, setEditingKeyword] = useState(null);
  const { t } = useTranslationApp();

  const {
    field: { value: keywords = [], onChange: keywordsOnChange },
  } = useController({
    name,
    control,
    rules,
  });

  const error = getReactHookFormNestedError(errors, name);

  const onAddKeywords = (newKeywordsAdded) => {
    const newKeywords = [...keywords];
    newKeywords.unshift(...newKeywordsAdded);
    keywordsOnChange(newKeywords);
  };

  const onEditKeyword = (keyword) => {
    const newKeywords = [...keywords];
    const index = newKeywords.indexOf(editingKeyword.original);
    if (index !== -1) {
      newKeywords[index] = keyword;
      keywordsOnChange(newKeywords);
    }
    setEditingKeyword(null);
  };

  const onDeleteKeywords = (keywordsToDelete) => {
    const newKeywords = keywords.filter((k) => !keywordsToDelete.includes(k));
    keywordsOnChange(newKeywords);

    return true;
  };

  const openEditModal = (keyword) => {
    setEditingKeyword(keyword);
    setModalKeywordOpen(true);
  };

  const onCloseModal = () => {
    setModalKeywordOpen(false);
    setEditingKeyword(null);
  };

  const keywordsFormatted = keywords.map((keyword) => ({
    ...getKeywordMatchType(keyword),
    original: keyword,
  }));

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 1,
          mt: 1,
          mb: 1.5,
          height: 32,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="body1">{label}</Typography>
          <TooltipModal
            title={label}
            content={<ContentCommon text={explanation} />}
          />
          {isNegativeKeywords && <ChipOptional />}
        </Box>
        {!disabled && (
          <Button onClick={() => setModalKeywordsOpen(true)}>
            {t(BUTTON_ADD_KEYWORDS)}
          </Button>
        )}
      </Box>

      <KeywordsTable
        isNegativeKeywords={isNegativeKeywords}
        keywords={keywordsFormatted}
        onEdit={openEditModal}
        onDelete={onDeleteKeywords}
        disabled={disabled}
      />

      {modalKeywordOpen && (
        <KeywordModal
          modalOpen={modalKeywordOpen}
          onCloseModal={onCloseModal}
          onSubmit={onEditKeyword}
          currentKeywords={keywords}
          initialData={editingKeyword}
        />
      )}

      {modalKeywordsOpen && (
        <KeywordsModal
          modalOpen={modalKeywordsOpen}
          onCloseModal={() => setModalKeywordsOpen(false)}
          onSubmit={onAddKeywords}
          currentKeywords={keywords}
        />
      )}

      {error?.message && (
        <Typography variant="caption" color="error" name={name}>
          {error.message}
        </Typography>
      )}
    </Box>
  );
};

export default KeywordsGoogleContainer;
