import { useState, useEffect, useCallback } from "react";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";

const parseLocationNames = (result, originalTypes) => {
  if (!result) {
    return { name: "", longName: "" };
  }

  const addressComponents = result.address_components || [];

  // Obtener el tipo de resultado principal basado en types
  const isCountry = originalTypes.includes("country");
  const isRegion = originalTypes.includes("administrative_area_level_1");
  const isSubRegion = originalTypes.includes("administrative_area_level_2");

  // search city (or sublocality) in the address_components:
  const cityComponent =
    addressComponents.find((comp) => comp.types.includes("locality")) ||
    addressComponents.find((comp) => comp.types.includes("sublocality")) ||
    addressComponents.find((comp) =>
      comp.types.includes("administrative_area_level_3")
    ) ||
    addressComponents.find((comp) =>
      comp.types.includes("administrative_area_level_2")
    );

  const city = cityComponent ? cityComponent.long_name : "";

  // search region/state in the address_components:
  const regionComponent = addressComponents.find((comp) =>
    comp.types.includes("administrative_area_level_1")
  );
  const region = regionComponent ? regionComponent.short_name : "";

  // search country in the address_components:
  const countryComponent = addressComponents.find((comp) =>
    comp.types.includes("country")
  );
  const country = countryComponent ? countryComponent.long_name : "";

  // Determinamos el nombre según el tipo de resultado principal
  let name;

  if (isCountry) {
    name = country;
  } else if (isRegion) {
    name = country ? `${region}, ${country}` : region;
  } else if (isSubRegion) {
    const subRegion = addressComponents.find((comp) =>
      comp.types.includes("administrative_area_level_2")
    );
    name = country
      ? `${subRegion?.long_name || ""}, ${country}`
      : subRegion?.long_name || "";
  } else if (city) {
    name = country
      ? `${city}, ${country}`
      : region
      ? `${city}, ${region}`
      : city;
  } else if (region) {
    name = country ? `${region}, ${country}` : region;
  } else {
    name = country;
  }

  // "longName" => concatena city, region and country, omitting the empty ones:
  const parts = [];

  // Si es un país, solo incluimos el país
  if (isCountry) {
    parts.push(country);
  }
  // Si es una región, incluimos región y país
  else if (isRegion) {
    if (region) parts.push(region);
    if (country) parts.push(country);
  }
  // En otros casos, seguimos el comportamiento normal
  else {
    if (city) parts.push(city);
    if (region) parts.push(region);
    if (country) parts.push(country);
  }

  const longName = parts.join(", ");

  return { name, longName };
};

const useMapsFeatures = () => {
  const places = useMapsLibrary("places");
  const map = useMap();

  const [sessionToken, setSessionToken] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);
  const [geocoder, setGeocoder] = useState(null);

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    const geo = new window.google.maps.Geocoder();
    setGeocoder(geo);
  }, [places, map]);

  const getLocationNameByLatLng = async ({ lat, lng, originalTypes }) => {
    const latLng = { lat, lng };

    const response = await geocoder.geocode({ location: latLng });
    const results = response.results || [];

    if (results[0]) {
      const result = results[0];
      if (!originalTypes) {
        originalTypes = result.types;
      }
      const { name, longName } = parseLocationNames(result, originalTypes);

      return {
        ok: true,
        name,
        longName,
        id: result.place_id,
      };
    }

    return {
      ok: false,
      name: "",
      longName: "",
    };
  };

  const getPredictionsByKeyword = useCallback(
    async (value) => {
      if (!autocompleteService || !value) {
        return [];
      }

      const request = { input: value, sessionToken };

      try {
        const predictions = await new Promise((resolve, reject) => {
          autocompleteService.getPlacePredictions(
            request,
            (predictions, status) => {
              if (
                status === window.google.maps.places.PlacesServiceStatus.OK &&
                predictions
              ) {
                resolve(predictions);
              } else {
                reject();
              }
            }
          );
        });

        return predictions;
      } catch (error) {
        console.error(error);

        return [];
      }
    },
    [autocompleteService, sessionToken]
  );

  return {
    sessionToken,
    autocompleteService,
    placesService,
    geocoder,
    getLocationNameByLatLng,
    getPredictionsByKeyword,
    parseLocationNames,
  };
};

export default useMapsFeatures;
