import { z } from "zod";
import {
  ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_HEADLINE_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH,
} from "../../../../../utils/constants";
import {
  FIELD_MAX_LENGTH,
  FIELD_MIN_CARDS_AD_CAROUSEL,
  FIELD_REQUIRED,
  FIELD_TEXT_REPEATED,
  META,
} from "../../../../../i18n/keysTranslations";
import i18next from "i18next";

function getIndicesMap(arrayOfStrings) {
  const map = {};
  arrayOfStrings.forEach((txt, i) => {
    if (!map[txt]) {
      map[txt] = [];
    }
    map[txt].push(i);
  });
  return map;
}

const textItemSchema = z.object({
  id: z.string().optional(),
  value: z.string().min(1, i18next.t(FIELD_REQUIRED)),
});

export const adMetaSchema = z
  .object({
    id: z.string().optional(),
    platform: z.literal(META),
    isCarousel: z.boolean().default(false),
    callToAction: z.string().nonempty(i18next.t(FIELD_REQUIRED)),

    mediaUrls: z
      .array(z.string().nonempty(i18next.t(FIELD_REQUIRED)))
      .min(1, i18next.t(FIELD_REQUIRED)),

    destinationUrls: z
      .array(
        z.object({
          value: z.string(),
          id: z.string(),
        })
      )
      .optional(),
    titles: z.array(
      textItemSchema.extend({
        value: z
          .string()
          .min(1, i18next.t(FIELD_REQUIRED))
          .max(
            ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[META],
            i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[META],
            })
          ),
      })
    ),
    descriptions: z.array(
      textItemSchema.extend({
        value: z
          .string()
          .min(1, i18next.t(FIELD_REQUIRED))
          .max(
            ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[META],
            i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[META],
            })
          ),
      })
    ),
    headlines: z.array(
      textItemSchema.extend({
        value: z
          .string()
          .min(1, i18next.t(FIELD_REQUIRED))
          .max(
            ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[META],
            i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[META],
            })
          ),
      })
    ),

    messageTemplate: z.any().optional(),
    instantForm: z.any().optional(),
  })

  .superRefine((data, ctx) => {
    const titlesNormalized = data.titles.map((item) =>
      item.value.trim().toLowerCase()
    );
    const titlesMap = getIndicesMap(titlesNormalized);

    Object.entries(titlesMap).forEach(([, indexes]) => {
      if (indexes.length > 1) {
        indexes.forEach((i) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["titles", i, "value"],
            message: i18next.t(FIELD_TEXT_REPEATED),
          });
        });
      }
    });
    const headlinesNormalized = data.headlines.map((item) =>
      item.value.trim().toLowerCase()
    );
    const headlinesMap = getIndicesMap(headlinesNormalized);

    Object.entries(headlinesMap).forEach(([, indexes]) => {
      if (indexes.length > 1) {
        indexes.forEach((i) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["headlines", i, "value"],
            message: i18next.t(FIELD_TEXT_REPEATED),
          });
        });
      }
    });
    const descriptionsNormalized = data.descriptions.map((item) =>
      item.value.trim().toLowerCase()
    );
    const descriptionsMap = getIndicesMap(descriptionsNormalized);

    Object.entries(descriptionsMap).forEach(([, indexes]) => {
      if (indexes.length > 1) {
        indexes.forEach((i) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["descriptions", i, "value"],
            message: i18next.t(FIELD_TEXT_REPEATED),
          });
        });
      }
    });

    if (data.isCarousel) {
      data.titles.forEach((title, index) => {
        if (title.value.trim() === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_REQUIRED),
            path: ["titles", index, "value"],
          });
        }

        if (title.value.length > ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[META]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[META],
            }),
            path: ["titles", index, "value"],
          });
        }
      });

      if (data.mediaUrls.length < 2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18next.t(FIELD_MIN_CARDS_AD_CAROUSEL, { value: 2 }),
          path: ["mediaUrls"],
        });
      }

      const expectedLength = data.mediaUrls.length;

      const headlines = data.headlines?.slice(0, expectedLength) || [];
      const descriptions = data.descriptions?.slice(0, expectedLength) || [];

      headlines.forEach((headline, index) => {
        if (headline.value.trim() === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_REQUIRED),
            path: ["headlines", index, "value"],
          });
        }

        if (
          headline.value.length >
          ADS_CAMPAIGN_PLATFORM_HEADLINE_MAX_LENGTH[META]
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_HEADLINE_MAX_LENGTH[META],
            }),
            path: ["headlines", index, "value"],
          });
        }
      });

      descriptions.forEach((description, index) => {
        if (description.value.trim() === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_REQUIRED),
            path: ["descriptions", index, "value"],
          });
        }
        if (
          description.value.length >
          ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[META]
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[META],
            }),
            path: ["descriptions", index, "value"],
          });
        }
      });
    } else {
      const mediaUrls = data?.mediaUrls || [];
      if (!mediaUrls?.length || !mediaUrls[0]?.trim()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18next.t(FIELD_REQUIRED),
          path: ["mediaUrls", 0],
        });
      }

      const titles = data?.titles || [];
      titles.forEach((title, index) => {
        if (title.value.trim() === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_REQUIRED),
            path: ["titles", index, "value"],
          });
        }
        if (title.value.length > ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[META]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[META],
            }),
            path: ["titles", index, "value"],
          });
        }
      });

      const headlines = data?.headlines || [];
      headlines.forEach((title, index) => {
        if (title.value.trim() === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_REQUIRED),
            path: ["headlines", index, "value"],
          });
        }
        if (
          title.value.length > ADS_CAMPAIGN_PLATFORM_HEADLINE_MAX_LENGTH[META]
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_HEADLINE_MAX_LENGTH[META],
            }),
            path: ["headlines", index, "value"],
          });
        }
      });

      const descriptions = data?.descriptions || [];
      descriptions.forEach((title, index) => {
        if (title.value.trim() === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_REQUIRED),
            path: ["descriptions", index, "value"],
          });
        }
        if (
          title.value.length >
          ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[META]
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[META],
            }),
            path: ["descriptions", index, "value"],
          });
        }
      });
    }
  });
