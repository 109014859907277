import { ADS_CAMPAIGNS_STATISTICS_SLIDE_MENU_INSIGHTS } from "../../../../i18n/keysTranslations";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import { useFormContext } from "react-hook-form";
import { getUniqueID } from "../../../../utils/numbers";
import InsightCard from "../PerformingAssets/components/InsightCard";
import InsightActionableContainer from "../../../../components/Containers/InsightActionableContainer";
import _ from "lodash";

const sxCutText = (maxLines = 2) => ({
  whiteSpace: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: maxLines,
  WebkitBoxOrient: "vertical",
});

const Insights = () => {
  const { t } = useTranslationApp();
  const { insights } = useFormContext();
  const platforms = Object.keys(insights || {});

  if (_.isEmpty(insights)) return null;

  return (
    <Box
      id="insights-section"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 2,
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        {t(ADS_CAMPAIGNS_STATISTICS_SLIDE_MENU_INSIGHTS)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {platforms.map((platform) => {
          return (
            <Box
              sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}
              key={getUniqueID()}
            >
              {insights[platform].map((insight) => {
                const isApplied = insight.isApplied;
                if (isApplied) return null;
                return (
                  <InsightCard
                    key={getUniqueID()}
                    platform={platform}
                    title={insight.title}
                    sxTitle={{ height: 26, ...sxCutText(1) }}
                    nextContent={
                      <InsightActionableContainer insight={insight} />
                    }
                  />
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Insights;
