import TableContainer from "../../../../../components/Tables/TableContainer";
import TableCell from "../../../../BillingAndPayments/components/TableCell";
import {
  VALUE_NUMBER,
  VALUE_WITH_CURRENCY_MANUAL_FORMAT,
  VALUE_WITH_PERCENTAGE,
} from "../../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";
import TableRow from "@mui/material/TableRow";
import { useFormContext } from "react-hook-form";

const GoogleKeywordRow = ({ index, keyword, data }) => {
  const { t } = useTranslationApp();
  const { getCostByMainCurrency } = useFormContext();

  const { cost, currency } = getCostByMainCurrency({
    cost: data.spend,
    currency: data.currency,
  });

  const { cost: costPerMainAction, currency: currencyPerMainAction } =
    getCostByMainCurrency({
      cost: data.costPerMainAction,
      currency: data.currency,
    });

  return (
    <TableContainer hasPagination={false} hasMarginBottom={false} keys={[""]}>
      <TableRow>
        <TableCell align="center" indexRow={index} sx={{ width: 25 }} />
        <TableCell
          align="center"
          indexRow={index}
          sx={{
            width: 200,
          }}
        >
          {keyword}
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
            value: cost,
            currency,
          })}
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_NUMBER, { value: data.impressions || 0 })}
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_NUMBER, { value: data.click || 0 })}
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_WITH_PERCENTAGE, { value: data.ctr || 0 })}
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_NUMBER, { value: data.mainAction || 0 })}
        </TableCell>
        <TableCell align="center" indexRow={index}>
          {t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
            value: costPerMainAction || 0,
            currency: currencyPerMainAction,
          })}
        </TableCell>
      </TableRow>
    </TableContainer>
  );
};

export default GoogleKeywordRow;
