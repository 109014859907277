import ModalBasicLayout from "../ModalBasicLayout";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_GO_BACK,
  BUTTON_IMPORT_CAMPAIGN,
  CAMPAIGN_SELECTED,
  MODAL_IMPORT_ADS_CAMPAIGN_DESCRIPTION,
  MODAL_IMPORT_ADS_CAMPAIGN_TITLE,
  PLATFORM_PLACEHOLDER,
} from "../../../i18n/keysTranslations";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Select from "../../Form/Select";
import {
  AD_ACCOUNT_PLATFORMS_LABELS,
  PLATFORMS_SUPPORTED,
} from "../../../utils/constants";
import SelectBusinessAccount from "../../Meta/SelectBusinessAccount";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { useEffect, useState, useRef } from "react";
import { getPlatformFromIntegrationKey } from "../../../actions/getters";
import ContainerModalActions from "../../Containers/ContainerModalActions";
import { useInView } from "react-intersection-observer";
import ImportAdsCampaignList from "./ImportAdsCampaignList";
import {
  startGetAdsCampaignsFromPlatform,
  startImportAdsCampaign,
} from "../../../actions/adsCampaigns";
import Loader from "../../Loaders/Loader";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const ModalImportAdsCampaign = ({ modalOpen, onCloseModal = () => {} }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoadingCampaigns, setIsFirstLoadingCampaigns] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [loadedLastCampaign, setLoadedLastCampaign] = useState(false);
  const [lastKey, setLastKey] = useState(null);
  const [isImportingCampaign, setIsImportingCampaign] = useState(false);
  const abortControllerRef = useRef(null);
  const { control, watch, setValue } = useForm({
    defaultValues: {
      platform: "",
      integrationID: "",
      adAccountID: "",
      campaignID: "",
    },
  });
  const platform = watch("platform");
  const integrationID = watch("integrationID");
  const adAccountID = watch("adAccountID");
  const campaignID = watch("campaignID");

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const { integrations = {} } = useSelectorApp((state) => state.user);

  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "20px 0px",
  });

  const onCleanItemsSelected = () => {
    setValue("campaignID", "");
    setCampaigns([]);
    setLastKey(null);
    setLoadedLastCampaign(false);
    setIsFirstLoadingCampaigns(true);
  };

  const onChangePlatform = () => {
    setValue("adAccountID", "");
    onCleanItemsSelected();
  };
  const onChangeAdAccount = (value) => {
    setValue("integrationID", value?.integrationID);
    onCleanItemsSelected();
  };

  const onGetAdsCampaigns = async () => {
    // Cancel previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    // Create new AbortController for this request
    abortControllerRef.current = new AbortController();

    const response = await dispatch(
      startGetAdsCampaignsFromPlatform({
        platform,
        integrationID,
        adAccountID,
        lastKey,
        onChangeLastKey: setLastKey,
        onChangeLoadLastItem: setLoadedLastCampaign,
        signal: abortControllerRef.current.signal,
      })
    );

    // If the request was aborted, don't update state
    if (response?.aborted) {
      return;
    }

    if (!response.ok) {
      setIsFirstLoadingCampaigns(false);
      setLoadedLastCampaign(true);
      return;
    }

    if (lastKey) {
      setCampaigns((prev) => [...prev, ...response.data]);
    } else {
      setCampaigns(response.data);
      setIsFirstLoadingCampaigns(false);
    }
  };

  const onSelectCampaign = (campaign) => {
    setValue("campaignID", campaign.id);
  };

  const onImportCampaign = async () => {
    setIsImportingCampaign(true);
    const response = await dispatch(
      startImportAdsCampaign({
        platform,
        integrationID,
        adAccountID,
        campaignID,
      })
    );
    setIsImportingCampaign(false);
    if (!response.ok) {
      return;
    }
    const newCampaignID = response.data.campaignID;
    navigate(`/ads-campaign?campaignID=${newCampaignID}`);
  };

  useEffect(() => {
    const keysPlatforms = Object.keys(integrations || {});
    if (keysPlatforms.length > 0) {
      const firstPlatformSupported = keysPlatforms.find((key) =>
        PLATFORMS_SUPPORTED.includes(getPlatformFromIntegrationKey(key))
      );
      const firstPlatform = getPlatformFromIntegrationKey(
        firstPlatformSupported
      );
      setValue("platform", firstPlatform);
    }

    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (platform && integrationID && adAccountID) {
      onGetAdsCampaigns();
    }
    // Cleanup function to abort any pending requests when component unmounts or dependencies change
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line
  }, [platform, integrationID, adAccountID]);

  useEffect(() => {
    if (inView && !loadedLastCampaign) {
      onGetAdsCampaigns();
    }
    // Cleanup function to abort any pending requests when component unmounts or dependencies change
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line
  }, [inView]);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      maxWidth="md"
      title={t(MODAL_IMPORT_ADS_CAMPAIGN_TITLE)}
      description={t(MODAL_IMPORT_ADS_CAMPAIGN_DESCRIPTION)}
    >
      {!isLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <Select
              name="platform"
              label={t(PLATFORM_PLACEHOLDER)}
              disabled={isImportingCampaign}
              variant="filled"
              fullWidth={true}
              options={PLATFORMS_SUPPORTED.map((platform) => ({
                value: platform,
                label: platform,
              }))}
              doTranslate={true}
              actionsChange={onChangePlatform}
              control={control}
            />
            {platform && (
              <SelectBusinessAccount
                label={t(AD_ACCOUNT_PLATFORMS_LABELS[platform])}
                disabled={isImportingCampaign}
                variant="filled"
                fullWidth={true}
                control={control}
                platform={platform}
                name={`adAccountID`}
                onChangeAction={onChangeAdAccount}
              />
            )}
          </Box>
          {isFirstLoadingCampaigns && <Loader size={20} hasMarginTop={true} />}
          {!isFirstLoadingCampaigns && (
            <ImportAdsCampaignList
              data={campaigns}
              platform={platform}
              lastItemRef={ref}
              loadedLastCampaign={loadedLastCampaign}
              selectedCampaign={campaignID}
              onSelectCampaign={onSelectCampaign}
            />
          )}
        </Box>
      )}
      <DialogActions
        sx={{
          p: 0,
          pt: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {campaignID && (
          <Typography variant="body1">{`${t(CAMPAIGN_SELECTED)}: ${
            campaigns.find((campaign) => campaign.id === campaignID)?.name
          }`}</Typography>
        )}
        <ContainerModalActions
          hasPadding={true}
          propsSecondaryButton={{
            disabled: isImportingCampaign || true,
            showButton: true,
            onClick: onCloseModal,
            text: BUTTON_GO_BACK,
          }}
          propsPrimaryButton={{
            showButton: true,
            disabled: !campaignID,
            text: BUTTON_IMPORT_CAMPAIGN,
            type: "submit",
            onClick: onImportCampaign,
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalImportAdsCampaign;
