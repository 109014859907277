import { z } from "zod";
import {
  ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPES_WITH_MESSAGE_TEMPLATE,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVES_META_WITH_DESTINATION_TYPE,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  META,
  PLATFORMS_SUPPORTED,
  TIKTOK,
  tiktokCurrenciesData,
} from "../../../../utils/constants";
import i18next from "i18next";
import {
  FIELD_MIN_DAILY_BUDGET,
  FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR,
  FIELD_REQUIRED,
  FIELD_URL_VALID,
} from "../../../../i18n/keysTranslations";
import { metaCampaignSchema } from "./platforms/metaCampaignSchema";
import { tiktokCampaignSchema } from "./platforms/tiktokCampaignSchema";
import { googleCampaignSchema } from "./platforms/googleCampaignSchema";
import { adGroupSchema } from "./adgroup/adGroupGeneralSchema";
import { REGEX_URL } from "../../../../utils/regex";
import { getIntegrationKey } from "../../../../actions/getters";
import differenceInDays from "date-fns/differenceInDays";
import { assetsGroupSchema } from "./assetsGroup/assetsGroupGeneralSchema";

export const getCampaignSchema = (context) => {
  const hasContext = Boolean(
    context.businessName && context.businessDescription
  );
  return z
    .object({
      context: hasContext
        ? z.string().optional()
        : z.string().min(1, { message: i18next.t(FIELD_REQUIRED) }),
      language: z.string().min(1, { message: i18next.t(FIELD_REQUIRED) }),
      locations: z.object(
        {
          locations: z
            .array(z.any(), {
              message: i18next.t(FIELD_REQUIRED),
            })
            .min(1, {
              message: i18next.t(FIELD_REQUIRED),
            }),
          contexts: z.array(z.string()).optional(),
        },
        {
          message: i18next.t(FIELD_REQUIRED),
        }
      ),
      name: z.string().min(1, { message: i18next.t(FIELD_REQUIRED) }),
      platforms: z.array(z.enum(PLATFORMS_SUPPORTED)).default([]),

      platformsData: z
        .object({
          meta: metaCampaignSchema.optional(),
          google: googleCampaignSchema.optional(),
          tiktok: tiktokCampaignSchema.optional(),
        })
        .optional(),

      adsGroups: z.array(adGroupSchema).default([]),
      assetsGroups: z.array(assetsGroupSchema).default([]),
    })
    .superRefine((data, ctx) => {
      const integrations = context.integrations || {};
      const adsGroups = data.adsGroups || [];

      adsGroups.forEach((adGroup, index) => {
        const destinationType = adGroup.destinationType;

        if (adGroup.platform === TIKTOK) {
          const platformData = data.platformsData?.[TIKTOK];
          const objective = platformData?.objective;
          const integrationKey = getIntegrationKey(TIKTOK, true);
          const integrationsTikTok = integrations[integrationKey] || {};
          const integrationID = platformData?.integrationID;
          const integration = integrationsTikTok?.[integrationID];

          if (integration) {
            const currency = integration?.currency;
            const currencyData = tiktokCurrenciesData[currency];
            if (currencyData) {
              const typeBudget = adGroup.typeBudget;
              const budget = Number(adGroup.budget || 0);
              const minDailyBudget = currencyData.minDailyBudget;
              if (typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION) {
                if (budget && budget < minDailyBudget) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["adsGroups", index, "budget"],
                    message: i18next.t(FIELD_MIN_DAILY_BUDGET, {
                      value: minDailyBudget,
                      currency,
                    }),
                  });
                }
              }
              if (typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION) {
                const startDateTime = adGroup.startDate
                  ? new Date(adGroup.startDate)
                  : new Date();
                const endDateTime = adGroup.endDate
                  ? new Date(adGroup.endDate)
                  : new Date();

                const diffDays = differenceInDays(endDateTime, startDateTime);
                const dailyBudget = budget / diffDays;
                if (dailyBudget < minDailyBudget) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["adsGroups", index, "budget"],
                    message: i18next.t(FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR, {
                      value: minDailyBudget * diffDays,
                      dailyValue: minDailyBudget,
                      currency,
                    }),
                  });
                }
              }
            }
          }

          if (
            ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK.includes(objective)
          ) {
            if (!adGroup.trackingID) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["adsGroups", index, "trackingID"],
                message: i18next.t(FIELD_REQUIRED),
              });
            }
            if (!adGroup.trackingCustomEventID) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["adsGroups", index, "trackingCustomEventID"],
                message: i18next.t(FIELD_REQUIRED),
              });
            }
          }
        }

        if (adGroup.platform === META) {
          const platformData = data.platformsData?.[META];
          const objective = platformData?.objective;

          const needsTracking =
            ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective);
          const isWebsite =
            destinationType ===
            ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION;

          if (
            ADS_CAMPAIGN_OBJECTIVES_META_WITH_DESTINATION_TYPE.includes(
              objective
            ) &&
            !destinationType
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["adsGroups", index, "destinationType"],
              message: i18next.t(FIELD_REQUIRED),
            });
          }

          if (
            destinationType ===
              ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION &&
            !adGroup.whatsappBusinessNumber
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["adsGroups", index, "whatsappBusinessNumber"],
              message: i18next.t(FIELD_REQUIRED),
            });
          }

          if (needsTracking && isWebsite) {
            if (!adGroup.trackingID) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["adsGroups", index, "trackingID"],
                message: i18next.t(FIELD_REQUIRED),
              });
            }
            if (!adGroup.trackingCustomEventID) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["adsGroups", index, "trackingCustomEventID"],
                message: i18next.t(FIELD_REQUIRED),
              });
            }
          }
        }

        const ads = adGroup.ads || [];

        ads.forEach((ad, adIndex) => {
          if (ad.platform === TIKTOK) {
            const objective = data.platformsData?.[TIKTOK]?.objective;
            if (
              objective !== ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION &&
              !ad.callToAction
            ) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["adsGroups", index, "ads", adIndex, "callToAction"],
                message: i18next.t(FIELD_REQUIRED),
              });
            }
          }

          if (ad.platform === META) {
            const objective = data.platformsData?.[META]?.objective;
            const isDestinationTypeWebsite =
              destinationType ===
              ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION;

            const adIsCarousel = ad.isCarousel;

            if (
              ADS_CAMPAIGN_META_DESTINATION_TYPES_WITH_MESSAGE_TEMPLATE.includes(
                destinationType
              ) &&
              !ad.messageTemplate
            ) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["adsGroups", index, "ads", adIndex, "messageTemplate"],
                message: i18next.t(FIELD_REQUIRED),
              });
            }

            if (
              objective === ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION &&
              destinationType ===
                ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION &&
              !ad.instantForm
            ) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["adsGroups", index, "ads", adIndex, "instantForm"],
                message: i18next.t(FIELD_REQUIRED),
              });
            }

            if (isDestinationTypeWebsite) {
              if (adIsCarousel) {
                const expectedLength = ad.mediaUrls.length;
                const destinationUrls =
                  ad.destinationUrls?.slice(0, expectedLength) || [];

                destinationUrls.forEach((destinationUrl, index) => {
                  if (destinationUrl.value.trim() === "") {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: i18next.t(FIELD_REQUIRED),
                      path: [
                        "adsGroups",
                        index,
                        "ads",
                        adIndex,
                        "destinationUrls",
                        index,
                        "value",
                      ],
                    });
                  }

                  if (!REGEX_URL.test(encodeURI(destinationUrl.value))) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: i18next.t(FIELD_URL_VALID),
                      path: [
                        "adsGroups",
                        index,
                        "ads",
                        adIndex,
                        "destinationUrls",
                        index,
                        "value",
                      ],
                    });
                  }
                });
              } else {
                if (
                  !ad?.destinationUrls?.length ||
                  !ad?.destinationUrls?.[0]?.value?.trim()
                ) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18next.t(FIELD_REQUIRED),
                    path: [
                      "adsGroups",
                      index,
                      "ads",
                      adIndex,
                      "destinationUrls",
                      0,
                      "value",
                    ],
                  });
                }
                if (
                  !REGEX_URL.test(encodeURI(ad?.destinationUrls?.[0]?.value))
                ) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18next.t(FIELD_URL_VALID),
                    path: [
                      "adsGroups",
                      index,
                      "ads",
                      adIndex,
                      "destinationUrls",
                      0,
                      "value",
                    ],
                  });
                }
              }
            }
          }
        });
      });
    });
};
