import Grid from "@mui/material/Grid";
import SelectBusinessAccount from "../../../Meta/SelectBusinessAccount";
import {
  ALERT_CHANGE_AD_ACCOUNT_GENERAL_TITLE,
  ALERT_CHANGE_AD_ACCOUNT_META_DESCRIPTION,
  ALERT_CHANGE_OBJECTIVE_OR_DESTINATION_TYPE_AFFECT_MEDIA_URLS_DESCRIPTION,
  ALERT_CHANGE_OBJECTIVE_OR_DESTINATION_TYPE_AFFECT_MEDIA_URLS_TITLE,
  BUTTON_CANCEL,
  BUTTON_CONTINUE,
  FIELD_REQUIRED,
  INSTAGRAM_ACCOUNT_PLACEHOLDER,
  OBJECTIVE_PLACEHOLDER,
  PAGE_FACEBOOK_PLACEHOLDER,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import { useFormContext } from "react-hook-form";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import {
  AD_ACCOUNT_PLATFORMS_LABELS,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  FONT_FAMILY,
  META,
} from "../../../../utils/constants";
import SelectPageFacebook from "../../../Meta/SelectPageFacebook";
import SelectInstagramAccount from "../../../Meta/SelectInstagramAccount";
import Select from "../../../Form/Select";
import { optionsAdsCampaignObjectives } from "../../../../utils/options";
import ReasoningContainer from "../../../../pages/NewAdsCampaign/components/Common/ReasoningContainer";
import { ConfirmAlert } from "../../../Alerts/Alerts";
import Typography from "@mui/material/Typography";

const platform = META;
const grid = {
  xs: 12,
  lg: 6,
};

const CampaignMetaForm = () => {
  const {
    control,
    formState,
    watch,
    setValue,
    isGeneralDisabledFields,
    getIsCampaignPlatformCreated,
    getValues,
  } = useFormContext();
  useFocusErrorForm(formState);
  const errors = formState.errors;

  const { t } = useTranslationApp();

  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);

  const basicPath = `platformsData.${platform}`;
  const platformData = watch(basicPath) || {};
  const objective = platformData?.objective;
  const reasoningPlatform = platformData?.reasoning;
  const oldReasoning = getValues("reasoning");

  const validationChangeObjective = async (newObjective) => {
    const adsGroups = getValues("adsGroups");
    const hasSomeAdGroupWithDestinationTypeOnPost = adsGroups.some(
      (adGroup) =>
        adGroup.platform === META &&
        adGroup.destinationType ===
          ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
    );
    if (
      objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION &&
      hasSomeAdGroupWithDestinationTypeOnPost &&
      newObjective !== objective
    ) {
      const result = await ConfirmAlert({
        title: t(
          ALERT_CHANGE_OBJECTIVE_OR_DESTINATION_TYPE_AFFECT_MEDIA_URLS_TITLE
        ),
        text: t(
          ALERT_CHANGE_OBJECTIVE_OR_DESTINATION_TYPE_AFFECT_MEDIA_URLS_DESCRIPTION
        ),
        confirmButtonText: t(BUTTON_CONTINUE),
        cancelButtonText: t(BUTTON_CANCEL),
      });

      if (!result.isConfirmed) return false;
      onCleanMediaUrls();
    }

    return true;
  };

  const onCleanMediaUrls = () => {
    const adsGroups = getValues("adsGroups");

    adsGroups.forEach((adGroup, index) => {
      if (
        adGroup.platform !== META ||
        adGroup.destinationType !==
          ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
      )
        return;
      const ads = adGroup?.ads || [];

      ads.forEach((ad, indexAd) => {
        setValue(`adsGroups.[${index}].ads.[${indexAd}].mediaUrls`, []);
        setValue(`adsGroups.[${index}].ads.[${indexAd}].mediaData`, []);
        setValue(`adsGroups.[${index}].ads.[${indexAd}].mediaIDs`, []);
      });
    });
  };

  const validationChangeAdAccount = async () => {
    const result = await ConfirmAlert({
      title: t(ALERT_CHANGE_AD_ACCOUNT_GENERAL_TITLE),
      html: (
        <Typography
          variant="body1"
          sx={{
            fontFamily: FONT_FAMILY,
            "& *": {
              p: "revert",
              m: "revert",
              listStyle: "revert",
              textDecoration: "revert",
            },
          }}
          dangerouslySetInnerHTML={{
            __html: t(ALERT_CHANGE_AD_ACCOUNT_META_DESCRIPTION),
          }}
        />
      ),
      confirmButtonText: t(BUTTON_CONTINUE),
      cancelButtonText: t(BUTTON_CANCEL),
    });

    if (!result.isConfirmed) return false;

    return true;
  };

  const onChangeAdAccount = (value) => {
    setValue(`${basicPath}.integrationID`, value?.integrationID);
    setValue(`${basicPath}.pageID`, "");
    setValue(`${basicPath}.instagramID`, "");

    const adsGroups = getValues("adsGroups");
    adsGroups.forEach((adGroup, index) => {
      if (adGroup.platform !== META) return;
      setValue(`adsGroups.[${index}].customAudiences`, []);
      setValue(`adsGroups.[${index}].excludedCustomAudiences`, []);
      setValue(`adsGroups.[${index}].trackingID`, "");
      setValue(`adsGroups.[${index}].trackingCustomEventID`, "");
      setValue(`adsGroups.[${index}].whatsappBusinessNumber`, {});

      const ads = adGroup?.ads || [];
      ads.forEach((ad, indexAd) => {
        setValue(`adsGroups.[${index}].ads.[${indexAd}].instantForm`, {});
      });
    });
  };

  return (
    <Grid container spacing={2}>
      {!isCampaignPlatformCreated && (
        <Grid item xs={12}>
          <ReasoningContainer reasoning={reasoningPlatform || oldReasoning} />
        </Grid>
      )}

      <Grid item {...grid}>
        <Select
          label={t(OBJECTIVE_PLACEHOLDER)}
          options={optionsAdsCampaignObjectives}
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
          name={`${basicPath}.objective`}
          actionsChange={() => {
            const adsGroups = getValues("adsGroups");
            adsGroups.forEach((adGroup, index) => {
              if (adGroup.platform !== META) return;
              setValue(
                `adsGroups.[${index}].destinationType`,
                ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
              );
            });
          }}
          validationChange={validationChangeObjective}
          variant="filled"
          fullWidth
          doTranslate={true}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectBusinessAccount
          variant="filled"
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
          label={t(AD_ACCOUNT_PLATFORMS_LABELS[platform])}
          control={control}
          platform={platform}
          name={`${basicPath}.adAccountID`}
          onChangeAction={onChangeAdAccount}
          validationChange={validationChangeAdAccount}
          errors={errors}
          fullWidth={true}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectPageFacebook
          variant="filled"
          disabled={
            isCampaignPlatformCreated ||
            !platformData?.adAccountID ||
            isGeneralDisabledFields
          }
          label={t(PAGE_FACEBOOK_PLACEHOLDER)}
          name={`${basicPath}.pageID`}
          integrationID={platformData?.integrationID}
          adAccountID={platformData?.adAccountID}
          control={control}
          platform={platform}
          errors={errors}
          fullWidth={true}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectInstagramAccount
          variant="filled"
          disabled={
            isCampaignPlatformCreated ||
            !platformData?.pageID ||
            isGeneralDisabledFields
          }
          label={t(INSTAGRAM_ACCOUNT_PLACEHOLDER)}
          name={`${basicPath}.instagramID`}
          integrationID={platformData?.integrationID}
          pageID={platformData?.pageID}
          adAccountID={platformData?.adAccountID}
          control={control}
          errors={errors}
          platform={platform}
          fullWidth={true}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CampaignMetaForm;
