import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useFormContext } from "react-hook-form";
import { getFromStatisticsAdsCampaign } from "../../../../../services/adsCampaigns";
import { GOOGLE } from "../../../../../utils/constants";
import {
  VALUE_NUMBER,
  VALUE_WITH_PERCENTAGE,
  ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD_GROUP,
  IMPRESSIONS,
  RESULTS,
  CTR,
  CLICKS,
  TOP_KEYWORDS,
  ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD,
  AD,
  ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD_TITLE,
  ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD_DESCRIPTION,
  ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_ASSETS_GROUP,
} from "../../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";
import { openLink } from "../../../../../utils/string";
import Grid from "@mui/material/Grid";
import InsightCard from "../components/InsightCard";
import React from "react";
import _ from "lodash";

const GooglePerformingAssets = () => {
  const { t } = useTranslationApp();
  const { campaign, campaignID } = useFormContext();
  const { statistics } = getFromStatisticsAdsCampaign(campaign);

  const getBestAssetsGroup = (statistics) => {
    if (!statistics) return null;

    return Object.entries(statistics).reduce((best, [assetsGroupId, stats]) => {
      if (!best || stats.mainAction > best.statistics.mainAction) {
        return {
          assetsGroupId,
          statistics: stats,
        };
      }
      return best;
    }, null);
  };
  const getBestAdGroup = (statistics) => {
    if (!statistics) return null;

    return Object.entries(statistics).reduce((best, [adGroupId, stats]) => {
      if (!best || stats.mainAction > best.statistics.mainAction) {
        return {
          adGroupId,
          statistics: stats,
        };
      }
      return best;
    }, null);
  };

  const getTop5Keywords = (adsGroups, statistics) => {
    if (!adsGroups || !statistics) return [];

    const allKeywords = [];

    // Iterate through each ad group
    adsGroups.forEach((adGroup) => {
      if (adGroup.platform !== GOOGLE) return;

      const adGroupKeywords = statistics[adGroup.id] || {};

      // Get keywords data for this ad group
      Object.entries(adGroupKeywords).forEach(([keywordId, keywordStats]) => {
        const keywordIndex = adGroup.keywordsIDs.indexOf(keywordId);
        if (keywordIndex === -1) return;

        allKeywords.push({
          adGroupId: adGroup.id,
          keywordId,
          keywordText: adGroup.keywords[keywordIndex],
          statistics: keywordStats,
        });
      });
    });

    // Sort by mainAction (conversions) and get top 5
    return allKeywords
      .sort((a, b) => b.statistics.mainAction - a.statistics.mainAction)
      .slice(0, 5);
  };

  const getBestAd = (adsGroups, statistics) => {
    if (!adsGroups || !statistics) return null;

    let bestAd = null;

    adsGroups.forEach((adGroup) => {
      if (adGroup.platform !== GOOGLE) return;

      adGroup.ads.forEach((ad) => {
        const adStats = statistics[ad.id];
        if (!adStats) return;

        if (!bestAd || adStats.mainAction > bestAd.statistics.mainAction) {
          bestAd = {
            adGroupId: adGroup.id,
            adId: ad.id,
            statistics: adStats,
          };
        }
      });
    });

    return bestAd;
  };

  const getBestCopies = (statistics) => {
    if (!statistics) return null;

    let allTitles = [];
    let allDescriptions = [];

    Object.entries(statistics).forEach(([, adTextsStats]) => {
      if (adTextsStats.titles) {
        allTitles.push(...Object.values(adTextsStats.titles));
      }
      if (adTextsStats.descriptions) {
        allDescriptions.push(...Object.values(adTextsStats.descriptions));
      }
    });

    const bestTitle = allTitles.reduce((best, current) => {
      if (!best || current.impressions > best?.statistics?.impressions) {
        return {
          text: current.text,
          statistics: current,
        };
      }
      return best;
    }, null);

    const bestDescription = allDescriptions.reduce((best, current) => {
      if (!best || current.impressions > best?.statistics?.impressions) {
        return {
          text: current.text,
          statistics: current.statistics,
        };
      }
      return best;
    }, null);

    return {
      bestTitle,
      bestDescription,
    };
  };

  const bestAssetsGroup = getBestAssetsGroup(statistics[GOOGLE].assetsGroups);
  const bestAdGroup = getBestAdGroup(statistics[GOOGLE].adsGroups);
  const bestAd = getBestAd(campaign.adsGroups, statistics[GOOGLE].ads);
  const { bestTitle, bestDescription } = getBestCopies(
    statistics[GOOGLE].adTexts
  );
  const top5Keywords = getTop5Keywords(
    campaign.adsGroups,
    statistics[GOOGLE].keywords
  );

  const getAssetsGroupByID = (id) => {
    if (!id) {
      return {};
    }
    const assetsGroup = campaign.assetsGroups.find(
      (assetsGroup) => assetsGroup.id === id
    );
    if (!assetsGroup) {
      return {};
    }
    return { assetsGroup, assetsGroupID: assetsGroup?.id };
  };

  const getAdGroupByID = (id) => {
    if (!id) {
      return {};
    }
    const adGroupGoogle = campaign.adsGroups.filter(
      (adGroup) => adGroup.platform === GOOGLE
    );
    const adGroup = adGroupGoogle.find((adGroup) => adGroup.id === id);

    return { adGroup, adGroupID: adGroup.id };
  };

  const getAdByID = (adId) => {
    if (!adId) {
      return {};
    }
    const adGroupsGoogle = campaign.adsGroups.filter(
      (adGroup) => adGroup.platform === GOOGLE
    );

    const foundAdGroup = adGroupsGoogle.find((adGroup) =>
      adGroup.ads.some((ad) => ad.id === adId)
    );

    if (!foundAdGroup) {
      return {};
    }

    const ad = foundAdGroup.ads.find((ad) => ad.id === adId);
    const indexAd = foundAdGroup.ads.findIndex((ad) => ad.id === adId);
    const adGroupIndex = adGroupsGoogle.findIndex(
      (group) => group.id === foundAdGroup.id
    );
    const adGroupID = foundAdGroup.id;

    return {
      ad,
      adID: ad.id,
      adGroupIndex,
      adGroupID,
      indexAd,
    };
  };

  const { assetsGroup = {}, assetsGroupID } = getAssetsGroupByID(
    bestAssetsGroup?.assetsGroupId
  );
  const { adGroup = {}, adGroupID } = getAdGroupByID(bestAdGroup?.adGroupId);
  const {
    ad = {},
    adID,
    adGroupID: adGroupIDAd,
    indexAd,
  } = getAdByID(bestAd?.adId);

  const bestAssetsGroupStats = !_.isEmpty(bestAssetsGroup)
    ? [
        {
          label: t(IMPRESSIONS),
          value: t(VALUE_NUMBER, {
            value: bestAssetsGroup.statistics.impressions,
          }),
        },
        {
          label: t(CLICKS),
          value: t(VALUE_NUMBER, { value: bestAssetsGroup.statistics.click }),
        },
        {
          label: t(CTR),
          value: t(VALUE_WITH_PERCENTAGE, {
            value: bestAssetsGroup.statistics.ctr,
          }),
        },
        {
          label: t(RESULTS),
          value: t(VALUE_NUMBER, {
            value: bestAssetsGroup.statistics.mainAction,
          }),
        },
      ]
    : [];

  const bestAdGroupStats = !_.isEmpty(bestAdGroup)
    ? [
        {
          label: t(IMPRESSIONS),
          value: t(VALUE_NUMBER, { value: bestAdGroup.statistics.impressions }),
        },
        {
          label: t(CLICKS),
          value: t(VALUE_NUMBER, { value: bestAdGroup.statistics.click }),
        },
        {
          label: t(CTR),
          value: t(VALUE_WITH_PERCENTAGE, {
            value: bestAdGroup.statistics.ctr,
          }),
        },
        {
          label: t(RESULTS),
          value: t(VALUE_NUMBER, { value: bestAdGroup.statistics.mainAction }),
        },
      ]
    : [];

  const bestAdStats = !_.isEmpty(bestAd)
    ? [
        {
          label: t(IMPRESSIONS),
          value: t(VALUE_NUMBER, { value: bestAd.statistics.impressions }),
        },
        {
          label: t(CLICKS),
          value: t(VALUE_NUMBER, { value: bestAd.statistics.click }),
        },
        {
          label: t(CTR),
          value: t(VALUE_WITH_PERCENTAGE, { value: bestAd.statistics.ctr }),
        },
        {
          label: t(RESULTS),
          value: t(VALUE_NUMBER, { value: bestAd.statistics.mainAction }),
        },
      ]
    : [];

  return (
    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
      {!_.isEmpty(bestAssetsGroup) && (
        <InsightCard
          platform={GOOGLE}
          title={t(
            ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_ASSETS_GROUP
          )}
          subtitle={assetsGroup.name}
          onSubtitleClick={() =>
            openLink(
              `ads-campaign?campaignID=${campaignID}&platform=${GOOGLE}&assetsGroupID=${assetsGroupID}`
            )
          }
          statistics={bestAssetsGroupStats}
        />
      )}
      {!_.isEmpty(bestAdGroup) && (
        <InsightCard
          platform={GOOGLE}
          title={t(ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD_GROUP)}
          subtitle={adGroup.name}
          onSubtitleClick={() =>
            openLink(
              `ads-campaign?campaignID=${campaignID}&platform=${GOOGLE}&adGroupID=${adGroupID}`
            )
          }
          statistics={bestAdGroupStats}
        />
      )}
      {!_.isEmpty(top5Keywords) && (
        <InsightCard
          platform={GOOGLE}
          nextContent={<TopKeywords top5Keywords={top5Keywords} />}
        />
      )}

      {!_.isEmpty(bestAd) && (
        <InsightCard
          platform={GOOGLE}
          title={t(ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD)}
          subtitle={ad.name || `${t(AD)} ${indexAd + 1}`}
          onSubtitleClick={() =>
            openLink(
              `ads-campaign?campaignID=${campaignID}&platform=${GOOGLE}&adGroupID=${adGroupIDAd}&adID=${adID}`
            )
          }
          statistics={bestAdStats}
          nextContent={
            <Box
              sx={{
                mt: 2,
              }}
            >
              {bestTitle && (
                <Box>
                  <Typography variant="subtitle1" color="fields.placeholder">
                    {t(
                      ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD_TITLE
                    )}
                  </Typography>
                  <Typography variant="body2">{bestTitle.text}</Typography>
                </Box>
              )}
              {bestDescription && (
                <Box>
                  <Typography variant="subtitle1" color="fields.placeholder">
                    {t(
                      ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD_DESCRIPTION
                    )}
                  </Typography>
                  <Typography variant="body2">
                    {bestDescription.text}
                  </Typography>
                </Box>
              )}
            </Box>
          }
        />
      )}
    </Box>
  );
};

const TopKeywords = ({ top5Keywords }) => {
  const { t } = useTranslationApp();
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Typography variant="subtitle2" color="fields.placeholder">
          {t(TOP_KEYWORDS)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          variant="caption"
          component="p"
          fontWeight="bold"
          align="right"
          color="fields.placeholder"
        >
          {t(IMPRESSIONS).slice(0, 4) + "."}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          variant="caption"
          component="p"
          fontWeight="bold"
          align="right"
          color="fields.placeholder"
        >
          {t(CTR).slice(0, 4)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          variant="caption"
          component="p"
          fontWeight="bold"
          align="right"
          color="fields.placeholder"
        >
          {t(RESULTS).slice(0, 5) + "."}
        </Typography>
      </Grid>
      {top5Keywords.map((keyword) => (
        <React.Fragment key={keyword.keywordId}>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              sx={{
                wordBreak: "keep-all",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {keyword.keywordText}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" align="right">
              {t(VALUE_NUMBER, {
                value: keyword.statistics.impressions,
              })}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" align="right">
              {t(VALUE_WITH_PERCENTAGE, {
                value: keyword.statistics.ctr.toFixed(1),
              })}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" align="right">
              {t(VALUE_NUMBER, {
                value: keyword.statistics.mainAction,
              })}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default GooglePerformingAssets;
