import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
  ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH,
  GOOGLE,
} from "../../../../utils/constants";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { useFormContext } from "react-hook-form";
import { getIsBlockedActions } from "../../../../actions/getters";
import { generateAdsCampaignDescription } from "../../../../actions/adsCampaigns";
import { getAdsTextsFormatted } from "../../../../services/adsCampaigns";
import { useDispatchApp } from "../../../../lib/redux";
import { getUniqueID } from "../../../../utils/numbers";
import {
  DESCRIPTION_PLACEHOLDER,
  DESCRIPTIONS,
  DESTINATION_URL_PLACEHOLDER,
  FIELD_MAX_LENGTH,
  FIELD_REQUIRED,
  FIELD_TEXT_REPEATED,
  FIELD_URL_VALID,
  TITLE_PLACEHOLDER,
  TITLES,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import TextField from "../../../Form/TextField";
import Loader from "../../../Loaders/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import IconButton from "@mui/material/IconButton";
import { waitDelay } from "../../../../utils/date";
import { REGEX_URL } from "../../../../utils/regex";

const platform = GOOGLE;
const grid = {
  xs: 12,
  lg: 6,
};
const maxTitles = 15;
const maxDescriptions = 4;

const AdGoogleForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    watch,
    getValues,
    control,
    formState,
    sectionSelected,
    isGeneralDisabledFields,
    setValue,
    trigger,
  } = useFormContext();
  useFocusErrorForm(formState);

  const errors = formState.errors;
  const adsGroups = watch("adsGroups");
  const { t } = useTranslationApp();

  const adGroupID = sectionSelected.adGroupID;
  const indexAdGroup = adsGroups.findIndex((item) => item.id === adGroupID);
  const adGroup = adsGroups[indexAdGroup];
  const BASIC_PATH_AD_GROUP = `adsGroups.[${indexAdGroup}]`;

  const adID = sectionSelected.adID;
  const indexAd = adGroup?.ads?.findIndex((item) => item.id === adID);
  const BASIC_PATH = `adsGroups.[${indexAdGroup}].ads.[${indexAd}]`;
  const titles = watch(`${BASIC_PATH}.titles`) || [{ value: "" }];
  const descriptions = watch(`${BASIC_PATH}.descriptions`) || [{ value: "" }];

  const platformData = watch(`platformsData.${platform}`);
  const objective = platformData?.objective;

  const dispatch = useDispatchApp();

  const onGenerateText = async ({ type, index }) => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }

    const currentTexts = getValues(`${BASIC_PATH}.${type}`) || [];

    const response = await dispatch(
      generateAdsCampaignDescription({
        objective,
        platform,
        websiteURL: getValues("websiteURL"),
        ageRange: getValues(`${BASIC_PATH_AD_GROUP}.ageRange`),
        genders: getValues(`${BASIC_PATH_AD_GROUP}.genders`),
        locations: getValues(`${BASIC_PATH_AD_GROUP}.locations`),
        interests: getValues(`${BASIC_PATH_AD_GROUP}.interests`),
        behaviors: getValues(`${BASIC_PATH_AD_GROUP}.behaviors`),
        demographics: getValues(`${BASIC_PATH_AD_GROUP}.demographics`),
        language: getValues(`language`),
        type,
        currentTexts: getAdsTextsFormatted({
          texts: currentTexts,
          format: "save",
        }),
      })
    );

    if (response) {
      setValue(`${BASIC_PATH}.${type}.[${index}].value`, response);
      trigger(`${BASIC_PATH}.${type}.[${index}].value`);
    }
  };

  const onAddText = async ({ type }) => {
    const currentTexts = getValues(`${BASIC_PATH}.${type}`) || [];
    setValue(`${BASIC_PATH}.${type}`, [
      ...currentTexts,
      {
        value: "",
        id: getUniqueID(),
      },
    ]);
  };
  const onRemoveText = async ({ type, index }) => {
    const currentTexts = getValues(`${BASIC_PATH}.${type}`);
    setValue(
      `${BASIC_PATH}.${type}`,
      currentTexts.filter((_, i) => i !== index)
    );
    trigger(`${BASIC_PATH}.${type}`);
  };

  //To reset values component when change selectedLevel
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await waitDelay(1);
      setIsLoading(false);
    })();
  }, [sectionSelected]);

  if (isLoading) return null;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        height: "min-content",
        width: "100%",
      }}
    >
      <Grid item {...grid}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body1">
            {t(DESTINATION_URL_PLACEHOLDER)}
          </Typography>
          <TextField
            name={`${BASIC_PATH}.destinationUrls.[0].value`}
            variant="filled"
            fullWidth
            disabled={isGeneralDisabledFields}
            control={control}
            errors={errors}
            rules={{
              required: {
                value: false,
              },
              validate: (value) => {
                if (!value) {
                  return t(FIELD_REQUIRED);
                }
                if (value && !REGEX_URL.test(encodeURI(value))) {
                  return t(FIELD_URL_VALID);
                }
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item {...grid} />
      <Grid
        item
        {...grid}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography variant="body1">{t(TITLES)}</Typography>
        {titles?.map((title, index) => (
          <TextFieldWithActions
            key={title.id}
            type="titles"
            label={`${t(TITLE_PLACEHOLDER)} ${index + 1}`}
            name={`${BASIC_PATH}.titles.[${index}].value`}
            disabled={isGeneralDisabledFields}
            showAddIcon={index === titles.length - 1 && index < maxTitles - 1}
            showDeleteIcon={index !== 0}
            platform={platform}
            onAdd={() => onAddText({ type: "titles" })}
            onRemove={() => onRemoveText({ type: "titles", index })}
            maxLength={ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[platform]}
            onGenerate={async () => {
              await onGenerateText({ type: "titles", index });
            }}
            control={control}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
              validate: (value) => {
                if (
                  value.length >
                  ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[platform]
                ) {
                  return t(FIELD_MAX_LENGTH, {
                    value: ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[platform],
                  });
                }

                const allTitles = titles.map((item) =>
                  (item.value || "").trim().toLowerCase()
                );
                const timesRepeated = allTitles.filter(
                  (text) => text === value.trim().toLowerCase()
                ).length;
                if (timesRepeated > 1) {
                  return t(FIELD_TEXT_REPEATED);
                }
                return true;
              },
            }}
            sx={{
              ".MuiInputBase-root": {
                pb: 4,
              },
            }}
          />
        ))}
      </Grid>
      <Grid
        item
        {...grid}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography variant="body1">{t(DESCRIPTIONS)}</Typography>
        {descriptions?.map((description, index) => (
          <TextFieldWithActions
            key={description.id}
            type="descriptions"
            label={`${t(DESCRIPTION_PLACEHOLDER)} ${index + 1}`}
            name={`${BASIC_PATH}.descriptions.[${index}].value`}
            disabled={isGeneralDisabledFields}
            platform={platform}
            showAddIcon={
              index === descriptions.length - 1 && index < maxDescriptions - 1
            }
            showDeleteIcon={index !== 0}
            maxLength={ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[platform]}
            onAdd={() => onAddText({ type: "descriptions" })}
            onRemove={() => onRemoveText({ type: "descriptions", index })}
            onGenerate={async () => {
              await onGenerateText({ type: "descriptions", index });
            }}
            control={control}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
              validate: (value) => {
                if (
                  value.length >
                  ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[platform]
                ) {
                  return t(FIELD_MAX_LENGTH, {
                    value:
                      ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[platform],
                  });
                }
                const allDescriptions = descriptions.map((item) =>
                  (item.value || "").trim().toLowerCase()
                );
                const timesRepeated = allDescriptions.filter(
                  (text) => text === value.trim().toLowerCase()
                ).length;
                if (timesRepeated > 1) {
                  return t(FIELD_TEXT_REPEATED);
                }
                return true;
              },
            }}
            sx={{
              ".MuiInputBase-root": {
                pb: 4,
              },
            }}
          />
        ))}
      </Grid>
    </Grid>
  );
};

const TextFieldWithActions = ({
  name,
  label,
  disabled,
  control,
  errors,
  rules,
  sx,
  showAddIcon = true,
  showDeleteIcon = true,
  onAdd = () => {},
  onRemove = () => {},
  onGenerate = () => {},
  type = "titles",
  maxLength = 0,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const getRows = () => {
    if (type === "titles") {
      return { min: 1, max: 1 };
    }
    return { min: 3, max: 3 };
  };

  const rows = getRows();

  const isDisabled = disabled || isGenerating;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <TextField
        label={label}
        name={name}
        variant="filled"
        multiline
        showCounterCharacters={true}
        maxCharacters={maxLength}
        minRows={rows.min}
        maxRows={rows.max}
        fullWidth
        disabled={disabled}
        control={control}
        errors={errors}
        rules={rules}
        sx={{ ...sx, zIndex: 1, position: "relative" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          position: "absolute",
          mb: 1,
          mr: 1,
          right: 0,
          height: "100%",
          mt: -5,
        }}
      >
        {showDeleteIcon && (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={() => onRemove()}
            sx={{
              p: 0.4,
              zIndex: 1,
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {isGenerating ? (
          <Loader
            size={20}
            hasMarginTop={false}
            sx={{
              mb: 0.75,
            }}
          />
        ) : (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={async () => {
              setIsGenerating(true);
              await onGenerate();
              setIsGenerating(false);
            }}
            sx={{
              p: 0.4,
              zIndex: 1,
            }}
          >
            <AutoAwesomeIcon />
          </IconButton>
        )}
        {showAddIcon && (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={() => onAdd()}
            sx={{
              p: 0.4,
              zIndex: 1,
            }}
          >
            <AddIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
export default AdGoogleForm;
