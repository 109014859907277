// useAdsCampaignConfig.js

import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslationApp } from "../../../lib/i18next";
import {
  BUTTON_SELECT_CONTENT,
  CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_DESCRIPTION_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_TOTAL_QUESTION,
  CREATE_ADS_CAMPAIGN_DESTINATION_TYPE_QUESTION,
  CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION,
  CREATE_ADS_CAMPAIGN_IDENTITIES_ACCOUNTS_QUESTION,
  CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION,
  CREATE_ADS_CAMPAIGN_START_END_DATE_QUESTION,
  CREATE_ADS_CAMPAIGN_TRACKING_QUESTION,
  CREATE_ADS_CAMPAIGN_TYPE_BUDGET_QUESTION,
  FIELD_REQUIRED,
  FIELD_URL_VALID,
  FIELDS_REQUIRED,
  START_DATE_AND_END_DATE_VALIDATION,
} from "../../../i18n/keysTranslations";
import { useDispatchApp } from "../../../lib/redux";
import {
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_OBJECTIVES_WITH_DESTINATION_TYPE_META,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  FORMATS_MEDIA_META,
  META,
  MIN_DIMENSIONS_CONTENT,
} from "../../../utils/constants";
import IconButtonAddContent from "../../../components/Buttons/IconButtonAddContent";
import { getPathDatabaseByRole } from "../../../actions/auth";
import {
  formatMediaUrl,
  formatMediaUrlAdFromCreatify,
} from "../../../services/adsCampaigns";
import { REGEX_URL } from "../../../utils/regex";
import { getCurrencyAdAccountPlatform } from "../../../actions/getters";

export function useGenerateCampaignFormMeta({
  campaignID,
  onCallbackSave = () => {},
}) {
  const STEPS = [
    "objective",
    "destinationType",
    "identities",
    "trackingID",
    "destinationURL",
    "typeBudget",
    "budget",
    "dates",
    "mediaUrls",
  ];
  const [error, setError] = useState(null);
  const { control, watch, setValue, getValues } = useForm({
    defaultValues: {
      step: 0,
      objective: "",
      integrationID: "",
      adAccountID: "",
      pageID: "",
      instagramID: "",
      destinationType: ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
      trackingID: "",
      destinationURL: "",
      typeBudget: "",
      budget: "",
      startDate: null,
      endDate: null,
      mediaUrls: [],
    },
  });

  const step = watch("step");
  const objective = watch("objective");
  const destinationType = watch("destinationType");
  const typeBudget = watch("typeBudget");
  const integrationID = watch("integrationID");
  const adAccountID = watch("adAccountID");
  const mediaUrls = watch("mediaUrls");

  const utils = {
    control,
    setValue,
    watch,
    getValues,
  };

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const currency = dispatch(
    getCurrencyAdAccountPlatform({
      platform: META,
      integrationID: integrationID,
      adAccountID: adAccountID,
    })
  );

  const { userID } = dispatch(getPathDatabaseByRole());

  const actionsToValidAnswer = () => {
    setError(null);
  };

  const onFinished = async () => {
    const formValues = getValues();

    const requestObject = {
      objective: formValues.objective,
      integrationID: formValues.integrationID,
      adAccountID: formValues.adAccountID,
      pageID: formValues.pageID,
      instagramID: formValues.instagramID,
      destinationType: formValues.destinationType,
      trackingID: formValues.trackingID,
      destinationURL: formValues.destinationURL,
      typeBudget: formValues.typeBudget,
      budget: formValues.budget,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      mediaUrls: formValues.mediaUrls,
      platform: META,
    };

    onCallbackSave(requestObject);
  };

  const { steps, fields } = useMemo(() => {
    const fieldsConfig = {
      objective: {
        isVisible: () => true,
        isFinishQuestion: false,
        utils,
        name: "objective",
        title: CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION,
        subtitle: CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION,
        showActionsButtons: true,
        showPreviousButton: false,
        onPreviousStep: () => {
          actionsToValidAnswer();
        },
        showNextButton: true,
        onNextStep: () => {
          const objective = getValues("objective");
          if (!objective || objective?.length === 0) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      destinationType: {
        isVisible: () =>
          ADS_CAMPAIGN_OBJECTIVES_WITH_DESTINATION_TYPE_META.includes(
            objective
          ),
        isFinishQuestion: false,
        utils,
        name: "destinationType",
        title: CREATE_ADS_CAMPAIGN_DESTINATION_TYPE_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const destinationType = getValues("destinationType");
          if (!destinationType) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      identities: {
        isVisible: () => true,
        isFinishQuestion: false,
        utils,
        name: "identities",
        title: CREATE_ADS_CAMPAIGN_IDENTITIES_ACCOUNTS_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const adAccountID = getValues("adAccountID");
          const pageID = getValues("pageID");
          const instagramID = getValues("instagramID");
          if (!adAccountID || !pageID || !instagramID) {
            setError(t(FIELDS_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      trackingID: {
        isVisible: () =>
          ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective) &&
          destinationType === ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
        isFinishQuestion: false,
        utils,
        name: "trackingID",
        title: CREATE_ADS_CAMPAIGN_TRACKING_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const trackingID = getValues("trackingID");
          if (!trackingID) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      destinationURL: {
        isVisible: () =>
          destinationType === ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
        isFinishQuestion: false,
        utils,
        name: "destinationURL",
        title: CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const destinationURL = getValues("destinationURL");
          if (!destinationURL) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          if (!REGEX_URL.test(encodeURI(destinationURL))) {
            setError(t(FIELD_URL_VALID));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      typeBudget: {
        isVisible: () => true,
        isFinishQuestion: false,
        utils,
        name: "typeBudget",
        title: CREATE_ADS_CAMPAIGN_TYPE_BUDGET_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const typeBudget = getValues("typeBudget");
          if (!typeBudget) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      budget: {
        isVisible: () => true,
        isFinishQuestion: false,
        utils,
        name: "budget",
        title:
          typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION
            ? CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION
            : CREATE_ADS_CAMPAIGN_BUDGET_TOTAL_QUESTION,
        subtitle: CREATE_ADS_CAMPAIGN_BUDGET_DESCRIPTION_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const budget = getValues("budget");
          if (!budget) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      dates: {
        isVisible: () =>
          typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
        isFinishQuestion: false,
        utils,
        name: "dates",
        title: CREATE_ADS_CAMPAIGN_START_END_DATE_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const startDate = getValues("startDate");
          const endDate = getValues("endDate");
          if (!startDate || !endDate) {
            setError(t(FIELDS_REQUIRED));
            return false;
          }

          if (startDate > endDate) {
            setError(t(START_DATE_AND_END_DATE_VALIDATION));
            return false;
          }

          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      mediaUrls: {
        isVisible: () => true,
        isFinishQuestion: true,
        disabledFinishButton: !mediaUrls || mediaUrls.length === 0,
        onFinish: () => {
          const mediaUrls = getValues("mediaUrls");
          if (!mediaUrls || mediaUrls.length === 0) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          onFinished();
        },
        utils,
        name: "mediaUrls",
        title: CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        customComponentPreviousNext: (
          <Controller
            name="mediaUrls"
            control={control}
            render={({ field }) => (
              <IconButtonAddContent
                mode="button"
                color="primary"
                size="large"
                minDimensions={MIN_DIMENSIONS_CONTENT}
                pathStorage={`adsCampaigns/${userID}/`}
                pathRTDB={`adsContentsUploaded/${userID}/${campaignID}`}
                modeUpload="async"
                onAddContent={(contents) => {
                  const contentsFormatted = contents.map((content) =>
                    formatMediaUrl(content)
                  );
                  field.onChange([...field.value, ...contentsFormatted]);
                }}
                onCallbackSaveAI={(videoData) => {
                  field.onChange([
                    ...field.value,
                    formatMediaUrlAdFromCreatify(videoData),
                  ]);
                }}
                multiple={true}
                acceptedFormats={FORMATS_MEDIA_META}
                textButton={BUTTON_SELECT_CONTENT}
                sx={{
                  alignItems: "flex-start",
                  width: { xs: "100%", md: "auto" },
                }}
                sxButton={{
                  height: 40,
                  fontSize: "0.875rem",
                  width: "100%",
                }}
                destinationType={destinationType}
                propsPostsAdsPlatforms={{
                  integrationID: getValues("integrationID"),
                  adAccountID: getValues("adAccountID"),
                  pageID: getValues("pageID"),
                }}
              />
            )}
          />
        ),
        showNextButton: false,
        onNextStep: () => {
          setValue("step", step + 1);
        },
      },
    };

    const stepsVisible = STEPS.filter((step) => fieldsConfig[step].isVisible());

    return {
      steps: stepsVisible,
      fields: fieldsConfig,
    };

    // eslint-disable-next-line
  }, [step, objective, destinationType, typeBudget, mediaUrls]);

  const currentAnswer = steps[step];
  const totalSteps = steps.length;
  const progress = Math.round(((step + 1) / totalSteps) * 100);

  return { steps, fields, utils, currentAnswer, error, progress, currency };
}
