import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled, useTheme } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import TextField from "../../../Form/TextField";
import {
  SITE_LINK_DESCRIPTION_PLACEHOLDER,
  SITE_LINK_DESCRIPTIONS_PLACEHOLDER,
  SITE_LINK_FINAL_URL_PLACEHOLDER,
  SITE_LINK_PLACEHOLDER,
  SITE_LINK_TEXT_PLACEHOLDER,
  SITE_LINKS_PLACEHOLDER,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  ADS_CAMPAIGN_GOOGLE_SITE_LINK_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_GOOGLE_SITE_LINK_NAME_MAX_LENGTH,
} from "../../../../utils/constants";
import { getReactHookFormNestedError } from "../../../../utils/errors";
import { RED_COLOR } from "../../../../utils/colors";
import ChipOptional from "../../../Chip/ChipOptional";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.border.grey} !important`,
  "&::before": {
    display: "none",
  },
  height: 56,
  "&.Mui-expanded": {
    height: "auto",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  height: "100%",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const SiteLinksContainer = ({
  control,
  errors,
  name = "sitelinks",
  label = SITE_LINKS_PLACEHOLDER,
  disabled = false,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const [expanded, setExpanded] = useState(null);
  const theme = useTheme();
  const { t } = useTranslationApp();

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 1.5 }}>
        <Typography variant="body1">{t(label)}</Typography>
        <ChipOptional />
      </Box>

      {fields.map((field, index) => {
        const hasSomeError = getReactHookFormNestedError(
          errors,
          `${name}[${index}]`
        );
        return (
          <Accordion
            key={field.id}
            expanded={expanded === index}
            onChange={handleAccordionChange(index)}
            sx={{
              border: `1px solid ${
                hasSomeError ? RED_COLOR : theme.palette.border.grey
              } !important`,
              borderRadius: "16px !important",
              marginBottom: 2,
            }}
          >
            <AccordionSummary
              aria-controls={`sitelink-${index}-content`}
              id={`sitelink-${index}-header`}
            >
              <Typography>
                {field.linkText && field.linkText.trim() !== ""
                  ? field.linkText
                  : `${t(SITE_LINK_PLACEHOLDER)} ${index + 1}`}
              </Typography>
              {!disabled && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => remove(index)}
                    type="button"
                    sx={{
                      p: 0,
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name={`${name}[${index}].linkText`}
                    label={t(SITE_LINK_TEXT_PLACEHOLDER)}
                    variant="outlined"
                    fullWidth
                    control={control}
                    errors={errors}
                    disabled={disabled}
                    showCounterCharacters={true}
                    maxCharacters={
                      ADS_CAMPAIGN_GOOGLE_SITE_LINK_NAME_MAX_LENGTH
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name={`${name}[${index}].finalUrl`}
                    label={t(SITE_LINK_FINAL_URL_PLACEHOLDER)}
                    variant="outlined"
                    fullWidth
                    control={control}
                    errors={errors}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    {t(SITE_LINK_DESCRIPTIONS_PLACEHOLDER)}
                  </Typography>
                  <Grid container spacing={2}>
                    {(field.descriptions || []).map((desc, descIndex) => (
                      <Grid item xs={12} sm={6} key={descIndex}>
                        <TextField
                          name={`${name}[${index}].descriptions[${descIndex}]`}
                          label={`${t(SITE_LINK_DESCRIPTION_PLACEHOLDER)} ${
                            descIndex + 1
                          }`}
                          variant="outlined"
                          fullWidth
                          control={control}
                          errors={errors}
                          disabled={disabled}
                          showCounterCharacters={true}
                          maxCharacters={
                            ADS_CAMPAIGN_GOOGLE_SITE_LINK_DESCRIPTION_MAX_LENGTH
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
      {fields.length < 10 && !disabled && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            onClick={() =>
              append({
                linkText: "",
                finalUrl: "",
                descriptions: ["", ""],
              })
            }
            type="button"
          >
            <AddIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default SiteLinksContainer;
