import { Controller, useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "../../Form/TextField";
import {
  ADVANCED_CONFIGURATION,
  BUTTON_CHANGE_AVATAR,
  BUTTON_CHANGE_LOGO,
  BUTTON_GENERATE,
  BUTTON_GENERATE_RANDOM,
  BUTTON_SELECT_AVATAR,
  BUTTON_UPLOAD_LOGO,
  CONTENT_GENERATOR,
  FIELD_MIN_CONTENT,
  FIELD_MIN_LENGTH,
  FIELD_REQUIRED,
  FIELD_URL_INSTAGRAM,
  FIELD_URL_VALID,
  GETTING_CREATIFY_VIDEO_DATA,
  INSTAGRAM_PROFILE_URL_PLACEHOLDER,
  LANGUAGE_CAMPAIGN_PLACEHOLDER,
  LOGO,
  SCRIPT_PLACEHOLDER,
  SELECT_BACKGROUND_CONTENT,
  WEBSITE_URL_PLACEHOLDER,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import Select from "../../Form/Select";
import { optionsLanguage } from "../../../utils/options";
import Button from "../../Buttons/Button";
import { useState } from "react";
import ModalSelectAvatar from "./ModalSelectAvatar";
import CardMedia from "../../Media/CardMedia";
import { BORDER_RADIUS, IMAGE_FORMATS } from "../../../utils/constants";
import IconButton from "../../Buttons/IconButton";
import Typography from "@mui/material/Typography";
import IconButtonAddContent from "../../Buttons/IconButtonAddContent";
import { useDispatchApp } from "../../../lib/redux";
import { getPathDatabaseByRole } from "../../../actions/auth";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import { REGEX_URL } from "../../../utils/regex";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import Loader from "../../Loaders/Loader";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Accordion from "../../Containers/Accordion";
import UploadFileButton from "../../Buttons/UploadFileButton";
import LinearLoaderWithTime from "../../Loaders/LinearLoaderWithTime";

const ConfigurationCreatifyForm = ({ onCloseDrawer = () => {} }) => {
  const [modalOpenSelectAvatar, setModalOpenSelectAvatar] = useState(false);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);

  const {
    control,
    formState,
    watch,
    setValue,
    getValues,
    isGenerating,
    onGenerateVideoPreview,
    isGeneratingScript,
    onGenerateScript,
    isUpdatingFormData,
    integrationID,
    adAccountID,
    pageID,
  } = useFormContext();
  useFocusErrorForm(formState);

  const errors = formState.errors;
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const avatar = watch("avatar");
  const mediaUrls = watch("mediaUrls");
  const logoURL = watch("logoURL");
  const websiteURL = watch("websiteURL");
  const socialNetworkURL = watch("socialNetworkURL");
  const { userID } = dispatch(getPathDatabaseByRole());

  const isDisabledButtons = !websiteURL || !socialNetworkURL || isGenerating;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const getTopPosition = () => {
    if (!isMobile) return -64;
    if (isMobile) return -56;
    return 0;
  };
  const getMinHeight = () => {
    return "100vh";
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          mb: 1,
        }}
      >
        <Box
          sx={{
            display: isUpdatingFormData ? "block" : "none",
            position: "absolute",
            top: getTopPosition(),
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            backdropFilter: "blur(5px)",
            zIndex: 1101,
            minHeight: getMinHeight(),
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              px: {
                xs: 1,
                sm: 2,
              },
            }}
          >
            <LinearLoaderWithTime
              time={10 * 1000}
              label={t(GETTING_CREATIFY_VIDEO_DATA)}
            />
          </Box>
        </Box>
        <Typography variant="h6">{t(CONTENT_GENERATOR)}</Typography>
        {isMobile && (
          <IconButton
            edge="start"
            onClick={(e) => {
              e.stopPropagation();
              onCloseDrawer();
            }}
            sx={{
              p: 0.5,
              color: "background.paperSecondaryContrastText",
              backgroundColor: "background.paperSecondary",
              "&:hover": {
                color: "background.paperSecondaryContrastText",
                backgroundColor: "background.paperSecondary",
              },
            }}
          >
            <CloseIcon
              sx={{
                fontSize: "1.25rem",
              }}
            />
          </IconButton>
        )}
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          mb: 3,
        }}
      >
        <Grid item xs={12}>
          <TextField
            label={t(WEBSITE_URL_PLACEHOLDER)}
            name="websiteURL"
            fullWidth
            variant="filled"
            control={control}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
              validate: (value) => {
                if (!REGEX_URL.test(encodeURI(value))) {
                  return t(FIELD_URL_VALID);
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t(INSTAGRAM_PROFILE_URL_PLACEHOLDER)}
            name="socialNetworkURL"
            fullWidth
            variant="filled"
            control={control}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
              validate: (value) => {
                if (value?.length > 0 && !REGEX_URL.test(encodeURI(value))) {
                  return t(FIELD_URL_VALID);
                }
                if (value?.length > 0 && !value?.includes("instagram.com")) {
                  return t(FIELD_URL_INSTAGRAM);
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label={t(LANGUAGE_CAMPAIGN_PLACEHOLDER)}
            name="language"
            fullWidth
            variant="filled"
            doTranslate={true}
            control={control}
            errors={errors}
            options={optionsLanguage}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <TextField
              label={t(SCRIPT_PLACEHOLDER)}
              name="script"
              multiline
              minRows={5}
              maxRows={5}
              fullWidth
              variant="filled"
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (value?.length > 0 && value?.length < 120) {
                    return t(FIELD_MIN_LENGTH, {
                      value: 120,
                    });
                  }
                },
              }}
              sx={{
                ".MuiInputBase-root": {
                  pb: 4,
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                position: "absolute",
                mb: 2,
                mr: 2,
                right: 0,
                height: "100%",
                mt: errors["script"] ? -5 : 0,
              }}
            >
              {isGeneratingScript ? (
                <Loader
                  size={20}
                  hasMarginTop={false}
                  sx={{
                    mb: 0.75,
                  }}
                />
              ) : (
                <IconButton
                  disabled={isGeneratingScript}
                  size={"small"}
                  onClick={onGenerateScript}
                  sx={{
                    p: 0.4,
                  }}
                >
                  <AutoAwesomeIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              position: "relative",
            }}
          >
            {avatar && (
              <CardMedia
                url={avatar.previewImage1_1}
                sx={{
                  borderRadius: BORDER_RADIUS,
                }}
              />
            )}
            <Button fullWidth onClick={() => setModalOpenSelectAvatar(true)}>
              {t(avatar ? BUTTON_CHANGE_AVATAR : BUTTON_SELECT_AVATAR)}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Accordion title={t(ADVANCED_CONFIGURATION)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t(LOGO)}
                </Typography>
                {errors["logoURL"] && (
                  <Typography variant="body2" color="error" gutterBottom>
                    {t(errors["logoURL"].message)}
                  </Typography>
                )}
                {logoURL && (
                  <CardMedia
                    url={logoURL}
                    sx={{
                      objectFit: "cover",
                      borderRadius: 3,
                      height: "auto",
                      width: "100%",
                      mb: 1,
                    }}
                  />
                )}
                <Controller
                  name="logoURL"
                  control={control}
                  render={({ field }) => (
                    <UploadFileButton
                      textUpload={BUTTON_UPLOAD_LOGO}
                      colorUpload="primary"
                      textRemove={BUTTON_CHANGE_LOGO}
                      colorRemove="primary"
                      value={field.value}
                      onChange={field.onChange}
                      pathStorage={`socialMedia/${userID}/`}
                      acceptedFormats={IMAGE_FORMATS}
                      modeUpload="sync"
                      loading={isUploadingLogo}
                      onCallbackLoading={(loading) =>
                        setIsUploadingLogo(loading)
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t(SELECT_BACKGROUND_CONTENT)}
                </Typography>
                {errors["mediaUrls"] && (
                  <Typography variant="body2" color="error">
                    {t(errors["mediaUrls"].message)}
                  </Typography>
                )}
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Controller
                      name="mediaUrls"
                      control={control}
                      rules={{
                        validate: (value) => {
                          if (value?.length > 0 && value?.length < 3) {
                            return t(FIELD_MIN_CONTENT, {
                              value: 3,
                            });
                          }
                        },
                      }}
                      render={() => (
                        <IconButtonAddContent
                          showGenerateWithAIOption={false}
                          propsPostsAdsPlatforms={{
                            integrationID,
                            adAccountID,
                            pageID,
                          }}
                          onAddContent={(urls) => {
                            const urlsFlatted = urls?.map((url) => url.url);
                            const currentMediaUrls = getValues("mediaUrls");
                            setValue("mediaUrls", [
                              ...currentMediaUrls,
                              ...urlsFlatted,
                            ]);
                          }}
                          multiple={true}
                          pathStorage={`socialMedia/${userID}/`}
                          mode="icon"
                          sx={{
                            height: 120,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {mediaUrls.map((url, index) => {
                    return (
                      <Grid item xs={4} key={index}>
                        <Box
                          sx={{
                            position: "relative",
                            "&:hover .overlay": {
                              opacity: 1,
                            },
                          }}
                        >
                          <CardMedia
                            url={url}
                            sx={{
                              objectFit: "cover",
                              borderRadius: 3,
                              height: 120,
                              width: "100%",
                            }}
                            propsType={{
                              controls: false,
                              autoplay: false,
                            }}
                          />
                          <ContainerOverlay
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              top: 0,
                              right: 0,
                            }}
                          >
                            <IconButton
                              sx={{ p: 0 }}
                              onClick={() => {
                                setValue(
                                  "mediaUrls",
                                  mediaUrls.filter((_, i) => i !== index)
                                );
                              }}
                            >
                              <DeleteIcon
                                sx={{
                                  color: "white !important",
                                }}
                              />
                            </IconButton>
                          </ContainerOverlay>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          height: "100%",
          position: "sticky",
          bottom: 0,
          backgroundColor: "background.paper",
          backgroundImage: "none",
          pb: 1,
          gap: 1,
        }}
      >
        <Button
          disabled={isDisabledButtons}
          onClick={() =>
            onGenerateVideoPreview({
              mode: "settings",
            })
          }
          sx={{
            width: "100%",
            background: GRADIENT_PURPLE_FUCHSIA,
            color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
            filter: isDisabledButtons ? "brightness(0.4)" : "none",
          }}
        >
          {t(BUTTON_GENERATE)}
        </Button>
        <Button
          disabled={isDisabledButtons}
          onClick={() => onGenerateVideoPreview({ mode: "random" })}
          sx={{
            width: "100%",
            filter: isDisabledButtons ? "brightness(0.4)" : "none",
          }}
        >
          {t(BUTTON_GENERATE_RANDOM)}
        </Button>
      </Box>
      {modalOpenSelectAvatar && (
        <ModalSelectAvatar
          modalOpen={modalOpenSelectAvatar}
          onCloseModal={() => setModalOpenSelectAvatar(false)}
          onCallbackSelectAvatar={(avatar) => {
            setValue("avatar", avatar);
            setModalOpenSelectAvatar(false);
          }}
        />
      )}
    </>
  );
};

const ContainerOverlay = ({ children, sx }) => {
  return (
    <Box
      className="overlay"
      sx={{
        position: "absolute",
        background: "rgba(0, 0, 0, 0.4)",
        width: "100%",
        opacity: 0,
        transition: ".3s",
        px: 1,
        py: 0.5,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default ConfigurationCreatifyForm;
