import { useEffect, useState } from "react";
import ModalBasicLayout from "../../../Modal/ModalBasicLayout";
import {
  BUTTON_GO_BACK,
  BUTTON_SAVE,
  MODAL_EDIT_ADVANTAGE_ENHANCES_META_DESCRIPTION,
  MODAL_EDIT_ADVANTAGE_ENHANCES_META_TITLE,
  // MODAL_EDIT_ADVANTAGE_ENHANCES_AUTO_CROP_TITLE,
  // MODAL_EDIT_ADVANTAGE_ENHANCES_AUTO_CROP_DESCRIPTION,
  // MODAL_EDIT_ADVANTAGE_ENHANCES_META_TEXT_OPTIMIZATIONS_TITLE,
  // MODAL_EDIT_ADVANTAGE_ENHANCES_META_TEXT_OPTIMIZATIONS_DESCRIPTION,
  // MODAL_EDIT_ADVANTAGE_ENHANCES_META_INLINE_COMMENT_TITLE,
  // MODAL_EDIT_ADVANTAGE_ENHANCES_META_INLINE_COMMENT_DESCRIPTION,
  // MODAL_EDIT_ADVANTAGE_ENHANCES_META_ENHANCE_CTA_TITLE,
  // MODAL_EDIT_ADVANTAGE_ENHANCES_META_ENHANCE_CTA_DESCRIPTION,
  // MODAL_EDIT_ADVANTAGE_ENHANCES_META_IMAGE_TEMPLATES_TITLE,
  // MODAL_EDIT_ADVANTAGE_ENHANCES_META_IMAGE_TEMPLATES_DESCRIPTION,
  MODAL_EDIT_ADVANTAGE_ENHANCES_META_IMAGE_ANIMATION_TITLE,
  MODAL_EDIT_ADVANTAGE_ENHANCES_META_IMAGE_ANIMATION_DESCRIPTION,
  MODAL_EDIT_ADVANTAGE_ENHANCES_CV_TRANSFORMATION_TITLE,
  MODAL_EDIT_ADVANTAGE_ENHANCES_CV_TRANSFORMATION_DESCRIPTION,
  MODAL_EDIT_ADVANTAGE_ENHANCES_IMAGE_BRIGHTNESS_AND_CONTRAST_TITLE,
  MODAL_EDIT_ADVANTAGE_ENHANCES_IMAGE_BRIGHTNESS_AND_CONTRAST_DESCRIPTION,
  MODAL_EDIT_ADVANTAGE_ENHANCES_VIDEO_FILTERING_TITLE,
  MODAL_EDIT_ADVANTAGE_ENHANCES_VIDEO_FILTERING_DESCRIPTION,
  MODAL_EDIT_ADVANTAGE_ENHANCES_STANDARD_ENHANCES_TITLE,
  MODAL_EDIT_ADVANTAGE_ENHANCES_STANDARD_ENHANCES_DESCRIPTION,
  MODAL_EDIT_ADVANTAGE_ENHANCES_DESCRIPTION_AUTOMATION_TITLE,
  MODAL_EDIT_ADVANTAGE_ENHANCES_DESCRIPTION_AUTOMATION_DESCRIPTION,
  MODAL_EDIT_ADVANTAGE_ENHANCES_PROFILE_CARD_TITLE,
  MODAL_EDIT_ADVANTAGE_ENHANCES_PROFILE_CARD_DESCRIPTION,
  MODAL_EDIT_ADVANTAGE_ENHANCES_CAROUSEL_TO_VIDEO_TITLE,
  MODAL_EDIT_ADVANTAGE_ENHANCES_CAROUSEL_TO_VIDEO_DESCRIPTION,
  MODAL_EDIT_ADVANTAGE_ENHANCES_MEDIA_ORDER_TITLE,
  MODAL_EDIT_ADVANTAGE_ENHANCES_MEDIA_ORDER_DESCRIPTION,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import Loader from "../../../Loaders/Loader";
import ContainerModalActions from "../../../Containers/ContainerModalActions";
import { Card, DialogActions, Grid, Typography, Box } from "@mui/material";
import { useForm, useFormContext } from "react-hook-form";
import Switch from "../../../Form/Switch";

const advantagePlusFeaturesConfigSingle = {
  // autoCrop: {
  //   id: "autoCrop",
  //   title: MODAL_EDIT_ADVANTAGE_ENHANCES_AUTO_CROP_TITLE,
  //   description: MODAL_EDIT_ADVANTAGE_ENHANCES_AUTO_CROP_DESCRIPTION,
  // },
  // textOptimizations: {
  //   id: "textOptimizations",
  //   title: MODAL_EDIT_ADVANTAGE_ENHANCES_META_TEXT_OPTIMIZATIONS_TITLE,
  //   description:
  //     MODAL_EDIT_ADVANTAGE_ENHANCES_META_TEXT_OPTIMIZATIONS_DESCRIPTION,
  // },
  // inlineComment: {
  //   id: "inlineComment",
  //   title: MODAL_EDIT_ADVANTAGE_ENHANCES_META_INLINE_COMMENT_TITLE,
  //   description: MODAL_EDIT_ADVANTAGE_ENHANCES_META_INLINE_COMMENT_DESCRIPTION,
  // },
  // imageTemplates: {
  //   id: "imageTemplates",
  //   title: MODAL_EDIT_ADVANTAGE_ENHANCES_META_IMAGE_TEMPLATES_TITLE,
  //   description: MODAL_EDIT_ADVANTAGE_ENHANCES_META_IMAGE_TEMPLATES_DESCRIPTION,
  // },
  // enhanceCta: {
  //   id: "enhanceCta",
  //   title: MODAL_EDIT_ADVANTAGE_ENHANCES_META_ENHANCE_CTA_TITLE,
  //   description: MODAL_EDIT_ADVANTAGE_ENHANCES_META_ENHANCE_CTA_DESCRIPTION,
  // },
  standardEnhances: {
    id: "standardEnhances",
    title: MODAL_EDIT_ADVANTAGE_ENHANCES_STANDARD_ENHANCES_TITLE,
    description: MODAL_EDIT_ADVANTAGE_ENHANCES_STANDARD_ENHANCES_DESCRIPTION,
  },
  imageAnimation: {
    id: "imageAnimation",
    title: MODAL_EDIT_ADVANTAGE_ENHANCES_META_IMAGE_ANIMATION_TITLE,
    description: MODAL_EDIT_ADVANTAGE_ENHANCES_META_IMAGE_ANIMATION_DESCRIPTION,
  },
  cvTransformation: {
    id: "cvTransformation",
    title: MODAL_EDIT_ADVANTAGE_ENHANCES_CV_TRANSFORMATION_TITLE,
    description: MODAL_EDIT_ADVANTAGE_ENHANCES_CV_TRANSFORMATION_DESCRIPTION,
  },
  imageBrightnessAndContrast: {
    id: "imageBrightnessAndContrast",
    title: MODAL_EDIT_ADVANTAGE_ENHANCES_IMAGE_BRIGHTNESS_AND_CONTRAST_TITLE,
    description:
      MODAL_EDIT_ADVANTAGE_ENHANCES_IMAGE_BRIGHTNESS_AND_CONTRAST_DESCRIPTION,
  },
  videoFiltering: {
    id: "videoFiltering",
    title: MODAL_EDIT_ADVANTAGE_ENHANCES_VIDEO_FILTERING_TITLE,
    description: MODAL_EDIT_ADVANTAGE_ENHANCES_VIDEO_FILTERING_DESCRIPTION,
  },
};
const advantagePlusFeaturesConfigCarousel = {
  standardEnhances: {
    id: "standardEnhances",
    title: MODAL_EDIT_ADVANTAGE_ENHANCES_STANDARD_ENHANCES_TITLE,
    description: MODAL_EDIT_ADVANTAGE_ENHANCES_STANDARD_ENHANCES_DESCRIPTION,
  },
  descriptionAutomation: {
    id: "descriptionAutomation",
    title: MODAL_EDIT_ADVANTAGE_ENHANCES_DESCRIPTION_AUTOMATION_TITLE,
    description:
      MODAL_EDIT_ADVANTAGE_ENHANCES_DESCRIPTION_AUTOMATION_DESCRIPTION,
  },
  profileCard: {
    id: "profileCard",
    title: MODAL_EDIT_ADVANTAGE_ENHANCES_PROFILE_CARD_TITLE,
    description: MODAL_EDIT_ADVANTAGE_ENHANCES_PROFILE_CARD_DESCRIPTION,
  },
  carouselToVideo: {
    id: "carouselToVideo",
    title: MODAL_EDIT_ADVANTAGE_ENHANCES_CAROUSEL_TO_VIDEO_TITLE,
    description: MODAL_EDIT_ADVANTAGE_ENHANCES_CAROUSEL_TO_VIDEO_DESCRIPTION,
  },
  mediaOrder: {
    id: "mediaOrder",
    title: MODAL_EDIT_ADVANTAGE_ENHANCES_MEDIA_ORDER_TITLE,
    description: MODAL_EDIT_ADVANTAGE_ENHANCES_MEDIA_ORDER_DESCRIPTION,
  },
};

const ModalEditAdvantageEnhances = ({
  modalOpen,
  onCloseModal = () => {},
  adPathForm,
  onCallbackSelect = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    setValue,
    getValues: getValuesForm,
    watch,
  } = useForm({
    defaultValues: {
      advantageEnhances: {},
    },
  });

  console.log(watch());

  const { getValues } = useFormContext();
  const { t } = useTranslationApp();
  const isCarousel = getValues(`${adPathForm}.isCarousel`);
  const advantagePlusFeaturesConfig = isCarousel
    ? advantagePlusFeaturesConfigCarousel
    : advantagePlusFeaturesConfigSingle;

  useEffect(() => {
    const featuresDefault = {};
    Object.keys(advantagePlusFeaturesConfig).forEach((key) => {
      const id = advantagePlusFeaturesConfig[key].id;
      featuresDefault[id] = false;
    });
    const advantageEnhances = getValues(`${adPathForm}.advantageEnhances`);
    console.log(advantageEnhances);

    setValue("advantageEnhances", advantageEnhances || featuresDefault);
    setIsLoading(false);

    // eslint-disable-next-line
  }, []);

  const handleToggleFeature = (id) => {
    const status = getStatusFeature(id);
    const featureID = advantagePlusFeaturesConfig[id].id;
    setValue(`advantageEnhances.${featureID}`, !status);
  };
  const getStatusFeature = (id) => {
    const featureID = advantagePlusFeaturesConfig[id].id;
    console.log(featureID);
    console.log(getValuesForm(`advantageEnhances.${featureID}`));
    const value = getValuesForm(`advantageEnhances.${featureID}`);
    return value;
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      title={t(MODAL_EDIT_ADVANTAGE_ENHANCES_META_TITLE)}
      description={t(MODAL_EDIT_ADVANTAGE_ENHANCES_META_DESCRIPTION)}
      maxWidth="md"
      fullWidth
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Grid container spacing={2} alignItems="stretch">
            {Object.keys(advantagePlusFeaturesConfig).map((key) => {
              const feature = advantagePlusFeaturesConfig[key];
              const isActive = getStatusFeature(key);
              return (
                <Grid item xs={12} sm={6} md={4} key={key}>
                  <FeatureCardItem
                    title={feature.title}
                    description={feature.description}
                    isActive={isActive}
                    onToggle={() => handleToggleFeature(key)}
                  />
                </Grid>
              );
            })}
          </Grid>
          <DialogActions
            sx={{
              p: 0,
              mt: 2,
            }}
          >
            <ContainerModalActions
              hasPadding={false}
              propsSecondaryButton={{
                showButton: true,
                onClick: onCloseModal,
                text: BUTTON_GO_BACK,
              }}
              propsPrimaryButton={{
                showButton: true,
                text: BUTTON_SAVE,
                type: "submit",
                onClick: () => {
                  onCloseModal();
                  onCallbackSelect(getValuesForm("advantageEnhances"));
                },
              }}
            />
          </DialogActions>
        </>
      )}
    </ModalBasicLayout>
  );
};

const FeatureCardItem = ({ title, description, isActive, onToggle }) => {
  console.log({ title, isActive });
  const { t } = useTranslationApp();
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        p: 1.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Typography variant="body1">{t(title)}</Typography>
        <Switch
          isActive={isActive}
          onChangeIsActive={onToggle}
          color="primary"
          hasLabel={false}
        />
      </Box>
      <Typography
        variant="body2"
        sx={{ mb: 1 }}
        dangerouslySetInnerHTML={{
          __html: t(description),
        }}
      />
    </Card>
  );
};

export default ModalEditAdvantageEnhances;
