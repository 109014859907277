import { useFieldArray, useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "../../../Form/TextField";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  CALLOUTS_PLACEHOLDER,
  CALLOUT_TEXT_PLACEHOLDER,
} from "../../../../i18n/keysTranslations";
import { ADS_CAMPAIGN_GOOGLE_CALL_OUT_MAX_LENGTH } from "../../../../utils/constants";
import ChipOptional from "../../../Chip/ChipOptional";

const CalloutsContainer = ({
  name = "callOuts",
  label = CALLOUTS_PLACEHOLDER,
  disabled = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const { t } = useTranslationApp();

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 1.5 }}>
        <Typography variant="body1">{t(label)}</Typography>
        <ChipOptional />
      </Box>

      {fields.map((field, index) => (
        <Grid
          container
          spacing={2}
          key={field.id}
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Grid item xs={disabled ? 12 : 10}>
            <TextField
              name={`${name}[${index}].value`}
              label={`${t(CALLOUT_TEXT_PLACEHOLDER)} ${index + 1}`}
              variant="filled"
              fullWidth
              control={control}
              errors={errors}
              showCounterCharacters={true}
              maxCharacters={ADS_CAMPAIGN_GOOGLE_CALL_OUT_MAX_LENGTH}
              disabled={disabled}
            />
          </Grid>
          {!disabled && (
            <Grid item xs={2}>
              <IconButton onClick={() => remove(index)} type="button">
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}

      {fields.length < 10 && !disabled && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton onClick={() => append({ value: "" })} type="button">
            <AddIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CalloutsContainer;
