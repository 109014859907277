import { useTranslationApp } from "../../../../lib/i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BORDER_RADIUS } from "../../../../utils/constants";
import {
  CLICKS,
  CPA,
  CTR,
  IMPRESSIONS,
  RESULTS,
  SPEND,
  VALUE_NUMBER,
  VALUE_WITH_CURRENCY_MANUAL_FORMAT,
} from "../../../../i18n/keysTranslations";

const STATISTICS_TO_SHOW = [
  "impressions",
  "click",
  "ctr",
  "mainAction",
  "costPerMainAction",
  "spend",
];

const MAP_TRANSLATIONS = {
  spend: SPEND,
  impressions: IMPRESSIONS,
  click: CLICKS,
  ctr: CTR,
  mainAction: RESULTS,
  costPerMainAction: CPA,
};

const StatisticsLevel = ({ statistics }) => {
  const { t } = useTranslationApp();

  const getValueTranslation = ({ metric, value }) => {
    if (metric === "spend" || metric === "costPerMainAction") {
      return t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
        value,
        currency: statistics?.currency,
      });
    } else {
      return t(VALUE_NUMBER, { value });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      {STATISTICS_TO_SHOW.map((metric) => {
        const value = statistics[metric];
        const label = t(MAP_TRANSLATIONS[metric]);

        return (
          <Box
            key={metric}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
              borderRadius: BORDER_RADIUS,
              backgroundColor: "background.paperSecondary",
              p: 1.5,
              width: 120,
            }}
          >
            <Typography variant="caption" color="fields.placeholder">
              {label}
            </Typography>
            <Typography variant="body2">
              {getValueTranslation({ metric, value })}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default StatisticsLevel;
