import Box from "@mui/material/Box";
import Sidebar from "./components/Layout/Sidebar";
import GeneralContainer from "./components/Layout/GeneralContainer";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { getKeyDatabase } from "../../services/public";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  startGetAdsCampaign,
  startGetBrandInformation,
  startGetRecommendedSettingsPlatform,
  startSaveAdsCampaign,
  startUpdateAdsCampaignsInformationFromPlatforms,
} from "../../actions/adsCampaigns";
import {
  ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DRAFT,
  ADS_CAMPAIGN_STATUS_FINISHED,
  ALERT_ICON_TYPE_SUCCESS,
  TIME_LOADER_GENERATING_CAMPAIGN_ADVANCED,
} from "../../utils/constants";
import { getIsBlockedActions, getUserIsViewer } from "../../actions/getters";
import Loader from "../../components/Loaders/Loader";
import {
  ADS_CAMPAIGN_HAS_BEEN_SAVE,
  ERROR_PROCESSING_CAMPAIGNS,
  LOADING_INFORMATION,
  WE_ARE_SYNCHRONIZING_INFORMATION_WITH_PLATFORMS,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { getCampaignUpdatedByStatus } from "../../services/adsCampaigns";
import { setNestedValue } from "../../utils/forms";
import { SimpleAlert } from "../../components/Alerts/Alerts";
import ModalErrorsCampaigns from "../AdsCampaign/components/ModalErrorsCampaigns";
import { zodResolver } from "@hookform/resolvers/zod";
import { getCampaignSchema } from "./utils/schemas/campaignSchema";
import { useUploadAsyncContentInCampaign } from "./hooks/useUploadAsyncContentInCampaign";
import i18next from "i18next";
import LinearLoaderWithTime from "../../components/Loaders/LinearLoaderWithTime";

const NewAdsCampaign = () => {
  const [schema, setSchema] = useState(null);
  const [isForcedSetupPage, setIsForcedSetupPage] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sectionSelected, setSectionSelected] = useState({
    platform: undefined,
    assetsGroupID: undefined,
    adGroupID: undefined,
    adID: undefined,
  });
  const [isSyncingData, setIsSyncingData] = useState(true);
  const [platformGenerating, setPlatformGenerating] = useState(null);
  const [lastInformationToGenerate, setLastInformationToGenerate] = useState(
    {}
  );
  const [showErrorGenerating, setShowErrorGenerating] = useState(false);
  const [modalErrorsCampaignsOpen, setModalErrorsCampaignsOpen] =
    useState(false);
  const [dataModalErrorsCampaigns, setDataModalErrorsCampaigns] = useState({});
  const [campaignID, setCampaignID] = useState(null);
  const [brandInformation, setBrandInformation] = useState({});
  const [statistics, setStatistics] = useState({});

  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      context: "",
      language: "",
      locations: {
        contexts: [],
        locations: [],
      },
      platforms: [],
    },
    shouldUnregister: false,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { getValues, setValue, watch, trigger } = methods;
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const { t } = useTranslationApp();
  const campaignIDParam = params.get("campaignID");
  const platformParam = params.get("platform");
  const assetsGroupIDParam = params.get("assetsGroupID");
  const adGroupIDParam = params.get("adGroupID");
  const adIDParam = params.get("adID");
  const integrations = useSelectorApp((state) => state?.user?.integrations);

  useUploadAsyncContentInCampaign({
    campaignID,
    getValues,
    setValue,
    watch,
  });

  const isIncompatibleCampaign = watch("isIncompatibleCampaign");
  const states = watch("states") || {};
  const platforms = watch("platforms") || [];
  const platformIDs = watch("platformIDs") || [];
  const hasSomePlatformDraft = Object.keys(states || {}).some(
    (key) => states[key] === ADS_CAMPAIGN_STATUS_DRAFT
  );
  const hasSomePlatformCreated = Object.keys(states || {}).some(
    (key) => states[key] !== ADS_CAMPAIGN_STATUS_DRAFT
  );

  const onGenerateCampaignPlatform = async (platformData) => {
    const formValues = getValues();
    const currentPlatforms = formValues.platforms || [];

    if (currentPlatforms.length === 0 || !currentPlatforms) {
      const isBlocked = await dispatch(
        getIsBlockedActions({
          action: ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION,
        })
      );
      if (isBlocked) {
        return;
      }
    }

    setShowErrorGenerating(false);
    const newPlatforms = [...currentPlatforms, platformData.platform];
    setLastInformationToGenerate(platformData);

    if (isForcedSetupPage) {
      setIsForcedSetupPage(false);
    }

    setPlatformGenerating(platformData.platform);
    onChangeSectionSelected({
      platform: undefined,
      assetsGroupID: undefined,
      adGroupID: undefined,
      adID: undefined,
    });
    const response = await dispatch(
      startGetRecommendedSettingsPlatform({
        ...platformData,
        ...formValues,
        platforms: newPlatforms,
        campaignID,
      })
    );
    if (response.ok) {
      setShowErrorGenerating(false);
      const data = response.data;
      Object.keys(data).forEach((key) => {
        setValue(key, data[key]);
      });
      onChangeSectionSelected({
        platform: platformData.platform,
        assetsGroupID: undefined,
        adGroupID: undefined,
        adID: undefined,
      });
    } else {
      setShowErrorGenerating(true);
    }
    setPlatformGenerating(null);
  };

  const onUpdateAdsCampaignInformationFromPlatforms = async (campaignID) => {
    const response = await dispatch(
      startUpdateAdsCampaignsInformationFromPlatforms({
        campaignID,
      })
    );
    if (response.ok) {
      const updatedData = response?.data?.campaign;
      const statistics = response?.data?.statistics;
      setStatistics(statistics);
      setNestedValue({ data: updatedData, setValue });
      onChangeSectionSelected({
        platform: platformParam || updatedData?.platforms?.[0],
        assetsGroupID: assetsGroupIDParam || undefined,
        adGroupID: adGroupIDParam || undefined,
        adID: adIDParam || undefined,
      });
    } else {
      const statistics = response?.data?.statistics;
      setStatistics(statistics);
      const currentData = getValues();
      onChangeSectionSelected({
        platform: platformParam || currentData?.platforms?.[0],
        assetsGroupID: assetsGroupIDParam || undefined,
        adGroupID: adGroupIDParam || undefined,
        adID: adIDParam || undefined,
      });
    }

    setIsSyncingData(false);
    setIsLoading(false);
    return response;
  };

  const onGetInitialData = async (campaignID) => {
    setIsLoading(true);
    setIsSyncingData(true);

    const response = await dispatch(startGetAdsCampaign(campaignID));
    if (response) {
      const data = response;

      Object.keys(data).forEach((key) => {
        setValue(key, data[key]);
      });

      const hasSomePlatformCreated = Object.keys(response.states || {}).some(
        (key) => response.states[key] !== ADS_CAMPAIGN_STATUS_DRAFT
      );
      if (hasSomePlatformCreated) {
        onUpdateAdsCampaignInformationFromPlatforms(campaignID);
      } else {
        onChangeSectionSelected({
          platform: data?.platforms?.[0],
          assetsGroupID: undefined,
          adGroupID: undefined,
          adID: undefined,
        });
        setIsLoading(false);
        setIsSyncingData(false);
      }
    } else {
      setIsLoading(false);
      setIsSyncingData(false);
    }
  };

  const getBrandInformation = async () => {
    const response = await dispatch(startGetBrandInformation());
    if (response.ok) {
      const { data } = response;
      setBrandInformation(data);
    }
  };

  const onUpdateAllCampaignInformation = async (newCampaignID) => {
    setIsLoading(true);
    methods.reset();
    setCampaignID();
    onGetInitialData(newCampaignID);
    setCampaignID(newCampaignID);
  };

  const onChangeStatusCampaign = async ({
    newStatus,
    target = "campaign",
    platform,
    assetsGroupID,
    adGroupID,
    adID,
    showAlertSave = false,
  }) => {
    const isValid = await trigger();
    if (!isValid) return;

    const objectUpdate = getCampaignUpdatedByStatus({
      campaign: getValues(),
      status: newStatus,
      target,
      platform,
      adGroupID,
      adID,
      assetsGroupID,
    });
    setIsUpdating(true);
    const response = await dispatch(
      startSaveAdsCampaign({
        campaign: {
          ...objectUpdate,
        },
        campaignID,
      })
    );
    setIsUpdating(false);

    if (response.ok) {
      setNestedValue({ data: response.campaign, setValue });

      if (showAlertSave) {
        SimpleAlert({
          title: t(ADS_CAMPAIGN_HAS_BEEN_SAVE),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    } else if (response.code === ERROR_PROCESSING_CAMPAIGNS) {
      setDataModalErrorsCampaigns({
        createdCampaigns: response?.createdCampaigns,
        failedCampaigns: response?.failedCampaigns,
        errors: response?.errors,
      });
      setModalErrorsCampaignsOpen(true);
    }
  };

  const onChangeSectionSelected = (section) => {
    const { from, customParams, ...rest } = section;
    if (isForcedSetupPage && from !== "addPlatforms") {
      setIsForcedSetupPage(false);
    }

    if (platformGenerating) return;

    const restCopy = { ...rest };

    const platforms = getValues("platforms");
    const platformIsPresent = platforms.includes(rest.platform);
    const assetsGroups = getValues("assetsGroups");
    const assetsGroupIsPresent =
      platformIsPresent && rest?.assetsGroupID
        ? assetsGroups?.some(
            (assetsGroup) => assetsGroup?.id === rest?.assetsGroupID
          )
        : false;
    const adsGroups = getValues("adsGroups");
    const adGroupIsPresent =
      platformIsPresent && rest?.adGroupID
        ? adsGroups?.some((adGroup) => adGroup?.id === rest?.adGroupID)
        : false;
    const adIsPresent =
      platformIsPresent && rest?.adGroupID && rest?.adID
        ? adsGroups
            .find((adGroup) => adGroup?.id === rest?.adGroupID)
            ?.ads.find((ad) => ad?.id === rest?.adID)
        : false;

    if (platformIsPresent) {
      params.set("platform", rest.platform);
    } else {
      params.set("platform", platforms[0]);
      restCopy.platform = platforms[0];
    }

    if (assetsGroupIsPresent) {
      params.set("assetsGroupID", rest.assetsGroupID);
    } else {
      params.delete("assetsGroupID");
      restCopy.assetsGroupID = undefined;
    }

    if (adGroupIsPresent) {
      params.set("adGroupID", rest.adGroupID);
    } else {
      params.delete("adGroupID");
      restCopy.adGroupID = undefined;
    }

    if (adIsPresent) {
      params.set("adID", rest.adID);
    } else {
      params.delete("adID");
      restCopy.adID = undefined;
    }

    if (customParams) {
      Object.keys(customParams || {}).forEach((key) => {
        params.set(key, customParams[key]);
      });
    }

    setSearchParams(params);
    setSectionSelected(restCopy);
  };

  const onGetItemSelectedLevel = ({
    platform,
    assetsGroupID,
    adGroupID,
    adID,
  }) => {
    if (!platform) return null;
    if (typeof assetsGroupID === "string") {
      return "assetsGroup";
    }
    if (typeof adGroupID === "string") {
      return typeof adID === "string" ? "ad" : "adGroup";
    }
    return "platform";
  };

  const getIsCampaignPlatformActive = (platform) => {
    const state = states?.[platform];
    return (
      state === ADS_CAMPAIGN_STATUS_ACTIVE ||
      state === ADS_CAMPAIGN_STATUS_FINISHED
    );
  };
  const getIsCampaignPlatformCreated = (platform) => {
    const state = states?.[platform];
    return state !== ADS_CAMPAIGN_STATUS_DRAFT;
  };
  const getShowActionsPlatform = (platform) => {
    const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);
    const state = states?.[platform];

    return (
      isCampaignPlatformCreated &&
      state &&
      state !== ADS_CAMPAIGN_STATUS_DRAFT &&
      state !== ADS_CAMPAIGN_STATUS_FINISHED
    );
  };

  useEffect(() => {
    getBrandInformation();
    if (campaignIDParam) {
      setCampaignID(campaignIDParam);
      onGetInitialData(campaignIDParam);
      return;
    }

    const newCampaignID = dispatch(getKeyDatabase());
    setCampaignID(newCampaignID);
    navigate(`/ads-campaign?campaignID=${newCampaignID}`, {
      replace: true,
    });
    setIsLoading(false);

    // eslint-disable-next-line
  }, [campaignIDParam]);

  useEffect(() => {
    function handleInitialized() {
      setSchema(getCampaignSchema({ ...brandInformation, integrations }));
    }

    if (i18next.isInitialized) {
      setSchema(getCampaignSchema({ ...brandInformation, integrations }));
    } else {
      i18next.on("initialized", handleInitialized);
    }

    return () => {
      i18next.off("initialized", handleInitialized);
    };
  }, [brandInformation, integrations]);

  const levelSectionSelected = onGetItemSelectedLevel({
    platform: sectionSelected.platform,
    assetsGroupID: sectionSelected.assetsGroupID,
    adGroupID: sectionSelected.adGroupID,
    adID: sectionSelected.adID,
  });

  const isGeneralCampaignActivated = getIsCampaignPlatformActive("general");
  const isGeneralCampaignCreated = getIsCampaignPlatformCreated("general");
  const isGeneralDisabledFields =
    dispatch(getUserIsViewer()) || isIncompatibleCampaign;

  const isAllCampaignsPlatformCreated =
    platforms.length > 0 &&
    !platforms?.some((platform) => !platformIDs?.[platform]);

  if (isSyncingData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 600,
          }}
        >
          <LinearLoaderWithTime
            labelSide="bottom"
            time={TIME_LOADER_GENERATING_CAMPAIGN_ADVANCED}
            max={99}
            label={t(WE_ARE_SYNCHRONIZING_INFORMATION_WITH_PLATFORMS)}
          />
        </Box>
      </Box>
    );
  }
  if (isLoading) {
    return (
      <Loader fullWidth hasMessage={true} message={t(LOADING_INFORMATION)} />
    );
  }
  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 64px)" }}>
      <FormProvider
        {...methods}
        campaignID={campaignID}
        onGenerateCampaignPlatform={onGenerateCampaignPlatform}
        platformGenerating={platformGenerating}
        isGenerating={Boolean(platformGenerating)}
        isSyncingData={isSyncingData}
        sectionSelected={sectionSelected}
        onChangeSectionSelected={onChangeSectionSelected}
        levelSectionSelected={levelSectionSelected}
        onGetItemSelectedLevel={onGetItemSelectedLevel}
        isGeneralCampaignActivated={isGeneralCampaignActivated}
        isGeneralCampaignCreated={isGeneralCampaignCreated}
        isGeneralDisabledFields={isGeneralDisabledFields}
        getIsCampaignPlatformCreated={getIsCampaignPlatformCreated}
        getIsCampaignPlatformActive={getIsCampaignPlatformActive}
        hasSomePlatformDraft={hasSomePlatformDraft}
        hasSomePlatformCreated={hasSomePlatformCreated}
        onChangeDataModalErrorsCampaigns={setDataModalErrorsCampaigns}
        onChangeModalErrorsCampaignsOpen={setModalErrorsCampaignsOpen}
        onChangeStatusCampaign={onChangeStatusCampaign}
        getShowActionsPlatform={getShowActionsPlatform}
        showErrorGenerating={showErrorGenerating}
        lastInformationToGenerate={lastInformationToGenerate}
        isUpdating={isUpdating}
        onChangeIsUpdating={setIsUpdating}
        isAllCampaignsPlatformCreated={isAllCampaignsPlatformCreated}
        isForcedSetupPage={isForcedSetupPage}
        onChangeIsForcedSetupPage={setIsForcedSetupPage}
        brandInformation={brandInformation}
        onUpdateAllCampaignInformation={onUpdateAllCampaignInformation}
        statistics={statistics}
      >
        {platforms.length > 0 && <Sidebar />}
        <GeneralContainer />
        {modalErrorsCampaignsOpen && (
          <ModalErrorsCampaigns
            modalOpen={modalErrorsCampaignsOpen}
            onCloseModal={() => setModalErrorsCampaignsOpen(false)}
            campaign={getValues()}
            successCampaigns={dataModalErrorsCampaigns.successCampaigns}
            failedCampaigns={dataModalErrorsCampaigns.failedCampaigns}
            errors={dataModalErrorsCampaigns.errors}
          />
        )}
      </FormProvider>
    </Box>
  );
};

export default NewAdsCampaign;
