import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useController, useFormContext } from "react-hook-form";
import Button from "../../../components/Buttons/Button";
import {
  BUTTON_ADD_RE_TARGETING,
  MODAL_CUSTOM_AUDIENCES_TITLE,
  MODAL_EXCLUDED_CUSTOM_AUDIENCES_TITLE,
  RETENTION_DAYS_PLACEHOLDER,
  TYPE_PLACEHOLDER,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import ModalCustomAudiences from "../../../components/Modal/ModalCustomAudiences/ModalCustomAudiences";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material";
import IconButton from "../../../components/Buttons/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { BORDER_RADIUS } from "../../../utils/constants";

const AdsGroupFormCustomAudiencesList = ({
  BASIC_PATH,
  control,
  name,
  disabled = false,
  isExcludedAudiences = false,
}) => {
  const [modalOpenCustomAudiences, setModalOpenCustomAudiences] =
    useState(false);
  const { t } = useTranslationApp();
  const { getValues } = useFormContext();
  const theme = useTheme();

  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const customAudiences = value || [];
  const platform = getValues(`${BASIC_PATH}.platform`);
  const platformData = getValues(`platformsData.${platform}`) || {};

  return (
    <Box
      sx={{
        mb: 1,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          mb: 1,
        }}
      >
        {t(
          isExcludedAudiences
            ? MODAL_EXCLUDED_CUSTOM_AUDIENCES_TITLE
            : MODAL_CUSTOM_AUDIENCES_TITLE
        )}
      </Typography>
      <Grid container spacing={2}>
        {customAudiences.map((customAudience) => (
          <Grid item xs={12} sm={6} lg={4} key={customAudience.id}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                border: `${theme.palette.border.grey} 1px solid`,
                p: 1,
                borderRadius: BORDER_RADIUS,
                height: "100%",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography variant="body1">{customAudience.name}</Typography>
                {customAudience.description && (
                  <Typography variant="body2">
                    {customAudience.description}
                  </Typography>
                )}
                {customAudience?.retentionDays && (
                  <Typography variant="body2">
                    {`${t(RETENTION_DAYS_PLACEHOLDER)}: ${
                      customAudience.retentionDays
                    }`}
                  </Typography>
                )}
                {customAudience?.type && (
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {`${t(TYPE_PLACEHOLDER)}: ${customAudience.type}`}
                  </Typography>
                )}
              </Box>
              {!disabled && (
                <IconButton
                  edge="end"
                  sx={{
                    p: 0,
                    mr: 0.2,
                  }}
                  onClick={() => {
                    onChange(value.filter((c) => c.id !== customAudience.id));
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        ))}
        {!disabled && (
          <Grid item xs={12} sm={6} lg={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Button
                type="button"
                variant="text"
                color="white"
                startIcon={<AddIcon />}
                onClick={() => setModalOpenCustomAudiences(true)}
                sx={{
                  p: 0,
                }}
              >
                {t(BUTTON_ADD_RE_TARGETING)}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      {modalOpenCustomAudiences && (
        <ModalCustomAudiences
          modalOpen={modalOpenCustomAudiences}
          onCloseModal={() => setModalOpenCustomAudiences(false)}
          platform={platform}
          isExcludedAudiences={isExcludedAudiences}
          adAccountID={platformData.adAccountID}
          integrationID={platformData.integrationID}
          filteredCustomAudiences={customAudiences.map((c) => c.id)}
          onCallbackSave={(customAudiences) => {
            onChange(value ? [...value, ...customAudiences] : customAudiences);
            setModalOpenCustomAudiences(false);
          }}
        />
      )}
    </Box>
  );
};

export default AdsGroupFormCustomAudiencesList;
