import MapRadius from "../Maps/MapRadius";
import ModalBasicLayout from "./ModalBasicLayout";
import Box from "@mui/material/Box";

const ModalMapRadius = ({
  modalOpen,
  onCloseModal,
  onCallbackSave = () => {},
  value,
}) => {
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xl"
      scroll="paper"
      sx={{
        "& .MuiDialog-paper": {
          maxHeight: "calc(100% - 64px)",
          height: "100%",
          borderRadius: 4,
          backgroundColor: "transparent",
          backgroundImage: "none",
          boxShadow: "none",
        },
        zIndex: 1302,
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <MapRadius
          value={value}
          sx={{ height: "100%", width: "100%" }}
          onCallbackSave={(value) => {
            onCloseModal();
            onCallbackSave(value);
          }}
        />
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalMapRadius;
