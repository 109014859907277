import Box from "@mui/material/Box";
import BottomBar from "./BottomBar";
import MainContent from "./MainContent";
import EditableText from "../../../../components/Texts/EditableText";
import { useFormContext } from "react-hook-form";
import SwitchStatusItem from "../Common/SwitchStatusItem";
import Button from "../../../../components/Buttons/Button";
import Typography from "@mui/material/Typography";
import {
  BUTTON_TRY_AGAIN,
  ERROR_GENERATING_CAMPAIGN,
  STATISTICS,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import ButtonDuplicateCampaign from "../../../../components/Buttons/ButtonDuplicateCampaign";
import BarChartIcon from "@mui/icons-material/BarChart";
import IconButton from "../../../../components/Buttons/IconButton";
import { openLink } from "../../../../utils/string";
import { useEffect, useState } from "react";

const GeneralContainer = () => {
  const [name, setName] = useState("");
  const {
    isAllCampaignsPlatformCreated,
    onUpdateAllCampaignInformation,
    setValue,
    watch,
    levelSectionSelected,
    sectionSelected,
    getShowActionsPlatform,
    onGenerateCampaignPlatform,
    showErrorGenerating,
    lastInformationToGenerate,
    getValues,
    campaignID,
    isForcedSetupPage,
    isGeneralDisabledFields,
  } = useFormContext();
  const { t } = useTranslationApp();
  const showActionsPlatform = getShowActionsPlatform(sectionSelected.platform);

  const platformsData = watch("platformsData") || {};
  const platforms = Object.keys(platformsData);

  useEffect(() => {
    if (levelSectionSelected === "platform") {
      const nameCampaigns = getValues("name");
      setName(nameCampaigns);
    }
    if (levelSectionSelected === "assetsGroup") {
      const assetsGroups = getValues("assetsGroups");
      const assetsGroup = assetsGroups.find(
        (assetsGroup) => assetsGroup.id === sectionSelected.assetsGroupID
      );
      const nameAssetGroup = assetsGroup.name;
      setName(nameAssetGroup);
    }
    if (levelSectionSelected === "adGroup") {
      const adsGroups = getValues("adsGroups");
      const adGroup = adsGroups.find(
        (adGroup) => adGroup.id === sectionSelected.adGroupID
      );
      const nameAdGroup = adGroup.name;
      setName(nameAdGroup);
    }
    if (levelSectionSelected === "ad") {
      const adsGroups = getValues("adsGroups");
      const adGroup = adsGroups.find(
        (adGroup) => adGroup.id === sectionSelected.adGroupID
      );
      const ads = adGroup.ads;
      const ad = ads.find((ad) => ad.id === sectionSelected.adID);
      const nameAd = ad.name;
      setName(nameAd);
    }
    // eslint-disable-next-line
  }, [levelSectionSelected, sectionSelected]);

  const onChangeName = (newValue) => {
    if (levelSectionSelected === "platform") {
      setValue("name", newValue);
    }

    if (levelSectionSelected === "assetsGroup") {
      const assetsGroups = getValues("assetsGroups");
      const assetsGroupIndex = assetsGroups.findIndex(
        (assetsGroup) => assetsGroup.id === sectionSelected.assetsGroupID
      );
      setValue(`assetsGroups.[${assetsGroupIndex}].name`, newValue);
    }

    if (levelSectionSelected === "adGroup") {
      const adsGroups = getValues("adsGroups");
      const adGroupIndex = adsGroups.findIndex(
        (adGroup) => adGroup.id === sectionSelected.adGroupID
      );
      setValue(`adsGroups.[${adGroupIndex}].name`, newValue);
    }

    if (levelSectionSelected === "ad") {
      const adsGroups = getValues("adsGroups");
      const adGroupIndex = adsGroups.findIndex(
        (adGroup) => adGroup.id === sectionSelected.adGroupID
      );
      const ads = adsGroups[adGroupIndex].ads;
      const adIndex = ads.findIndex((ad) => ad.id === sectionSelected.adID);
      setValue(`adsGroups.[${adGroupIndex}].ads.[${adIndex}].name`, newValue);
    }
  };

  if (showErrorGenerating) {
    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography variant="h5">{t(ERROR_GENERATING_CAMPAIGN)}</Typography>
        <Button
          onClick={() => onGenerateCampaignPlatform(lastInformationToGenerate)}
        >
          {t(BUTTON_TRY_AGAIN)}
        </Button>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      {!isForcedSetupPage && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            zIndex: 1100,
            mt: -1.5,
            mx: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
            }}
          >
            {name && (
              <EditableText
                value={name}
                variantText="h6"
                disabledEditable={isGeneralDisabledFields}
                onCallbackSave={onChangeName}
                sx={{
                  maxWidth: 500,
                }}
                sxText={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            )}
            {name && !isGeneralDisabledFields && (
              <ButtonDuplicateCampaign
                campaign={getValues()}
                onCallbackDuplicate={onUpdateAllCampaignInformation}
              />
            )}
            {isAllCampaignsPlatformCreated && (
              <IconButton
                onClick={() => {
                  openLink(`/ads-campaign-statistics?campaignID=${campaignID}`);
                }}
                tooltipText={STATISTICS}
                sx={{
                  ml: 1,
                }}
              >
                <svg width={0} height={0}>
                  <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor="rgb(250, 85, 96)" />
                    <stop offset={2} stopColor="rgb(177, 75, 244)" />
                    <stop offset={2} stopColor="rgb(77, 145, 255)" />
                  </linearGradient>
                </svg>
                <BarChartIcon
                  sx={{ fill: "url(#linearColors)", width: 30, height: 30 }}
                />
              </IconButton>
            )}
          </Box>
          {showActionsPlatform && !isGeneralDisabledFields && (
            <SwitchStatusItem
              selectedSection={sectionSelected}
              hasLabel={true}
              forceRefreshForSelectedSection={true}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "unset",
                mr: 2,
              }}
            />
          )}
        </Box>
      )}

      <MainContent />
      {platforms?.length > 0 && !isGeneralDisabledFields && <BottomBar />}
    </Box>
  );
};

export default GeneralContainer;
