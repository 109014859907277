import { useState } from "react";
import ModalMapRadius from "../Modal/ModalMapRadius";
import {
  ADS_CAMPAIGN_LOCATION_EXAMPLE,
  ADS_CAMPAIGN_LOCATION_PLACEHOLDER,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { useFormContext } from "react-hook-form";
import { Box, TextField, InputAdornment } from "@mui/material";
import { getReactHookFormNestedError } from "../../utils/errors";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const FieldOpenMapRadius = ({
  onCallbackSave = () => {},
  name,
  variant = "filled",
  placeholder = ADS_CAMPAIGN_LOCATION_EXAMPLE,
  label = ADS_CAMPAIGN_LOCATION_PLACEHOLDER,
  helperText = "",
}) => {
  const [modalMapRadiusOpen, setModalMapRadiusOpen] = useState(false);
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslationApp();
  const error = getReactHookFormNestedError(errors, name)?.locations;
  const value = watch(name) || { locations: [], contexts: [] };
  const locationsText = (value?.locations || [])
    .map((location) => location.name)
    .join(", ");

  const onOpenMapRadius = (e) => {
    e?.stopPropagation();
    setModalMapRadiusOpen(true);
  };
  const onCloseModal = () => {
    setModalMapRadiusOpen(false);
  };

  return (
    <>
      <TextField
        label={t(label) || ""}
        onClick={onOpenMapRadius}
        variant={variant}
        fullWidth={true}
        value={locationsText}
        placeholder={t(placeholder)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnIcon />
            </InputAdornment>
          ),
        }}
        error={!!error}
        helperText={
          <Box
            component="span"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>{!!error ? error?.message : helperText}</span>
          </Box>
        }
        sx={{
          ".MuiFormHelperText-root": {
            mx: 0,
          },
        }}
      />
      {modalMapRadiusOpen && (
        <ModalMapRadius
          modalOpen={modalMapRadiusOpen}
          onCloseModal={onCloseModal}
          onCallbackSave={onCallbackSave}
          value={value}
        />
      )}
    </>
  );
};

export default FieldOpenMapRadius;
