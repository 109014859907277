import Box from "@mui/material/Box";
import SetupContainer from "../Setup/SetupContainer";
import { useFormContext } from "react-hook-form";
import { logoPlatform } from "../../../../utils/string";
import {
  GOOGLE,
  META,
  TIKTOK,
  TIME_LOADER_GENERATING_CAMPAIGN_ADVANCED,
} from "../../../../utils/constants";
import {
  CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS,
  CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS,
  CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import LinearLoaderWithTime from "../../../../components/Loaders/LinearLoaderWithTime";
import AnimatedImage from "../../../../components/Media/AnimatedImage";
import CampaignMetaForm from "../../../../components/Platforms/Meta/Campaign/CampaignMetaForm";
import AdGroupMetaForm from "../../../../components/Platforms/Meta/AdGroup/AdGroupMetaForm";
import AdMetaForm from "../../../../components/Platforms/Meta/Ad/AdMetaForm";
import CampaignGoogleForm from "../../../../components/Platforms/Google/Campaign/CampaignGoogleForm";
import AdGroupGoogleForm from "../../../../components/Platforms/Google/AdGroup/AdGroupGoogleForm";
import AdGoogleForm from "../../../../components/Platforms/Google/Ad/AdGoogleForm";
import CampaignTikTokForm from "../../../../components/Platforms/TikTok/Campaign/CampaignTikTokForm";
import AdGroupTikTokForm from "../../../../components/Platforms/TikTok/AdGroup/AdGroupTikTokForm";
import AdTikTokForm from "../../../../components/Platforms/TikTok/Ad/AdTikTokForm";
import ReportContainer from "../Common/ReportContainer";
import StatisticsLevel from "../Common/StatisticsLevel";
import AssetsGroupGoogleForm from "../../../../components/Platforms/Google/AssetsGroup/AssetsGroupGoogleForm";

const DICTIONARY_PLATFORMS_COMPONENTS = {
  [META]: {
    campaign: CampaignMetaForm,
    adGroup: AdGroupMetaForm,
    ad: AdMetaForm,
  },
  [GOOGLE]: {
    campaign: CampaignGoogleForm,
    assetsGroup: AssetsGroupGoogleForm,
    adGroup: AdGroupGoogleForm,
    ad: AdGoogleForm,
  },
  [TIKTOK]: {
    campaign: CampaignTikTokForm,
    adGroup: AdGroupTikTokForm,
    ad: AdTikTokForm,
  },
};

const MainContent = () => {
  const {
    isAllCampaignsPlatformCreated,
    getIsCampaignPlatformCreated,
    isGenerating,
    platformGenerating,
    watch,
    sectionSelected,
    levelSectionSelected,
    isForcedSetupPage,
    statistics,
  } = useFormContext();
  const { t } = useTranslationApp();

  const platforms = watch("platforms") || [];

  const getPlatformComponent = () => {
    if (!levelSectionSelected) return null;

    const platform = sectionSelected.platform;
    const components = DICTIONARY_PLATFORMS_COMPONENTS[platform];

    if (levelSectionSelected === "platform") return components.campaign;
    if (levelSectionSelected === "assetsGroup") return components.assetsGroup;
    if (levelSectionSelected === "adGroup") return components.adGroup;
    if (levelSectionSelected === "ad") return components.ad;

    return null;
  };
  const getPlatformKey = () => {
    if (levelSectionSelected === "platform") return sectionSelected.platform;
    if (levelSectionSelected === "assetsGroup")
      return `${sectionSelected.platform}-assetsGroup-${sectionSelected.assetsGroupID}`;
    if (levelSectionSelected === "adGroup")
      return `${sectionSelected.platform}-adGroup-${sectionSelected.adGroupID}`;
    if (levelSectionSelected === "ad")
      return `${sectionSelected.platform}-adGroup-${sectionSelected.adGroupID}-ad-${sectionSelected.adID}`;

    return null;
  };

  const PlatformComponent = getPlatformComponent();
  const platformSelectedIsCreated = getIsCampaignPlatformCreated(
    sectionSelected.platform
  );
  const platformKey = getPlatformKey();

  const getStatisticsLevel = () => {
    const totalStatistics = statistics?.total || {};

    if (levelSectionSelected === "platform") {
      return totalStatistics?.[sectionSelected.platform]?.campaign;
    }
    if (levelSectionSelected === "assetsGroup") {
      return totalStatistics?.[sectionSelected.platform]?.assetsGroups?.[
        sectionSelected.assetsGroupID
      ];
    }
    if (levelSectionSelected === "adGroup") {
      return totalStatistics?.[sectionSelected.platform]?.adsGroups?.[
        sectionSelected.adGroupID
      ];
    }
    if (levelSectionSelected === "ad") {
      return totalStatistics?.[sectionSelected.platform]?.ads?.[
        sectionSelected.adID
      ];
    }

    return null;
  };

  const statisticsLevel = getStatisticsLevel();

  return (
    <Box
      sx={{
        flex: 1,
        overflow: "auto",
        pt: 1.5,
        pl: 3,
        px: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
    >
      {isGenerating && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            maxWidth: 300,
            width: "100%",
          }}
        >
          <AnimatedImage
            src={logoPlatform(platformGenerating)}
            sx={{
              width: 50,
              height: 50,
            }}
          />
          <LinearLoaderWithTime
            labelSide="bottom"
            time={TIME_LOADER_GENERATING_CAMPAIGN_ADVANCED}
            max={99}
            labels={[
              t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
              t(CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION),
              t(CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS),
              t(CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS),
            ]}
          />
        </Box>
      )}
      {(platforms.length === 0 || isForcedSetupPage) && !isGenerating && (
        <SetupContainer />
      )}
      {PlatformComponent && !isGenerating && !isForcedSetupPage && (
        <Box
          sx={{
            display: "grid",
            placeItems: "flex-start",
            pb: 2,
            pr: 1,
            width: "100%",
            maxWidth: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {isAllCampaignsPlatformCreated && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              {statisticsLevel && (
                <StatisticsLevel statistics={statisticsLevel} />
              )}
            </Box>
          )}
          {levelSectionSelected === "platform" && platformSelectedIsCreated && (
            <ReportContainer />
          )}
          <PlatformComponent key={platformKey} />
        </Box>
      )}
    </Box>
  );
};

export default MainContent;
