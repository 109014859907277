import { useState } from "react";
import { BUTTON_EDIT_PLACEMENTS } from "../../../../i18n/keysTranslations";
import Button from "../../../Buttons/Button";
import { useTranslationApp } from "../../../../lib/i18next";
import ModalEditPlacementsMeta from "./ModalEditPlacementsMeta";
import { ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION } from "../../../../utils/constants";

const EditPlacementsMeta = ({
  adID,
  adPathForm,
  onCallbackSelect = () => {},
  disabled = false,
  destinationType = ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
}) => {
  const [modalEditPlacementsOpen, setModalEditPlacementsOpen] = useState(false);
  const { t } = useTranslationApp();

  return (
    <>
      {!disabled && (
        <Button
          type="button"
          variant="text"
          color="white"
          onClick={() => setModalEditPlacementsOpen(true)}
        >
          {t(BUTTON_EDIT_PLACEMENTS)}
        </Button>
      )}
      {modalEditPlacementsOpen && (
        <ModalEditPlacementsMeta
          modalOpen={modalEditPlacementsOpen}
          onCloseModal={() => setModalEditPlacementsOpen(false)}
          destinationType={destinationType}
          adID={adID}
          adPathForm={adPathForm}
          onCallbackSelect={onCallbackSelect}
        />
      )}
    </>
  );
};

export default EditPlacementsMeta;
