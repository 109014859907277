import {
  AD_ACCOUNT_PLATFORMS_LABELS,
  FONT_FAMILY,
  TIKTOK,
} from "../../../../utils/constants";
import Grid from "@mui/material/Grid";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { useFormContext } from "react-hook-form";
import { useTranslationApp } from "../../../../lib/i18next";
import SelectBusinessAccount from "../../../Meta/SelectBusinessAccount";
import {
  ALERT_CHANGE_AD_ACCOUNT_GENERAL_TITLE,
  ALERT_CHANGE_AD_ACCOUNT_TIKTOK_DESCRIPTION,
  BUTTON_CANCEL,
  BUTTON_CONTINUE,
  FIELD_REQUIRED,
  IDENTITY_TIK_TOK_PLACEHOLDER,
  OBJECTIVE_PLACEHOLDER,
} from "../../../../i18n/keysTranslations";
import { optionsAdsCampaignObjectives } from "../../../../utils/options";
import Select from "../../../Form/Select";
import SelectTikTokIdentityCustom from "../../../Form/SelectTikTokIdentityCustom";
import ReasoningContainer from "../../../../pages/NewAdsCampaign/components/Common/ReasoningContainer";
import { ConfirmAlert } from "../../../Alerts/Alerts";
import Typography from "@mui/material/Typography";

const platform = TIKTOK;
const grid = {
  xs: 12,
  lg: 6,
};

const CampaignTikTokForm = () => {
  const {
    control,
    formState,
    watch,
    setValue,
    getValues,
    isGeneralDisabledFields,
    getIsCampaignPlatformCreated,
  } = useFormContext();
  useFocusErrorForm(formState);
  const errors = formState.errors;

  const { t } = useTranslationApp();

  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);

  const basicPath = `platformsData.${platform}`;
  const platformData = watch(basicPath) || {};
  const objective = platformData?.objective;
  const reasoningPlatform = platformData?.reasoning;
  const oldReasoning = getValues("reasoning");

  const validationChangeAdAccount = async () => {
    const result = await ConfirmAlert({
      title: t(ALERT_CHANGE_AD_ACCOUNT_GENERAL_TITLE),
      html: (
        <Typography
          variant="body1"
          sx={{
            fontFamily: FONT_FAMILY,
            "& *": {
              p: "revert",
              m: "revert",
              listStyle: "revert",
              textDecoration: "revert",
            },
          }}
          dangerouslySetInnerHTML={{
            __html: t(ALERT_CHANGE_AD_ACCOUNT_TIKTOK_DESCRIPTION),
          }}
        />
      ),
      confirmButtonText: t(BUTTON_CONTINUE),
      cancelButtonText: t(BUTTON_CANCEL),
    });

    if (!result.isConfirmed) return false;

    return true;
  };

  const onChangeAdAccount = (value) => {
    setValue(`${basicPath}.integrationID`, value?.integrationID);
    setValue(`${basicPath}.identityTikTokID`, "");

    const adsGroups = getValues("adsGroups");
    adsGroups.forEach((adGroup, index) => {
      if (adGroup.platform !== TIKTOK) return;

      setValue(`adsGroups.[${index}].trackingID`, "");
      setValue(`adsGroups.[${index}].trackingCustomEventID`, "");
    });
  };

  return (
    <Grid container spacing={2}>
      {!isCampaignPlatformCreated && (
        <Grid item xs={12}>
          <ReasoningContainer reasoning={reasoningPlatform || oldReasoning} />
        </Grid>
      )}
      <Grid item {...grid}>
        <Select
          label={t(OBJECTIVE_PLACEHOLDER)}
          options={optionsAdsCampaignObjectives}
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
          name={`${basicPath}.objective`}
          variant="filled"
          fullWidth
          doTranslate={true}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectBusinessAccount
          variant="filled"
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
          label={t(AD_ACCOUNT_PLATFORMS_LABELS[platform])}
          control={control}
          platform={platform}
          name={`${basicPath}.adAccountID`}
          onChangeAction={onChangeAdAccount}
          validationChange={validationChangeAdAccount}
          errors={errors}
          fullWidth={true}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectTikTokIdentityCustom
          label={t(IDENTITY_TIK_TOK_PLACEHOLDER)}
          objective={objective}
          adAccountID={platformData?.adAccountID}
          integrationID={platformData?.integrationID}
          name={`${basicPath}.identityTikTokID`}
          fullWidth
          variant="filled"
          control={control}
          errors={errors}
          disabled={
            isCampaignPlatformCreated ||
            !platformData?.adAccountID ||
            isGeneralDisabledFields
          }
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CampaignTikTokForm;
