import { useEffect, useState } from "react";
import { useTranslationApp } from "../../lib/i18next";
import { useDispatchApp } from "../../lib/redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPathDatabaseByRole } from "../../actions/auth";
import {
  startGetAdsCampaign,
  startGetDetailedCampaignReport,
} from "../../actions/adsCampaigns";
import Loader from "../../components/Loaders/Loader";
import { LOADING_INFORMATION } from "../../i18n/keysTranslations";
import Box from "@mui/material/Box";
import { ADS_CAMPAIGN_GENERATE_DETAILED_REPORT_ACTION } from "../../utils/constants";
import { getIsBlockedActions } from "../../actions/getters";
import _ from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import useFocusErrorForm from "../../hooks/useFocusErrorForm";
import differenceInWeeks from "date-fns/differenceInWeeks";
import addWeeks from "date-fns/addWeeks";
import ContainerStatistics from "./components/ContainerStatistics";
import { getCostByMainCurrency as getCostByMainCurrencyFunc } from "../../services/adsCampaigns";

const getEndDateTime = (startDateTime) => {
  const now = new Date();
  const differenceWeeks = differenceInWeeks(now, startDateTime);
  if (differenceWeeks === 0) return addWeeks(startDateTime, 1);
  if (differenceWeeks >= 1 && differenceWeeks <= 4)
    return addWeeks(startDateTime, differenceWeeks);

  return addWeeks(startDateTime, 4);
};

const AdsCampaignStatistics = () => {
  const [campaign, setCampaign] = useState({});
  const [reportConfiguration, setReportConfiguration] = useState({});
  const [isEmptyReport, setIsEmptyReport] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isGeneratingReport, setIsGeneratingReport] = useState(true);
  const [isOpenChat, setIsOpenChat] = useState(true);
  const [currenciesConfiguration, setCurrenciesConfiguration] = useState({});
  const [insights, setInsights] = useState({});

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const campaignID = params.get("campaignID");
  const { userID } = dispatch(getPathDatabaseByRole());

  const methods = useForm({
    defaultValues: {
      dateRange: {
        startDateTime: null,
        endDateTime: null,
      },
      breakdown: "weekly",
    },
  });

  const { setValue, getValues } = methods;

  useFocusErrorForm(methods.formState);

  const getInitialData = async () => {
    setIsLoading(true);
    const campaignObject = await dispatch(startGetAdsCampaign(campaignID));

    if (!campaignObject) {
      navigate("/");
    }

    setCampaign(campaignObject);

    getDetailedCampaignReport();
    const startDateTime = campaignObject.firstActiveTime
      ? new Date(campaignObject.firstActiveTime)
      : new Date();
    const newReportRange = {
      startDateTime: startDateTime,
      endDateTime: getEndDateTime(startDateTime),
    };
    setReportConfiguration(newReportRange);
    setValue("dateRange", newReportRange);

    setIsLoading(false);
  };

  const getDetailedCampaignReport = async (isRegeneration) => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_DETAILED_REPORT_ACTION,
      })
    );

    if (isBlocked) {
      if (!isRegeneration) navigate(`/ads-campaign?campaignID=${campaignID}`);
      return;
    }

    setIsEmptyReport(false);
    setIsGeneratingReport(true);

    const dateRange = getValues("dateRange");

    const response = await dispatch(
      startGetDetailedCampaignReport({
        campaignID,
        breakdown: "weekly", // forced weekly to be able to switch between weekly / month in the UI
        startDateTime: new Date(dateRange.startDateTime).getTime(),
        endDateTime: new Date(dateRange.endDateTime).getTime(),
      })
    );
    if (response.ok) {
      const { statistics, mainCurrency, currenciesTax, insights } =
        response.data;
      if (_.isEmpty(statistics)) {
        setIsEmptyReport(true);
        setIsGeneratingReport(false);
        return;
      }
      setCampaign((prev) => ({
        ...prev,
        historicalStatistics: statistics,
      }));
      const newReportRange = {
        startDateTime: new Date(dateRange.startDateTime),
        endDateTime: new Date(dateRange.endDateTime),
      };
      setValue("dateRange", newReportRange);
      setReportConfiguration(newReportRange);
      setCurrenciesConfiguration({
        mainCurrency,
        currenciesTax,
      });
      setInsights(insights);
    } else {
      setIsEmptyReport(true);
    }
    setIsGeneratingReport(false);
  };

  const getCostByMainCurrency = ({ cost, currency }) => {
    return getCostByMainCurrencyFunc({
      cost,
      costCurrency: currency,
      mainCurrency: currenciesConfiguration.mainCurrency,
      currenciesTax: currenciesConfiguration.currenciesTax,
    });
  };
  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [campaignID, userID]);

  if (isLoading) {
    return (
      <Loader fullWidth hasMessage={true} message={t(LOADING_INFORMATION)} />
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      <FormProvider
        {...methods}
        campaign={campaign}
        campaignID={campaignID}
        reportConfiguration={reportConfiguration}
        isGeneratingReport={isGeneratingReport}
        isEmptyReport={isEmptyReport}
        isOpenChat={isOpenChat}
        onChangeShowChat={setIsOpenChat}
        getDetailedCampaignReport={getDetailedCampaignReport}
        getCostByMainCurrency={getCostByMainCurrency}
        insights={insights}
      >
        <ContainerStatistics />
      </FormProvider>
    </Box>
  );
};

export default AdsCampaignStatistics;
