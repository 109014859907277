import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useFormContext } from "react-hook-form";
import { getFromStatisticsAdsCampaign } from "../../../../../services/adsCampaigns";
import { META } from "../../../../../utils/constants";
import {
  VALUE_NUMBER,
  VALUE_WITH_PERCENTAGE,
  ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD_GROUP,
  ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD,
  IMPRESSIONS,
  RESULTS,
  CTR,
  CLICKS,
  AGE_RANGE_PLACEHOLDER,
  GENDERS_PLACEHOLDER,
  FEMALES,
  MALES,
  LOCATIONS_PLACEHOLDER,
  INTERESTS_PLACEHOLDER,
  DEMOGRAPHICS_PLACEHOLDER,
  BEHAVIORS_PLACEHOLDER,
  CUSTOM_AUDIENCES,
  CREATIVE,
  ROAS,
} from "../../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../../lib/i18next";
import { openLink } from "../../../../../utils/string";
import Grid from "@mui/material/Grid";
import InsightCard from "../components/InsightCard";
import CardMedia from "../../../../../components/Media/CardMedia";
import _ from "lodash";

const MetaPerformingAssets = () => {
  const { t } = useTranslationApp();
  const { campaign, campaignID } = useFormContext();
  const { statistics } = getFromStatisticsAdsCampaign(campaign);

  const getBestAdGroup = (statistics) => {
    if (!statistics) return null;

    return Object.entries(statistics).reduce((best, [adGroupId, stats]) => {
      if (!best || stats.mainAction > best.statistics.mainAction) {
        return {
          adGroupId,
          statistics: stats,
        };
      }
      return best;
    }, null);
  };

  const getBestAd = (adsGroups, statistics) => {
    if (!adsGroups || !statistics) return null;

    let bestAd = null;

    adsGroups.forEach((adGroup) => {
      if (adGroup.platform !== META) return;

      adGroup.ads.forEach((ad) => {
        const adStats = statistics[ad.id];
        if (!adStats) return;

        if (!bestAd || adStats.mainAction > bestAd.statistics.mainAction) {
          bestAd = {
            adGroupId: adGroup.id,
            adId: ad.id,
            statistics: adStats,
          };
        }
      });
    });

    return bestAd;
  };

  const bestAdGroup = getBestAdGroup(statistics[META].adsGroups);
  const bestAd = getBestAd(campaign.adsGroups, statistics[META].ads);

  const getAdGroupByID = (id) => {
    if (!id) {
      return {};
    }
    const adGroupMeta = campaign.adsGroups.filter(
      (adGroup) => adGroup.platform === META
    );
    const adGroup = adGroupMeta.find((adGroup) => adGroup.id === id);

    return { adGroup, adGroupID: adGroup.id };
  };

  const getAdByID = (adId) => {
    if (!adId) {
      return {};
    }
    const adGroupsMeta = campaign.adsGroups.filter(
      (adGroup) => adGroup.platform === META
    );

    const foundAdGroup = adGroupsMeta.find((adGroup) =>
      adGroup.ads.some((ad) => ad.id === adId)
    );

    if (!foundAdGroup) {
      return {};
    }

    const ad = foundAdGroup.ads.find((ad) => ad.id === adId);
    const adGroupIndex = adGroupsMeta.findIndex(
      (group) => group.id === foundAdGroup.id
    );
    const adGroupID = foundAdGroup.id;

    return {
      ad,
      adID: ad.id,
      adGroupIndex,
      adGroupID,
    };
  };

  console.log({ bestAdGroup, bestAd });

  const { adGroup = {}, adGroupID } = getAdGroupByID(bestAdGroup?.adGroupId);
  const { ad = {}, adID, adGroupID: adGroupIDAd } = getAdByID(bestAd?.adId);

  const bestAdGroupStats = !_.isEmpty(bestAdGroup)
    ? [
        {
          label: t(IMPRESSIONS),
          value: t(VALUE_NUMBER, { value: bestAdGroup.statistics.impressions }),
        },
        {
          label: t(CLICKS),
          value: t(VALUE_NUMBER, { value: bestAdGroup.statistics.click }),
        },
        {
          label: t(CTR),
          value: t(VALUE_WITH_PERCENTAGE, {
            value: bestAdGroup.statistics.ctr,
          }),
        },
        {
          label: t(RESULTS),
          value: t(VALUE_NUMBER, { value: bestAdGroup.statistics.mainAction }),
        },
      ]
    : [];

  const bestAdStats = !_.isEmpty(bestAd)
    ? [
        {
          label: t(IMPRESSIONS),
          value: t(VALUE_NUMBER, { value: bestAd.statistics.impressions }),
        },
        {
          label: t(CLICKS),
          value: t(VALUE_NUMBER, { value: bestAd.statistics.click }),
        },
        {
          label: t(CTR),
          value: t(VALUE_WITH_PERCENTAGE, { value: bestAd.statistics.ctr }),
        },
        {
          label: t(RESULTS),
          value: t(VALUE_NUMBER, { value: bestAd.statistics.mainAction }),
        },
      ]
    : [];

  return (
    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
      {!_.isEmpty(bestAdGroup) && (
        <InsightCard
          platform={META}
          title={t(ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD_GROUP)}
          subtitle={adGroup.name}
          onSubtitleClick={() =>
            openLink(
              `ads-campaign?campaignID=${campaignID}&platform=${META}&adGroupID=${adGroupID}`
            )
          }
          statistics={
            bestAdGroup.statistics.roas
              ? [
                  ...bestAdGroupStats,
                  {
                    label: t(ROAS),
                    value: t(VALUE_NUMBER, {
                      value: bestAdGroup.statistics.roas,
                    }),
                  },
                ]
              : bestAdGroupStats
          }
          previousContent={<BestAdGroupSegmentation adGroup={adGroup} />}
        />
      )}

      {!_.isEmpty(bestAd) && (
        <InsightCard
          platform={META}
          title={t(ADS_CAMPAIGNS_STATISTICS_INSIGHTS_BEST_PERFORMING_AD)}
          subtitle={ad.name}
          onSubtitleClick={() =>
            openLink(
              `ads-campaign?campaignID=${campaignID}&platform=${META}&adGroupID=${adGroupIDAd}&adID=${adID}`
            )
          }
          statistics={bestAdStats}
          nextContent={<BestAdCreative ad={ad} />}
        />
      )}
    </Box>
  );
};

const SEGMENTATION_FIELDS = [
  "ageRange",
  "genders",
  "locations",
  "interests",
  "demographics",
  "behaviors",
  "customAudiences",
];
const BestAdGroupSegmentation = ({ adGroup = {} }) => {
  const { t } = useTranslationApp();

  const getSegmentationFields = () => {
    const segmentationFieldsData = [];

    SEGMENTATION_FIELDS.forEach((field) => {
      const fieldData = adGroup[field];
      if (!fieldData || fieldData?.length === 0) return;
      if (field === "ageRange") {
        const [minAge, maxAge] = fieldData || [];
        segmentationFieldsData.push({
          label: t(AGE_RANGE_PLACEHOLDER),
          value: `${minAge}-${maxAge}`,
        });
      } else if (field === "genders") {
        const genders = fieldData || {};
        segmentationFieldsData.push({
          label: t(GENDERS_PLACEHOLDER),
          value: Object.values(genders)
            .filter(Boolean)
            .map((gender) => t(gender === "male" ? MALES : FEMALES))
            .join(", "),
        });
      } else {
        const value = fieldData.map((item) => item.name).join(", ");
        let label = "";
        switch (field) {
          case "locations":
            label = t(LOCATIONS_PLACEHOLDER);
            break;
          case "interests":
            label = t(INTERESTS_PLACEHOLDER);
            break;
          case "demographics":
            label = t(DEMOGRAPHICS_PLACEHOLDER);
            break;
          case "behaviors":
            label = t(BEHAVIORS_PLACEHOLDER);
            break;
          case "customAudiences":
            label = t(CUSTOM_AUDIENCES);
            break;
        }

        segmentationFieldsData.push({
          label,
          value,
        });
      }
    });

    return segmentationFieldsData;
  };

  const segmentationFields = getSegmentationFields();

  return (
    <Grid container spacing={1} sx={{ mb: 2 }}>
      {segmentationFields.map((field) => (
        <Grid item xs={6} key={field.label}>
          <Box>
            <Typography
              variant="caption"
              color="fields.placeholder"
              component="p"
            >
              {field.label}
            </Typography>
            <Typography variant="body2">{field.value}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

const BestAdCreative = ({ ad = {} }) => {
  const { t } = useTranslationApp();

  const firstMediaUrl = ad?.mediaUrls?.[0];

  if (!firstMediaUrl) return null;

  return (
    <Box>
      <Typography variant="subtitle1" color="fields.placeholder">
        {t(CREATIVE)}
      </Typography>
      <CardMedia
        url={firstMediaUrl}
        videoComponent="native"
        sx={{
          width: "auto",
          height: 200,
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

export default MetaPerformingAssets;
