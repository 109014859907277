import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { GoogleMapsContext, latLngEquals } from "@vis.gl/react-google-maps";

const useCircle = (props) => {
  const {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onRadiusChanged,
    onCenterChanged,
    radius,
    center,
    ...circleOptions
  } = props;

  // Evita re-ejecuciones innecesarias de useEffect por cambios en los callbacks
  const callbacks = useRef({});
  Object.assign(callbacks.current, {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onRadiusChanged,
    onCenterChanged,
  });

  const circleRef = useRef(new window.google.maps.Circle());
  const circle = circleRef.current;

  // Actualiza las opciones de la circunferencia
  circle.setOptions(circleOptions);

  useEffect(() => {
    if (!center) return;
    if (!latLngEquals(center, circle.getCenter())) {
      circle.setCenter(center);
    }
  }, [center, circle]);

  useEffect(() => {
    if (radius === undefined || radius === null) return;
    if (radius !== circle.getRadius()) {
      circle.setRadius(radius);
    }
  }, [radius, circle]);

  const map = useContext(GoogleMapsContext)?.map;

  useEffect(() => {
    if (!map) {
      if (map === undefined) {
        console.error("<Circle> has to be inside a Map component.");
      }
      return;
    }

    circle.setMap(map);

    return () => {
      circle.setMap(null);
    };
  }, [map, circle]);

  useEffect(() => {
    if (!circle) return;

    const gme = window.google.maps.event;
    [
      ["click", "onClick"],
      ["drag", "onDrag"],
      ["dragstart", "onDragStart"],
      ["dragend", "onDragEnd"],
      ["mouseover", "onMouseOver"],
      ["mouseout", "onMouseOut"],
    ].forEach(([eventName, eventCallback]) => {
      gme.addListener(circle, eventName, (e) => {
        const callback = callbacks.current[eventCallback];
        if (callback) callback(e);
      });
    });

    gme.addListener(circle, "radius_changed", () => {
      const newRadius = circle.getRadius();
      if (callbacks.current.onRadiusChanged) {
        callbacks.current.onRadiusChanged(newRadius);
      }
    });

    gme.addListener(circle, "center_changed", () => {
      const newCenter = circle.getCenter();
      if (callbacks.current.onCenterChanged) {
        callbacks.current.onCenterChanged(newCenter);
      }
    });

    return () => {
      gme.clearInstanceListeners(circle);
    };
  }, [circle]);

  return circle;
};

const Circle = forwardRef((props, ref) => {
  const circle = useCircle(props);
  useImperativeHandle(ref, () => circle);
  return null;
});

Circle.displayName = "Circle";

export default Circle;
