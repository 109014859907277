import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { GoogleMapsContext, useMapsLibrary } from "@vis.gl/react-google-maps";

const usePolyline = (props) => {
  const {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    encodedPath,
    ...polylineOptions
  } = props;

  // Se utiliza para evitar que los cambios en los callbacks disparen efectos innecesarios
  const callbacks = useRef({});
  Object.assign(callbacks.current, {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
  });

  const geometryLibrary = useMapsLibrary("geometry");

  const polylineRef = useRef(new window.google.maps.Polyline());
  const polyline = polylineRef.current;

  // Actualiza las opciones del polyline
  useMemo(() => {
    polyline.setOptions(polylineOptions);
  }, [polyline, polylineOptions]);

  const map = useContext(GoogleMapsContext)?.map;

  // Actualiza la propiedad "path" usando la ruta codificada (si está disponible)
  useMemo(() => {
    if (!encodedPath || !geometryLibrary) return;
    const path = geometryLibrary.encoding.decodePath(encodedPath);
    polyline.setPath(path);
  }, [polyline, encodedPath, geometryLibrary]);

  // Añade la instancia de polyline al mapa cuando éste esté disponible
  useEffect(() => {
    if (!map) {
      if (map === undefined)
        console.error("<Polyline> has to be inside a Map component.");
      return;
    }

    polyline.setMap(map);

    return () => {
      polyline.setMap(null);
    };
  }, [map, polyline]);

  // Adjunta y re-adjunta los manejadores de eventos
  useEffect(() => {
    if (!polyline) return;

    const gme = window.google.maps.event;
    [
      ["click", "onClick"],
      ["drag", "onDrag"],
      ["dragstart", "onDragStart"],
      ["dragend", "onDragEnd"],
      ["mouseover", "onMouseOver"],
      ["mouseout", "onMouseOut"],
    ].forEach(([eventName, eventCallback]) => {
      gme.addListener(polyline, eventName, (e) => {
        const callback = callbacks.current[eventCallback];
        if (callback) callback(e);
      });
    });

    return () => {
      gme.clearInstanceListeners(polyline);
    };
  }, [polyline]);

  return polyline;
};

const Polyline = forwardRef((props, ref) => {
  const polyline = usePolyline(props);
  useImperativeHandle(ref, () => polyline, [polyline]);
  return null;
});

Polyline.displayName = "Polyline";

export default Polyline;
