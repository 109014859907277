import {
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_SALES,
  ADS_CAMPAIGN_OBJECTIVE_SALES_TITLE_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU,
  ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP,
  ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE,
  ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US,
  ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_DIRECTIONS,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES,
  ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_SEND_MESSAGE,
  ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME,
  ADS_CAMPAIGN_CALL_TO_ACTION_SAVE,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER,
  ADS_CAMPAIGN_CALL_TO_ACTION_INQUIRE_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_BUY_TICKETS,
  ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME,
  ADS_CAMPAIGN_CALL_TO_ACTION_EXPLORE_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_USE_APP,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_TITLE_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME,
  ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_TITLE_EXPLANATION,
  ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_DESCRIPTION_EXPLANATION,
  SPANISH,
  ENGLISH,
  ADS_CAMPAIGN_CUSTOM_AUDIENCE_TYPE_WEBSITE,
  ADS_CAMPAIGN_CUSTOM_AUDIENCE_TYPE_CUSTOMER_LIST,
  ADS_CAMPAIGNS_CUSTOM_AUDIENCE_TYPE_WEBSITE_TITLE_EXPLANATION,
  ADS_CAMPAIGNS_CUSTOM_AUDIENCE_TYPE_WEBSITE_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGNS_CUSTOM_AUDIENCE_TYPE_CUSTOMER_LIST_TITLE_EXPLANATION,
  ADS_CAMPAIGNS_CUSTOM_AUDIENCE_TYPE_CUSTOMER_LIST_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_READ_MORE,
  ADS_CAMPAIGN_CALL_TO_ACTION_VIEW_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_INTERESTED,
  ADS_CAMPAIGN_CALL_TO_ACTION_GET_TICKETS_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_EXPERIENCE_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_PRE_ORDER_NOW,
  ADS_CAMPAIGN_CALL_TO_ACTION_VISIT_STORE,
  ADS_CAMPAIGN_CUSTOM_EVENT_COMPLETE_REGISTRATION,
  ADS_CAMPAIGN_CUSTOM_EVENT_CONTACT,
  ADS_CAMPAIGN_CUSTOM_EVENT_FIND_LOCATION,
  ADS_CAMPAIGN_CUSTOM_EVENT_LEAD,
  ADS_CAMPAIGN_CUSTOM_EVENT_SCHEDULE,
  ADS_CAMPAIGN_CUSTOM_EVENT_SEARCH,
  ADS_CAMPAIGN_CUSTOM_EVENT_START_TRIAL,
  ADS_CAMPAIGN_CUSTOM_EVENT_SUBMIT_APPLICATION,
  ADS_CAMPAIGN_CUSTOM_EVENT_SUBSCRIBE,
  ADS_CAMPAIGN_CUSTOM_EVENT_CONTENT_VIEW,
  ADS_CAMPAIGN_CUSTOM_EVENT_ADD_TO_CART,
  ADS_CAMPAIGN_CUSTOM_EVENT_ADD_TO_WISHLIST,
  ADS_CAMPAIGN_CUSTOM_EVENT_DONATE,
  ADS_CAMPAIGN_CUSTOM_EVENT_INITIATED_CHECKOUT,
  ADS_CAMPAIGN_CUSTOM_EVENT_PURCHASE,
  ADS_CAMPAIGN_CUSTOM_EVENT_ADD_PAYMENT_INFO,
  ADS_CAMPAIGN_CUSTOM_EVENT_CUSTOMIZE_PRODUCT,
  ADS_CAMPAIGN_CUSTOM_EVENT_CLICK_BUTTON,
  ADS_CAMPAIGN_CUSTOM_EVENT_SUBMIT_FORM,
  ADS_CAMPAIGN_CUSTOM_EVENT_PLACE_ORDER,
  PORTUGUESE,
  FRENCH,
  RUSSIAN,
  JAPANESE,
  MANDARIN,
  HINDI,
  ARABIC,
  GERMAN,
  KOREAN,
  INDONESIAN,
  TURKISH,
  CROATIAN,
  SLOVAK,
  BULGARIAN,
  DANISH,
  ADS_CAMPAIGN_TYPE_SEARCH_TITLE_EXPLANATION,
  ADS_CAMPAIGN_TYPE_SEARCH_DESCRIPTION_EXPLANATION,
  // ADS_CAMPAIGN_TYPE_SHOPPING_TITLE_EXPLANATION,
  // ADS_CAMPAIGN_TYPE_SHOPPING_DESCRIPTION_EXPLANATION,
  // ADS_CAMPAIGN_TYPE_VIDEO_DESCRIPTION_EXPLANATION,
  // ADS_CAMPAIGN_TYPE_VIDEO_TITLE_EXPLANATION,
  ADS_CAMPAIGN_TYPE_SEARCH,
  // ADS_CAMPAIGN_TYPE_SHOPPING,
  // ADS_CAMPAIGN_TYPE_VIDEO,
  ADS_META_DESTINATION_TYPE_WEBSITE,
  ADS_META_DESTINATION_TYPE_INSTAGRAM_PROFILE,
  ADVERTISER,
  MARKETER,
  VIEWER,
  ADVERTISER_ROLE_TITLE_EXPLANATION,
  ADVERTISER_ROLE_DESCRIPTION_EXPLANATION,
  MARKETER_ROLE_TILE_EXPLANATION,
  MARKETER_ROLE_DESCRIPTION_EXPLANATION,
  VIEWER_ROLE_TITLE_EXPLANATION,
  VIEWER_ROLE_DESCRIPTION_EXPLANATION,
  ADS_META_DESTINATION_TYPE_ON_VIDEO,
  ADS_META_DESTINATION_TYPE_ON_POST,
  ADS_CAMPAIGN_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE,
  ADS_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM,
  ADS_META_DESTINATION_TYPE_MESSAGES_ON_MESSENGER,
  ADS_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM_AND_MESSENGER,
  ADS_META_DESTINATION_TYPE_INSTANT_FORM,
  ADS_META_DESTINATION_TYPE_WHATSAPP,
  ADS_CAMPAIGN_REPORT_BREAKDOWN_WEEKLY,
  ADS_CAMPAIGN_REPORT_BREAKDOWN_MONTHLY,
  UKRAINIAN,
  KEYWORD_BROAD_MATCH_TYPE_OPTION,
  KEYWORD_PHRASE_MATCH_TYPE_OPTION,
  KEYWORD_EXACT_MATCH_TYPE_OPTION,
  ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX,
  ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_TITLE_EXPLANATION,
  ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_DESCRIPTION_EXPLANATION,
  ACTIVE,
  DEACTIVATED,
} from "../i18n/keysTranslations";
import {
  ADS_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE_META_VALUE,
  ADS_CAMPAIGN_CUSTOM_AUDIENCE_TYPE_CUSTOMER_LIST_OPTION,
  ADS_CAMPAIGN_CUSTOM_AUDIENCE_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM_AND_MESSENGER_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_MESSAGES_ON_MESSAGE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_VIDEO_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  ADVERTISER_ROLE,
  MARKETER_ROLE,
  VIEWER_ROLE,
  ADS_CAMPAIGN_TYPE_SEARCH_OPTION,
  ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_OPTION,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  // ADS_CAMPAIGN_TYPE_SHOPPING_OPTION,
  // ADS_CAMPAIGN_TYPE_VIDEO_OPTION,
} from "./constants";

export const optionsAdsCampaignStatus = [
  {
    label: ACTIVE,
    value: ADS_CAMPAIGN_STATUS_ACTIVE,
  },
  {
    label: DEACTIVATED,
    value: ADS_CAMPAIGN_STATUS_DEACTIVATED,
  },
];

export const optionsAdsCampaignObjectives = [
  {
    value: ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION,
    label: ADS_CAMPAIGN_OBJECTIVE_AWARENESS,
    title: ADS_CAMPAIGN_OBJECTIVE_AWARENESS_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_OBJECTIVE_AWARENESS_DESCRIPTION_EXPLANATION,
  },
  {
    value: ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION,
    label: ADS_CAMPAIGN_OBJECTIVE_TRAFFIC,
    title: ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_DESCRIPTION_EXPLANATION,
  },
  {
    value: ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
    label: ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT,
    title: ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_DESCRIPTION_EXPLANATION,
  },
  {
    value: ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
    label: ADS_CAMPAIGN_OBJECTIVE_LEADS,
    title: ADS_CAMPAIGN_OBJECTIVE_LEADS_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_OBJECTIVE_LEADS_DESCRIPTION_EXPLANATION,
  },
  // {
  //   value: ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_OPTION,
  //   label: ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION,
  //   title: ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_TITLE_EXPLANATION,
  //   explanation: ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_DESCRIPTION_EXPLANATION,
  // },
  {
    value: ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
    label: ADS_CAMPAIGN_OBJECTIVE_SALES,
    title: ADS_CAMPAIGN_OBJECTIVE_SALES_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_OBJECTIVE_SALES_DESCRIPTION_EXPLANATION,
  },
];

export const optionsCallToActionAwarenessMeta = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "SEE_MENU", label: ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "WATCH_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "DOWNLOAD", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  {
    value: "GET_DIRECTIONS",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_DIRECTIONS,
  },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "SEND_MESSAGE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SEND_MESSAGE },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "REQUEST_TIME", label: ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME },
  { value: "SAVE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SAVE },
];

export const optionsCallToActionTrafficMeta = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "GET_OFFER", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "DOWNLOAD", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  {
    value: ADS_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE_META_VALUE,
    label: ADS_CAMPAIGN_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE,
  },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "REQUEST_TIME", label: ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME },
  { value: "SEE_MENU", label: ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "WATCH_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE },
];

export const optionsCallToActionEngagementMeta = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "INQUIRE_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_INQUIRE_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
];

export const optionsCallToActionLeadsMeta = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "GET_OFFER", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "BUY_TICKETS", label: ADS_CAMPAIGN_CALL_TO_ACTION_BUY_TICKETS },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "DOWNLOAD", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  {
    value: ADS_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE_META_VALUE,
    label: ADS_CAMPAIGN_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE,
  },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "REQUEST_TIME", label: ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME },
  { value: "SEE_MENU", label: ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "WATCH_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE },
];

export const optionsCallToActionLeadsInstantFormMeta = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "DOWNLOAD", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "GET_OFFER", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
];

export const optionsCallToActionAppPromotionMeta = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "EXPLORE_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_EXPLORE_MORE },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "INSTALL_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW },
  { value: "WATCH_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE },
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "USE_APP", label: ADS_CAMPAIGN_CALL_TO_ACTION_USE_APP },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "DOWNLOAD", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  {
    value: ADS_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE_META_VALUE,
    label: ADS_CAMPAIGN_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE,
  },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
];

export const optionsCallToActionSalesMeta = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "DOWNLOAD", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "REQUEST_TIME", label: ADS_CAMPAIGN_CALL_TO_ACTION_REQUEST_TIME },
  { value: "SEE_MENU", label: ADS_CAMPAIGN_CALL_TO_ACTION_SEE_MENU },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  { value: "WATCH_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_MORE },
  { value: "BUY_TICKETS", label: ADS_CAMPAIGN_CALL_TO_ACTION_BUY_TICKETS },
  { value: "GET_OFFER", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_OFFER },
];

export const marksAge = [
  {
    value: 18,
    label: "18",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
];

export const optionsAdsCampaignTypeBudgets = [
  {
    value: ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
    label: ADS_CAMPAIGN_TYPE_BUDGET_DAILY,
    title: ADS_CAMPAIGN_TYPE_BUDGET_DAILY_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_TYPE_BUDGET_DAILY_DESCRIPTION_EXPLANATION,
  },
  {
    value: ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
    label: ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME,
    title: ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_TYPE_BUDGET_TOTAL_DESCRIPTION_EXPLANATION,
  },
];

export const optionsLanguage = [
  { value: "es", label: SPANISH },
  { value: "en", label: ENGLISH },
  { value: "pt", label: PORTUGUESE },
  { value: "fr", label: FRENCH },
  { value: "ru", label: RUSSIAN },
  { value: "ja", label: JAPANESE },
  { value: "zh", label: MANDARIN },
  { value: "hi", label: HINDI },
  { value: "ar", label: ARABIC },
  { value: "de", label: GERMAN },
  { value: "ko", label: KOREAN },
  { value: "da", label: DANISH },
  { value: "bg", label: BULGARIAN },
  { value: "sk", label: SLOVAK },
  { value: "hr", label: CROATIAN },
  { value: "id", label: INDONESIAN },
  { value: "tr", label: TURKISH },
  { value: "uk", label: UKRAINIAN },
];

export const optionsCustomAudiencesMeta = [
  {
    value: ADS_CAMPAIGN_CUSTOM_AUDIENCE_TYPE_WEBSITE_OPTION,
    label: ADS_CAMPAIGN_CUSTOM_AUDIENCE_TYPE_WEBSITE,
    title: ADS_CAMPAIGNS_CUSTOM_AUDIENCE_TYPE_WEBSITE_TITLE_EXPLANATION,
    explanation:
      ADS_CAMPAIGNS_CUSTOM_AUDIENCE_TYPE_WEBSITE_DESCRIPTION_EXPLANATION,
  },
  {
    value: ADS_CAMPAIGN_CUSTOM_AUDIENCE_TYPE_CUSTOMER_LIST_OPTION,
    label: ADS_CAMPAIGN_CUSTOM_AUDIENCE_TYPE_CUSTOMER_LIST,
    title: ADS_CAMPAIGNS_CUSTOM_AUDIENCE_TYPE_CUSTOMER_LIST_TITLE_EXPLANATION,
    explanation:
      ADS_CAMPAIGNS_CUSTOM_AUDIENCE_TYPE_CUSTOMER_LIST_DESCRIPTION_EXPLANATION,
  },
];

export const optionsCallToActionAwarenessTikTokBusiness = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "DOWNLOAD_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "WATCH_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_NOW },
  { value: "READ_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_READ_MORE },
  { value: "VIEW_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_VIEW_NOW },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "INSTALL_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "INTERESTED", label: ADS_CAMPAIGN_CALL_TO_ACTION_INTERESTED },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  {
    value: "GET_TICKETS_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_TICKETS_NOW,
  },
  {
    value: "EXPERIENCE_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_EXPERIENCE_NOW,
  },
  { value: "PREORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_PRE_ORDER_NOW },
  { value: "VISIT_STORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_VISIT_STORE },
];

export const optionsCallToActionTrafficTikTokBusiness = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "INTERESTED", label: ADS_CAMPAIGN_CALL_TO_ACTION_INTERESTED },
  { value: "VISIT_STORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_VISIT_STORE },
  { value: "WATCH_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "DOWNLOAD_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "READ_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_READ_MORE },
  { value: "VIEW_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_VIEW_NOW },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "INSTALL_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  {
    value: "GET_TICKETS_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_TICKETS_NOW,
  },
  {
    value: "EXPERIENCE_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_EXPERIENCE_NOW,
  },
  { value: "PREORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_PRE_ORDER_NOW },
];

export const optionsCallToActionEngagementTikTokBusiness = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "DOWNLOAD_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "WATCH_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_NOW },
  { value: "READ_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_READ_MORE },
  { value: "VIEW_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_VIEW_NOW },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "INSTALL_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "INTERESTED", label: ADS_CAMPAIGN_CALL_TO_ACTION_INTERESTED },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  {
    value: "GET_TICKETS_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_TICKETS_NOW,
  },
  {
    value: "EXPERIENCE_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_EXPERIENCE_NOW,
  },
  { value: "PREORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_PRE_ORDER_NOW },
  { value: "VISIT_STORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_VISIT_STORE },
];

export const optionsCallToActionLeadsTikTokBusiness = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "INTERESTED", label: ADS_CAMPAIGN_CALL_TO_ACTION_INTERESTED },
  { value: "VISIT_STORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_VISIT_STORE },
  { value: "WATCH_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "DOWNLOAD_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "READ_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_READ_MORE },
  { value: "VIEW_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_VIEW_NOW },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "INSTALL_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  {
    value: "GET_TICKETS_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_TICKETS_NOW,
  },
  {
    value: "EXPERIENCE_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_EXPERIENCE_NOW,
  },
  { value: "PREORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_PRE_ORDER_NOW },
];

export const optionsCallToActionAppPromotionTikTokBusiness = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "WATCH_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "INSTALL_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "DOWNLOAD_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "READ_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_READ_MORE },
  { value: "VIEW_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_VIEW_NOW },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "INTERESTED", label: ADS_CAMPAIGN_CALL_TO_ACTION_INTERESTED },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  {
    value: "GET_TICKETS_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_TICKETS_NOW,
  },
  {
    value: "EXPERIENCE_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_EXPERIENCE_NOW,
  },
  { value: "PREORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_PRE_ORDER_NOW },
];

export const optionsCallToActionSalesTikTokBusiness = [
  { value: "LEARN_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_LEARN_MORE },
  { value: "INTERESTED", label: ADS_CAMPAIGN_CALL_TO_ACTION_INTERESTED },
  { value: "VISIT_STORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_VISIT_STORE },
  { value: "WATCH_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_WATCH_NOW },
  { value: "SIGN_UP", label: ADS_CAMPAIGN_CALL_TO_ACTION_SIGN_UP },
  { value: "LISTEN_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_LISTEN_NOW },
  { value: "ORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_ORDER_NOW },
  { value: "BOOK_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_BOOK_NOW },
  { value: "SHOP_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_SHOP_NOW },
  { value: "PLAY_GAME", label: ADS_CAMPAIGN_CALL_TO_ACTION_PLAY_GAME },
  { value: "DOWNLOAD_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_DOWNLOAD },
  { value: "CONTACT_US", label: ADS_CAMPAIGN_CALL_TO_ACTION_CONTACT_US },
  { value: "APPLY_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_APPLY_NOW },
  { value: "READ_MORE", label: ADS_CAMPAIGN_CALL_TO_ACTION_READ_MORE },
  { value: "VIEW_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_VIEW_NOW },
  { value: "GET_QUOTE", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_QUOTE },
  { value: "INSTALL_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_INSTALL_NOW },
  { value: "GET_SHOWTIMES", label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_SHOWTIMES },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CALL_TO_ACTION_SUBSCRIBE },
  {
    value: "GET_TICKETS_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_GET_TICKETS_NOW,
  },
  {
    value: "EXPERIENCE_NOW",
    label: ADS_CAMPAIGN_CALL_TO_ACTION_EXPERIENCE_NOW,
  },
  { value: "PREORDER_NOW", label: ADS_CAMPAIGN_CALL_TO_ACTION_PRE_ORDER_NOW },
];

export const optionsCustomEventsEngagement = [
  {
    value: "ADD_TO_WISHLIST",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_ADD_TO_WISHLIST,
  },
  { value: "CONTACT", label: ADS_CAMPAIGN_CUSTOM_EVENT_CONTACT },
  {
    value: "CUSTOMIZE_PRODUCT",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_CUSTOMIZE_PRODUCT,
  },
  { value: "DONATE", label: ADS_CAMPAIGN_CUSTOM_EVENT_DONATE },
  { value: "FIND_LOCATION", label: ADS_CAMPAIGN_CUSTOM_EVENT_FIND_LOCATION },
  { value: "SCHEDULE", label: ADS_CAMPAIGN_CUSTOM_EVENT_SCHEDULE },
  { value: "SEARCH", label: ADS_CAMPAIGN_CUSTOM_EVENT_SEARCH },
  { value: "START_TRIAL", label: ADS_CAMPAIGN_CUSTOM_EVENT_START_TRIAL },
  {
    value: "SUBMIT_APPLICATION",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_SUBMIT_APPLICATION,
  },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CUSTOM_EVENT_SUBSCRIBE },
  { value: "CONTENT_VIEW", label: ADS_CAMPAIGN_CUSTOM_EVENT_CONTENT_VIEW },
];

export const optionsCustomEventsLeadsMeta = [
  {
    value: "COMPLETE_REGISTRATION",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_COMPLETE_REGISTRATION,
  },
  { value: "CONTACT", label: ADS_CAMPAIGN_CUSTOM_EVENT_CONTACT },
  { value: "FIND_LOCATION", label: ADS_CAMPAIGN_CUSTOM_EVENT_FIND_LOCATION },
  { value: "LEAD", label: ADS_CAMPAIGN_CUSTOM_EVENT_LEAD },
  { value: "SCHEDULE", label: ADS_CAMPAIGN_CUSTOM_EVENT_SCHEDULE },
  { value: "SEARCH", label: ADS_CAMPAIGN_CUSTOM_EVENT_SEARCH },
  { value: "START_TRIAL", label: ADS_CAMPAIGN_CUSTOM_EVENT_START_TRIAL },
  {
    value: "SUBMIT_APPLICATION",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_SUBMIT_APPLICATION,
  },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CUSTOM_EVENT_SUBSCRIBE },
  { value: "CONTENT_VIEW", label: ADS_CAMPAIGN_CUSTOM_EVENT_CONTENT_VIEW },
];
export const optionsCustomEventSalesMeta = [
  {
    value: "ADD_PAYMENT_INFO",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_ADD_PAYMENT_INFO,
  },
  { value: "ADD_TO_CART", label: ADS_CAMPAIGN_CUSTOM_EVENT_ADD_TO_CART },
  {
    value: "ADD_TO_WISHLIST",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_ADD_TO_WISHLIST,
  },
  {
    value: "COMPLETE_REGISTRATION",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_COMPLETE_REGISTRATION,
  },
  { value: "DONATE", label: ADS_CAMPAIGN_CUSTOM_EVENT_DONATE },
  {
    value: "INITIATED_CHECKOUT",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_INITIATED_CHECKOUT,
  },
  { value: "PURCHASE", label: ADS_CAMPAIGN_CUSTOM_EVENT_PURCHASE },
  { value: "SEARCH", label: ADS_CAMPAIGN_CUSTOM_EVENT_SEARCH },
  { value: "START_TRIAL", label: ADS_CAMPAIGN_CUSTOM_EVENT_START_TRIAL },
  { value: "SUBSCRIBE", label: ADS_CAMPAIGN_CUSTOM_EVENT_SUBSCRIBE },
  { value: "CONTENT_VIEW", label: ADS_CAMPAIGN_CUSTOM_EVENT_CONTENT_VIEW },
];

export const optionsCustomEventsLeadsTikTok = [
  {
    value: "ON_WEB_ADD_TO_WISHLIST",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_ADD_TO_WISHLIST,
  },
  { value: "ON_WEB_SEARCH", label: ADS_CAMPAIGN_CUSTOM_EVENT_SEARCH },
  { value: "BUTTON", label: ADS_CAMPAIGN_CUSTOM_EVENT_CLICK_BUTTON },
  { value: "CONSULT", label: ADS_CAMPAIGN_CUSTOM_EVENT_CONTACT },
  { value: "FORM", label: ADS_CAMPAIGN_CUSTOM_EVENT_SUBMIT_FORM },
  {
    value: "ON_WEB_REGISTER",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_COMPLETE_REGISTRATION,
  },
  { value: "ON_WEB_SUBSCRIBE", label: ADS_CAMPAIGN_CUSTOM_EVENT_SUBSCRIBE },
  { value: "ON_WEB_DETAIL", label: ADS_CAMPAIGN_CUSTOM_EVENT_CONTENT_VIEW },
];
export const optionsCustomEventsSalesTikTok = [
  {
    value: "SHOPPING",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_PURCHASE,
  },
  {
    value: "ON_WEB_CART",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_ADD_TO_CART,
  },
  {
    value: "ADD_BILLING",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_ADD_PAYMENT_INFO,
  },
  {
    value: "ON_WEB_ORDER",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_PLACE_ORDER,
  },
  {
    value: "ON_WEB_ADD_TO_WISHLIST",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_ADD_TO_WISHLIST,
  },
  { value: "ON_WEB_SEARCH", label: ADS_CAMPAIGN_CUSTOM_EVENT_SEARCH },
  { value: "BUTTON", label: ADS_CAMPAIGN_CUSTOM_EVENT_CLICK_BUTTON },
  { value: "CONSULT", label: ADS_CAMPAIGN_CUSTOM_EVENT_CONTACT },
  {
    value: "ON_WEB_REGISTER",
    label: ADS_CAMPAIGN_CUSTOM_EVENT_COMPLETE_REGISTRATION,
  },
  { value: "ON_WEB_SUBSCRIBE", label: ADS_CAMPAIGN_CUSTOM_EVENT_SUBSCRIBE },
  { value: "ON_WEB_DETAIL", label: ADS_CAMPAIGN_CUSTOM_EVENT_CONTENT_VIEW },
];

export const optionsCampaignsTypeGoogleAds = [
  {
    value: ADS_CAMPAIGN_TYPE_SEARCH_OPTION,
    label: ADS_CAMPAIGN_TYPE_SEARCH,
    title: ADS_CAMPAIGN_TYPE_SEARCH_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_TYPE_SEARCH_DESCRIPTION_EXPLANATION,
  },
  {
    value: ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_OPTION,
    label: ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX,
    title: ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_TITLE_EXPLANATION,
    explanation: ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_DESCRIPTION_EXPLANATION,
  },
  // {
  //   value: ADS_CAMPAIGN_TYPE_SHOPPING_OPTION,
  //   label: ADS_CAMPAIGN_TYPE_SHOPPING,
  //   title: ADS_CAMPAIGN_TYPE_SHOPPING_TITLE_EXPLANATION,
  //   explanation: ADS_CAMPAIGN_TYPE_SHOPPING_DESCRIPTION_EXPLANATION,
  // },
  // {
  //   value: ADS_CAMPAIGN_TYPE_VIDEO_OPTION,
  //   label: ADS_CAMPAIGN_TYPE_VIDEO,
  //   title: ADS_CAMPAIGN_TYPE_VIDEO_TITLE_EXPLANATION,
  //   explanation: ADS_CAMPAIGN_TYPE_VIDEO_DESCRIPTION_EXPLANATION,
  // },
];

export const optionsMetaDestinationTypeTrafficObjective = [
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
    label: ADS_META_DESTINATION_TYPE_WEBSITE,
  },
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION,
    label: ADS_META_DESTINATION_TYPE_WHATSAPP,
  },
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION,
    label: ADS_META_DESTINATION_TYPE_INSTAGRAM_PROFILE,
  },
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM_OPTION,
    label: ADS_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM,
  },
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_MESSAGES_ON_MESSAGE_OPTION,
    label: ADS_META_DESTINATION_TYPE_MESSAGES_ON_MESSENGER,
  },
  {
    value:
      ADS_CAMPAIGN_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM_AND_MESSENGER_OPTION,
    label: ADS_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM_AND_MESSENGER,
  },
];

export const optionsAdsRoles = [
  {
    value: ADVERTISER_ROLE,
    label: ADVERTISER,
    title: ADVERTISER_ROLE_TITLE_EXPLANATION,
    explanation: ADVERTISER_ROLE_DESCRIPTION_EXPLANATION,
  },
  {
    value: MARKETER_ROLE,
    label: MARKETER,
    title: MARKETER_ROLE_TILE_EXPLANATION,
    explanation: MARKETER_ROLE_DESCRIPTION_EXPLANATION,
  },
  {
    value: VIEWER_ROLE,
    label: VIEWER,
    title: VIEWER_ROLE_TITLE_EXPLANATION,
    explanation: VIEWER_ROLE_DESCRIPTION_EXPLANATION,
  },
];

export const optionsMetaDestinationTypeEngagementObjective = [
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
    label: ADS_META_DESTINATION_TYPE_WEBSITE,
  },
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION,
    label: ADS_META_DESTINATION_TYPE_WHATSAPP,
  },
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
    label: ADS_META_DESTINATION_TYPE_ON_POST,
  },
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_VIDEO_OPTION,
    label: ADS_META_DESTINATION_TYPE_ON_VIDEO,
  },
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM_OPTION,
    label: ADS_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM,
  },
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_MESSAGES_ON_MESSAGE_OPTION,
    label: ADS_META_DESTINATION_TYPE_MESSAGES_ON_MESSENGER,
  },
  {
    value:
      ADS_CAMPAIGN_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM_AND_MESSENGER_OPTION,
    label: ADS_META_DESTINATION_TYPE_MESSAGES_ON_INSTAGRAM_AND_MESSENGER,
  },
];

export const optionsMetaDestinationTypeLeadsObjective = [
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
    label: ADS_META_DESTINATION_TYPE_WEBSITE,
  },
  // {
  //   value: ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION,
  //   label: ADS_META_DESTINATION_TYPE_WHATSAPP,
  // },
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION,
    label: ADS_META_DESTINATION_TYPE_INSTANT_FORM,
  },
];
export const optionsMetaDestinationTypeSalesObjective = [
  {
    value: ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
    label: ADS_META_DESTINATION_TYPE_WEBSITE,
  },
  // {
  //   value: ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION,
  //   label: ADS_META_DESTINATION_TYPE_WHATSAPP,
  // },
];

export const optionsAdsCampaignBreakdownsReport = [
  {
    value: "weekly",
    label: ADS_CAMPAIGN_REPORT_BREAKDOWN_WEEKLY,
  },
  {
    value: "monthly",
    label: ADS_CAMPAIGN_REPORT_BREAKDOWN_MONTHLY,
  },
];

export const optionsKeywordMatchType = [
  {
    value: "broad",
    label: KEYWORD_BROAD_MATCH_TYPE_OPTION,
  },
  {
    value: "phrase",
    label: KEYWORD_PHRASE_MATCH_TYPE_OPTION,
  },
  {
    value: "exact",
    label: KEYWORD_EXACT_MATCH_TYPE_OPTION,
  },
];
