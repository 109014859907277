import Box from "@mui/material/Box";
import CardMedia from "../Media/CardMedia";
import ContainerOverlay from "../Containers/ContainerOverlay";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
const sxCutText = {
  whiteSpace: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
};
const GalleryItem = ({
  item,
  index,
  isSelected,
  onSelectItem,
  onFailedLoadUrl,
  onSelectPreview,
  multiple,
  from,
}) => {
  if (multiple) {
    return (
      <Box
        onClick={() => onSelectItem(index)}
        sx={{
          cursor: "pointer",
          position: "relative",
          "&:hover .overlay": {
            opacity: 1,
          },
          aspectRatio: "1/1",
        }}
      >
        <CardMedia
          url={item.url}
          onFailedLoadUrl={() => onFailedLoadUrl(index)}
          sx={{
            objectFit: "cover",
            borderRadius: 3,
            height: 400,
            width: "100%",
          }}
          propsType={{
            controls: false,
            autoplay: true,
            muted: true,
            loop: true,
          }}
        />
        <ContainerOverlay
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            top: 0,
            right: 0,
            opacity: 1,
          }}
        >
          <Checkbox
            checked={isSelected}
            onChange={() => onSelectItem(index)}
            sx={{
              p: 0,
            }}
          />
        </ContainerOverlay>
        {from === "posts" && (
          <ContainerOverlay
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              bottom: 0,
              right: 0,
              opacity: 1,
            }}
          >
            <Typography variant="body1" sx={sxCutText}>
              {item.text}
            </Typography>
          </ContainerOverlay>
        )}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        cursor: "pointer",
        position: "relative",
        "&:hover .overlay": {
          opacity: 1,
        },
        aspectRatio: "1/1",
      }}
      onClick={() => onSelectPreview(index)}
    >
      <CardMedia
        url={item.url}
        onFailedLoadUrl={() => onFailedLoadUrl(index)}
        sx={{
          objectFit: "cover",
          borderRadius: 3,
          height: 400,
          width: "100%",
        }}
        propsType={{
          controls: false,
          autoplay: true,
          muted: true,
          loop: true,
        }}
      />
      {from === "posts" && (
        <ContainerOverlay
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            bottom: 0,
            right: 0,
            opacity: 1,
          }}
        >
          <Typography variant="body1" sx={sxCutText}>
            {item.text}
          </Typography>
        </ContainerOverlay>
      )}
    </Box>
  );
};

export default GalleryItem;
