import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "../Buttons/IconButton";
import { useForm } from "react-hook-form";
import TextField from "../Form/TextField";
import { useTranslationApp } from "../../lib/i18next";
import { NAME_PLACEHOLDER, FIELD_REQUIRED } from "../../i18n/keysTranslations";

const EditableText = ({
  value,
  sx,
  sxText = {},
  onCallbackSave = () => {},
  variantText = "body1",
  disabledEditable = false,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      text: value,
    },
  });
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslationApp();
  const text = watch("text");

  const handleToggleEdit = () => {
    if (isEditing) {
      onCallbackSave(text);
    }
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    setValue("text", value);
    // eslint-disable-next-line
  }, [value]);

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={sx}>
      {isEditing ? (
        <TextField
          control={control}
          name={"text"}
          fullWidth
          placeholder={t(NAME_PLACEHOLDER)}
          variant="standard"
          rules={{
            required: { value: true, message: t(FIELD_REQUIRED) },
          }}
          errors={errors}
        />
      ) : (
        <Typography variant={variantText} sx={sxText}>
          {text}
        </Typography>
      )}

      {!disabledEditable && (
        <IconButton onClick={handleToggleEdit}>
          {isEditing ? <SaveIcon /> : <EditIcon />}
        </IconButton>
      )}
    </Stack>
  );
};

export default EditableText;
