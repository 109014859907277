import React, { useEffect, useState } from "react";
import i18next from "i18next";
import {
  optionsCallToActionAppPromotionMeta,
  optionsCallToActionAppPromotionTikTokBusiness,
  optionsCallToActionAwarenessMeta,
  optionsCallToActionAwarenessTikTokBusiness,
  optionsCallToActionEngagementTikTokBusiness,
  optionsCallToActionLeadsInstantFormMeta,
  optionsCallToActionLeadsMeta,
  optionsCallToActionLeadsTikTokBusiness,
  optionsCallToActionSalesMeta,
  optionsCallToActionSalesTikTokBusiness,
  optionsCallToActionTrafficMeta,
  optionsCallToActionTrafficTikTokBusiness,
} from "../../utils/options";
import {
  ADS_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE_META_VALUE,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION,
  META,
  TIKTOK,
} from "../../utils/constants";
import { FIELD_REQUIRED } from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import Select from "./Select";
import { useController } from "react-hook-form";

const SelectCallToActionAds = React.forwardRef(function SelectCallToActionAds(
  props,
  ref
) {
  const {
    control,
    errors,
    rules = {
      required: {
        value: true,
        message: i18next.t(FIELD_REQUIRED),
      },
    },
    name,
    label,
    disabled,
    platform,
    destinationType = ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
    fullWidth,
    variant = "filled",
    objective,
    sx = {},
  } = props;

  const [options, setOptions] = useState([]);
  const { t } = useTranslationApp();

  const {
    field: { value: fieldValue, onChange: fieldOnChange },
  } = useController({
    control,
    name,
  });

  useEffect(() => {
    if (!objective) return;

    if (platform === META) {
      const isDestinationTypeWhatsapp =
        destinationType === ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION;
      const isDestinationTypeInstantForm =
        destinationType ===
        ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION;
      if (objective === ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION)
        setOptions(optionsCallToActionAwarenessMeta);
      if (objective === ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION)
        setOptions(
          optionsCallToActionTrafficMeta.filter((option) =>
            isDestinationTypeWhatsapp
              ? true
              : option.value !==
                ADS_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE_META_VALUE
          )
        );
      if (objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION)
        setOptions(
          optionsCallToActionTrafficMeta.filter((option) =>
            isDestinationTypeWhatsapp
              ? true
              : option.value !==
                ADS_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE_META_VALUE
          )
        );
      if (objective === ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION) {
        if (isDestinationTypeInstantForm) {
          setOptions(optionsCallToActionLeadsInstantFormMeta);
        } else {
          setOptions(
            optionsCallToActionLeadsMeta.filter((option) =>
              isDestinationTypeWhatsapp
                ? true
                : option.value !==
                  ADS_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE_META_VALUE
            )
          );
        }
      }
      if (objective === ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_OPTION)
        setOptions(optionsCallToActionAppPromotionMeta);
      if (objective === ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION)
        setOptions(
          optionsCallToActionSalesMeta.filter((option) =>
            isDestinationTypeWhatsapp
              ? true
              : option.value !==
                ADS_CALL_TO_ACTION_SEND_WHATSAPP_MESSAGE_META_VALUE
          )
        );
    }
    if (platform === TIKTOK) {
      if (objective === ADS_CAMPAIGN_OBJECTIVE_AWARENESS_OPTION)
        setOptions(optionsCallToActionAwarenessTikTokBusiness);
      if (objective === ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION)
        setOptions(optionsCallToActionTrafficTikTokBusiness);
      if (objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION)
        setOptions(optionsCallToActionEngagementTikTokBusiness);
      if (objective === ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION)
        setOptions(optionsCallToActionLeadsTikTokBusiness);
      if (objective === ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_OPTION)
        setOptions(optionsCallToActionAppPromotionTikTokBusiness);
      if (objective === ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION)
        setOptions(optionsCallToActionSalesTikTokBusiness);
    }
  }, [objective, platform, destinationType]);

  useEffect(() => {
    if (options?.length === 0) return;

    if (!fieldValue) {
      fieldOnChange(options?.[0].value);
      return;
    }

    const isValidCallToAction = options.some((o) => o.value === fieldValue);
    if (!isValidCallToAction) {
      fieldOnChange(options?.[0].value);
    }

    // eslint-disable-next-line
  }, [fieldValue, options]);

  return (
    <Select
      ref={ref}
      label={t(label)}
      disabled={disabled}
      name={name}
      variant={variant}
      options={options}
      fullWidth={fullWidth}
      doTranslate={true}
      control={control}
      errors={errors}
      rules={{
        ...rules,
        validate: (value) => {
          if (!options.map((o) => o.value).includes(value))
            return t(FIELD_REQUIRED);
        },
      }}
      sx={sx}
    />
  );
});

export default SelectCallToActionAds;
