import { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  BUTTON_ADD_SELECTED_CONTENT,
  BUTTON_PREVIOUS,
  BUTTON_REMOVE_SELECTION,
  BUTTON_SELECT_THIS_CONTENT,
  LOADING_CONTENT,
  MODAL_GALLERY_ARIA_LABEL,
  MODAL_GALLERY_EMPTY_CONTENT,
  PROCESSING_MEDIA_TO_USE,
  SAVING_MEDIA,
} from "../../i18n/keysTranslations";
import DialogActions from "@mui/material/DialogActions";
import ModalMedia from "../Modal/ModalMedia";
import { useTranslationApp } from "../../lib/i18next";
import useNearScreen from "../../hooks/useNearScreen";
import { useDispatchApp } from "../../lib/redux";
import { useDebounced } from "../../hooks/useDebounce";
import ModalBasicLayout from "./ModalBasicLayout";
import Loader from "../Loaders/Loader";
import Button from "../Buttons/Button";
import Gallery from "../Gallery/Gallery";
import { usePagination } from "../../hooks/usePagination";
import { getExtensionFile } from "../../utils/string";
import {
  ADS_CAMPAIGN_MEDIA_FROM_PHYLLO,
  FILTERS_MEDIA,
  IMAGE_FORMATS,
  META,
  VIDEO_FORMATS,
} from "../../utils/constants";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../utils/colors";
import { startGetGalleryAdsPlatforms } from "../../actions/adsCampaigns";
import {
  startAddContentsToList,
  startSaveMediaInStorage,
} from "../../actions/user";
import LinearLoaderWithTime from "../Loaders/LinearLoaderWithTime";

const PAGE_SIZE = 10;

const ModalGalleryAdsPlatforms = ({
  modalOpen,
  filterByFormat,
  multiple = true,
  acceptedFormats = VIDEO_FORMATS,
  onCloseModal,
  onCallbackSelectedItems,
  socialNetworkURL,
  pathStorage,
}) => {
  const [data, setData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectPreview, setSelectPreview] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter] = useState(filterByFormat);
  const [loading, setLoading] = useState(true);
  const [loadLastItem, setLoadLastItem] = useState(false);
  const [isCalling, setIsCalling] = useState(false);

  const { t } = useTranslationApp();

  const galleryRef = useRef(null);

  const { isNearScreen, fromRef } = useNearScreen({
    distance: "100px",
    once: false,
    disabledTimeout: true,
  });

  const dispatch = useDispatchApp();

  const getInitialData = async () => {
    setLoading(true);
    setData([]);

    const response = await dispatch(
      startGetGalleryAdsPlatforms({
        platform: META,
        socialNetworkURL,
        lastKey,
        onChangeLastKey,
        onChangeLoadLastItem: setLoadLastItem,
        onChangeIsCalling: setIsCalling,
        limit: PAGE_SIZE,
        from: ADS_CAMPAIGN_MEDIA_FROM_PHYLLO,
      })
    );
    if (response.ok) {
      const dataFormatted = response?.data?.map((media) => ({
        ...media,
        id: media.id,
      }));
      setData(dataFormatted);
    }

    setLoading(false);
  };

  const getNextMedia = useDebounced(async () => {
    if (loadLastItem || isCalling) return;

    const response = await dispatch(
      startGetGalleryAdsPlatforms({
        platform: META,
        socialNetworkURL,
        lastKey,
        onChangeLastKey,
        onChangeLoadLastItem: setLoadLastItem,
        onChangeIsCalling: setIsCalling,
        limit: PAGE_SIZE,
        from: ADS_CAMPAIGN_MEDIA_FROM_PHYLLO,
      })
    );

    if (response.ok) {
      const dataFormatted = response?.data?.map((media) => ({
        ...media,
        id: media.id,
      }));
      setData((prevState) => [...prevState, ...dataFormatted]);
    }
  }, 1000);

  const { lastKey, onChangeLastKey } = usePagination({
    rowsPerPageValue: PAGE_SIZE,
    onCallBackNextPage: getNextMedia,
  });

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isNearScreen && !loadLastItem) getNextMedia();
    //eslint-disable-next-line
  }, [isNearScreen]);

  const onFailedLoadUrl = (index) => {
    setData((prevState) => {
      const newData = [...prevState];
      const filteredData = newData.filter((item, i) => i !== index);
      return filteredData;
    });
  };

  const onSelectItem = (index) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
    } else {
      if (multiple) {
        setSelectedItems([...selectedItems, index]);
      } else {
        setSelectedItems([index]);
      }
    }
  };

  const onSelectPreview = (index) => {
    setSelectPreview(index);
  };

  const onSendSelectedItems = async (indexes) => {
    const dataSelectedItems = [];
    (indexes || selectedItems).forEach((index) => {
      const item = data[index];
      dataSelectedItems.push({
        url: item.url,
        from: item.from || "gallery",
        id: item.id,
        userID: item.userID || "",
      });
    });

    const urls = dataSelectedItems.map((item) => item.url);

    setIsUploading(true);
    const response = await dispatch(
      startSaveMediaInStorage({
        urls,
        path: pathStorage,
      })
    );

    if (!response.ok) {
      setIsUploading(false);
      return false;
    }

    const urlsStored = response.data.urls;

    urlsStored.forEach((urlStored, index) => {
      dataSelectedItems[index].url = urlStored;
      dispatch(startAddContentsToList([{ url: urlStored, type: "upload" }]));
    });

    setIsUploading(false);

    onCallbackSelectedItems(dataSelectedItems);
    onCloseModal();
  };

  const dataFiltered =
    filter && FILTERS_MEDIA.includes(filter)
      ? data.filter((item) => {
          const extension = getExtensionFile(item.url);
          return (
            ((filter === "image" && IMAGE_FORMATS.includes(extension)) ||
              (filter === "video" && VIDEO_FORMATS.includes(extension))) &&
            acceptedFormats.includes(extension)
          );
        })
      : data;

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      fullScreen
      scroll="paper"
      aria-label={t(MODAL_GALLERY_ARIA_LABEL)}
      sx={{ ".MuiPaper-root": { backgroundColor: "#000" } }}
      sxContent={{
        p: 0,
        pl: 3,
        pb: 2,
        mt: 1,
        overflowX: "hidden",
        "::-webkit-scrollbar": {
          width: 0,
          height: 0,
          background: "transparent",
        },
      }}
      refContent={galleryRef}
    >
      {loading && (
        <Loader size={70} hasMessage={true} message={t(LOADING_CONTENT)} />
      )}
      {!loading && (
        <Gallery
          data={dataFiltered}
          onCloseModal={onCloseModal}
          lastItemRef={fromRef}
          loadLastItem={loadLastItem}
          onFailedLoadUrl={onFailedLoadUrl}
          onSelectItem={onSelectItem}
          selectedItems={selectedItems}
          onSelectPreview={onSelectPreview}
          multiple={multiple}
        />
      )}
      {!loading && dataFiltered?.length === 0 && loadLastItem && (
        <Box
          sx={{
            height: "calc(100% - 64px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ml: "-12px",
          }}
        >
          <Typography variant="h4" align="center">
            {t(MODAL_GALLERY_EMPTY_CONTENT)}
          </Typography>
        </Box>
      )}

      {selectedItems.length > 0 && (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            mb: 2,
          }}
        >
          {isUploading ? (
            <LinearLoaderWithTime
              labels={[t(PROCESSING_MEDIA_TO_USE), t(SAVING_MEDIA)]}
              time={30 * 1000}
            />
          ) : (
            <Button
              color="secondary"
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                onSendSelectedItems();
              }}
              sx={{
                background: GRADIENT_PURPLE_FUCHSIA,
                color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
              }}
            >
              {t(BUTTON_ADD_SELECTED_CONTENT)}
            </Button>
          )}
        </DialogActions>
      )}
      {selectPreview !== null && (
        <ModalMedia
          modalOpen={selectPreview !== null}
          onCloseModal={() => {
            setSelectPreview(null);
          }}
          url={dataFiltered?.[selectPreview]?.url}
          customActions={
            isUploading ? (
              <LinearLoaderWithTime
                labels={[t(PROCESSING_MEDIA_TO_USE), t(SAVING_MEDIA)]}
                time={30 * 1000}
              />
            ) : (
              <>
                <Button
                  color="primary"
                  sx={{ minWidth: 150 }}
                  onClick={() => {
                    setSelectPreview(null);
                  }}
                >
                  {t(BUTTON_PREVIOUS)}
                </Button>

                <Button
                  loading={isUploading}
                  sx={{
                    minWidth: 150,
                    background: GRADIENT_PURPLE_FUCHSIA,
                    color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                  }}
                  onClick={async (e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    if (selectedItems.includes(selectPreview)) {
                      setSelectedItems(
                        selectedItems.filter((item) => item !== selectPreview)
                      );
                    } else {
                      if (multiple) {
                        onSelectItem(selectPreview);
                      } else {
                        const response = await onSendSelectedItems([
                          selectPreview,
                        ]);
                        if (!response) {
                          return;
                        }
                      }
                    }
                    setSelectPreview(null);
                  }}
                >
                  {t(
                    selectedItems.includes(selectPreview)
                      ? BUTTON_REMOVE_SELECTION
                      : BUTTON_SELECT_THIS_CONTENT
                  )}
                </Button>
              </>
            )
          }
          disabledActions={true}
        />
      )}
    </ModalBasicLayout>
  );
};

export default ModalGalleryAdsPlatforms;
