import i18next from "i18next";
import { FIELD_REQUIRED } from "../../../../../i18n/keysTranslations";
import { z } from "zod";
import { GOOGLE } from "../../../../../utils/constants";
import { adGoogleBaseSchema } from "../ad/adGoogleSchema";

export const adGroupGoogleBaseSchema = z.object({
  ads: z.array(adGoogleBaseSchema).min(1, i18next.t(FIELD_REQUIRED)),
  platform: z.literal(GOOGLE),
  keywords: z.array(z.string()).optional(),
  keywordsNegative: z.array(z.string()).optional(),
});
