import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../../lib/i18next";
import { ADS_CAMPAIGNS_STATISTICS_SLIDE_MENU_PERFORMING_ASSETS } from "../../../../i18n/keysTranslations";
import { useFormContext } from "react-hook-form";
import { getFromStatisticsAdsCampaign } from "../../../../services/adsCampaigns";
import { GOOGLE, META, TIKTOK } from "../../../../utils/constants";
import MetaPerformingAssets from "./Meta/MetaPerformingAssets";
import TiktokPerformingAssets from "./Tiktok/TiktokPerformingAssets";
import GooglePerformingAssets from "./Google/GooglePerformingAssets";

const COMPONENT_INSIGHTS = {
  [META]: MetaPerformingAssets,
  [GOOGLE]: GooglePerformingAssets,
  [TIKTOK]: TiktokPerformingAssets,
};

const PerformingAssets = () => {
  const { t } = useTranslationApp();
  const { campaign } = useFormContext();
  const { platforms } = getFromStatisticsAdsCampaign(campaign);

  return (
    <Box
      id="insights-section"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 2,
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        {t(ADS_CAMPAIGNS_STATISTICS_SLIDE_MENU_PERFORMING_ASSETS)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {platforms.map((platform) => {
          const Component = COMPONENT_INSIGHTS[platform];
          if (!Component) return null;
          return <Component key={platform} />;
        })}
      </Box>
    </Box>
  );
};

export default PerformingAssets;
