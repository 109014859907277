import ModalBasicLayout from "../../../Modal/ModalBasicLayout";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  BUTTON_GO_BACK,
  BUTTON_SAVE,
  FORMAT_PLACEHOLDER,
  MODAL_EDIT_PLACEMENTS_META_DESCRIPTION,
  MODAL_EDIT_PLACEMENTS_META_TITLE,
} from "../../../../i18n/keysTranslations";
import Box from "@mui/material/Box";
import Select from "../../../Form/Select";
import { useForm, useFormContext } from "react-hook-form";
import PlacementPreview from "./PlacementPreview";
import IconButtonAddContent from "../../../Buttons/IconButtonAddContent";
import {
  ADS_CAMPAIGN_AD_FORMAT_FACEBOOK_STORY,
  ADS_CAMPAIGN_META_AD_FORMATS_FILTERED_BY_SPECIAL_CONFIGURATION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  FORMATS_MEDIA_META,
  META,
  MIN_DIMENSIONS_CONTENT,
} from "../../../../utils/constants";
import { useDispatchApp } from "../../../../lib/redux";
import { getPathDatabaseByRole } from "../../../../actions/auth";
import { useEffect, useState } from "react";
import Accordion from "../../../Containers/Accordion";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContainerModalActions from "../../../Containers/ContainerModalActions";
import { DialogActions } from "@mui/material";
import Loader from "../../../Loaders/Loader";
import { getIsAdWithSpecialConfigurationCustomizationPlacements } from "../../../../services/adsCampaigns";
import _ from "lodash";

const PLACEMENTS_GROUPS = [
  {
    id: "feedsAndSearch",
    title: "Feeds, In-stream ads for videos and reels, Search results",
    placementsData: {
      platforms: ["facebook", "instagram", "messenger"],
      facebookPositions: [
        "feed",
        "facebook_reels_overlay",
        "profile_feed",
        "video_feeds",
        "instream_video",
        "marketplace",
      ],
      instagramPositions: [
        "stream",
        "ig_search",
        "explore",
        "explore_home",
        "profile_feed",
      ],
      messengerPositions: ["messenger_home"],
    },
    items: [
      { id: "facebookFeed", label: "Facebook Feed" },
      {
        id: "facebookInstreamForVideos",
        label: "Facebook In-stream for Videos",
      },
      { id: "adsOnFacebookReels", label: "Ads on Facebook Reels" },
      { id: "facebookMarketplace", label: "Facebook Marketplace" },
      { id: "facebookProfileFeed", label: "Facebook Profile feed" },
      { id: "facebookVideoFeeds", label: "Facebook Video feeds" },
      { id: "instagramFeed", label: "Instagram Feed" },
      { id: "instagramExplore", label: "Instagram Explore" },
      { id: "instagramExploreHome", label: "Instagram Explore home" },
      { id: "instagramProfileFeed", label: "Instagram Profile feed" },
      { id: "instagramSearchResults", label: "Instagram Search results" },
      { id: "messengerInbox", label: "Messenger Inbox" },
    ],
  },
  {
    id: "storiesAndReels",
    title: "Stories and Reels, Apps and sites",
    placementsData: {
      platforms: ["facebook", "instagram", "audience_network", "messenger"],
      facebookPositions: ["facebook_reels", "story"],
      instagramPositions: ["profile_reels", "story", "reels"],
      messengerPositions: ["story"],
      audienceNetworkPositions: ["classic", "rewarded_video"],
    },
    items: [
      { id: "facebookStories", label: "Facebook Stories" },
      { id: "instagramStories", label: "Instagram Stories" },
      { id: "instagramReels", label: "Instagram Reels" },
      { id: "instagramProfileReels", label: "Instagram Profile reels" },
      {
        id: "audienceNetworkNativeBanner",
        label: "Audience Network Native, banner and interstitial",
      },
      {
        id: "audienceNetworkRewardedVideos",
        label: "Audience Network Rewarded videos",
      },
      { id: "messengerStories", label: "Messenger Stories" },
      { id: "facebookReels", label: "Facebook Reels" },
    ],
  },
  {
    id: "rightColumnAndSearchResults",
    title: "Right column, Search results",
    placementsData: {
      platforms: ["facebook"],
      facebookPositions: ["right_hand_column", "search"],
    },
    items: [
      { id: "facebookRightColumn", label: "Facebook Right column" },
      { id: "facebookSearchResults", label: "Facebook Search results" },
    ],
  },
];

const optionsAdFormats = [
  { label: "Facebook Feed", value: "DESKTOP_FEED_STANDARD" },
  { label: "Facebook In-stream for Videos", value: "INSTREAM_VIDEO_MOBILE" },
  { label: "Facebook Marketplace", value: "MARKETPLACE_MOBILE" },
  { label: "Facebook Profile feed", value: "FACEBOOK_PROFILE_FEED_MOBILE" },
  { label: "Facebook Video feeds", value: "WATCH_FEED_MOBILE" },
  { label: "Instagram Feed", value: "INSTAGRAM_STANDARD" },
  { label: "Instagram Explore", value: "INSTAGRAM_EXPLORE_IMMERSIVE" },
  { label: "Instagram Explore home", value: "INSTAGRAM_EXPLORE_GRID_HOME" },
  { label: "Instagram Profile feed", value: "INSTAGRAM_PROFILE_FEED" },
  { label: "Instagram Search results", value: "INSTAGRAM_SEARCH_GRID" },
  { label: "Messenger Inbox", value: "MESSENGER_MOBILE_INBOX_MEDIA" },
  { label: "Facebook Stories", value: "FACEBOOK_STORY_MOBILE" },
  { label: "Instagram Stories", value: "INSTAGRAM_STORY" },
  { label: "Instagram Reels", value: "INSTAGRAM_REELS" },
  { label: "Messenger Stories", value: "MESSENGER_MOBILE_STORY_MEDIA" },
  { label: "Facebook Reels", value: "FACEBOOK_REELS_MOBILE" },
  // {
  //   label: "Audience Network Native, banner and interstitial",
  //   value: "AUDIENCE_NETWORK_OUTSTREAM_VIDEO",
  // },
  // {
  //   label: "Audience Network Rewarded videos",
  //   value: "AUDIENCE_NETWORK_REWARDED_VIDEO",
  // },
  { label: "Facebook Right column", value: "RIGHT_COLUMN_STANDARD" },
  { label: "Facebook Search results", value: "SUGGESTED_VIDEO_DESKTOP" },
];

const getIDGroup = (customizationPlacement) => {
  const group = PLACEMENTS_GROUPS.find((group) => {
    const { placementsData } = group;
    const { facebookPositions = [], instagramPositions = [] } = placementsData;
    const {
      facebookPositions: facebookPositionsCustomizationPlacement = [],
      instagramPositions: instagramPositionsCustomizationPlacement = [],
    } = customizationPlacement;

    const includeFacebookPositions =
      facebookPositionsCustomizationPlacement.every((position) => {
        return facebookPositions.includes(position);
      });
    const includeInstagramPositions =
      instagramPositionsCustomizationPlacement.every((position) => {
        return instagramPositions.includes(position);
      });

    if (includeFacebookPositions && includeInstagramPositions) {
      return group.id;
    }

    return null;
  });

  return group?.id;
};

const ModalEditPlacementsMeta = ({
  modalOpen,
  onCloseModal = () => {},
  destinationType = ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  adID,
  adPathForm,
  index = 0,
  onCallbackSelect = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const { control, setValue, errors, watch } = useForm({
    defaultValues: {
      adFormat: ADS_CAMPAIGN_AD_FORMAT_FACEBOOK_STORY,
      customizationPlacements: [],
      creativeID: "",
    },
  });
  const { getValues } = useFormContext();

  const dispatch = useDispatchApp();
  const { userID } = dispatch(getPathDatabaseByRole());
  const { t } = useTranslationApp();

  const mediaData = getValues(`${adPathForm}.mediaData`) || [];
  const isCarousel = getValues(`${adPathForm}.isCarousel`) || false;

  const adFormat = watch("adFormat");
  const creativeID = watch("creativeID");
  const customizationPlacements = watch("customizationPlacements");

  const isAdWithSpecialConfigurationCustomizationPlacements =
    getIsAdWithSpecialConfigurationCustomizationPlacements({
      platform: META,
      destinationType,
      isCarousel,
      customizationPlacements,
    });

  useEffect(() => {
    let baseOptions = _.cloneDeep(optionsAdFormats);
    if (isAdWithSpecialConfigurationCustomizationPlacements) {
      baseOptions = baseOptions.filter(
        (option) =>
          !ADS_CAMPAIGN_META_AD_FORMATS_FILTERED_BY_SPECIAL_CONFIGURATION.includes(
            option.value
          )
      );
    }
    setOptions(baseOptions);

    const mediaDataAd = mediaData?.[index] || {};
    const customizationPlacements = mediaDataAd?.customizationPlacements || [];
    const verifyCustomizationPlacements = customizationPlacements
      .map((item) => {
        if (item.id) {
          return item;
        }

        const idGroup = getIDGroup(item);

        if (idGroup) {
          return {
            ...item,
            id: idGroup,
          };
        }

        return null;
      })
      .filter(Boolean);

    setValue("customizationPlacements", verifyCustomizationPlacements);

    setIsLoading(false);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      isAdWithSpecialConfigurationCustomizationPlacements &&
      adFormat &&
      ADS_CAMPAIGN_META_AD_FORMATS_FILTERED_BY_SPECIAL_CONFIGURATION.includes(
        adFormat
      )
    ) {
      setValue("adFormat", ADS_CAMPAIGN_AD_FORMAT_FACEBOOK_STORY);
    }

    let baseOptions = _.cloneDeep(optionsAdFormats);
    if (isAdWithSpecialConfigurationCustomizationPlacements) {
      baseOptions = baseOptions.filter(
        (option) =>
          !ADS_CAMPAIGN_META_AD_FORMATS_FILTERED_BY_SPECIAL_CONFIGURATION.includes(
            option.value
          )
      );
    }
    setOptions(baseOptions);
    // eslint-disable-next-line
  }, [isAdWithSpecialConfigurationCustomizationPlacements]);

  const onRemoveGroup = (groupID) => {
    const newPlacements = customizationPlacements.filter(
      (item) => item.id !== groupID
    );
    setValue("customizationPlacements", newPlacements);
    setValue("creativeID", "");
  };

  const onEditContentGroup = ({ id, mediaUrl }) => {
    const existsIndex = customizationPlacements.findIndex(
      (item) => item.id === id
    );
    if (existsIndex > -1) {
      const newPlacements = customizationPlacements.map((placement) => {
        if (placement.id === id) {
          return {
            ...placement,
            mediaUrl,
            mediaID: "",
          };
        }
        return placement;
      });
      setValue("customizationPlacements", newPlacements);
    } else {
      const newPlacement = {
        id: id,
        mediaUrl: mediaUrl,
        ...PLACEMENTS_GROUPS.find((group) => group.id === id).placementsData,
      };
      setValue("customizationPlacements", [
        ...customizationPlacements,
        newPlacement,
      ]);
    }
    setValue("creativeID", "");
  };

  const onCallbackGeneratedAdPreview = ({ creativeID, mediaData = {} }) => {
    setValue("creativeID", creativeID);
    if (mediaData.customizationPlacements)
      setValue(customizationPlacements, mediaData.customizationPlacements);
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      title={t(MODAL_EDIT_PLACEMENTS_META_TITLE)}
      description={t(MODAL_EDIT_PLACEMENTS_META_DESCRIPTION)}
      maxWidth="md"
      fullWidth
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                flex: 1,
              }}
            >
              {PLACEMENTS_GROUPS.map((group) => {
                const isGroupPresent = customizationPlacements.some(
                  (item) => item.id === group.id
                );
                return (
                  <Accordion
                    key={group.id}
                    title={group.title}
                    titleAlign="left"
                    sx={{
                      py: 1,
                    }}
                    actionsComponent={
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          mr: 1,
                        }}
                      >
                        <IconButtonAddContent
                          id={`edit-group-content-${group.id}`}
                          sx={{
                            width: "auto",
                            height: "auto",
                          }}
                          mode="icon"
                          icon={<EditIcon sx={{ color: "white" }} />}
                          minDimensions={MIN_DIMENSIONS_CONTENT}
                          showButtonUpload={true}
                          pathStorage={`adsCampaigns/${userID}/`}
                          modeUpload="sync"
                          onAddContent={(contents) => {
                            const mediaUrl = contents?.[0]?.url || "";
                            onEditContentGroup({ id: group.id, mediaUrl });
                          }}
                          multiple={false}
                          acceptedFormats={FORMATS_MEDIA_META}
                          showUploadDeviceOption={
                            destinationType !==
                            ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
                          }
                          showGalleryOption={
                            destinationType !==
                            ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
                          }
                        />
                        {isGroupPresent && (
                          <DeleteIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => onRemoveGroup(group.id)}
                          />
                        )}
                      </Box>
                    }
                  >
                    {group.items.map((item) => {
                      return (
                        <Box
                          key={item.id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1,
                          }}
                        >
                          <Typography variant="body2">{item.label}</Typography>
                        </Box>
                      );
                    })}
                  </Accordion>
                );
              })}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                gap: 2,
                maxWidth: "fit-content",
                width: "100%",
              }}
            >
              <Select
                name="adFormat"
                label={t(FORMAT_PLACEHOLDER)}
                variant="filled"
                options={options}
                fullWidth
                control={control}
                errors={errors}
              />
              <PlacementPreview
                destinationType={destinationType}
                adFormat={adFormat}
                customizationPlacements={customizationPlacements}
                adID={adID}
                adPathForm={adPathForm}
                index={index}
                creativeID={creativeID}
                onCallbackGeneratedAdPreview={onCallbackGeneratedAdPreview}
              />
            </Box>
          </Box>
          <DialogActions
            sx={{
              p: 0,
              mt: 2,
            }}
          >
            <ContainerModalActions
              hasPadding={false}
              propsSecondaryButton={{
                showButton: true,
                onClick: onCloseModal,
                text: BUTTON_GO_BACK,
              }}
              propsPrimaryButton={{
                showButton: true,
                text: BUTTON_SAVE,
                type: "submit",
                onClick: () => {
                  onCloseModal();
                  onCallbackSelect(customizationPlacements);
                },
              }}
            />
          </DialogActions>
        </>
      )}
    </ModalBasicLayout>
  );
};

export default ModalEditPlacementsMeta;
