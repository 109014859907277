import { useState } from "react";
import { useTranslationApp } from "../../../../lib/i18next";
import Button from "../../../Buttons/Button";
import { BUTTON_EDIT_ADVANTAGE_ENHANCES } from "../../../../i18n/keysTranslations";
import ModalEditAdvantageEnhances from "./ModalEditAdvantageEnhances";
import Box from "@mui/material/Box";

const EditAdvantageEnhances = ({
  adID,
  adPathForm,
  onCallbackSelect = () => {},
  disabled = false,
}) => {
  const [modalEditAdvantageEnhancesOpen, setModalEditAdvantageEnhancesOpen] =
    useState(false);
  const { t } = useTranslationApp();

  if (disabled) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
        }}
      >
        <Button
          type="button"
          sx={{
            width: "fit-content",
          }}
          onClick={() => setModalEditAdvantageEnhancesOpen(true)}
        >
          {t(BUTTON_EDIT_ADVANTAGE_ENHANCES)}
        </Button>
      </Box>
      {modalEditAdvantageEnhancesOpen && (
        <ModalEditAdvantageEnhances
          modalOpen={modalEditAdvantageEnhancesOpen}
          onCloseModal={() => setModalEditAdvantageEnhancesOpen(false)}
          adID={adID}
          adPathForm={adPathForm}
          onCallbackSelect={onCallbackSelect}
        />
      )}
    </>
  );
};

export default EditAdvantageEnhances;
