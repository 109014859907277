import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import { ConfirmAlert } from "../../Alerts/Alerts";
import {
  ALERT_DELETE_KEYWORD_DESCRIPTION,
  ALERT_DELETE_KEYWORD_TITLE,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";

const KeywordsTableActions = ({ keyword, onEdit, onDelete }) => {
  const { t } = useTranslationApp();

  const onConfirmDelete = async () => {
    const result = await ConfirmAlert({
      title: t(ALERT_DELETE_KEYWORD_TITLE),
      text: t(ALERT_DELETE_KEYWORD_DESCRIPTION),
    });

    if (result.isConfirmed) {
      onDelete([keyword.original]);
    }
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
      <IconButton onClick={() => onEdit(keyword)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={onConfirmDelete}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default KeywordsTableActions;
