import i18next from "i18next";
import { SimpleAlert } from "../components/Alerts/Alerts";
import { setShowBackdrop } from "./ui";
import {
  ALERT_POPUP_BLOCKED_DESCRIPTION,
  ERROR,
  PLATFORMS_CONNECTED_INSTAGRAM_FAILED,
  WARNING,
} from "../i18n/keysTranslations";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_WARNING,
  FACEBOOK,
  GOOGLE,
  META,
  THEME_ID_PROJECT,
  TIKTOK_BUSINESS,
} from "../utils/constants";
import { getPathDatabaseByRole, renewToken } from "./auth";
import axios from "../lib/axios";
import types from "../types";
import {
  get,
  limitToFirst,
  limitToLast,
  orderByChild,
  push,
  query,
  ref,
  startAfter,
  update,
} from "firebase/database";
import { db } from "../lib/firebase";
import { getUniqueID } from "../utils/numbers";
import { LaunchError } from "../utils/errors";
import _ from "lodash";
import { downloadContent, encryptPassword } from "../utils/string";

const FUNCTIONS_URL = import.meta.env.VITE_CLOUD_FUNCTIONS_URL;

export const startGetIntegrations = () => async (dispatch) => {
  try {
    const paths = dispatch(getPathDatabaseByRole());
    const dbRef = ref(db, paths.pathIntegrations);
    const snapshot = await get(dbRef);
    const integrationsPlatforms = [FACEBOOK, TIKTOK_BUSINESS, GOOGLE];

    if (snapshot.exists()) {
      const integrations = snapshot.val();
      dispatch(getIntegrations(integrations));
      const integrationsPlatformsFiltered = integrationsPlatforms.filter(
        (platform) => !integrations[platform]
      );
      dispatch(
        startCallBootIntegrationFuctions({
          platforms: integrationsPlatformsFiltered,
        })
      );
      return integrations;
    }
    dispatch(getIntegrations({}));
    dispatch(
      startCallBootIntegrationFuctions({
        platforms: integrationsPlatforms,
      })
    );
    return {};
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
const getIntegrations = (data) => ({
  type: types.GET_INTEGRATIONS,
  payload: data,
});
const startCallBootIntegrationFuctions =
  ({ platforms = [] }) =>
  async (dispatch) => {
    try {
      const promises = [];
      const token = await dispatch(renewToken());

      platforms.forEach((platform) => {
        if (platform === FACEBOOK) {
          promises.push(
            axios({
              method: "post",
              url: `${FUNCTIONS_URL}/saveTokenFacebook`,
              data: {},
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
          );
        }
        if (platform === TIKTOK_BUSINESS) {
          promises.push(
            axios({
              method: "post",
              url: `${FUNCTIONS_URL}/connectTiktokBusiness`,
              data: {},
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
          );
          promises.push(
            axios({
              method: "post",
              url: `${FUNCTIONS_URL}/getTokenTiktokBusiness`,
              data: {},
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
          );
        }
        if (platform === GOOGLE) {
          promises.push(
            axios({
              method: "post",
              url: `${FUNCTIONS_URL}/connectGoogleAds`,
              data: {},
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
          );
          promises.push(
            axios({
              method: "post",
              url: `${FUNCTIONS_URL}/getTokenGoogleAds`,
              data: {},
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
          );
        }
      });

      await Promise.all(promises);

      return true;
    } catch (error) {
      const response = dispatch(LaunchError(error, false));
      console.log(response);
      return false;
    }
  };
export const startGetAdvertisersByAgency = () => async (dispatch) => {
  try {
    const paths = dispatch(getPathDatabaseByRole());
    const dbRef = ref(db, paths.pathAdvertisersByAgency);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const advertisersByAgency = snapshot.val();
      return { ok: true, data: advertisersByAgency };
    }
    return { ok: false };
  } catch (error) {
    dispatch(LaunchError(error));
    return { ok: false };
  }
};
export const startGetLastSelectedIntegrationData = () => async (dispatch) => {
  try {
    const paths = dispatch(getPathDatabaseByRole());
    const dbRef = ref(db, paths.pathLastSelectedIntegrationData);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const integrations = snapshot.val();
      dispatch(getLastSelectedIntegrationData(integrations));
      return integrations;
    }
    dispatch(getLastSelectedIntegrationData({}));
    return {};
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
const getLastSelectedIntegrationData = (data) => ({
  type: types.GET_LAST_SELECTED_INTEGRATION_DATA,
  payload: data,
});
export const startGetBusinessName = () => async (dispatch) => {
  try {
    const paths = dispatch(getPathDatabaseByRole());
    const dbRef = ref(db, `${paths.pathBase}/businessName`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const businessName = snapshot.val();
      dispatch(getBusinessName(businessName));
      return businessName;
    }
    return {};
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
const getBusinessName = (data) => ({
  type: types.GET_BUSINESS_NAME,
  payload: data,
});

export const startGetAdsUser = (userID) => async (dispatch, getState) => {
  try {
    const currentUsers = getState().user.users || {};
    if (currentUsers?.[userID]) return currentUsers?.[userID];
    const paths = dispatch(getPathDatabaseByRole());
    const dbRef = ref(db, `${paths.pathUsers}/${userID}`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const adsUser = snapshot.val();
      dispatch(
        getAdsUsers({
          [userID]: adsUser,
        })
      );
      return adsUser;
    }
    return false;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
export const startGetAdsUsers =
  ({ onCallbackLoadingFinish = () => {} }) =>
  async (dispatch) => {
    try {
      const paths = dispatch(getPathDatabaseByRole());
      const dbRef = ref(db, `${paths.pathUsers}`);
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const adsUsers = snapshot.val();
        dispatch(getAdsUsers(adsUsers));
        onCallbackLoadingFinish();
        return adsUsers;
      }
      onCallbackLoadingFinish();
      return {};
    } catch (error) {
      console.log(error);
      dispatch(LaunchError(error));
      onCallbackLoadingFinish();
      return false;
    }
  };
const getAdsUsers = (data) => ({
  type: types.GET_ADS_USERS,
  payload: data,
});

export const startSetLastSelectedIntegrationDataByPlatform =
  ({ platform, integrationID, adAccountID }) =>
  async (dispatch) => {
    try {
      const paths = dispatch(getPathDatabaseByRole());
      const path = `${paths.pathLastSelectedIntegrationData}/${platform}`;

      const dbRef = ref(db);

      const updates = {};

      updates[`${path}/integrationID`] = integrationID;
      updates[`${path}/adAccountID`] = adAccountID;

      await update(dbRef, updates);

      dispatch(
        setLastSelectedIntegrationDataByPlatform({
          platform,
          data: {
            integrationID,
            adAccountID,
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };
const setLastSelectedIntegrationDataByPlatform = (data) => ({
  type: types.SET_LAST_SELECTED_INTEGRATION_DATA_BY_PLATFORM,
  payload: data,
});

export const startConnectFacebook =
  ({
    onChangeLoadingAuth = () => {},
    onChangeLoadingConnect = () => {},
    showGlobalLoading = false,
    onCallbackConnected = () => {},
  }) =>
  async (dispatch) => {
    try {
      onChangeLoadingAuth(true);
      if (showGlobalLoading) dispatch(setShowBackdrop(true));

      window.FB.login(
        function (response) {
          if (response.status === "unknown") {
            onChangeLoadingAuth(false);
            return;
          }
          if (response.status !== "connected" || !response.authResponse) {
            SimpleAlert({
              title: i18next.t(ERROR),
              message: i18next.t(PLATFORMS_CONNECTED_INSTAGRAM_FAILED),
              icon: ALERT_ICON_TYPE_ERROR,
            });
            onChangeLoadingAuth(false);
            return;
          }
          const { accessToken, userID } = response.authResponse;

          onChangeLoadingAuth(false);
          dispatch(
            startSaveTokenFacebook({
              data: {
                id: userID,
                accessToken,
                name: "",
              },
              showGlobalLoading,
              onChangeLoading: onChangeLoadingConnect,
              onCallbackConnected,
            })
          );
        },
        {
          config_id: import.meta.env.VITE_FACEBOOK_CONFIG_ID,
        }
      );
    } catch (error) {
      onChangeLoadingAuth(false);
      onChangeLoadingConnect(false);
      if (showGlobalLoading) dispatch(setShowBackdrop(false));
      dispatch(LaunchError(error));
      return false;
    }
  };
export const startSaveTokenFacebook =
  ({
    data,
    showGlobalLoading = false,
    onChangeLoading = () => {},
    onCallbackConnected = () => {},
  }) =>
  async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());

      const metaIntegrations = getState()?.user?.integrations?.facebook;
      onChangeLoading(true);
      if (showGlobalLoading) dispatch(setShowBackdrop(true));

      const { data: result } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/saveTokenFacebook`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      onChangeLoading(false);
      if (showGlobalLoading) dispatch(setShowBackdrop(false));

      if (!result.ok) return false;

      if (_.isEmpty(metaIntegrations)) {
        dispatch(
          startSetLastSelectedIntegrationDataByPlatform({
            platform: META,
            integrationID: result?.data?.id,
            adAccountID: Object.keys(result?.data?.businessAccounts || {})[0],
          })
        );
      }

      dispatch(saveFacebookIntegration(result.data));
      onCallbackConnected();
      return true;
    } catch (error) {
      onChangeLoading(false);
      if (showGlobalLoading) dispatch(setShowBackdrop(false));
      dispatch(LaunchError(error));
      return false;
    }
  };
const saveFacebookIntegration = (data) => ({
  type: types.GET_FACEBOOK_INTEGRATION,
  payload: data,
});
export const startRevokeFacebookAccount =
  ({ showGlobalLoading = false, onChangeLoading = () => {}, id }) =>
  async (dispatch) => {
    try {
      onChangeLoading(true);
      if (showGlobalLoading) dispatch(setShowBackdrop(true));

      window.FB.getLoginStatus(async (response) => {
        if (response.session) {
          window.FB.logout(
            dispatch(
              startRevokeFacebookAccount({
                showGlobalLoading,
                onChangeLoading,
              })
            )
          );
        } else {
          const token = await dispatch(renewToken());
          const { data: result } = await axios({
            method: "post",
            url: `${FUNCTIONS_URL}/revokeFacebookAccount`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: { id },
          });

          onChangeLoading(false);
          if (showGlobalLoading) dispatch(setShowBackdrop(false));

          if (result.ok) {
            dispatch(
              revokeFacebookIntegration({
                id,
              })
            );
            return true;
          }
          return false;
        }
      });
    } catch (error) {
      onChangeLoading(false);
      if (showGlobalLoading) dispatch(setShowBackdrop(false));
      dispatch(LaunchError(error));
      return false;
    }
  };
const revokeFacebookIntegration = (data) => ({
  type: types.REVOKE_FACEBOOK_INTEGRATION,
  payload: data,
});

export const startAddContentsToList = (data) => async (dispatch) => {
  try {
    const { userID } = dispatch(getPathDatabaseByRole());

    const updates = {};

    const returnData = {};

    data.forEach((item) => {
      const key = push(ref(db, `contentList/${userID}`)).key;
      const newData = {
        ...item,
        creationTime: new Date().getTime(),
        creationTimeNegative: -new Date().getTime(),
      };

      updates[`contentList/${userID}/${key}`] = newData;
      returnData[key] = newData;
    });

    await update(ref(db), updates);

    return returnData;
  } catch (error) {
    console.log(error);
    dispatch(LaunchError(error));
    return false;
  }
};

const getOrdersFormattedToList = (orders = {}) => {
  const keys = Object.keys(orders);

  const data = {};

  keys.forEach((key) => {
    const order = orders[key];
    const posts = order.posts;

    if (!posts) return;

    Object.keys(posts || {}).forEach((postKey) => {
      const url = posts[postKey];

      if (!url.includes("firebasestorage") && !url.includes("amazonaws"))
        return;
      const uniqueID = getUniqueID();
      data[uniqueID] = {
        url,
        from: "order",
        key: uniqueID,
      };
    });
  });

  return data;
};

export const startGetGalleryContent =
  ({
    lastKey,
    onChangeLastKey = () => {},
    limit = 15,
    onLoadLastItem = () => {},
  }) =>
  async (dispatch) => {
    try {
      const { userID, isUserPlatform } = dispatch(getPathDatabaseByRole());

      let q;

      if (isUserPlatform) {
        q = lastKey
          ? query(
              ref(db, `contentList/${userID}`),
              orderByChild("creationTimeNegative"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              ref(db, `contentList/${userID}`),
              orderByChild("creationTimeNegative"),
              limitToFirst(limit)
            );
      } else {
        q = lastKey
          ? query(
              ref(db, `shopsIDS/${userID}/orders`),
              orderByChild("creationTime"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              ref(db, `shopsIDS/${userID}/orders`),
              orderByChild("creationTime"),
              limitToFirst(limit)
            );
      }

      const snapshot = await get(q);

      if (!snapshot.exists()) {
        onLoadLastItem();
        return {};
      }

      if (isUserPlatform) {
        const data = snapshot.val();
        const values = Object.values(data);
        onChangeLastKey(values[0].creationTimeNegative);
        return data;
      }

      const snapshotSize = snapshot.size;
      const queriesOrders = [];
      let count = 1;

      snapshot.forEach((snapshot) => {
        const data = snapshot.val();

        if (count === snapshotSize && limit) onChangeLastKey(data.creationTime);

        const orderID = snapshot.key;
        queriesOrders.push(get(ref(db, `orders/${orderID}`)));
        count++;
      });

      const ordersSnapshots = await Promise.all(queriesOrders);
      let data = {};

      ordersSnapshots.forEach((orderSnapshot) => {
        const order = orderSnapshot.val();
        data[orderSnapshot.key] = order;
      });

      return getOrdersFormattedToList(data);
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startGetContentsFromList =
  ({
    lastKey,
    onChangeLastKey = () => {},
    limit = 15,
    onLoadLastItem = () => {},
  }) =>
  async (dispatch) => {
    try {
      let q;

      const { userID } = dispatch(getPathDatabaseByRole());

      q = lastKey
        ? query(
            ref(db, `contentList/${userID}`),
            orderByChild("creationTimeNegative"),
            startAfter(lastKey),
            limitToLast(limit)
          )
        : query(
            ref(db, `contentList/${userID}`),
            orderByChild("creationTimeNegative"),
            limitToLast(limit)
          );

      const snapshot = await get(q);

      if (!snapshot.exists()) {
        onLoadLastItem();
        return {};
      }

      const data = snapshot.val();
      const values = Object.values(data);

      onChangeLastKey(values[0].creationTimeNegative);

      return data;
    } catch (error) {
      onLoadLastItem(true);
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startConnectCanva =
  ({ onChangeLoading, onChangePopupReference = () => {} }) =>
  async (dispatch) => {
    try {
      onChangeLoading(true);
      const token = await dispatch(renewToken());
      const response = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/connectCanva`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onChangeLoading(false);
      if (response.data.ok) {
        const width = 500;
        const height = 700;
        const title = `Canva Auth`;
        const url = response.data.url;
        const popup = window.open(
          url,
          title,
          `width=${width},height=${height}`
        );
        onChangePopupReference(popup);
        return;
      }
      return false;
    } catch (error) {
      onChangeLoading(false);
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startGetTokenCanva = (data) => async (dispatch) => {
  const withAuth = data?.withAuth ?? false;
  try {
    let headers = {};
    if (withAuth) {
      const token = await dispatch(renewToken());
      headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/getTokenCanva`,
      data,
      headers,
    });

    if (result.ok) {
      if (withAuth) {
        dispatch(getCanvaIntegration(result.data));
      }
      return true;
    }
    return false;
  } catch (error) {
    dispatch(LaunchError(error, withAuth));
    return false;
  }
};
const getCanvaIntegration = (data) => ({
  type: types.GET_CANVA_INTEGRATION,
  payload: data,
});

export const startRevokeCanvaAccount = () => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/revokeCanva`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.ok) {
      dispatch(revokeCanvaIntegration());
      return true;
    }
    return false;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
const revokeCanvaIntegration = (data) => ({
  type: types.REVOKE_CANVA_INTEGRATION,
  payload: data,
});

export const startCreateCanvaDesign = () => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/createCanvaDesign`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.ok) {
      return result.data;
    }
    return false;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
export const startExportCanvaDesign =
  ({ designID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data: result } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/exportCanvaDesign`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          designID,
        },
      });
      if (result.ok) {
        return result?.data;
      }
      return false;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startConnectTiktokBusiness =
  ({ onChangeLoading, onChangePopupReference = () => {} }) =>
  async (dispatch) => {
    try {
      onChangeLoading(true);
      const token = await dispatch(renewToken());
      const response = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/connectTiktokBusiness`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          themeID: THEME_ID_PROJECT,
        },
      });
      onChangeLoading(false);
      if (response.data.ok) {
        const width = 500;
        const height = 700;
        const title = `Google Auth`;
        const url = response.data.url;
        const popup = window.open(
          url,
          title,
          `width=${width},height=${height}`
        );

        if (!popup || popup.closed || typeof popup.closed == "undefined") {
          SimpleAlert({
            title: i18next.t(WARNING),
            text: i18next.t(ALERT_POPUP_BLOCKED_DESCRIPTION),
            icon: ALERT_ICON_TYPE_WARNING,
          });
          return;
        }

        onChangePopupReference(popup);
        return;
      }
      return false;
    } catch (error) {
      onChangeLoading(false);
      dispatch(LaunchError(error));
      return false;
    }
  };
export const startGetTokenTiktokBusiness =
  (data) => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());

      const tikTokBusinessIntegrations =
        getState()?.user?.integrations?.tiktokBusiness;

      const { data: result } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/getTokenTiktokBusiness`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!result.ok) return { ok: false };

      if (_.isEmpty(tikTokBusinessIntegrations)) {
        dispatch(
          startSetLastSelectedIntegrationDataByPlatform({
            platform: TIKTOK_BUSINESS,
            integrationID: result?.data?.id,
            adAccountID: Object.keys(result?.data?.advertisers || {})[0],
          })
        );
      }

      dispatch(getTiktokBusinessIntegration(result.data));
      return { ok: true, data: result.data };
    } catch (error) {
      dispatch(LaunchError(error));

      return { ok: false };
    }
  };
const getTiktokBusinessIntegration = (data) => ({
  type: types.GET_TIKTOK_BUSINESS_INTEGRATION,
  payload: data,
});

export const startRevokeTiktokBusinessAccount =
  ({ id }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data: result } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/revokeTiktokBusinessAccount`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          id,
        },
      });
      if (result.ok) {
        dispatch(
          revokeTiktokBusinessIntegration({
            id,
          })
        );
        return true;
      }
      return false;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };
const revokeTiktokBusinessIntegration = (data) => ({
  type: types.REVOKE_TIKTOK_BUSINESS_INTEGRATION,
  payload: data,
});

export const startConnectGoogleAds =
  ({ onChangeLoading, onChangePopupReference = () => {} }) =>
  async (dispatch) => {
    try {
      onChangeLoading(true);
      const token = await dispatch(renewToken());
      const response = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/connectGoogleAds`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          themeID: THEME_ID_PROJECT,
        },
      });
      onChangeLoading(false);
      if (response.data.ok) {
        const width = 500;
        const height = 700;
        const title = `Google Auth`;
        const url = response.data.url;
        const popup = window.open(
          url,
          title,
          `width=${width},height=${height}`
        );

        if (!popup || popup.closed || typeof popup.closed == "undefined") {
          SimpleAlert({
            title: i18next.t(WARNING),
            text: i18next.t(ALERT_POPUP_BLOCKED_DESCRIPTION),
            icon: ALERT_ICON_TYPE_WARNING,
          });
          return;
        }

        onChangePopupReference(popup);
        return;
      }
      return false;
    } catch (error) {
      onChangeLoading(false);
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startGetTokenGoogleAds = (data) => async (dispatch, getState) => {
  try {
    const token = await dispatch(renewToken());

    const googleIntegrations = getState()?.user?.integrations?.google;

    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/getTokenGoogleAds`,
      data: {
        ...data,
        themeID: THEME_ID_PROJECT,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!result.ok) return { ok: false };

    if (_.isEmpty(googleIntegrations)) {
      dispatch(
        startSetLastSelectedIntegrationDataByPlatform({
          platform: GOOGLE,
          integrationID: result?.data?.id,
          adAccountID: Object.keys(result?.data?.advertisers || {})[0],
        })
      );
    }

    dispatch(getGoogleAdsIntegration(result.data));
    return { ok: true, data: result.data };
  } catch (error) {
    dispatch(LaunchError(error));

    return { ok: false };
  }
};
const getGoogleAdsIntegration = (data) => ({
  type: types.GET_GOOGLE_ADS_INTEGRATION,
  payload: data,
});

export const startRevokeGoogleAdsAccount =
  ({ id }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data: result } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/revokeGoogleAdsAccount`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          id,
        },
      });
      if (result.ok) {
        dispatch(revokeGoogleAdsIntegration({ id }));
        return true;
      }
      return false;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };
const revokeGoogleAdsIntegration = (data) => ({
  type: types.REVOKE_GOOGLE_ADS_INTEGRATION,
  payload: data,
});

export const startGetCustomLoginToken =
  ({ hash }) =>
  async (dispatch) => {
    try {
      const { data: result } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/getCustomLoginToken`,
        data: {
          hash,
        },
      });

      return {
        ok: result.ok,
        data: result.data,
      };
    } catch (error) {
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };

export const getUserIP = (data) => ({
  type: types.GET_USER_IP,
  payload: data,
});

export const startChangeAdvertiserIDCustomClaim =
  ({ advertiserID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/changeAdvertiserIDCustomClaim`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          advertiserID,
        },
      });

      if (data.ok) {
        await dispatch(renewToken());
        dispatch(changeAdvertiserIDCustomClaim(advertiserID));
        dispatch(globalResetAdvertiserData());
      }

      return data.ok;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startGetBusinessNameByAdvertiserIDs =
  ({ advertiserIDs }) =>
  async (dispatch) => {
    try {
      const queriesAdvertisers = [];
      advertiserIDs.forEach((advertiserID) => {
        queriesAdvertisers.push(
          get(ref(db, `advertisers/${advertiserID}/businessName`))
        );
      });
      const advertisersSnapshot = await Promise.all(queriesAdvertisers);

      let advertisers = {};

      advertisersSnapshot.forEach((advertiser, index) => {
        const advertiserData = advertiser.val();
        advertisers[advertiserIDs[index]] = advertiserData;
      });

      return advertisers;
    } catch (error) {
      console.log(error);
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startRenderVideoFromCreatify = (data) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/renderVideoFromCreatify`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result;
  } catch (error) {
    const errorFormatted = dispatch(LaunchError(error, true));
    return errorFormatted;
  }
};
export const startGetStatusVideoFromCreatify = (data) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/getStatusVideoFromCreatify`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};

export const startGenerateVideoPreviewCreatify =
  (data) => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const geoIP = getState().user.geoIP;
      const { data: result } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/generateVideoPreviewCreatify`,
        data: {
          ...data,
          userLocation: geoIP,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return result;
    } catch (error) {
      dispatch(LaunchError(error));
      return { ok: false };
    }
  };

export const startGetAvatarsCreatify = () => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/getAvatarsCreatify`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result;
  } catch (error) {
    dispatch(LaunchError(error));
    return { ok: false };
  }
};
export const startGenerateScriptAdVideo = (data) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/generateScriptAdVideo`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    return result;
  } catch (error) {
    dispatch(LaunchError(error));
    return { ok: false };
  }
};
export const startGetCreatifyVideoData = (data) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/getCreatifyVideoData`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    return result;
  } catch (error) {
    dispatch(LaunchError(error));
    return { ok: false };
  }
};

export const startGetAwsDownloadUrl = (urlValue) => async (dispatch) => {
  try {
    const ownBuckets = [
      import.meta.env.VITE_AWS_BUCKET_NAME,
      import.meta.env.VITE_AWS_BUCKET_NAME_STAGING,
      import.meta.env.VITE_AWS_BUCKET_ORDERS_NAME,
      import.meta.env.VITE_AWS_BUCKET_ORDERS_NAME_STAGING,
    ];

    const isOwnUrl = ownBuckets.find(
      (bucket) => urlValue.includes(bucket) && urlValue.includes("amazonaws")
    );

    if (!isOwnUrl) {
      await downloadContent(urlValue);
      return true;
    }

    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/generateAwsDownloadUrl`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        url: urlValue,
      },
    });
    if (result.ok) {
      const { url: downloadUrl } = result?.data || {};
      const anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.click();
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    await downloadContent(urlValue);
    return false;
  }
};

export const startManageUser = (data) => async (dispatch, getState) => {
  try {
    const token = await dispatch(renewToken());
    const agencyID = getState()?.auth?.user?.agencyID;
    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/manageAdsUser`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...data,
        password: encryptPassword(data?.password),
        agencyID,
      },
    });

    if (result.ok) {
      dispatch(
        manageUser({ userID: result?.data?.userID, data: result?.data?.data })
      );
    }
    return result.ok;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
const manageUser = (data) => ({
  type: types.MANAGE_USER,
  payload: data,
});
export const startDeleteUser = (userID) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/deleteAdsUser`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        userID,
      },
    });

    if (result.ok) {
      dispatch(deleteUser(userID));
    }
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
const deleteUser = (data) => ({
  type: types.DELETE_USER,
  payload: data,
});

export const startSaveMediaInStorage =
  ({ urls, path }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/saveMediaInStorage`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          urls,
          path,
        },
      });

      return data;
    } catch (error) {
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };

const changeAdvertiserIDCustomClaim = (data) => ({
  type: types.CHANGE_ADVERTISER_ID_CUSTOM_CLAIM,
  payload: data,
});

const globalResetAdvertiserData = () => ({
  type: types.GLOBAL_RESET_ADVERTISER_DATA,
});
