import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslationApp } from "../../../lib/i18next";
import {
  CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_DESCRIPTION_QUESTION,
  CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION,
  CREATE_ADS_CAMPAIGN_IDENTITIES_ACCOUNTS_QUESTION,
  CREATE_ADS_CAMPAIGN_TYPE_QUESTION,
  FIELD_REQUIRED,
  FIELDS_REQUIRED,
  CREATE_ADS_CAMPAIGN_GOOGLE_CONVERSION_ACTIONS_QUESTION,
  CREATE_ADS_CAMPAIGN_GOOGLE_CONVERSION_ACTIONS_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_VIDEO_URLS_QUESTION,
  CREATE_ADS_CAMPAIGN_IMAGE_URLS_QUESTION,
  CREATE_ADS_CAMPAIGN_IMAGE_URLS_DESCRIPTION_QUESTION,
  BUTTON_SELECT_CONTENT,
  FIELD_VIDEO_YOUTUBE_URL_INVALID,
} from "../../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_OPTION,
  ADS_CAMPAIGN_TYPE_SEARCH_OPTION,
  GOOGLE,
  GOOGLE_IMAGE_FORMATS,
} from "../../../utils/constants";
import { useDispatchApp } from "../../../lib/redux";
import { getCurrencyAdAccountPlatform } from "../../../actions/getters";
import _ from "lodash";
import IconButtonAddContent from "../../../components/Buttons/IconButtonAddContent";
import {
  formatMediaUrl,
  formatMediaUrlAdFromCreatify,
} from "../../../services/adsCampaigns";
import { getPathDatabaseByRole } from "../../../actions/auth";
import { REGEX_YOUTUBE_URL } from "../../../utils/regex";

export function useGenerateCampaignFormGoogle({
  campaignID,
  onCallbackSave = () => {},
}) {
  const STEPS = [
    "objective",
    "identities",
    "conversionActions",
    "destinationURL",
    "budget",
    "videoUrls",
    "imageUrls",
  ];
  const [error, setError] = useState(null);
  const { control, watch, setValue, getValues } = useForm({
    defaultValues: {
      step: 0,
      objective: ADS_CAMPAIGN_TYPE_SEARCH_OPTION,
      integrationID: "",
      adAccountID: "",
      conversionActions: [],
      destinationURL: "",
      typeBudget: ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
      budget: "",
      startDate: new Date(),
      endDate: null,
      imageUrls: [],
      videoUrls: [""],
    },
  });

  const step = watch("step");
  const objective = watch("objective");
  const typeBudget = watch("typeBudget");
  const integrationID = watch("integrationID");
  const adAccountID = watch("adAccountID");
  const imageUrls = watch("imageUrls");
  const utils = {
    control,
    setValue,
    watch,
    getValues,
  };

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const { userID } = dispatch(getPathDatabaseByRole());

  const actionsToValidAnswer = () => {
    setError(null);
  };

  const currency = dispatch(
    getCurrencyAdAccountPlatform({
      platform: GOOGLE,
      integrationID: integrationID,
      adAccountID: adAccountID,
    })
  );

  const onFinished = async () => {
    const formValues = getValues();

    if (!_.isEmpty(formValues.videoUrls)) {
      const videosFiltered = formValues.videoUrls.filter(Boolean);

      formValues.videoUrls = videosFiltered;
    }
    const requestObject = {
      objective: formValues.objective,
      integrationID: formValues.integrationID,
      adAccountID: formValues.adAccountID,
      conversionActions: formValues.conversionActions,
      destinationURL: formValues.destinationURL,
      typeBudget: formValues.typeBudget,
      budget: formValues.budget,
      startDate: _.isDate(formValues.startDate)
        ? new Date(formValues.startDate).getTime()
        : null,
      endDate: _.isDate(formValues.endDate)
        ? new Date(formValues.endDate).getTime()
        : null,
      imageUrls: formValues.imageUrls,
      videoUrls: _.isEmpty(formValues.videoUrls)
        ? []
        : formValues.videoUrls.map((videoUrl) => ({
            url: videoUrl,
          })),
      platform: GOOGLE,
    };

    onCallbackSave(requestObject);
  };

  const { steps, fields } = useMemo(() => {
    const fieldsConfig = {
      objective: {
        isVisible: () => true,
        isFinishQuestion: false,
        utils,
        name: "objective",
        title: CREATE_ADS_CAMPAIGN_TYPE_QUESTION,
        showActionsButtons: true,
        showPreviousButton: false,
        onPreviousStep: () => {
          actionsToValidAnswer();
        },
        showNextButton: true,
        onNextStep: () => {
          const objective = getValues("objective");
          if (!objective || objective?.length === 0) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },

      conversionActions: {
        isVisible: () => true,
        isFinishQuestion: false,
        utils,
        name: "conversionActions",
        title: CREATE_ADS_CAMPAIGN_GOOGLE_CONVERSION_ACTIONS_QUESTION,
        subtitle: CREATE_ADS_CAMPAIGN_GOOGLE_CONVERSION_ACTIONS_DESCRIPTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showSkipButton: true,
        onSkipStep: () => {
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
        showNextButton: true,
        onNextStep: () => {
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      identities: {
        isVisible: () => true,
        isFinishQuestion: false,
        utils,
        name: "identities",
        title: CREATE_ADS_CAMPAIGN_IDENTITIES_ACCOUNTS_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const integrationID = getValues("integrationID");
          const adAccountID = getValues("adAccountID");
          if (!adAccountID || !integrationID) {
            setError(t(FIELDS_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      destinationURL: {
        isVisible: () => true,
        isFinishQuestion: false,
        utils,
        name: "destinationURL",
        title: CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const destinationURL = getValues("destinationURL");
          if (!destinationURL) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      budget: {
        isVisible: () => true,
        isFinishQuestion: objective === ADS_CAMPAIGN_TYPE_SEARCH_OPTION,
        utils,
        name: "budget",
        title: CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION,
        subtitle: CREATE_ADS_CAMPAIGN_BUDGET_DESCRIPTION_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: objective === ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_OPTION,
        onNextStep: () => {
          const budget = getValues("budget");
          if (!budget) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
        onFinish: () => {
          const budget = getValues("budget");
          if (!budget) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          onFinished();
        },
      },
      imageUrls: {
        isVisible: () => objective === ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_OPTION,
        isFinishQuestion: true,
        disabledFinishButton: !imageUrls || imageUrls.length === 0,
        utils,
        name: "imageUrls",
        title: CREATE_ADS_CAMPAIGN_IMAGE_URLS_QUESTION,
        subtitle: CREATE_ADS_CAMPAIGN_IMAGE_URLS_DESCRIPTION_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: false,
        onFinish: () => {
          const imageUrls = getValues("imageUrls");
          if (!imageUrls || imageUrls?.length === 0) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          onFinished();
        },
        customComponentPreviousNext: (
          <Controller
            name="imageUrls"
            control={control}
            render={({ field }) => (
              <IconButtonAddContent
                mode="button"
                color="primary"
                size="large"
                pathStorage={`adsCampaigns/${userID}/`}
                pathRTDB={`adsContentsUploaded/${userID}/${campaignID}`}
                modeUpload="async"
                onAddContent={(contents) => {
                  const contentsFormatted = contents.map((content) =>
                    formatMediaUrl(content)
                  );
                  field.onChange([...field.value, ...contentsFormatted]);
                }}
                onCallbackSaveAI={(videoData) => {
                  field.onChange([
                    ...field.value,
                    formatMediaUrlAdFromCreatify(videoData),
                  ]);
                }}
                multiple={true}
                acceptedFormats={GOOGLE_IMAGE_FORMATS}
                textButton={BUTTON_SELECT_CONTENT}
                sx={{
                  alignItems: "flex-start",
                  width: { xs: "100%", md: "auto" },
                }}
                sxButton={{
                  height: 40,
                  fontSize: "0.875rem",
                  width: "100%",
                }}
                propsPostsAdsPlatforms={{
                  integrationID: getValues("integrationID"),
                  adAccountID: getValues("adAccountID"),
                }}
              />
            )}
          />
        ),
      },
      videoUrls: {
        isVisible: () => objective === ADS_CAMPAIGN_TYPE_PERFORMANCE_MAX_OPTION,
        isFinishQuestion: false,
        utils,
        name: "videoUrls",
        title: CREATE_ADS_CAMPAIGN_VIDEO_URLS_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showSkipButton: true,
        onSkipStep: () => {
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const videoUrls = getValues("videoUrls");
          if (!videoUrls || videoUrls?.length === 0) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          const someVideoUrlIsInvalid = videoUrls.some((videoUrl) => {
            return !REGEX_YOUTUBE_URL.test(videoUrl);
          });
          if (someVideoUrlIsInvalid) {
            setError(t(FIELD_VIDEO_YOUTUBE_URL_INVALID));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
    };

    const stepsVisible = STEPS.filter((step) => fieldsConfig[step].isVisible());

    return {
      steps: stepsVisible,
      fields: fieldsConfig,
    };

    // eslint-disable-next-line
  }, [step, objective, typeBudget, imageUrls]);

  const currentAnswer = steps[step];
  const totalSteps = steps.length;
  const progress = Math.round(((step + 1) / totalSteps) * 100);

  return { steps, fields, utils, currentAnswer, error, progress, currency };
}
