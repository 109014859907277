import { useFormContext } from "react-hook-form";
import {
  getFromStatisticsAdsCampaign,
  getLabelCount,
  METRICS_TO_CONVERT_TO_MAIN_CURRENCY,
  sumStatisticsAdsCampaign,
} from "../../../../services/adsCampaigns";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getUniqueID } from "../../../../utils/numbers";
import {
  ADS_CAMPAIGN_STATISTICS_PLATFORMS_CPA,
  ADS_CAMPAIGN_STATISTICS_PLATFORMS_CPC,
  ADS_CAMPAIGN_STATISTICS_PLATFORMS_CPM,
  ADS_CAMPAIGN_STATISTICS_SLIDE_MENU_OVERVIEW,
  ADS_CAMPAIGNS_STATISTICS_PERFORMANCE_TRENDS,
  CLICKS,
  DATE_DAY_AND_MONTH_SHORT,
  IMPRESSIONS,
  RESULTS,
  TOTAL_CLICKS,
  TOTAL_IMPRESSIONS,
  TOTAL_RESULTS,
  TOTAL_SPEND,
  VALUE_NUMBER,
  VALUE_WITH_CURRENCY_MANUAL_FORMAT,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import { COLORS_BY_TYPE_STATISTIC } from "../../../../utils/colors";
import { BORDER_RADIUS } from "../../../../utils/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LineChart from "../../../../components/Charts/LineChart";
import useResizeItemsWithSameHeight from "../../../../hooks/useResizeItemsWithSameHeight";
import { numberDecimal } from "../../../../utils/numbers.js";

const MAP_STATISTICS_COMPARISON_BY_METRIC = {
  cpm: ADS_CAMPAIGN_STATISTICS_PLATFORMS_CPM,
  cpc: ADS_CAMPAIGN_STATISTICS_PLATFORMS_CPC,
  costPerMainAction: ADS_CAMPAIGN_STATISTICS_PLATFORMS_CPA,
  impressions: IMPRESSIONS,
  click: CLICKS,
  mainAction: RESULTS,
};

const grids = {
  xs: 12,
  sm: 6,
  lg: 4,
};

const OverviewStatistics = () => {
  const { campaign } = useFormContext();
  const { platforms, statistics, historical } =
    getFromStatisticsAdsCampaign(campaign);
  const { t } = useTranslationApp();

  return (
    <Box
      id="overview-section"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        gap: 2,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
        }}
      >
        {t(ADS_CAMPAIGN_STATISTICS_SLIDE_MENU_OVERVIEW)}
      </Typography>
      <TotalMetrics statistics={statistics} platforms={platforms} />
      <TotalMetricsTrends historical={historical} />
      <ComparisonBetweenPlatforms
        statistics={statistics}
        historical={historical}
      />
    </Box>
  );
};

const TotalMetrics = ({ statistics, platforms }) => {
  const { t } = useTranslationApp();
  const { getCostByMainCurrency } = useFormContext();

  const getTotalSpendAndCurrencyPlatform = () => {
    let totalSpend = 0;
    let totalCurrency = "";

    platforms.forEach((platform) => {
      const { cost, currency } = getCostByMainCurrency({
        cost: statistics?.[platform]?.campaign?.spend,
        currency: statistics?.[platform]?.campaign?.currency,
      });
      totalSpend += cost;
      totalCurrency = currency;
    });

    return {
      spend: totalSpend,
      currency: totalCurrency,
    };
  };

  const getTotalStatistics = () => {
    let totalImpressions = 0;
    let totalClicks = 0;
    let totalMainActions = 0;

    Object.keys(statistics).forEach((platform) => {
      totalImpressions += statistics?.[platform]?.campaign?.impressions;
      totalClicks += statistics?.[platform]?.campaign?.click;
      totalMainActions += statistics?.[platform]?.campaign?.mainAction;
    });

    return {
      impressions: totalImpressions,
      clicks: totalClicks,
      mainActions: totalMainActions,
    };
  };

  const { impressions, clicks, mainActions } = getTotalStatistics();
  const totalMetrics = [
    {
      value: impressions,
      label: t(TOTAL_IMPRESSIONS),
      icon: <VisibilityIcon sx={{ width: 20, height: 20 }} />,
    },
    {
      value: clicks,
      label: t(TOTAL_CLICKS),
      icon: <AdsClickIcon sx={{ width: 20, height: 20 }} />,
    },
    {
      value: mainActions,
      label: t(TOTAL_RESULTS),
      icon: <CheckCircleIcon sx={{ width: 20, height: 20 }} />,
    },
  ];

  const { spend, currency } = getTotalSpendAndCurrencyPlatform();

  return (
    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
      {totalMetrics.map((metric) => (
        <ContainerStatistic key={getUniqueID()}>
          <TypographyLabelStatistic>{metric.label}</TypographyLabelStatistic>
          <TypographyValueStatistic>
            {t(VALUE_NUMBER, { value: metric.value })}
          </TypographyValueStatistic>
        </ContainerStatistic>
      ))}
      <ContainerStatistic key={getUniqueID()}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
          }}
        >
          <TypographyLabelStatistic>{t(TOTAL_SPEND)}</TypographyLabelStatistic>
        </Box>
        <TypographyValueStatistic>
          {`${t(VALUE_WITH_CURRENCY_MANUAL_FORMAT, {
            value: spend,
            currency,
          })}`}
        </TypographyValueStatistic>
      </ContainerStatistic>
    </Box>
  );
};

const TotalMetricsTrends = ({ historical }) => {
  const { t } = useTranslationApp();
  const { watch, reportConfiguration } = useFormContext();
  const startDateTime = new Date(reportConfiguration?.startDateTime);
  const endDateTime = new Date(reportConfiguration?.endDateTime);
  const breakdown = watch("breakdown");

  const label = getLabelCount(breakdown);

  const getWeeklyMetrics = () => {
    let chartConfig = {};
    let chartData = [];

    let dataProcessed = {};

    if (breakdown === "monthly") {
      const dataKeys = Object.keys(historical);
      const batchSize = 4;
      for (let i = 0; i < dataKeys.length; i += batchSize) {
        const batch = dataKeys.slice(i, i + batchSize);
        let snapshot = {};
        for (let j = 0; j < batchSize; j++) {
          if (!batch[j]) break;

          const dataSnapshot = historical?.[batch?.[j]];

          if (j === 0) {
            snapshot.startDateTime = historical?.[batch?.[j]]?.startDateTime;
          }
          if (j === batchSize - 1) {
            snapshot.endDateTime = historical?.[batch?.[j]]?.endDateTime;
          }

          const platformsStatistics = Object.keys(dataSnapshot).filter(
            (key) => key !== "startDateTime" && key !== "endDateTime"
          );

          for (const platform of platformsStatistics) {
            snapshot[platform] = {
              campaign: sumStatisticsAdsCampaign({
                current: snapshot?.[platform]?.campaign,
                addition: dataSnapshot?.[platform]?.campaign,
              }),
            };
          }
        }

        dataProcessed[dataKeys[i]] = snapshot;
      }
    } else {
      dataProcessed = historical;
    }

    Object.keys(dataProcessed).forEach((snapshotKey, index) => {
      const snapshot = dataProcessed[snapshotKey] || {};
      let totalImpressions = 0;
      let totalClicks = 0;
      let totalMainActions = 0;
      Object.keys(snapshot).forEach((platform) => {
        if (platform !== "startDateTime" && platform !== "endDateTime") {
          const campaignStats = snapshot[platform]?.campaign;
          if (campaignStats) {
            totalImpressions +=
              numberDecimal(campaignStats.impressions / 1000, 1) || 0;
            totalClicks += campaignStats.click || 0;
            totalMainActions += campaignStats.mainAction || 0;
          }
        }
      });

      const currentChartData = {
        label: t(label, {
          value: index + 1,
        }),
        impressions: totalImpressions,
        clicks: totalClicks,
        mainActions: totalMainActions,
      };

      chartData.push(currentChartData);
    });

    chartConfig = {
      impressions: {
        label: t(IMPRESSIONS),
        color: "hsl(var(--chart-1))",
        hasLabelList: false,
        valueFormatter: (value) => `${value}K`,
      },
      clicks: {
        label: t(CLICKS),
        color: "hsl(var(--chart-2))",
        hasLabelList: false,
      },
      mainActions: {
        label: t(RESULTS),
        color: "hsl(var(--chart-3))",
        hasLabelList: false,
      },
    };

    const title = t(ADS_CAMPAIGNS_STATISTICS_PERFORMANCE_TRENDS);
    const subtitle = `${t(DATE_DAY_AND_MONTH_SHORT, {
      date: startDateTime,
    })} - ${t(DATE_DAY_AND_MONTH_SHORT, {
      date: endDateTime,
    })}`;

    return {
      chartConfig,
      chartData,
      title,
      subtitle,
      xAxisKey: "label",
    };
  };

  const weeklyMetrics = getWeeklyMetrics();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
      }}
    >
      <LineChart
        chartConfig={weeklyMetrics.chartConfig}
        chartData={weeklyMetrics.chartData}
        hasCartesianGrid={false}
        xAxisKey={weeklyMetrics.xAxisKey}
        title={weeklyMetrics.title}
        subtitle={weeklyMetrics.subtitle}
        valueFormat={{
          translation: VALUE_NUMBER,
          key: "value",
        }}
        sx={{
          width: "100%",
        }}
        height={250}
      />
    </Box>
  );
};

const ComparisonBetweenPlatforms = ({ historical, statistics }) => {
  const refs = useResizeItemsWithSameHeight();
  const { reportConfiguration, watch, getCostByMainCurrency } =
    useFormContext();
  const startDateTime = new Date(reportConfiguration?.startDateTime);
  const endDateTime = new Date(reportConfiguration?.endDateTime);
  const breakdown = watch("breakdown");

  const { t } = useTranslationApp();

  const label = getLabelCount(breakdown);

  const getChartConfigAndDataByMetric = (metric) => {
    let chartConfig = {};
    let chartData = [];

    let dataProcessed = {};

    if (breakdown === "monthly") {
      const dataKeys = Object.keys(historical);
      const batchSize = 4;
      for (let i = 0; i < dataKeys.length; i += batchSize) {
        const batch = dataKeys.slice(i, i + batchSize);
        let snapshot = {};
        for (let j = 0; j < batchSize; j++) {
          if (!batch[j]) break;

          const dataSnapshot = historical?.[batch?.[j]];

          if (j === 0) {
            snapshot.startDateTime = historical?.[batch?.[j]]?.startDateTime;
          }
          if (j === batchSize - 1) {
            snapshot.endDateTime = historical?.[batch?.[j]]?.endDateTime;
          }

          const platformsStatistics = Object.keys(dataSnapshot).filter(
            (key) => key !== "startDateTime" && key !== "endDateTime"
          );

          for (const platform of platformsStatistics) {
            snapshot[platform] = {
              campaign: sumStatisticsAdsCampaign({
                current: snapshot?.[platform]?.campaign,
                addition: dataSnapshot?.[platform]?.campaign,
              }),
            };
          }
        }

        dataProcessed[dataKeys[i]] = snapshot;
      }
    } else {
      dataProcessed = historical;
    }

    Object.keys(dataProcessed).forEach((snapshotKey, index) => {
      const snapshot = dataProcessed[snapshotKey] || {};
      const platformsValue = {};
      Object.keys(snapshot).forEach((platform, indexPlatform) => {
        if (platform !== "startDateTime" && platform !== "endDateTime") {
          const campaignStats = snapshot[platform]?.campaign;
          if (campaignStats) {
            if (METRICS_TO_CONVERT_TO_MAIN_CURRENCY.includes(metric)) {
              const value = campaignStats[metric];
              const { cost } = getCostByMainCurrency({
                cost: value,
                currency: campaignStats?.currency,
              });
              platformsValue[platform] = cost;
            } else {
              platformsValue[platform] = campaignStats[metric];
            }
            chartConfig[platform] = {
              label: t(platform),
              color: `hsl(var(--chart-${indexPlatform + 1}))`,
              hasLabelList: false,
            };
          }
        }
      });

      const currentChartData = {
        label: t(label, {
          value: index + 1,
        }),
        ...platformsValue,
      };

      chartData.push(currentChartData);
    });

    const title = t(MAP_STATISTICS_COMPARISON_BY_METRIC[metric]);
    const subtitle = `${t(DATE_DAY_AND_MONTH_SHORT, {
      date: startDateTime,
    })} - ${t(DATE_DAY_AND_MONTH_SHORT, {
      date: endDateTime,
    })}`;

    return {
      chartConfig,
      chartData,
      title,
      subtitle,
      xAxisKey: "label",
    };
  };

  const chartCpm = getChartConfigAndDataByMetric("cpm");
  const chartCpc = getChartConfigAndDataByMetric("cpc");
  const chartCpa = getChartConfigAndDataByMetric("costPerMainAction");

  return (
    <Grid container spacing={2}>
      <Grid item {...grids}>
        <LineChart
          ref={(el) => (refs.current[0] = el)}
          chartConfig={chartCpm.chartConfig}
          chartData={chartCpm.chartData}
          hasCartesianGrid={false}
          labelKey="label"
          valueKey="value"
          valueFormat={{
            translation: VALUE_NUMBER,
            key: "value",
          }}
          title={chartCpm.title}
          subtitle={chartCpm.subtitle}
          height={250}
          customComponentDetail={
            <DetailComparisonBetweenPlatforms
              statistics={statistics}
              metric="cpm"
            />
          }
        />
      </Grid>
      <Grid item {...grids}>
        <LineChart
          ref={(el) => (refs.current[1] = el)}
          chartConfig={chartCpc.chartConfig}
          chartData={chartCpc.chartData}
          hasCartesianGrid={false}
          labelKey="label"
          valueKey="value"
          valueFormat={{
            translation: VALUE_NUMBER,
            key: "value",
          }}
          title={chartCpc.title}
          subtitle={chartCpc.subtitle}
          height={250}
          customComponentDetail={
            <DetailComparisonBetweenPlatforms
              statistics={statistics}
              metric="cpc"
            />
          }
        />
      </Grid>
      <Grid item {...grids}>
        <LineChart
          ref={(el) => (refs.current[2] = el)}
          chartConfig={chartCpa.chartConfig}
          chartData={chartCpa.chartData}
          hasCartesianGrid={false}
          labelKey="label"
          valueKey="value"
          valueFormat={{
            translation: VALUE_NUMBER,
            key: "value",
          }}
          title={chartCpa.title}
          subtitle={chartCpa.subtitle}
          height={250}
          customComponentDetail={
            <DetailComparisonBetweenPlatforms
              statistics={statistics}
              metric="costPerMainAction"
            />
          }
        />
      </Grid>
    </Grid>
  );
};

const DetailComparisonBetweenPlatforms = ({ statistics, metric }) => {
  const { t } = useTranslationApp();

  const metricValueByMetricCost = {
    cpm: "impressions",
    cpc: "click",
    costPerMainAction: "mainAction",
  };

  const platforms = Object.keys(statistics);

  return (
    <Box>
      <Typography variant="body2" align="right">
        {t(
          MAP_STATISTICS_COMPARISON_BY_METRIC[metricValueByMetricCost[metric]]
        )}
      </Typography>
      {platforms.map((platform) => {
        return (
          <Box
            key={getUniqueID()}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography
              variant="caption"
              align="right"
              color="fields.placeholder"
            >
              {`${t(platform)}:`}
            </Typography>
            <Typography variant="caption" align="right">
              {t(VALUE_NUMBER, {
                value:
                  statistics?.[platform].campaign?.[
                    metricValueByMetricCost[metric]
                  ],
              })}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const TypographyValueStatistic = ({ children, type }) => {
  const color = COLORS_BY_TYPE_STATISTIC[type];
  return (
    <Typography
      variant="h5"
      component="p"
      align="left"
      sx={{
        fontWeight: "bold",
        color,
      }}
    >
      {children}
    </Typography>
  );
};
const TypographyLabelStatistic = ({ children }) => {
  return (
    <Typography variant="body2" align="left" color="fields.placeholder">
      {children}
    </Typography>
  );
};

const ContainerStatistic = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        backgroundColor: "background.paperSecondary",
        borderRadius: BORDER_RADIUS,
        p: 2,
        width: 250,
      }}
    >
      {children}
    </Box>
  );
};

export default OverviewStatistics;
