import { Controller } from "react-hook-form";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import i18next from "i18next";
import {
  FIELD_REQUIRED,
  PRESS_ENTER_TO_ADD_OPTION,
} from "../../i18n/keysTranslations";
import { getReactHookFormNestedError } from "../../utils/errors";
import { useTranslationApp } from "../../lib/i18next";

const filter = createFilterOptions();

const AutocompleteField = ({
  control,
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  id,
  errors = {},
  label = "",
  name = "",
  value = [],
  fullWidth = true,
  helperText,
  disabled = false,
  placeholder = "",
  limitTags = 2,
  sx,
  className = "",
  color = "primary",
  variant = "filled",
  InputProps = {},
  InputLabelProps = {},
}) => {
  const { t } = useTranslationApp();
  const error = getReactHookFormNestedError(errors, name);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          id={id}
          limitTags={limitTags}
          value={field.value || []}
          disabled={disabled}
          onChange={(_, newValue) => {
            // Handle both string values and objects with inputValue
            const processedValues = newValue.map((item) => {
              if (typeof item === "string") return item.toLowerCase();
              if (item.inputValue) return item.inputValue.toLowerCase();
              return item;
            });
            field.onChange(processedValues);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;

            // Show "Press enter to add option" message instead of the value
            if (inputValue !== "") {
              filtered.push({
                inputValue,
                label: t(PRESS_ENTER_TO_ADD_OPTION),
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            // Show original value for existing chips
            if (option.inputValue) {
              return option.inputValue;
            }
            return option;
          }}
          renderOption={(props, option) => (
            <li {...props}>{option.label || option}</li>
          )}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={[]}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              fullWidth={fullWidth}
              placeholder={placeholder}
              error={!!error}
              helperText={error?.message || helperText}
              variant={variant}
              color={color}
              className={className}
              InputProps={{
                ...params.InputProps,
                ...InputProps,
              }}
              InputLabelProps={InputLabelProps}
              sx={{
                ...sx,
                ".MuiFormHelperText-root": {
                  mx: 0,
                },
              }}
            />
          )}
        />
      )}
    />
  );
};

export default AutocompleteField;
