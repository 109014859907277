import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import {
  ADS_CAMPAIGN_STATUS_DRAFT,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  GOOGLE,
} from "../../utils/constants";
import { logoPlatform } from "../../utils/string";
import { useTranslationApp } from "../../lib/i18next";
import {
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME,
  ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_DESCRIPTION,
  ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_TITLE,
  DATE_SHORT_LOCALIZED,
  VALUE_WITH_CURRENCY,
} from "../../i18n/keysTranslations";
import Typography from "@mui/material/Typography";
import { getCurrencyAdAccountPlatform } from "../../actions/getters";

const translations = {
  [ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION]: ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME,
  [ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION]: ADS_CAMPAIGN_TYPE_BUDGET_DAILY,
};

const ContainerBudgetAlert = ({ campaign, dispatch }) => {
  const { t } = useTranslationApp();
  const getBudgetsInformation = () => {
    const platforms = campaign.platforms || [];
    const budgetsArray = [];

    platforms.forEach((platform) => {
      const status = campaign?.states?.[platform];
      if (status !== ADS_CAMPAIGN_STATUS_DRAFT) return;

      const platformData = campaign.platformsData?.[platform] || {};

      if (platform === GOOGLE) {
        const currency = dispatch(
          getCurrencyAdAccountPlatform({
            platform,
            integrationID: platformData.integrationID,
            adAccountID: platformData.adAccountID,
          })
        );
        const googlePlatformData = campaign.platformsData?.[GOOGLE] || {};
        budgetsArray.push({
          platform,
          budget: googlePlatformData.budget || 0,
          typeBudget: googlePlatformData.typeBudget,
          startDate: googlePlatformData.startDate,
          endDate: googlePlatformData.endDate,
          currency,
        });
        return;
      }

      const adsGroups = campaign.adsGroups || {};

      Object.keys(adsGroups).forEach((key) => {
        const adsGroup = adsGroups[key];

        if (adsGroup.platform === platform) {
          const currency = dispatch(
            getCurrencyAdAccountPlatform({
              platform,
              integrationID: platformData.integrationID,
              adAccountID: platformData.adAccountID,
            })
          );
          budgetsArray.push({
            platform,
            budget: adsGroup.budget || 0,
            typeBudget: adsGroup.typeBudget,
            startDate: adsGroup.startDate,
            endDate: adsGroup.endDate,
            currency,
          });
        }
      });
    });

    return budgetsArray;
  };

  const budgetsInformation = getBudgetsInformation();
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t(ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_TITLE)}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 3,
        }}
      >
        {t(ALERT_ADS_CAMPAIGN_BUDGET_CONFIRM_DESCRIPTION)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {budgetsInformation.map((budget, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Avatar
                  src={logoPlatform(budget.platform)}
                  variant="square"
                  sx={{
                    width: "20px",
                    height: "20px",
                    mr: 1,
                    ".MuiAvatar-img": {
                      objectFit: "contain",
                    },
                  }}
                />
                <Typography variant="body1">
                  {t(VALUE_WITH_CURRENCY, {
                    value: budget.budget,
                    currency: budget.currency,
                  })}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="body2">
                  {t(translations[budget.typeBudget])}
                </Typography>
                {budget.typeBudget ===
                  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION && (
                  <Typography
                    variant="body2"
                    textAlign={"left"}
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`${t(DATE_SHORT_LOCALIZED, {
                      date: new Date(budget.startDate),
                    })} - ${t(DATE_SHORT_LOCALIZED, {
                      date: new Date(budget.endDate),
                    })}`}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ContainerBudgetAlert;
